import React from "react";
import {Box, Collapse, createStyles, Grid, MenuItem, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../App/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendCheckbox from "../shared/components/AscendCheckbox";
import AscendButton from "../shared/components/AscendButton";
import PersonalDetailsConfirmModal from "./component/PersonalDetailsConfirmModal";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import {EnrollmentInput, PreferredLanguageDto} from "../enrollment-types";
import moment from "moment";
import * as _ from 'lodash';
import {client} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import useGetCounty from "../Questionnaire/hooks/useGetCounty";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import useUserProfile, {useUserProfileLazy} from "../Private/hooks/useUserProfile";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import MedicareNumberInput from "../shared/components/MedicareNumberInput";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    }
  })
);
const formName = 'EnrollmentForm';


const Schema = yup.object().shape({
  city: yup.string().required("Enter a valid city"),
  aptNumber: yup.string().required("Enter a valid Apt./Unit"),
  street: yup.string().required("Enter a valid street"),
  phoneNumber: yup.string(),
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
  mailAptNumber: yup.string(),
  mailStreet: yup.string(),
  mailCity: yup.string(),
  mailZip: yup.string().matches(/^(\s*|[0-9]{5})$/, 'Please enter a valid zip code'),
  email: yup.string().email('Enter a valid email address'),
});

interface PersonalDetailsFormState {
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  street: string;
  mailZip: string;
  mailEmail: string;
  mailAptNumber: string;
  mailCity: string;
  mailStreet: string;
  phoneNumber: string;
}

function EnrollmentForm2(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [communicationPreference, setCommunicationPreference] = React.useState<string>();
  const [state, setState] = React.useState<string>('');
  const [mailState, setMailState] = React.useState<string>('');
  const [preferredLanguage, setPreferredLanguage] = React.useState<PreferredLanguageDto>(PreferredLanguageDto.English);
  const [mailingDifferent, setMailingDifferent] = React.useState(false);
  const [isElectronicallyCommunications, setIsElectronicallyCommunications] = React.useState(false);
  const history = useHistory();
  const [save] = useSaveEnrollment();
  const [getCounty, countyData] = useGetCounty();
  const [getMailCounty, mailCountyData] = useGetCounty();
  const [getUserInfo, userInfoData] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);
  const [county, setCounty] = React.useState<string | undefined>('');
  const [mailCounty, setMailCounty] = React.useState<string | undefined>('');

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  React.useEffect(() => {
    if (zip) {
      getCounty({variables: {zip}})
    }
  }, [zip])

  const permanentStates = React.useMemo(() => {
    return _.uniq(countyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [countyData.data?.plans_zip_county_fips])

  React.useEffect(() => {
    if (getValues().mailZip) {
      getMailCounty({variables: {zip: getValues().mailZip}})
    }
  }, [getValues().mailZip])

  const mailStates = React.useMemo(() => {
    return _.uniq(mailCountyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [mailCountyData.data?.plans_zip_county_fips])

  const [getPlan, {data}] = usePlanLazy({ client });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const counties = React.useMemo(() => {
    return countyData.data?.plans_zip_county_fips || [];
  }, [countyData.data, countyData.called])

  const mailCounties = React.useMemo(() => {
    return mailCountyData.data?.plans_zip_county_fips || [];
  }, [mailCountyData.data, mailCountyData.called])

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (formState.isValid && state) {
      clearErrors()
      /*save({variables: {data: values}}).then(data => {
        setStoredUser({
          ...getStoredUser(),
          enrollmentId: data?.data?.saveEnrollment,
        })
      });*/
      props.history.push(paths.personalDetails3 + "/" + planId + "/" + zip + "/" + countyName)
    }
  };

  React.useEffect(() => {
    if (authenticated) {
      getUserInfo();
    }
  }, [authenticated])

  React.useEffect(() => {
    if (userInfoData.data?.userProfile) {
      setState(userInfoData.data.userProfile.state || '');
    }
  }, [userInfoData])

  const userInfo = React.useMemo(() => {
    if (authenticated) {
      return userInfoData.data?.userProfile;
    } else if (getStoredUser()) {
      return getStoredUser()
    }
    return undefined
  }, [authenticated, userInfoData])

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800}>
    {plan && <div className={'mb-40'}><ShortPlanCard plan={plan}/></div>}
    <Typography className={'bold fs-26'} color={'textPrimary'}>Contact information</Typography>
    <Typography className={'medium fs-18'} color={'textPrimary'}>We need to collect your contact information so that your insurance company can reach you with important enrollment and benefits information.</Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography className={'bold fs-24'} color={'textPrimary'}>Permanent address</Typography>
            <Typography className={'fs-16 mb-15'} color={'textPrimary'}>Please enter your permanent address (this is usually where you’re registered vote).</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'street'}
                           defaultValue={userInfoData.data?.userProfile?.streetName}
                           error={formState.isSubmitted && !!errors.street}
                           helperText={formState.isSubmitted && errors?.street?.message}
                           inputRef={register}
                           placeholder={'Enter street address'}
                           label={'Street Address (P.O. box not allowed)*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'aptNumber'}
                           defaultValue={userInfoData.data?.userProfile?.aptNumber}
                           error={formState.isSubmitted && !!errors.aptNumber}
                           helperText={formState.isSubmitted && errors?.aptNumber?.message}
                           inputRef={register}
                           placeholder={'Enter apt. / unit'}
                           label={'Apt. / Unit*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'city'}
                           defaultValue={userInfoData.data?.userProfile?.city}
                           error={formState.isSubmitted && !!errors.city}
                           helperText={formState.isSubmitted && errors?.city?.message}
                           inputRef={register}
                           placeholder={'Enter city'}
                           label={'City*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'State*'}
              value={state}
              placeholder={'Select state'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setState(event.target.value as string);
              }}
            >
              {permanentStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'County*'}
              value={county}
              disabled={!counties.length}
              placeholder={'Select county'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCounty(event.target.value as string);
              }}
            >
              {
                counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
              }
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           fullWidth
                           inputRef={register}
                           label={'Zip*'}
                           name={'zip'}
                           value={zip}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter 5 digit zip code'} />
          </Grid>

          <Grid item xs={12}>
            <Box ml={'-14px'} mt={'-25px'}>
              <AscendCheckbox variant={'standart'}
                          label={'My mailing address is different to my permanent address.'}
                          checked={mailingDifferent}
                          onChange={() => setMailingDifferent(!mailingDifferent)}

              />
            </Box>
            <Collapse in={mailingDifferent}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AscendDivider className={classes.divider} mb={'23px'} />
                  <Typography className={'bold fs-24 mb-5'} color={'textPrimary'}>Mailing address</Typography>
                  <Typography className={'fs-16 mb-15'} color={'textPrimary'}>Add your mailing address or P.O. box here, if applicable</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'mailStreet'}
                                 error={formState.isSubmitted && !!errors.mailStreet}
                                 helperText={formState.isSubmitted && errors?.mailStreet?.message}
                                 inputRef={register}
                                 placeholder={'Enter street address'}
                                 label={'Street Address (or P.O. box)'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'mailAptNumber'}
                                 error={formState.isSubmitted && !!errors.mailAptNumber}
                                 helperText={formState.isSubmitted && errors?.mailAptNumber?.message}
                                 inputRef={register}
                                 placeholder={'Enter apt / unit'}
                                 label={'Apt. / Unit'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'mailCity'}
                                 error={formState.isSubmitted && !!errors.mailCity}
                                 helperText={formState.isSubmitted && errors?.mailCity?.message}
                                 inputRef={register}
                                 placeholder={'Enter city'}
                                 label={'City'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelectInput
                    fullWidth
                    label={'State'}
                    value={mailState}
                    placeholder={'Select state'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setMailState(event.target.value as string);
                    }}
                  >
                    {mailStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                  </FormSelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelectInput
                    fullWidth
                    label={'County'}
                    value={mailCounty}
                    disabled={!counties.length}
                    placeholder={'Select county'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setMailCounty(event.target.value as string);
                    }}
                  >
                    {
                      mailCounties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
                    }
                  </FormSelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput error={formState.isSubmitted && !!errors.mailZip}
                                 helperText={formState.isSubmitted && errors?.mailZip?.message}
                                 fullWidth
                                 inputRef={register}
                                 label={'Zip'}
                                 name={'mailZip'}
                                 inputProps={{
                                   maxLength: 5
                                 }}
                                 type={'tel'}
                                 placeholder={'Enter zip'} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'23px'} />
            <Typography className={'bold fs-24 mb-5'} color={'textPrimary'}>Contact information</Typography>
          </Grid>

          <Grid item xs={12} sm={6} alignItems={'flex-end'} className={'flex'}>
            <FormTextInput fullWidth
                           label={<>Email address<br/><span className={'fs-12 thin'}>By providing your email address, you authorize your plan to send you health information to this address.</span></>}
                           name={'email'}
                           defaultValue={userInfo?.email}
                           inputProps={{
                             autoCapitalize: 'none'
                           }}
                           placeholder={'Enter your email address (optional)'}
                           error={!!errors.email && formState.isSubmitted}
                           helperText={formState.isSubmitted && errors?.email?.message}
                           inputRef={register} />
          </Grid>
          <Grid item xs={12} sm={6} alignItems={'flex-end'} className={'flex'}>
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ onChange }) => <PhoneNumberInput label={<>Phone number<br/><span className={'fs-12 thin'}>There may be times when your plan will use an automated system to call or text you. When that happens we will be sure to use the telephone number you provided.</span></>}
                                                          name={'phoneNumber'}
                                                          onChange={onChange} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Box ml={'-14px'} mt={'-25px'}>
              <AscendCheckbox variant={'standart'}
                              label={'Go digital! I would like to receive all plan communications electronically.'}
                              checked={isElectronicallyCommunications}
                              onChange={() => setIsElectronicallyCommunications(!isElectronicallyCommunications)}

              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box mt={'20px'}>
              <FormSelectInput
                fullWidth
                label={'Preferred language'}
                error={formState.isSubmitted && !preferredLanguage}
                helperText={formState.isSubmitted ? "Select a language (optional)" : undefined}
                value={preferredLanguage}
                placeholder={'English'}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setPreferredLanguage(event.target.value as PreferredLanguageDto);
                }}
              >
                <MenuItem value={PreferredLanguageDto.English}>English</MenuItem>
                <MenuItem value={PreferredLanguageDto.Spanish}>Spanish</MenuItem>
                <MenuItem value={PreferredLanguageDto.Chinese}>Chinese</MenuItem>
                <MenuItem value={PreferredLanguageDto.French}>French</MenuItem>
                <MenuItem value={PreferredLanguageDto.FrenchCreole}>French Creole</MenuItem>
                <MenuItem value={PreferredLanguageDto.Korean}>Korean</MenuItem>
                <MenuItem value={PreferredLanguageDto.Polish}>Polish</MenuItem>
                <MenuItem value={PreferredLanguageDto.Vietnamese}>Vietnamese</MenuItem>
              </FormSelectInput>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'Accessible communication preference (optional)'}
              value={communicationPreference}
              placeholder={'Select'}
              helperText={'Please call a licensed sales agent at (888)-123-4567 (TTY: 711) if you need information in another format or language.'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCommunicationPreference(event.target.value as string);
              }}
            >
              <MenuItem value={'audio'}>Audio</MenuItem>
              <MenuItem value={'largePrint'}>Large print</MenuItem>
              <MenuItem value={'accessibleScreenReader'}>Accessible screen reader</MenuItem>
              <MenuItem value={'phone'}>Oral over the phone</MenuItem>
              <MenuItem value={'braille'}>Braille</MenuItem>
            </FormSelectInput>
          </Grid>
        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'100px'}>
        <AscendButton variant={'contained'} onClick={() => {
          history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Save and continue</AscendButton>
      </Box>
    </form>
  </Box>
}

export default EnrollmentForm2;
