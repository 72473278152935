import React, {PropsWithChildren} from "react";
import {Box, createStyles, Divider, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 320,
    },
    container: {
      padding: '30px 30px 30px 40px',
      background: 'white',
      flexDirection: 'column',
      display: 'flex',
      flex: 1
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    }
  }),
);

interface SidePanelProps {
  sideHeaderContent?: JSX.Element,
  containerClassName?: string,
}

export default function SidePanel(props: PropsWithChildren<SidePanelProps>) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.container, props.containerClassName)}>
        <Box display={'flex'} justifyContent={'space-between'} width={'100%'}>
          <img src={'/img/Ascend.svg'} width={91} height={30} className={'pointer'} onClick={() => document.location.href = document.location.origin} />
          {props.sideHeaderContent && <>{props.sideHeaderContent}</>}
        </Box>
        <Box marginTop={'20px'} width={'100%'}>
          <Divider className={classes.divider} />
        </Box>
        {props.children}
      </div>
    </div>
  )
}
