import React from "react";
import {DrugTierOutput} from "../../types";
import usePlan, {PlanWithTiers} from "./hooks/usePlan";
import {Box, Collapse, createStyles, Divider, Grid, IconButton, Theme, Typography, withStyles} from "@material-ui/core";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {planStyles, RoundedButton} from "../PlanList/PlanCard";
import AscendCard from "../../shared/components/AscendCard";
import {PlanTitle} from "../PlanList/components/PlanTitle";
import TooltipedTitle from "../PlanList/components/TooltipedTitle";
import AscendButton from "../../shared/components/AscendButton";
import {SimplePlanDetail} from "./components/SimplePlanDetail";
import {ListPlanDetail} from "./components/ListPlanDetail";
import {AscendSubtitle} from "./components/AscendSubtitle";
import {PlanSectionTitle} from "./components/PlanSectionTitle";
import Layout from "../../shared/Layout";
import PlanDetailsContent from "./PlanDetailsContent";
import * as Scroll from "react-scroll";
import {JsxPlanDetail} from "./components/JsxPlanDetail";
import {DrugCoverageList} from "./components/DrugCoverageList";
import {getQuoteId} from "../../shared/QuoteId";
import {DoctorCoverageList} from "./components/DoctorCoverageList";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import ComparisonPanel from "../PlanComparison/ComparisonPanel";
import {makeStyles} from "@material-ui/core/styles";
import Benefit from "../../shared/components/Benefit";
import {colors} from "../../shared/AppTheme";
import clsx from "clsx";
import {QuoteContext} from "../PlanList/QuoteContext";
import {FavoritesContext} from "../shared/FavoritesContext";
import paths from "../config/router-paths";
import PlanEmailModal from "./components/PlanEmailModal";
import Footer from "../shared/Footer";
import {config} from "../config/config";
import planFiles from "../shared/planFiles";
import AscendPreloader from "../../shared/components/AscendPreloader";
import PlanSummaryModal from "../PlanList/PlanSummaryModal";
import useTemporalData from "../../shared/hooks/useTemporalData";
import {getStoredUser} from "../../shared/StoredUser";
import LoginWidget from "../PlanList/LoginWidget";
import useGetEHealthLinkRequest from "../../shared/hooks/useGetEHealthLinkRequest";
import {AdminContext} from "../PlanList/AdminContext";

var Element = Scroll.Element;
const Link = Scroll.Link;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: colors.custom.green.variant2,
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: 400,
    },
    headerCard: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '8px 0 9px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '10px 0 24px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    headerCell: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '17.5px',
      color: colors.text.primary,
      background: 'rgba(215, 229, 250, 0.2)',
      padding: '10px 15px',
    },
    tableRow: {
      '& td': {
        whiteSpace: 'nowrap',
        color: colors.text.primary,
        fontSize: 12,
        padding: 15,
        borderBottom: '1px solid #E8ECED',
        textAlign: 'left',
      },
      '& td:first-child': {
        fontWeight: 700,
      }
    },
    enrollSection: {
      maxWidth: 195,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '19px 19px 16px 16px',
      position: 'relative',
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      '& div': {
        fontSize: 14,
        color: colors.text.primary,
        marginLeft: 4,
        marginTop: 3,
      }
    },
    checkbox: {
      flex: 'initial'
    },
    title: {
      flex: 1
    },
  })
);

export default (props: RouteComponentProps<{ zip: string, countyName: string, planId: string }>) => {

  let {zip, countyName, planId} = props.match.params;
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const {preferredDrugs, pin} = React.useContext(QuoteContext);
  const [expandedState, setExpandedState] = React.useState<{[key: string]: boolean}>({});
  const [emailOpen, setEmailOpen] = React.useState(false);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const {put} = useTemporalData();
  const adminContext = React.useContext(AdminContext);
  const [generateEhealthPlanLink] = useGetEHealthLinkRequest({
    onCompleted: data => {
      if (data?.generateEhealthPlanLink) {
        navigator.clipboard.writeText(data?.generateEhealthPlanLink)
      }
    }
  })

  const history = useHistory();
  const ownClasses = styles();
  const classes = planStyles();

  const copyEHealthLink = () => {
    generateEhealthPlanLink({variables: {bidId: planId, countyName, zip}})
  }

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === 'undefined' ? true : expandedState[key];

  const {data, loading} = usePlan({zip, bidId: planId, quoteId: getQuoteId(), countyName});
  const [plan, setPlan] = React.useState<PlanWithTiers>();

  React.useEffect(() => {
    window.scrollTo(0, 0)
    setPlan(data?.plan)
  }, [data]);

  const files = React.useMemo(() => {
    return planFiles(plan?.planFiles);
  }, [plan])

  const onEnrollClick = () => {
    put({variables: {data: JSON.stringify(getStoredUser() || {})}})
      .then((data: any) => { document.location.href = config.enrollUrl + planId + '/' + zip + '/' + data.data.putTemporalData})
  }

  if (loading) {
    return <Box display={"flex"} flexDirection={'column'} flexGrow={1} justifyContent={"center"} alignItems={'center'} mt={'20vh'}>
      <AscendPreloader />
      <Footer/>
    </Box>
  }
  return <Layout pin={pin} sideHeaderContent={<LoginWidget/>} SideComponent={() => <PlanDetailsContent plan={plan}/>}>
    <PlanSummaryModal zip={zip} planId={planId} countyName={countyName} open={modalOpen} onClose={() => setModalOpen(false)} />
    <PlanEmailModal zip={zip} planId={planId} open={emailOpen} county={zip} onClose={() => setEmailOpen(false)} />
    <Box display={'flex'} flexDirection={'column'} alignItems={"center"} mt={'31px'}>
      <Box maxWidth={800}>
        {!!plan &&

        <Box justifyContent={"center"}>
            <AscendCard className={ownClasses.headerCard}>
              <Box display={"flex"} p={'7px 15px 7px 0'}>
                <div className={classes.infoWrapper}>
                  <PlanTitle showBid plan={plan} className={ownClasses.title}
                             inFavorites={favorites.indexOf(plan.bidId) >= 0}
                             favoritesClick={() => toggleFavorite(plan.bidId, zip)}/>
                  <Box width={"100%"}>
                    <Divider className={classes.divider}/>
                    <Box display={'flex'} flex={1} alignItems={'center'} justifyContent={'space-between'} mt={"20px"}>
                      <Box display={'flex'}>
                        <div className={ownClasses.iconButton} onClick={() => setEmailOpen(true)}>
                          <img src={'/img/message.svg'}/>
                          <div>Email me this plan</div>
                        </div>
                      </Box>
                      <AscendCheckbox variant={"standart"} controlClassName={clsx(classes.checkbox, ownClasses.checkbox)}
                                      labelClassName={classes.checkboxLabel}
                                      label={"Add to compare"} checked={isInCompare(plan.bidId)}
                                      onChange={(e, checked) => {
                                        checked ? addToCompare(plan.bidId) : removeFromCompare(plan.bidId)
                                      }}/>
                    </Box>
                  </Box>
                </div>
                <div className={ownClasses.enrollSection}>
                  {!!adminContext && <Box position={'absolute'} right={5} top={5}
                                          onClick={copyEHealthLink}
                                          className={`${classes.excl} fs-12 pointer underline`}>E Health</Box>}
                  <Box display={"flex"} flexDirection={"column"} flex={1}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Typography className={classes.price}>{plan.monthlyCost || "N/A"}</Typography>
                      <Box mb={"3px"}>
                        <img src={"/img/info-green.svg"}/>
                      </Box>
                    </Box>
                    <Typography variant={"body2"} className={classes.bold} color={'textPrimary'}>average total cost per month</Typography>
                  </Box>
                  <div className={'mt-5 flex-space-between'}>
                    <Typography variant={'h5'} color={'textPrimary'}>Monthly Premium:</Typography>
                    <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan.monthlyPremium}</Typography>
                  </div>
                  <div className={'mt-5 flex-space-between'}>
                    <Typography variant={'h5'} color={'textPrimary'}>Drug costs:</Typography>
                    <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan.drugsCost || 'N/A'}</Typography>
                  </div>
                  <div className={'mt-5 flex-space-between'}>
                    <Typography variant={'h5'} color={'textPrimary'}>Doctor costs:</Typography>
                    <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan.doctorsCost}</Typography>
                  </div>
                  <Box mt={"14px"}>
                    <AscendButton className={classes.enrollButton}
                                  onClick={onEnrollClick}
                                  variant={"contained"}>
                      Select this plan
                    </AscendButton>
                  </Box>
                </div>
              </Box>
            </AscendCard>

          <div className={"h-40"}/>

          <Element name={"Key Plan Benefits Overview"}>
            <AscendCard className={ownClasses.card}>
              <PlanSectionTitle title={"Key Plan Benefits Overview"}/>
              <Grid container direction={"column"}>
                <Element name={"Estimated Monthly Cost Summary"}></Element>
                <AscendSubtitle title={"Estimated Monthly Cost Summary"} icon={"/img/expenses.svg"}/>

                <Box ml={'72px'} mr={'55px'} maxHeight={195}>
                  <SimplePlanDetail title={"Monthly plan premium"} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'} content={plan!!.monthlyPremium} thin hideDidider/>
                  <SimplePlanDetail title={"Estimated drug costs"} tooltip={'Estimated monthly drug costs includes any annual out-of-pocket cost sharing expenses that you must pay for the medications entered, calculated on an average monthly basis. Cost sharing expenses are based on the drug coverage breakdown in the plan details. This number can only be calculated if you have entered medication information.'} content={<Box display={'flex'} alignItems={'center'}>
                    <Typography color={'textPrimary'} className={'fs-12'}>{plan!!.drugsCost}</Typography>
                    <div className={'w-40'} />
                    {!!preferredDrugs?.preferredDrugs?.length && (
                      <RoundedButton variant={'contained'} onClick={() => setModalOpen(true)}>See monthly drug cost</RoundedButton>
                    )}
                  </Box>} thin hideDidider/>
                  <SimplePlanDetail title={"Estimated doctor costs"} tooltip={'Estimated doctor costs are calculated based on your estimated number of Primary Care Physician and Specialist visits per year, multiplied by the copays or coinsurance for in-network providers.'} content={plan!!.doctorsCost} thin/>

                  <SimplePlanDetail title={"Total estimated monthly cost"} tooltip={'This is the sum of monthly premium, estimated monthly doctor costs and estimated monthly drug costs.'} content={<Typography color={'textPrimary'} className={'fs-12 bold'}>{plan!!.monthlyCost}</Typography>} hideDidider/>
                  {plan!!.optionalSupplementalBenefits && <SimplePlanDetail title={"Optional supplemental benefits"} tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'} content={'$53.70' /*todo hardcode*/} thin/>}
                  <SimplePlanDetail title={<i>plus Medicare Part B Premium</i>}
                                    tooltip={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You stil pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}
                                    content={<i className={'fs-12'} style={{color: colors.text.primary}}>$148.50</i>}
                                    thin hideDidider/>
                </Box>

                <Element name={"Your Healthcare Selections"}></Element>
                <AscendSubtitle title={"Your Healthcare Selections"} icon={"/img/healthcare.svg"}/>


                <Box ml={'72px'} mr={'55px'}>
                  <JsxPlanDetail title={<strong>Your doctors</strong>}
                                 content={<div>
                                   <DoctorCoverageList coverage={plan?.doctorsCoverage}/>
                                   <div className={`h-8`} />
                                   <RoundedButton className={'w-130'}
                                                  variant={'contained'}
                                                  onClick={() => history.push(paths.editPreferences + zip)}>
                                     {plan?.doctorsCoverage?.length ? 'Edit' : 'Add'} doctors
                                   </RoundedButton>
                                 </div>}/>
                  <JsxPlanDetail title={<strong>Your drugs:</strong>}
                                 content={<div>
                                   <DrugCoverageList coverage={plan.drugsCoverage}/>
                                   <div className={`h-8`} />
                                   <RoundedButton className={'w-130'}
                                                  variant={'contained'}
                                                  onClick={() => history.push(paths.editPreferences + zip)}>
                                     {plan?.drugsCoverage?.length ? 'Edit' : 'Add'} drugs
                                   </RoundedButton>
                                 </div>}/>
                  <JsxPlanDetail title={<strong>Your pharmacy:</strong>}
                                 content={<div>
                                   {plan?.quoteDrugsTiers?.pharmacyName ?
                                     <Benefit label={plan?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={classes.pharmacyLabelClass}/> :
                                     <Typography className={'fs-12'} color={'textPrimary'}>You haven’t added a pharmacy</Typography>
                                   }
                                   <div className={'h-8'} />
                                   <RoundedButton className={'w-130'}
                                                  variant={'contained'}
                                                  onClick={() => history.push(paths.editPreferences + zip)}>
                                     {plan?.quoteDrugsTiers?.pharmacyName ? 'Edit' : 'Add'} pharmacy
                                   </RoundedButton>
                                 </div>}/>
                </Box>

                <Element name={"PlanCoverageOverview"}></Element>
                <AscendSubtitle title={"Plan Highlights"} icon={"/img/medical-record-1.svg"}/>

                <Box ml={'72px'} mr={'55px'}>
                  <SimplePlanDetail title={"Plan Type"} tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'} content={plan!!.planType}/>
                  {!!plan.partBGiveBack && <SimplePlanDetail title={"Part B Giveback"}
                                                             tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                                                             content={<Benefit label={`${plan.partBAmount}`} value={!!plan.partBGiveBack} />}/>}
                  <SimplePlanDetail title={"Annual In-Network Deductible"}
                                    tooltip={'The amount you must pay out of pocket for your health care from in-network medical services before your coverage begins to pay. '}
                                    content={plan.inNetworkDeductableAmount}
                                    emptyString={"This plan does not have a deductible."}/>
                  <ListPlanDetail title={"Out-of-Pocket Maximum"} tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'} content={plan?.outOfPocketMaximum}/>



                  <ListPlanDetail title={"Primary Doctor Visit"}
                                  tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'}
                                  content={plan?.primaryCarePhysician}/>
                  <ListPlanDetail title={"Specialist Visit"} tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'} content={plan?.physicianSpecialist}/>
                  <ListPlanDetail title={"Inpatient Hospital Care"} tooltip={'Typically includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'} content={plan?.hospitalBenefits}/>



                  {!!plan?.drugDetails &&
                  (<>
                      <Element name={"DrugCoverage"}></Element>

                      <SimplePlanDetail title={"Prescription Drug Coverage"}
                                        tooltip={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'}
                                        content={<Benefit value={plan?.extraBenefits.drugCoverage} label={''} />}/>
                      {(plan?.drugDetails!!.mrxAltDedAmount || plan?.drugDetails!!.mrxAltNoDedTier) && <ListPlanDetail title={"Prescription Drug Deductible"}
                                      tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '}
                                      content={[plan?.drugDetails!!.mrxAltDedAmount, plan?.drugDetails!!.mrxAltNoDedTier]}/>}
                    </>
                  )
                  }
                  <SimplePlanDetail title={"Dental Services"}
                                    tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan?.dental.length > 0} label={plan?.dental.length > 0 ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Vision Services"}
                                    tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan?.vision.length > 0} label={plan?.vision.length > 0 ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Hearing Services"}
                                    tooltip={'Medicare Advantage plans often provide hearing benefits at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan?.hearing.length > 0} label={plan?.hearing.length > 0 ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Fitness Benefits"}
                                    tooltip={'Medicare Advantage plans often provide fitness benefits at no extra cost, for example gym memberships or fitness programs.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan.extraBenefits.fitness} label={plan.extraBenefits.fitness ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Insulin Savings"}
                                    tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan.extraBenefits.insulinSavings} label={plan.extraBenefits.insulinSavings ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Over-the-counter Benefits"}
                                    tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan?.oTC.length > 0} label={plan?.oTC.length > 0 ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Telehealth Services"}
                                    tooltip={'Medicare Advantage plans often provide telehealth benefits at no extra cost. These benefits can provide beneficiaries with access to medical and health-related services via telecommunications and digital communications technologies, such as computers and mobile devices.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan.extraBenefits.telehealth} label={plan.extraBenefits.telehealth ? 'View details' : ''} />
                                    </Link>}/>
                  <SimplePlanDetail title={"Transportation Services"}
                                    tooltip={'Medicare Advantage plans often provide transportation benefits at no extra cost, for example coverage for trips to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                                    content={<Link to={'Extra Benefits'} smooth={true} spy={true} duration={200}>
                                      <Benefit labelClass={ownClasses.label} value={plan?.transportation.length > 0} label={plan?.transportation.length > 0 ? 'View details' : ''} />
                                    </Link>}/>
                </Box>

              </Grid>
            </AscendCard>
          </Element>

          {(!!plan?.drugDetails && !!plan.extraBenefits.drugCoverage) &&
          <Element name={"Drug Coverage & Costs"}>
            <div className={"h-40"}/>
            {!plan.SNPType && <DrugDetails plan={plan} getExpandedValue={getExpandedValue} setExpandedState={setExpandedState} />}
            {!!plan.SNPType && <SNPDrugDetails plan={plan} getExpandedValue={getExpandedValue} setExpandedState={setExpandedState} />}
          </Element>
          }


          <div className={"h-40"}/>

          <Element name={"Medical & Hospital Coverage"}>
            <AscendCard className={ownClasses.card}>
              <PlanSectionTitle title={"Medical & Hospital Coverage"}/>
              <Grid container direction={"column"}>
                <AscendSubtitle title={"Your Doctor Costs"} icon={"/img/doctor.svg"}/>

                <Box ml={'72px'} mr={'55px'}>
                  <ListPlanDetail title={"Primary Doctor Visit"}
                                  tooltip={'The amount you can expect to pay for a regular visit to your primary care physician as outlined in your policy details.'}
                                  content={plan?.primaryCarePhysician}/>
                  <ListPlanDetail title={"Specialist Visit"}
                                  tooltip={'The amount you can expect to pay for a visit to a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details.'}
                                  content={plan?.physicianSpecialist}/>
                  <ListPlanDetail title={"Preventive Care"}
                                  tooltip={'Typically, periodic health exams that occur on a regular basis for preventive purposes. These can include routine physicals or annual check-ups.'}
                                  content={plan?.preventiveCare}/>
                </Box>


                <div className={"h-20"}/>
                <AscendSubtitle title={"Medical and Hospital Coverage Overview"} icon={"/img/healthcare.svg"}/>

                <Box ml={'72px'} mr={'55px'}>

                  <SimplePlanDetail title={"Emergency Room"}
                                    tooltip={'Typically, emergency room services include all services provided when a patient visits an emergency room for an emergency condition. An emergency condition is any medical condition that would lead most people to believe that failing to obtain immediate medical care could result in placing the patient\'s health at serious risk.'}
                                    content={plan?.hospitalityDetails.emergencyRoom}/>

                  <SimplePlanDetail title={"Urgently Needed Services"}
                                    tooltip={'Care that you get outside of your Medicare health plan\'s service area for a sudden illness or injury that needs attention but isn\'t life threatening. If it\'s not safe to wait until you get home to get care from a plan doctor, the health plan must pay for the care.'}
                                    content={plan?.hospitalityDetails.urgentServices}/>

                  <ListPlanDetail title={"Ambulance Services"}
                                  tooltip={'Transport by ambulance to or from a hospital from your home or a medical facility to receive medical care. Medicare covers the following types of ambulance services depending on the seriousness of your medical condition or whether other methods of transportation could endanger your health: emergency ground (vehicle), emergency air (airplane or helicopter) and non-emergency ground.'}
                                  content={plan?.hospitalityDetails.ambulanceServices}/>

                  <ListPlanDetail title={"Inpatient Hospital Care"}
                                  tooltip={"Typically includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies."}
                                  content={plan?.hospitalBenefits}/>


                  <ListPlanDetail title={"Skilled Nursing Facilities (SNF)"}
                                  tooltip={'A nursing facility with the staff and equipment to give skilled nursing care and, in most cases, skilled rehabilitative services and other related health services.'}
                                  content={plan?.skilledNursing}/>

                  <ListPlanDetail title={"Outpatient Diagnostic Tests and Therapeutic Services"}
                                  titleClassName={'w-205'}
                                  tooltip={'Tests done to help a doctor diagnose or rule out a suspected illness, injury, or condition. These tests are ordered by your treating doctor and may include x-rays, blood tests, urinalysis, and tests done on tissue specimens.'}
                                  content={plan?.outpatientHospitality}/>

                  <ListPlanDetail title={"Outpatient Hospital And Surgery"}
                                  tooltip={'Typically defined as any surgical procedure that does not require an overnight stay in a hospital.'}
                                  content={plan?.outpatientSurgery}/>

                  <ListPlanDetail title={"Outpatient Rehabilitation Services"}
                                  tooltip={'Services to help you regain abilities, such as speech or walking, that have been impaired by an illness or injury. These services are provided by nurses, as well as by physical, occupational and speech therapists. Examples include working with a physical therapist to help you walk or working with an occupational therapist to help you get dressed.'}
                                  content={plan?.outpatientRehabilitation}/>

                  <ListPlanDetail title={"Outpatient Mental Health Care"}
                                  tooltip={'Typically for services provided by a mental health professional in an outpatient setting.'}
                                  content={plan?.mentalHealth}/>

                  <ListPlanDetail title={"Home Health Care"}
                                  tooltip={'Health care services and supplies that a doctor decides you may receive in your home under a doctor-established plan of care. Medicare only covers home health care on a limited basis as ordered by your doctor.'}
                                  content={plan?.homeHealth}/>

                </Box>
              </Grid>
            </AscendCard>
          </Element>

          <div className={"h-40"}/>

          <Element name={"Extra Benefits"}>
            <AscendCard className={ownClasses.card}>
              <PlanSectionTitle title={"Extra Benefits"}/>
              <Grid container direction={"column"}>
                <AscendSubtitle title={"Additional benefits"} icon={"/img/teeth.svg"}/>
                <Box ml={'72px'} mr={'55px'}>
                  {plan!!.optionalSupplementalBenefits && <SimplePlanDetail title={"Optional supplemental benefits"}
                                                                          tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'}
                                                                          content={''} thin/>}
                  <ListPlanDetail title={"Dental Services"}
                                  tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                                  content={plan?.dental}/>

                  <ListPlanDetail title={"Vision Services"}
                                  tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                                  content={plan?.vision}/>

                  <ListPlanDetail title={"Hearing Services"}
                                  tooltip={'Medicare Advantage plans often provide hearing benefits at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}
                                  content={plan?.hearing}/>

                  <ListPlanDetail title={"Fitness Benefits"}
                                  tooltip={'Medicare Advantage plans often provide fitness benefits at no extra cost, for example gym memberships or fitness programs.'}
                                  content={plan?.otherDefinedSupplemental.fitnessBenefit}/>

                  <SimplePlanDetail title={"Insulin Savings"}
                                    tooltip={'Medicare Advantage plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                                    content={'N/A'}/>

                  <ListPlanDetail title={"Meals After Hospital Stay"}
                                  tooltip={'Healthy food or home deliveries for plan members post discharge from hospital or skilled nursing facility.'}
                                  content={plan?.meal}/>

                  <ListPlanDetail title={"Over-the-Counter Benefits"}
                                  tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                                  content={plan?.oTC}/>

                  <ListPlanDetail title={"Telehealth Services"}
                                  tooltip={'Medicare Advantage plans often provide telehealth benefits at no extra cost. These benefits can provide beneficiaries with access to medical and health-related services via telecommunications and digital communications technologies, such as computers and mobile devices.'}
                                  content={plan?.telehealth}/>

                  <ListPlanDetail title={"Transportation Services"}
                                  tooltip={'Medicare Advantage plans often provide transportation benefits at no extra cost, for example coverage for trips to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                                  content={plan?.transportation}/>
                </Box>
              </Grid>
            </AscendCard>
          </Element>


          <div className={"h-40"}/>

          <Element name={"Plan Brochures"}>
            <AscendCard className={ownClasses.card}>
              <PlanSectionTitle title={"Plan Brochures"}/>
              <Grid container direction={"column"}>
                <AscendSubtitle title={"Plan and Medicare Brochures"} icon={"/img/brochures.svg"}/>
                <Box ml={'72px'} mr={'55px'} maxHeight={132}>
                  <ListPlanDetail title={"Plan Brochures"}
                                  content={files.filter(i => !!i.url).map(item => <a className={`${classes.link} flex flex-align-center`} target={'_blank'} href={item.url}>
                                    <img src={'/img/download.svg'} className={'mr-8'} />
                                    {item.label}
                                  </a>)}/>
                  <SimplePlanDetail title={"Medicare Brochure(s)"}
                                  content={plan?.planFiles?.preEnrollmentChecklistDocUrl ?
                                    <a className={`${classes.link} flex flex-align-center`}
                                       target={'_blank'}
                                       href={config.filesUrl + '/' + plan?.planFiles?.preEnrollmentChecklistDocUrl}>
                                      <img src={'/img/download.svg'} className={'mr-8'} />
                                      Medicare & You Handbook (2021)
                                    </a> : ''
                                  }/>
                </Box>
              </Grid>
            </AscendCard>
          </Element>
        </Box>
        }
      </Box>

      <Footer />
    </Box>

    {zip && <ComparisonPanel zip={zip} countyName={countyName} forceHide={!isInCompare(planId)}/>}
  </Layout>;
}

const CoverageSectionTitle = ({title, description}: { title: string, description: string }) => {

  return (<Box my={6} textAlign={"center"}>
    <Box my={6} textAlign={"center"}>
      <Typography variant={"h2"}>{title}</Typography>
      <Typography variant={"body2"}>{description}</Typography>
    </Box>
  </Box>)
}

const SectionTitleH2 = withStyles({
  cell: {
    padding: '0 11px!important'
  },
  table: {
    background: 'white!important',
    borderSpacing: 0,
  },
  wrapper: {
    background: 'rgba(232,236,237,0.5)',
    borderRadius: "4px",
    marginLeft: 10,
    marginRight: 10,
    width: 'auto',
  },
  medium: {
    fontWeight: 500,
  },
  icon: {
    transform: 'rotate(-180deg)',
    transition: 'all 0.3s'
  },
  iconOpen: {
    transform: 'rotate(0) !important',
  },
  button: {
    background: 'rgba(2, 144, 148, 0.5)',
    width: 30,
    maxWidth: 30,
    height: 30,
    padding: 10,
    marginTop: 5,
    '&:hover': {
      background: 'rgba(2, 144, 148, 0.5)',
    }
  }
})(({classes, ...props}: TitleProps) => (
      <Box p={'16px 16px 14px 55px'}
           className={clsx(classes.wrapper, props.wrapperClassName)}
           display={"flex"}
           flexDirection={"row"}
           alignItems={"center"}
           justifyContent={"space-between"}
           width={1}>

        <Box display={'flex'}
             flexDirection={'column'}
             justifyContent={"center"}
             alignItems={"start"}>
          <Typography variant={'h3'} color={'textPrimary'}><strong>{props.title}</strong></Typography>
          {props.subtitle && <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>{props.subtitle}</Typography>}
        </Box>
        <IconButton onClick={() => props.onToggle(!props.expanded)} className={classes.button}>
          <img className={clsx(classes.icon, props.expanded && classes.iconOpen)} src={'/img/arrow-white.svg'} />
        </IconButton>
      </Box>
))

type TitleProps = {
  title: string,
  subtitle?: string,
  classes: any,
  expanded: boolean,
  onToggle: (value: boolean) => void,
  wrapperClassName?: string,
}

export const getInitialTooltipByTier = (tier: DrugTierOutput): string => {
  switch (tier) {
    case DrugTierOutput.PreferredGeneric: return 'Typically includes generic drugs that have been available for a long period of time. Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.Generic: return 'Chemically equivalent variants of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.PreferredBrand: return 'Typically the brand-name drugs that have been available for a long period of time and are widely accepted. May include medications manufactured by one manufacturer that are typically lower-cost among all brand-name drugs.';
    case DrugTierOutput.NonPreferredBrand: return 'Typically includes higher cost than Tier 3, non-preferred generic and brand-name drugs.';
    case DrugTierOutput.Specialty: return 'Typically higher cost than Tier 4, non-preferred generic and brand-name drugs.';
    case DrugTierOutput.SelectCareDrugs: return 'Typically highest cost, non-preferred generic and brand-name drugs.';
  }
}

export const getGapTooltipByTier = (tier: DrugTierOutput): string => {
  switch (tier) {
    case DrugTierOutput.Generic: return 'Chemically equivalent versions of brand name drugs that can be approved by the FDA once the brand\'s patent or other periods of exclusivity in the market expire.';
    case DrugTierOutput.NonPreferredBrand: return 'A drug sold by a drug company under a specific name or trademark and that is protected by a patent. ';
  }
  return '';
}

const DrugDetails = ({plan, getExpandedValue, setExpandedState}: {plan: PlanWithTiers, getExpandedValue: (key: string) => boolean, setExpandedState: any}) => {
  const ownClasses = styles();
  const classes = planStyles();



  const tiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.initialCoverageDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.initialCoverageDrugTiers
    } else {
      return plan?.drugsTiers30Standard?.initialCoverageDrugTiers
    }
  }, [plan]);

  const gapTiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.coverageGapDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.coverageGapDrugTiers
    } else {
      return plan?.drugsTiers30Standard?.coverageGapDrugTiers
    }
  }, [plan]);

  return <>
    <AscendCard className={ownClasses.card}>
      <PlanSectionTitle title={"Drug Coverage & Costs"}/>
      <Grid container direction={"column"}>
        <Grid item>
          <AscendSubtitle title={"Prescription Drug Costs"} icon={`/img/pills.svg`}/>
          <div className={'h-16'} />
          <SectionTitleH2 title={'Deductible phase'}
                          subtitle={'You pay the full retail price of your drugs up until you meet the deductible level (if applicable).'}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, deductible: value}))}
                          expanded={getExpandedValue('deductible')}
          />
          <Collapse in={getExpandedValue('deductible')}>
            <Box ml={'72px'} mr={'55px'}>
              <ListPlanDetail title={"Deductible amount"}
                              tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '}
                              content={[plan?.drugDetails!!.mrxAltDedAmount, plan?.drugDetails!!.mrxAltNoDedTier]}/>

            </Box>
          </Collapse>

          {!!plan?.drugDetails?.initialCoverage?.length && <>
            <div className={'h-24'} />
            <SectionTitleH2 title={'Initial Coverage Limit phase'}
                            subtitle={plan?.drugDetails?.initialCoverageDescription}
                            onToggle={value => setExpandedState((prevState: any) => ({...prevState, initialCoverage: value}))}
                            expanded={getExpandedValue('initialCoverage')}
            />
            <Collapse in={getExpandedValue('initialCoverage')}>
              <div className={'h-16'} />
              <table className={'mh-10'}>
                <tbody>
                <tr>
                  <td></td>
                  <td className={ownClasses.headerCell}>Preferred Retail Pharmacy
                    (30 day supply)</td>
                  <td className={ownClasses.headerCell}>Standard Retail Pharmacy
                    (30 day supply)</td>
                  <td className={ownClasses.headerCell}>Preferred Mail Order
                    (90 day supply) </td>
                  <td className={ownClasses.headerCell}>Standard Mail Order
                    (90 day supply)</td>
                </tr>
                {plan?.quoteDrugsTiers?.pharmacyName && <tr className={ownClasses.tableRow}>
                  <td>Your pharmacy</td>
                  <td colSpan={4}><Benefit label={plan?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={classes.pharmacyLabelClass}/></td>
                </tr>}
                {tiers?.map((tier, i) => <tr key={tier.tierName} className={ownClasses.tableRow}>
                  <td><TooltipedTitle title={tier.tierName} className={'bold'} tooltip={getInitialTooltipByTier(tier.tier)} /></td>
                  {[
                    plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage,
                    plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage,
                    plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage,
                    plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage
                  ].every( v => v === plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage ) ? (
                    <td colSpan={4} style={{textAlign: 'center'}}>{plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage}</td>
                  ) : <>
                    <td>{plan?.drugsTiers30Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
                    <td>{plan?.drugsTiers30Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
                    <td>{plan?.drugsTiers90Preferred?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
                    <td>{plan?.drugsTiers90Standard?.initialCoverageDrugTiers[i].coverage || 'n.a.'}</td>
                  </>}
                </tr>)}
                </tbody>
              </table>
            </Collapse>
          </>}

          <div className={'h-24'} />
          <SectionTitleH2 title={'Coverage Gap phase'}
                          subtitle={plan?.drugDetails?.coverageGapDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, gapCoverage: value}))}
                          expanded={getExpandedValue('gapCoverage')}
          />
          <Collapse in={getExpandedValue('gapCoverage')}>
            <div className={'h-16'} />
            <table className={'mh-10'}>
              <tbody>
              <tr>
                <td></td>
                <td className={ownClasses.headerCell}>Preferred Retail Pharmacy
                  (30 day supply)</td>
                <td className={ownClasses.headerCell}>Standard Retail Pharmacy
                  (30 day supply)</td>
                <td className={ownClasses.headerCell}>Preferred Mail Order
                  (90 day supply) </td>
                <td className={ownClasses.headerCell}>Standard Mail Order
                  (90 day supply)</td>
              </tr>
              {gapTiers?.map((tier, i) => <tr key={tier.tierName} className={ownClasses.tableRow}>
                <td><TooltipedTitle title={tier.tierName} className={'bold'} tooltip={getGapTooltipByTier(tier.tier)} /></td>
                {[
                  plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage,
                  plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage,
                  plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage,
                  plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage
                ].every( v => v === plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage ) ? (
                  <td colSpan={4} style={{textAlign: 'center'}}>{plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage}</td>
                ) : <>
                  <td>{plan?.drugsTiers30Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
                  <td>{plan?.drugsTiers30Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
                  <td>{plan?.drugsTiers90Preferred?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
                  <td>{plan?.drugsTiers90Standard?.coverageGapDrugTiers[i].coverage || 'n.a.'}</td>
                </>}
              </tr>)}
              </tbody>
            </table>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Catastrophic Coverage phase'}
                          subtitle={plan?.drugDetails?.catastrophicCoverageDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, catastrophicCoverage: value}))}
                          expanded={getExpandedValue('catastrophicCoverage')}
          />
          <Collapse in={getExpandedValue('catastrophicCoverage')}>
            <Box ml={'72px'} mr={'55px'}>
              {plan?.drugDetails?.catastrophicCoverage[0].items.map((item, i) => <ListPlanDetail
                key={item.title}
                title={item.title}
                tooltip={getGapTooltipByTier([DrugTierOutput.Generic, DrugTierOutput.NonPreferredBrand][i])}
                content={item.values}/>)}
            </Box>
          </Collapse>
        </Grid>
      </Grid>
    </AscendCard>
    </>
}


const SNPDrugDetails = ({plan, getExpandedValue, setExpandedState}: {plan: PlanWithTiers, getExpandedValue: (key: string) => boolean, setExpandedState: any}) => {
  const ownClasses = styles();
  const classes = planStyles();

  return <>
    <AscendCard className={ownClasses.card}>
      <PlanSectionTitle title={"Drug Coverage & Costs"}/>
      <Grid container direction={"column"}>
        <Grid item>
          <AscendSubtitle title={"Prescription Drug Costs"} icon={`/img/pills.svg`}/>
          <div className={'h-24'} />
          <SectionTitleH2 title={'Deductible phase'}
                          subtitle={'You pay the full retail price of your drugs up until you meet the deductible level (if applicable).'}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, deductible: value}))}
                          expanded={getExpandedValue('deductible')}
          />
          <Collapse in={getExpandedValue('deductible')}>
            <Box ml={'72px'} mr={'55px'}>
              <ListPlanDetail title={"Deductible amount"}
                              tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay. '}
                              content={[plan?.drugDetails!!.mrxAltDedAmount, plan?.drugDetails!!.mrxAltNoDedTier]}/>

            </Box>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Initial Coverage Limit phase'}
                          subtitle={plan?.drugDetails?.initialCoverageDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, initialCoverage: value}))}
                          expanded={getExpandedValue('initialCoverage')}
          />
          <Collapse in={getExpandedValue('initialCoverage')}>
            <Box ml={'72px'} mr={'55px'}>
              <ListPlanDetail title={"All Tiers"}
                              content={['[b]Generic drugs:[/b]', '$0 copay; or', '$1.30 copay; or', '$3.70 copay; or', '15%', <br />, '[b]Brand-name drugs:[/b]', '$0 copay; or', '$4.00 copay; or', '$9.20 copay; or', '15%', <br />, '(depending on your level of Extra Help)']}/>
            </Box>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Coverage Gap phase'}
                          subtitle={plan?.drugDetails?.coverageGapDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, gapCoverage: value}))}
                          expanded={getExpandedValue('gapCoverage')}
          />
          <Collapse in={getExpandedValue('gapCoverage')}>
            <Box ml={'72px'} mr={'55px'}>
              <ListPlanDetail title={"All Tiers"}
                              content={['[b]Generic drugs:[/b]', '$0 copay; or', '$1.30 copay; or', '$3.70 copay; or', '15%', <br />, '[b]Brand-name drugs:[/b]', '$0 copay; or', '$4.00 copay; or', '$9.20 copay; or', '15%', <br />, '(depending on your level of Extra Help)']}/>
            </Box>
          </Collapse>

          <div className={'h-24'} />
          <SectionTitleH2 title={'Catastrophic Coverage phase'}
                          subtitle={plan?.drugDetails?.catastrophicCoverageDescription}
                          onToggle={value => setExpandedState((prevState: any) => ({...prevState, gapCoverage: value}))}
                          expanded={getExpandedValue('gapCoverage')}
          />
          <Box ml={'72px'} mr={'55px'}>
            <SimplePlanDetail title={"Generic drugs"}
                              content={'$0 or $3.70 copay (depending on your level of Extra Help)'}/>
            <SimplePlanDetail title={"Branded drugs"}
                              content={'$0 or $9.20 copay (depending on your level of Extra Help)'}/>
          </Box>
        </Grid>
      </Grid>
    </AscendCard>
    </>
}
