import React from 'react';
import {useLazyQuery} from "@apollo/react-hooks";
import {ApolloError, gql} from "apollo-boost";
import {PageablePlanOutput, QueryPlansArgs} from "../../../types";

export default function usePlans(onCompleted: (response: IPlansOutput) => void, onError?: (error: ApolloError) => void) {

  return useLazyQuery<IPlansOutput, QueryPlansArgs>(gql(getPlans), {
    onCompleted: onCompleted,
    fetchPolicy: "no-cache",
    onError
  });
}
//   return useLazyQuery(<IPlanSearchOutput>(gql(getPlans), {
//
//   }))
// }

interface IPlansOutput {
  plans: PageablePlanOutput
}

const getPlans = `
query($filter: PlansFilterInput!, $page: PageInput!, $sort: [PlansSortInput!]!, $quoteId: UUID) {
    plans(filter: $filter, page: $page, quoteId: $quoteId, sort: $sort) {
      data  {
        SNPType
        monthlyPremium
        planName
        planType
        bidId
        ratingNote
        orgName
        parentOrgName
        outOfPocketMaximum
        primaryCarePhysicianShort
        physicianSpecialistShort
        rating
        partBAmount
        partBGiveBack
        outOfPocketAmount
        maxEnrollmentAmount
        inNetworkDeductableAmount
        firstWeekOfStay
        drugDetails {
          mrxAltDedAmount
        }
        drugsCoverage{
          rxcui
          coverage
          name
          tier
        }
        doctorsCost
        drugsCost
        doctorsCoverage {
          npi
          coverage
          name
        }
        monthlyCost
        extraBenefits {
          drugCoverage
          dental
          vision
          hearing
          transportation
          fitness
          telehealth      
          worldwideEmergency
          insulinSavings
          overTheCounter
        }
      }
      totalElements
    }
}
`;
