import React, {useState} from "react";
import {Badge, Box, Button, createStyles, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {colors} from "../../shared/AppTheme";
import PlanCard, {RoundedButton} from "./PlanCard";
import usePlans from "./hooks/usePlans";
import {Field, Plan, Sort} from "../../types";
import {PlanFilterContext} from "./PlanFilterContext";
import {QuoteContext} from "./QuoteContext";
import ComparisonPanel from "../PlanComparison/ComparisonPanel";
import useGetCounty from "../../Questionnaire/hooks/useGetCounty";
import UpdateZipModal from "./components/UpdateZipModal";
import useSaveRequest from "../../Questionnaire/hooks/useSaveRequest";
import {getQuoteId, setQuoteId} from "../../shared/QuoteId";
import paths from "../config/router-paths";
import {useHistory} from "react-router-dom";
import PlanTopFilter from "./PlanTopFilter";
import Footer from "../shared/Footer";
import AscendPreloader from "../../shared/components/AscendPreloader";
import CreateAccountModal from "./components/CreateAccountModal";
import withErrorBoundary from "../../shared/withErrorBoundary";
import PlanTour from "./components/PlanTour";
import {FavoritesContext} from "../shared/FavoritesContext";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
    card: {
      padding: '24px 30px',
      maxWidth: 1000
    },
    disclosure: {
      fontSize: 20,
      fontWeight: 500,
      marginRight: 8,
      marginTop: 1,
    },
    sort: {
      padding: '4px 14px 2px',
      fontSize: 12,
      color: colors.text.primary,
      cursor: 'pointer',
      borderRadius: 4,
      fontWeight: 700
    },
    sortActive: {
      color: colors.custom.green.variant1,
    },
    underline: {
      borderBottom: '1px dashed #1C434F',
      cursor: 'pointer'
    },
    county: {
      cursor: 'pointer'
    },
    seeMoreButton: {
      '& .MuiButton-label': {
        textTransform: 'none',
        color: colors.custom.green.variant2
      }
    },
    hugeText: {
      fontSize: 32,
      fontWeight: 700,
    },
    bold: {
      fontWeight: 700,
    },
    thin: {
      fontWeight: 400,
    }
  }),
);

function PlanList() {
  const pageSize = 20;

  const history = useHistory();

  const classes = useStyles();
  const [sortBy, setSortBy] = React.useState<Field>(Field.MonthlyCost);
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const {id, county} = React.useContext(QuoteContext);
  const {favorites} = React.useContext(FavoritesContext);
  const [save] = useSaveRequest();
  const {authenticated} = React.useContext(KeycloakContext);
  const { enqueueSnackbar } = useSnackbar();

  const [total, setTotal] = React.useState<number>();
  const [request, results] = usePlans((response) => {
    setPlans([...plans, ...response.plans.data])
  }, error => {
    setPlans([]);
    enqueueSnackbar('Something wrong. Please try again later.', {variant: 'error'}); // todo add text
  });

  const [updateZipModalOpen, setUpdateZipModalOpen] = React.useState(false);
  const [createAccountModalOpen, setCreateAccountModalOpen] = React.useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!authenticated) {
      if (!localStorage.getItem('tourShown')) {
        localStorage.setItem('tourShown', 'true');
        setIsTourOpen(true)
      } else if (!localStorage.getItem('createAccountShown') && id) {
        setTimeout(() => {
          localStorage.setItem('createAccountShown', 'true');
          setCreateAccountModalOpen(true)
        }, 7000)
      }
    }
  }, [])

  const onTourClose = () => {
    setIsTourOpen(false);
    if (!localStorage.getItem('createAccountShown') && id && !authenticated) {
      setTimeout(() => {
        localStorage.setItem('createAccountShown', 'true');
        setCreateAccountModalOpen(true)
      }, 7000)
    }
  }

  const {values: filterValues, switchZip} = React.useContext(PlanFilterContext)

  const loadMore = React.useCallback(() => {
    setPage({
      ...page,
      page: page.page + 1
    })
  },[page]);

  React.useEffect(() => {
    if (filterValues?.zip) {
      setPlans([]);
      setPage({
        page: 0,
        size: pageSize
      });
      window.scrollTo(0, 0)
    }
  }, [filterValues, sortBy]);

  const quoteId = React.useMemo(() => {
    return id || getQuoteId();
  }, [id])

  React.useEffect(() => {
    if (hasMorePlans && filterValues) {
      request({
        variables: {
          filter: filterValues,
          page,
          quoteId,
          sort: sortBy === Field.Rating ?[
            { "field": Field.Rating,"direction":Sort.Desc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ] : [
            { "field": Field.MonthlyCost,"direction":Sort.Asc },
            { "field": Field.Premium,"direction":Sort.Asc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ]
        }
      })
    }
  }, [page]);

  const hasMorePlans = React.useMemo(() => {
    return !total || plans.length < total;
  }, [total, plans.length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data?.plans.totalElements)
    }
  }, [results.data]);

  const onUpdateZipModalClose = (zip?: string, county?: string) => {
    if (zip && county) {
      save({
        variables: {
          data: {
            county,
            zip,
            form: 'SEARCH_RESULTS',
            id: quoteId,
          }
        },
      }).then((data: any) => {
        switchZip(zip, county);
        setQuoteId(data.data.saveMedicareQuote)
      }).finally(() => {
        document.location.href = paths.plans + zip + '/' + county;
      })
    }
    setUpdateZipModalOpen(false);
  }

  return <>
    <PlanTour open={isTourOpen} onClose={onTourClose} />
    <CreateAccountModal open={createAccountModalOpen} onClose={() => setCreateAccountModalOpen(false)} />
    <UpdateZipModal open={updateZipModalOpen}
                    title={'Update your zip code'}
                    subtitle={'Your plan options will update automatically.'}
                    onClose={onUpdateZipModalClose}
                    zip={filterValues?.zip}
                    county={county} />
    <Box flexDirection={'column'} my={'15px'} maxWidth={'800px'} alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>We’ve found {total} Medicare Advantage plan{total !== 1 ? 's' : ''} in your area.</Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-18 mt-16'} color={'textPrimary'}>
          Interested in <span style={{borderBottom: '1px dotted ' + colors.text.primary}}>Medicare Supplement Insurance Plans (Medigap)</span> or <span style={{borderBottom: '1px dotted ' + colors.text.primary}}>Prescription Drug (PDP)</span> plans instead?
        </Typography>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={4}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant={'h4'} color={'textPrimary'} className={classes.title}>Showing plans for:</Typography>
          <Box ml={1} className={classes.county} display={'flex'} alignItems={'center'} onClick={() => setUpdateZipModalOpen(true)}>
            <Typography variant={'h4'} color={'textPrimary'} className={classes.bold}>{filterValues?.zip} {filterValues?.countyName}</Typography>
            <Box ml={1} pt={'5px'}>
              <img src={'/img/edit.svg'}/>
            </Box>
          </Box>
        </Box>
        <RoundedButton variant={"contained"}
                       classes={{
                         contained: 'ph-16 pv-5'
                       }}
                       onClick={() => {}}>
          <img src={'/img/envelope.svg'} className={'mr-10'} />
          Email me this page</RoundedButton>
      </Box>
      <Box mt={'19px'}>
        <PlanTopFilter />
      </Box>
      <Box mt={'16px'}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'-16px'}>
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant={'h5'} color={'textSecondary'} className={'bold'}>Sort by:</Typography>
            <Box ml={'13px'}>
              <div className={clsx({[classes.sort]: true, [classes.sortActive]: sortBy === Field.MonthlyCost})}
                   onClick={() => setSortBy(Field.MonthlyCost)}>Lowest Cost
              </div>
            </Box>
            <Box ml={'10px'}>
              <div className={clsx({[classes.sort]: true, [classes.sortActive]: sortBy === Field.Rating})}
                   onClick={() => setSortBy(Field.Rating)}>Star rating
              </div>
            </Box>
          </Box>
          <div className={'flex flex-align-center pointer'} onClick={() => history.push(paths.favorites + filterValues?.zip + '/' + filterValues?.countyName)}>
              <Typography className={'fs-12 medium'} color={'textPrimary'}>See your favorites</Typography>
              <div className={'w-5'} />
              <Badge color="primary" overlap="circle" badgeContent={favorites.length} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
                <img width={30} height={30} src={'/img/heart.svg'}/>
              </Badge>
          </div>
        </Box>

        {!!filterValues && plans.map(plan => <PlanCard plan={plan}
                                     zip={filterValues.zip}
                                     countyName={filterValues.countyName}
                                     key={plan.bidId} />)}

        {results.loading &&
          <Box textAlign={"center"} my={2}>
            <AscendPreloader />
          </Box>
        }
        {(hasMorePlans && !results.loading && plans.length > 0) && <Box textAlign={"center"} mt={'32px'}>
          <Button disabled={results.loading} className={classes.seeMoreButton} onClick={loadMore}
                  endIcon={<ExpandMoreIcon />}><div style={{textDecoration: 'underline'}}>See more plans</div></Button>
        </Box>}
      </Box>
      <Footer />
    </Box>
    {filterValues?.zip && <ComparisonPanel countyName={filterValues?.countyName} zip={filterValues?.zip} />}
  </>
}

export default withErrorBoundary(PlanList);
