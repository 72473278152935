import React from 'react';
import {DrugCoverage} from "../../../types";
import {PlanParam} from './PlanParam';
import Benefit from "../../../shared/components/Benefit";
import {PreferenceLine} from "../../PlanList/HealthcarePreferences";
import {Typography} from "@material-ui/core";

function DrugCoverageList({coverage}: { coverage: DrugCoverage[] }) {
  if(coverage.length === 0) {
    return <Typography className={'fs-12'} color={'textPrimary'}>You haven’t added any drugs</Typography>;
  }

  return <>
    {coverage.map((drug, index) => <PlanParam key={drug.rxcui}
                                              value={<Benefit value={drug.coverage}
                                                              label={drug.name || 'Drug is missing'}/>}/>)}
  </>
}

export {DrugCoverageList}
