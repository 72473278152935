import React from "react";
import {
  Box,
  Typography,
} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import AscendStepper from "../../shared/SidePanel/AscendStepper";
import Terms from "../../shared/SidePanel/Terms";
import useEnrollmentStepperState, {setCompletedByLabel} from "../hooks/useEnrollmentStepperState";


export default function EnrollmentStepper(props: RouteComponentProps<{routeName: string}>) {
  const {state} = useEnrollmentStepperState();

  React.useEffect(() => {
    if (props.match.params.routeName) {
      for (const step of state.steps) {
        let completed: boolean;
        if (step.questions?.length) {
          completed = step.questions?.some(q => {
            return q.link === (props.match.params.routeName || '')
          });
        } else {
          completed = step.firstStep === props.match.params.routeName;
        }
        setCompletedByLabel(step.label, !completed);
        if (completed) {
          break;
        }
      }
      window.scrollTo(0, 0)
    }
  }, [props.match.params.routeName])

  return (
    <>
      <Box marginTop={'24px'} maxWidth={245}>
        <Typography color={'textPrimary'} className={'bold fs-20'}>Enrollment progress:</Typography>
      </Box>
      <AscendStepper {...props} state={state} />
      <Terms />
    </>
  )
}
