import React from "react";
import {Box, Collapse, createStyles, IconButton, Theme, Typography, withStyles} from "@material-ui/core";
import AscendButton from "../components/AscendButton";
import AscendModal from "../components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import FormTextInput, {TickAdornment} from "./FormTextInput";
import {useSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneNumberInput from "./PhoneNumberInput";
import ReceivedRequestModal from "./ReceivedRequestModal";
import useSaveUserProfile from "../../Private/hooks/useSaveUserProfile";
import {ProfileOption} from "../../enrollment-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      textAlign: 'center',
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer'
    },
    phone: {
      color: colors.custom.green.variant1,
      display: 'flex',
      borderRadius: 4,
      fontSize: 36,
      fontWeight: 700,
      margin: '0 0 0 8px',
      textDecoration: 'none',
      '& img': {
        marginRight: 10
      }
    },
    checkboxWrapper: {
      alignItems: 'flex-start',
    },
    wrapper: {
      minWidth: 500,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    nameWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    textLink: {
      color: colors.text.secondary,
    }
  })
);

type NotServedModalProps = {
  onSubmit: (zip?: string) => void,
  onClose: () => void,
  open: boolean
}

function NotServedModal(props: NotServedModalProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [requestACall] = useSaveUserProfile();

  const {register, handleSubmit, errors, watch, formState, control, getValues} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()

  const onSubmit = (values: any) => {
    if (formState.isValid) {
      requestACall({variables: {
          data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            zip: values?.zip,
            options: [ProfileOption.UnsupportedZip],
            cId: (window as any).gaGlobal?.vid,
          }
        }}).then((res) => {
        //enqueueSnackbar('Request submitted', {variant: 'success'});
        props.onSubmit(values?.zip);
      }).catch(() => {
        enqueueSnackbar('Error', {variant: 'error'}); // todo add text
        props.onClose();
      });
    }
  }

  return <AscendModal
    width={590}
    open={props.open}
    onClose={props.onClose}
    modalScroll
  >
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.centered}>Sorry, we’re not in your area yet!</Typography>
        </Box>
        <Box display={'flex'} mt={'24px'} mb={2} justifyContent={'center'}>
          <Typography variant={'h3'} color={'textSecondary'} className={classes.centered}>We currently don’t operate in your state.  We can have one of our trusted insurance brokerage partners reach out to you to help serve your needs.<br /><br /><strong>Add your details below to be contacted by one of Ascend’s partners.</strong>
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
          <Box flex={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box className={classes.wrapper}>
              <Box className={classes.nameWrapper} >
                <FormTextInput fullWidth
                               name={'firstName'}
                               autoFocus={true}
                               error={formState.touched.firstName && !!errors.firstName}
                               helperText={formState.touched.firstName && errors?.firstName?.message}
                               inputRef={register}
                               InputProps={{
                                 endAdornment: (formState.touched.firstName && !errors.firstName && getValues().firstName?.length > 0) ?
                                   <TickAdornment/> : null
                               }}
                               placeholder={'First name'} />
                <div className={'w-20'} />
                <FormTextInput fullWidth
                               name={'lastName'}
                               error={formState.touched.lastName && !!errors.lastName}
                               helperText={formState.touched.lastName && errors?.lastName?.message}
                               inputRef={register}
                               InputProps={{
                                 endAdornment: (formState.touched.lastName && !errors.lastName && getValues().lastName?.length > 0) ?
                                   <TickAdornment/> : null
                               }}
                               placeholder={'Last name'} />
              </Box>
              <Box className={classes.nameWrapper} >
                <FormTextInput name={'zip'}
                               inputProps={{
                                 autoCapitalize: 'none'
                               }}
                               placeholder={'ZIP code'}
                               InputProps={{
                                 endAdornment: (formState.touched.zip && !errors.zip && getValues().zip?.length > 0) ?
                                   <TickAdornment/> : null
                               }}
                               error={!!errors.zip && formState.touched.zip}
                               helperText={formState.touched.zip && errors?.zip?.message}
                               inputRef={register} />
                <div className={'w-20'} />
                <Controller
                  control={control}
                  name={'phoneNumber'}
                  render={({ onChange }) => <PhoneNumberInput label={''} placeholder={'Phone'} name={'phoneNumber'} onChange={onChange} />}
                />
              </Box>
              <FormTextInput fullWidth
                             name={'email'}
                             inputProps={{
                               autoCapitalize: 'none'
                             }}
                             InputProps={{
                               endAdornment: (!errors.email && getValues().email?.length > 0 && formState.touched.email) ?
                                 <TickAdornment/> : null
                             }}
                             placeholder={'Email'}
                             error={(!!errors.email && formState.touched.email)}
                             helperText={(formState.touched.email && errors?.email?.message)}
                             inputRef={register} />
              <Box display={'flex'} alignItems={'center'} className={'mb-35'}>
                <img src={'/img/padlock.svg'} />
                <Typography className={'bold fs-14 ml-8'} color={'textPrimary'}>Your data is 100% secure.</Typography>
              </Box>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <CallButton variant={'contained'}
                        className={'gtm-button-event'} data-gtm-button-name={'CREATE_PROFILE_BUTTON'}
                        disabled={!formState.isValid} type={'submit'}>Submit</CallButton>
          </Box>
        </form>
        <Typography variant={'body1'} color={'textSecondary'} className={'mt-24'}>By clicking ‘Get my quotes’ above and submitting my email and phone number, I confirm that the email and phone number provided is my own, and I provide my express written consent to be contacted by Ascend.com and its owner and operator Ascend Holdings Inc., including its subsidiaries and/or its network of insurance agents, brokers and other licensed insurance providers at the email or phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of email, text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with Ascend are subject to Ascend's Privacy Policy and Terms of Use.<br /><br />

          I understand my consents to receive text messages and telephone calls are not required to receive services from Ascend; I may instead reach Ascend directly at +1 (888) 969-7667 and I can revoke my consents at any time by emailing support@ascendmedicare.com.</Typography>
      </Box>
  </AscendModal>
}

interface FormState {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  zip: string;
}
const Schema = yup.object().shape({
  firstName: yup.string().required("Please enter a valid first name").typeError('Please enter a valid first name'),
  lastName: yup.string().required("Please enter a valid last name").typeError('Please enter a valid last name'),
  email: yup.string().email('Enter a valid email address').required("Enter a valid email address"),
  phoneNumber: yup.string().required("Please enter a valid phone").min(10, "Please enter a valid phone"),
  zip: yup.string().required("Please enter a valid zip code"),
});

export const RoundedButton = withStyles({
  contained: {
    borderRadius: 4,
    width: 310,
    padding: '15px 0 11px',
  },
  label: {
    fontSize: 24,
  },
})(AscendButton);

export const CallButton = withStyles({
  contained: {
    borderRadius: 4,
    width: 310,
    padding: '14px 0 11px',
  },
  label: {
    fontSize: 16,
  },
})(AscendButton);

export default function (props: {open: boolean, onClose: () => void, zip: string}) {
  const [showNotServed, setShowNotServed] = React.useState(false);
  const [showReceivedRequest, setShowReceivedRequest] = React.useState(false);

  React.useEffect(() => {
    if (props.open && props.zip) {
      const sent = getSent();
      if (sent.includes(props.zip)) {
        setShowNotServed(false)
        setShowReceivedRequest(true)
      } else {
        setShowNotServed(true)
        setShowReceivedRequest(false)
      }
    } else {
      setShowNotServed(false)
      setShowReceivedRequest(false)
    }
  }, [props.open, props.zip])
  return <>
    <NotServedModal onClose={props.onClose} open={showNotServed} onSubmit={(zip?: string) => {
      setShowNotServed(false)
      setShowReceivedRequest(true)
      if (zip) {
        let sent = getSent();
        sent = [...sent, zip];
        sessionStorage.setItem('NOT_SERVED_SENT', JSON.stringify(sent));
      }
    }}/>
    <ReceivedRequestModal onClose={props.onClose} open={showReceivedRequest}/>
  </>
}

const getSent = (): string[] => {
  let sent: any = sessionStorage.getItem('NOT_SERVED_SENT');
  if (sent) {
    sent = JSON.parse(sent);
  } else {
    sent = [];
  }
  return sent;
}
