import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, RadioGroup} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendButton from "../../shared/components/AscendButton";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import AscendTitle from "../../shared/components/AscendTitle";
import useCommonStyles from "../../shared/useCommonStyles";
import {storeFilters} from "../../shared/utils";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";

const formName = 'QUIZ_2';

function Quiz2(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [prepay, setPrepay] = React.useState(state.QUIZ_2?.prepay?.value);
  const [certainDoctors, setCertainDoctors] = React.useState(state.QUIZ_2?.certainDoctors?.value);
  const [drugsCoverage, setDrugsCoverage] = React.useState(state.QUIZ_2?.drugsCoverage?.value);
  const [extraBenefits, setExtraBenefits] = React.useState(state.QUIZ_2?.extraBenefits?.value);
  const [monthlyBudget, setMonthlyBudget] = React.useState(state.QUIZ_2?.monthlyBudget?.value);
  const [receiveMedicaid, setReceiveMedicaid] = React.useState(state.QUIZ_2?.receiveMedicaid?.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: createPayload(
        prepay,
        monthlyBudget,
        certainDoctors,
        drugsCoverage,
        extraBenefits,
        receiveMedicaid,
      )
    })
    if (receiveMedicaid) {
      storeFilters({ SNPTypes: ["D_SNP"] }, props.match.params.zip)
    }
    if (drugsCoverage) {
      storeFilters({ SNPTypes: ["D_SNP"] }, props.match.params.zip)
    }
    props.history.push(paths.questionnaire + '/' + questionsRoutes.quiz3)
  };

  return <>
    <div className={'h-32'} />
    <AscendTitle title={questions[0]} />
    <div className={'h-8'} />
    <RadioGroup>
      <AscendRadio checked={!!prepay}
                   onChange={() => {setPrepay(true)}}
                   label={answers[0][1]}
      />
      <div className={'h-8'} />
      <AscendRadio checked={prepay === false}
                   onChange={() => {setPrepay(false)}}
                   label={answers[0][0]}
      />
    </RadioGroup>
    <Collapse in={typeof prepay !== "undefined"}>
      <div className={'h-32'} />
      <AscendTitle title={questions[1]} />
      <div className={'h-8'} />
      <RadioGroup>
        <AscendRadio checked={!!monthlyBudget}
                     onChange={() => {setMonthlyBudget(true)}}
                     label={answers[1][1]}
        />
        <div className={'h-8'} />
        <AscendRadio checked={monthlyBudget === false}
                     onChange={() => {setMonthlyBudget(false)}}
                     label={answers[1][0]}
        />
      </RadioGroup>
    </Collapse>
    <Collapse in={typeof monthlyBudget !== "undefined"}>
      <div className={'h-32'} />
      <AscendTitle title={questions[2]} />
      <div className={'h-8'} />
      <RadioGroup>
        <AscendRadio checked={!!certainDoctors}
                     onChange={() => {setCertainDoctors(true)}}
                     label={answers[2][1]}
        />
        <div className={'h-8'} />
        <AscendRadio checked={certainDoctors === false}
                     onChange={() => {setCertainDoctors(false)}}
                     label={answers[2][0]}
        />
      </RadioGroup>
    </Collapse>
    <Collapse in={typeof certainDoctors !== "undefined"}>
      <div className={'h-32'} />
      <AscendTitle title={questions[3]} />
      <div className={'h-8'} />
      <RadioGroup>
        <AscendRadio checked={!!drugsCoverage}
                     onChange={() => {setDrugsCoverage(true)}}
                     label={answers[3][1]}
        />
        <div className={'h-8'} />
        <AscendRadio checked={drugsCoverage === false}
                     onChange={() => {setDrugsCoverage(false)}}
                     label={answers[3][0]}
        />
      </RadioGroup>
    </Collapse>
    <Collapse in={typeof drugsCoverage !== "undefined"}>
      <div className={'h-32'} />
      <AscendTitle title={questions[4]} />
      <div className={'h-8'} />
      <RadioGroup>
        <AscendRadio checked={extraBenefits === false}
                     onChange={() => {setExtraBenefits(false)}}
                     label={answers[4][1]}
        />
        <div className={'h-8'} />
        <AscendRadio checked={!!extraBenefits}
                     onChange={() => {setExtraBenefits(true)}}
                     label={answers[4][0]}
        />
      </RadioGroup>
    </Collapse>
    <Collapse in={typeof extraBenefits !== "undefined"}>
      <div className={'h-32'} />
      <AscendTitle title={questions[5]} />
      <div className={'h-8'} />
      <RadioGroup>
        <AscendRadio checked={!!receiveMedicaid}
                     onChange={() => {setReceiveMedicaid(true)}}
                     label={answers[5][1]}
        />
        <div className={'h-8'} />
        <AscendRadio checked={receiveMedicaid === false}
                     onChange={() => {setReceiveMedicaid(false)}}
                     label={answers[5][0]}
        />
      </RadioGroup>
    </Collapse>
    <Box className={commonClasses.buttonWrapper} mt={'30px'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton className={'gtm-step-event'} id={formName} disabled={typeof receiveMedicaid === 'undefined'} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
  </>;
}

export default withTitle(
  Quiz2,
  () => 'Let’s confirm which type of Medicare program is right for you.',
  'We’ll provide a high-level comparison of costs and features after these selections.'
);

export const createPayload = (prepay: boolean, monthlyBudget: boolean, certainDoctors: boolean, drugsCoverage: boolean, extraBenefits: boolean, receiveMedicaid: boolean) => ({
  stateKey: formName,
  form: formName,
  prepay: {
    value: prepay,
    questionText: questions[0],
    answerText: answers[0][prepay ? 1 : 0]
  },
  monthlyBudget: {
    value: monthlyBudget,
    questionText: questions[1],
    answerText: answers[1][monthlyBudget ? 1 : 0]
  },
  certainDoctors: {
    value: certainDoctors,
    questionText: questions[2],
    answerText: answers[2][certainDoctors ? 1 : 0]
  },
  drugsCoverage: {
    value: drugsCoverage,
    questionText: questions[3],
    answerText: answers[3][drugsCoverage ? 1 : 0]
  },
  extraBenefits: {
    value: extraBenefits,
    questionText: questions[4],
    answerText: answers[4][extraBenefits ? 1 : 0]
  },
  receiveMedicaid: {
    value: receiveMedicaid,
    questionText: questions[5],
    answerText: answers[5][receiveMedicaid ? 1 : 0]
  }
})


const questions = [
  'How do you prefer to pay for your healthcare costs?',
  'What is your monthly premium budget?',
  'How important is choice when it comes to visiting doctors and hospitals?',
  'Do you want prescription drug coverage?',
  'Are extra benefits in your plan (like vision, dental and hearing benefits) important to you?',
  'Do you currently receive Medicaid benefits?'
];



const answers = [
  [
    "Pay out-of-pocket costs as I go, with low (or no) monthly premiums",
    "Pay a monthly premium for more comprehensive coverage, with limited out-of-pocket costs when I use healthcare services",
  ],
  [
    "Under $100 per month",
    "$100+ per month"
  ],
  [
    "I’m okay with using a network of healthcare providers if it lowers costs",
    "I want to be able to see doctors outside of a network"
  ],
  [
    "No",
    "Yes"
  ],
  [
    "Yes — I’m looking for a plan that includes extra benefits such as vision, dental and hearing",
    "No — I’m indifferent to whether my plan includes any extra benefits"
  ],
  [
    "No",
    "Yes"
  ],
];
