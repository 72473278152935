import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
import * as Questionnaire from './Questionnaire/App';
import * as Enrollment from './Enrollment/App';
import * as EnrollmentLong from './EnrollmentLong/App';
import * as Private from './Private/App';
import * as Quote from './Quote/App';
import * as serviceWorker from './serviceWorker';
import {MuiThemeProvider} from "@material-ui/core";
import AppTheme from "./shared/AppTheme";
import { SnackbarProvider } from 'notistack';
import Preloader from "./shared/Preloader";

const QuestionnaireApp = Questionnaire.default;
const EnrollmentApp = Enrollment.default;
const EnrollmentLongApp = EnrollmentLong.default;
const PrivateApp = Private.default;
const QuoteApp = Quote.default;

const getApp = () => {
  if (process.env.REACT_APP_SITE === 'Quote') {
    return <QuoteApp/>
  }
  if (process.env.REACT_APP_SITE === 'Questionnaire') {
    return <QuestionnaireApp/>
  }
  if (process.env.REACT_APP_SITE === 'Enrollment') {
    return <EnrollmentApp/>
  }
  if (process.env.REACT_APP_SITE === 'EnrollmentLong') {
    return <EnrollmentLongApp/>
  }
  if (process.env.REACT_APP_SITE === 'Private') {
    return <PrivateApp/>
  }
  return <App/>
}

ReactDOM.render(
  <MuiThemeProvider theme={AppTheme}>
    <SnackbarProvider maxSnack={3} preventDuplicate={true}>
      <Suspense fallback={<Preloader />}>
        {getApp()}
      </Suspense>
    </SnackbarProvider>
  </MuiThemeProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

