import React from "react";
import {Box, Collapse, createStyles, Grid, MenuItem, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../App/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendCheckbox from "../shared/components/AscendCheckbox";
import AscendButton from "../shared/components/AscendButton";
import PersonalDetailsConfirmModal from "./component/PersonalDetailsConfirmModal";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import {EnrollmentInput, PreferredLanguageDto} from "../enrollment-types";
import moment from "moment";
import * as _ from 'lodash';
import {client} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import useGetCounty from "../Questionnaire/hooks/useGetCounty";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import useUserProfile, {useUserProfileLazy} from "../Private/hooks/useUserProfile";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import MedicareNumberInput from "../shared/components/MedicareNumberInput";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import AscendRadio from "../shared/components/AscendRadio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    checkbox: {
      marginLeft: -15,
      alignItems:'flex-start'
    },
    checkboxLeft: {
      marginLeft: -15,
    },
    radioLeft: {
      marginLeft: -8,
    }
  })
);
const formName = 'EnrollmentForm';

const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  lastName: yup.string().required("Enter a valid last name"),
  city: yup.string(),
  aptNumber: yup.string(),
  street: yup.string(),
  phoneNumber: yup.string(),
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
  email: yup.string().required("Enter a valid email address").email('Enter a valid email address'),
});

interface PersonalDetailsFormState {
  firstName: string;
  lastName: string;
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  street: string;
  phoneNumber: string;
}

function EnrollmentForm(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, setState] = React.useState<string>('');
  const [represent, setRepresent] = React.useState();
  const [agreement, setAgreement] = React.useState(false);
  const [partC, setPartC] = React.useState(false);
  const [partD, setPartD] = React.useState(false);
  const [medigap, setMedigap] = React.useState(false);
  const [hospital, setHospital] = React.useState(false);
  const [benefits, setBenefits] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const [save] = useSaveEnrollment();
  const [getCounty, countyData] = useGetCounty();
  const [county, setCounty] = React.useState<string | undefined>('');
  const [getUserInfo, userInfoData] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  React.useEffect(() => {
    if (zip) {
      getCounty({variables: {zip}})
    }
  }, [zip])

  React.useEffect(() => {
    if (countyData.data?.plans_zip_county_fips && countyData.data?.plans_zip_county_fips.length === 1) {
      setCounty(countyData.data?.plans_zip_county_fips[0].countyname)
    }
  }, [countyData.data]);

  const counties = React.useMemo(() => {
    return countyData.data?.plans_zip_county_fips || [];
  }, [countyData.data, countyData.called])

  const permanentStates = React.useMemo(() => {
    return _.uniq(countyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [countyData.data?.plans_zip_county_fips])

  const [getPlan, {data}] = usePlanLazy({ client });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const values: EnrollmentInput = React.useMemo(() => {
    return {
      quoteId: authenticated ? userInfoData?.data?.userProfile.quoteId : getStoredUser()?.quoteId,
      id: authenticated ? undefined : getStoredUser()?.enrollmentId,
      bidId: planId,
      email: getValues().email,
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      permanentAddress: {
        aptNumber: getValues().aptNumber,
        city: getValues().city,
        state: state || '',
        streetName: getValues().street,
        zip: getValues().zip,
      },
      phoneNumber: getValues().phoneNumber,
    }
  }, [getValues(), state])

  const onSubmit = () => {
    if (formState.isValid && state) {
      save({variables: {data: values}}).then(data => {
        setStoredUser({
          ...getStoredUser(),
          enrollmentId: data?.data?.saveEnrollment,
        })
      });
      props.history.push(paths.receive)
    }
  };

  React.useEffect(() => {
    if (authenticated) {
      getUserInfo();
    }
  }, [authenticated])

  React.useEffect(() => {
    if (userInfoData.data?.userProfile) {
      setState(userInfoData.data.userProfile.state || '');
    }
  }, [userInfoData])

  const userInfo = React.useMemo(() => {
    if (authenticated) {
      return userInfoData.data?.userProfile;
    } else if (getStoredUser()) {
      return getStoredUser()
    }
    return undefined
  }, [authenticated, userInfoData])

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800}>
    {plan && <div className={'mb-40'}><ShortPlanCard plan={plan}/></div>}
    <Typography color={'textPrimary'} className={'bold fs-26'}>Personal details</Typography>
    <Typography color={'textPrimary'} className={'medium fs-18'}>We need to collect some additional details first, so one of our licensed sales agents can contact you regarding your enrollment.<br /><br /> <i>Fields marked with an asterisk (*) are required.</i></Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Personal information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'firstName'}
                           defaultValue={userInfo?.firstName}
                           error={formState.isSubmitted && !!errors.firstName}
                           helperText={formState.isSubmitted && errors?.firstName?.message}
                           inputRef={register}
                           placeholder={'First name'}
                           label={'First name*'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           defaultValue={userInfoData.data?.userProfile?.lastName}
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Last name'}
                           label={'Last name*'} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           label={'Email address*'}
                           name={'email'}
                           defaultValue={userInfo?.email}
                           inputProps={{
                             autoCapitalize: 'none'
                           }}
                           placeholder={'Enter your email address'}
                           error={!!errors.email && formState.isSubmitted}
                           helperText={formState.isSubmitted && errors?.email?.message}
                           inputRef={register} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={'phoneNumber'}
              render={({ onChange }) => <PhoneNumberInput label={'Phone number'} placeholder={'+1 (123) 456-7890'} name={'phoneNumber'} onChange={onChange} />}
            />
          </Grid>

          <Grid item xs={12}>
            <AscendDivider className={classes.divider} mb={'32px'} />
            <Typography className={'bold fs-24'}>Permanent address</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'street'}
                           defaultValue={userInfoData.data?.userProfile?.streetName}
                           error={formState.isSubmitted && !!errors.street}
                           helperText={formState.isSubmitted && errors?.street?.message}
                           inputRef={register}
                           placeholder={'Enter street address'}
                           label={'Street Address (P.O. box not allowed)'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'aptNumber'}
                           defaultValue={userInfoData.data?.userProfile?.aptNumber}
                           error={formState.isSubmitted && !!errors.aptNumber}
                           helperText={formState.isSubmitted && errors?.aptNumber?.message}
                           inputRef={register}
                           placeholder={'Enter apt. / unit'}
                           label={'Apt. / Unit'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'city'}
                           defaultValue={userInfoData.data?.userProfile?.city}
                           error={formState.isSubmitted && !!errors.city}
                           helperText={formState.isSubmitted && errors?.city?.message}
                           inputRef={register}
                           placeholder={'Enter city'}
                           label={'City'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'State'}
              value={state}
              placeholder={'Select state'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setState(event.target.value as string);
              }}
            >
              {permanentStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectInput
              fullWidth
              label={'County'}
              value={county}
              disabled={!counties.length}
              placeholder={'Select county'}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setCounty(event.target.value as string);
              }}
            >
              {
                counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
              }
            </FormSelectInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput error={formState.isSubmitted && !!errors.zip}
                           helperText={formState.isSubmitted && errors?.zip?.message}
                           fullWidth
                           inputRef={register}
                           label={'Zip'}
                           name={'zip'}
                           value={zip}
                           inputProps={{
                             maxLength: 5
                           }}
                           type={'tel'}
                           placeholder={'Enter 5 digit zip code'} />
          </Grid>

        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'100px'}>
        <AscendButton variant={'contained'} onClick={() => {
          history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Confirm and submit</AscendButton>
      </Box>
    </form>
  </Box>
}

export default EnrollmentForm;
