import React, {FunctionComponent} from "react";
import {
  Box,
  createStyles, Drawer, Hidden, IconButton,
  makeStyles,
  Theme, useMediaQuery, useTheme,
} from "@material-ui/core";
import clsx from 'clsx';
import paths from "../Questionnaire/config/router-paths";
import withErrorBoundary from "./withErrorBoundary";
import SidePanel from "./SidePanel/SidePanel";
import AscendStepper from "./SidePanel/AscendStepper";
import {colors} from "./AppTheme";
import PlanFilter from "../App/PlanList/PlanFilter";
import {RouteComponentProps} from "react-router";
import ContactsSection from "./components/ContactsSection";
import {AdminContext} from "../App/PlanList/AdminContext";
import {AdminNotificationHeight} from "../App/PlanList/AdminNotification";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      backgroundColor: '#F5F7F6',
      flexDirection: 'column',
      padding: '16px 20px 200px',
      [theme.breakpoints.down('lg')]: {
        padding: '16px 20px',
      },
    },
    drawerPaper: {
      width: 320,
      backgroundColor: 'white',
    },
    drawerOffsetForNotification: {
      top: AdminNotificationHeight,
    },
    mobileTitle: {
      fontWeight: 600,
      marginTop: 5,
    },
    phoneButton: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
    sideContainer: {
      height: '100%'
    }
  }),
);

interface LayoutProps extends RouteComponentProps<any> {
  SideComponent: FunctionComponent,
  children: JSX.Element,
  sideHeaderContent?: JSX.Element,
  pin?: string,
}

function ScrollableLayout({SideComponent, ...props}: LayoutProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const adminContext = React.useContext(AdminContext);

  return (
    <Box display={'flex'} flex={1}>
      {phone && <Drawer
        variant="temporary"
        anchor={'left'}
        open={mobileOpen}
        onClose={() => setMobileOpen(!mobileOpen)}
        classes={{
          paper: clsx({[classes.drawerPaper]: true, [classes.drawerOffsetForNotification]: !!adminContext}),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SidePanel sideHeaderContent={props.sideHeaderContent}><SideComponent {...props} /></SidePanel>
      </Drawer>}
      {!phone && <SidePanel sideHeaderContent={props.sideHeaderContent}><SideComponent {...props} /></SidePanel>}
      <div className={classes.container}>
        {phone && <Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'} px={'10px'}>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(mobileOpen => !mobileOpen)}
            >
              <img src={'/img/mobile-menu.png'} />
            </IconButton>
            <img src={'/img/Ascend.svg'} width={91} />
          </Box>
          <a href="tel:8889697667"
             className={classes.phoneButton}
          >
            <img src={'/img/phone.svg'} />
          </a>
        </Box>}
        {!phone && <ContactsSection pin={props.pin} />}
        {props.children}
      </div>
    </Box>
  );
}

export default withErrorBoundary(ScrollableLayout, true);
