import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {Box, createStyles, Grid, Theme, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import {withTitle} from "../shared/components/withTitle";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../shared/AppTheme";
import {AccountType} from "./CreateAccount";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      padding: 0,
      marginTop: 24,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: 4
    },
    header: {
      background: colors.custom.green.variant2,
      color: 'white',
      fontWeight: 700,
      textAlign: 'center',
      padding: '7px 0',
    },
    green: {
      fontWeight: 700,
      color: colors.custom.green.variant2,
      fontSize: 40,
      marginRight: 28,
      lineHeight: 1,
      width: 25,
    }
  })
);

function Receive(props: RouteComponentProps<any>) {
  const classes = useStyles();

  const accountType = sessionStorage.getItem('ACCOUNT_TYPE');

  return <Grid container alignItems={"center"} justify={"center"}>
    <div className={'mt-8'} style={{width: 600}}>
      <Box display={'flex'} justifyContent={'center'} mb={'-10px'}>
        <img src={'/img/congratulations.svg'} />
      </Box>

      <div className={classes.card}>
        <div className={classes.header}>A licensed sales agent will be in touch shortly to help<br />you with your enrollment.</div>
        <Box p={'20px 25px'}>
          <Typography className={'bold fs-18 mb-8'} color={'textSecondary'}>Here’s what to expect next:</Typography>
          <Box display={'flex'}>
            <Typography className={classes.green}>1</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>We’ll be in touch — watch out for our call!</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>Your dedicated licensed sales agent will review the details of the plan you have selected.</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>2</Typography>
            <div>
              <Typography className={'bold fs-18'} color={'textPrimary'}>Enroll in your selected plan</Typography>
              <Typography className={'fs-14'} color={'textPrimary'}>Your agent will help you enroll in your new plan, feel free to ask any additional questions you might have during the call.</Typography>
            </div>
          </Box>
          <Box display={'flex'} className={'mt-20'}>
            <Typography className={classes.green}>3</Typography>
            <div>
              {accountType === AccountType.SKIPPED && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Receive information from your new plan</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Once you are enrolled, your new plan will be in touch to confirm your enrollment or ask any additional questions.</Typography>
              </>}
              {accountType === AccountType.CREATED && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Receive information from your new plan</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Once you are enrolled, your new plan will be in touch to confirm your enrollment or ask any additional questions.</Typography>
              </>}
              {(accountType === AccountType.SIGN_IN || !accountType) && <>
                <Typography className={'bold fs-18'} color={'textPrimary'}>Receive information from your new plan</Typography>
                <Typography className={'fs-14'} color={'textPrimary'}>Once you are enrolled, your new plan will be in touch to confirm your enrollment or ask any additional questions.</Typography>
              </>}
            </div>
          </Box>
        </Box>
      </div>
      <Typography className={'bold fs-18  mt-32 mb-12 text-align-centered'} color={'textSecondary'}>
        {accountType === AccountType.SKIPPED && <>Go to the Ascend homepage to sign up to our newsletter!</>}
        {accountType === AccountType.CREATED && <>Check your email to activate your personalized Ascend account!</>}
        {(accountType === AccountType.SIGN_IN || !accountType) && <>Visit your Ascend profile now to access your other Ascend offers!</>}
      </Typography>
      <Box mt={'30px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <AscendButton variant={'contained'}
                      style={{width: 326}} onClick={() => {
                        switch (accountType) {
                          case AccountType.SKIPPED: document.location.href = 'https://ascendmedicare.com/blog'; break;
                          case AccountType.CREATED: document.location.href = 'https://ascendmedicare.com/'; break;
                          default: document.location.href = process.env.REACT_APP_PRIVATE_URL as string;
                        }
                      }}>
          {accountType === AccountType.SKIPPED && <>Sign up to Ascend newsletter</>}
          {accountType === AccountType.CREATED && <>Go to Ascend home page</>}
          {(accountType === AccountType.SIGN_IN || !accountType) && <>Log in to Ascend profile</>}
        </AscendButton>
      </Box>
    </div>
  </Grid>;
}

export default withTitle(
  Receive,
  () => 'We’ve received your request!',
  ''
);
