import React from "react";
import {keycloak} from "./keycloak";

interface IKeycloakContext {
  authenticated: boolean,
  keycloak: any
}

export const KeycloakContext = React.createContext<IKeycloakContext>({authenticated: false, keycloak});

export function KeycloakProvider(props: React.PropsWithChildren<KeycloakProviderProps>) {

  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (keycloak) {
      keycloak.init({onLoad: 'check-sso', checkLoginIframe: false}).success((authenticated: boolean) => {
        setAuthenticated(authenticated);
        console.log(keycloak);
        setLoaded(true)
      })
    }
  }, []);

  return <KeycloakContext.Provider value={{authenticated, keycloak}}>
    {loaded && props.children}
  </KeycloakContext.Provider>

}

type KeycloakProviderProps = {
  keycloak: any
}
