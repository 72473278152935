import React from "react";
import {Box, Collapse, Typography, withStyles} from "@material-ui/core";
import TooltipedTitle from "./components/TooltipedTitle";
import CollapseSection from "../../shared/components/CollapseSection";
import clsx from "clsx";
import {useHistory, useParams} from "react-router";
import paths from "../config/router-paths";
import AscendButton from "../../shared/components/AscendButton";
import {QuoteContext} from "./QuoteContext";
import {DrugOutput, PharmacyLocationDistanceOutput, ProviderOutput} from "../../types";

const HealthcarePreferences = () => {
  const params = useParams<{zip: string, countyName: string}>();
  const history = useHistory();
  const [doctorsExpanded, setDoctorsExpanded] = React.useState(false);
  const [drugsExpanded, setDrugsExpanded] = React.useState(false);
  const quote = React.useContext(QuoteContext);

  const doctors = React.useMemo(() => {
    return quote?.preferredDoctors?.preferredDoctors || [];
  }, [quote])

  const drugs = React.useMemo(() => {
    return quote?.preferredDrugs?.preferredDrugs || [];
  }, [quote])

  const pharmacies = React.useMemo(() => {
    return quote?.preferredPharmacies?.preferredPharmacies || [];
  }, [quote])

  return <div className={'fill-width healthcare-preferences'}>
    <div className={'h-16'} />
    <FilterButton variant={'contained'}
                  id={'edit-your-info-button'}
                  onClick={() => history.push(paths.editPreferences + params.zip + '/' + params.countyName)}
                  startIcon={<img src={'/img/edit-green2.svg'}/>}>
      <Box mt={'3px'}>Edit your info</Box>
    </FilterButton>
    <div className={'h-10'} />
    <CollapseSection title={<TooltipedTitle className={'fs-14 medium'} title={'Your doctors'} tooltip={''}/>}>
      {!!doctors.length && <>
        {doctors.slice(0, 2).map((doctor: ProviderOutput) => <PreferenceLine key={doctor.npi}
                                               title={doctor.name}
                                               subtitle={doctor.address.specialty}/>)}
        <Collapse in={doctorsExpanded}>
          {doctors.slice(2).map((doctor: ProviderOutput) => <PreferenceLine key={doctor.npi}
                                                          title={doctor.name}
                                                          subtitle={doctor.address.specialty}/>)}
        </Collapse>
        {doctors.length > 2 && <SeeMore expanded={doctorsExpanded} onToggle={value => setDoctorsExpanded(!value)} />}

      </>}

      {!doctors.length && <PreferenceLine title={'You haven\'t added any doctors'}
                                          subtitle={''}/>}

    </CollapseSection>
    <div className={'h-12'} />
    <CollapseSection title={<TooltipedTitle className={'fs-14 medium'} title={'Your drugs'} tooltip={''}/>}>
      {!!drugs.length && <>
        {drugs.slice(0, 2).map((drug: DrugOutput) => <PreferenceLine key={drug.rxcui}
                                           title={drug.name || ''}
                                           subtitle={`${drug.packName}, ${drug.quantity} per ${drug.frequency}`}
        />)}
        <Collapse in={drugsExpanded}>
          {drugs.slice(2).map((drug: DrugOutput) => <PreferenceLine key={drug.rxcui}
                                                         title={drug.name || ''}
                                                         subtitle={`${drug.packName}, ${drug.quantity} per ${drug.frequency}`}
          />)}
        </Collapse>
        {drugs.length > 2 && <SeeMore expanded={drugsExpanded} onToggle={value => setDrugsExpanded(!value)} />}
      </>}
      {!drugs.length && <PreferenceLine title={'You haven\'t added any drugs'}
                                          subtitle={''}/>}
    </CollapseSection>
    <div className={'h-12'} />
    <CollapseSection title={<TooltipedTitle className={'fs-14 medium'} title={'Your pharmacy'} tooltip={''}/>}>
      {!!pharmacies?.length && pharmacies?.map((pharmacy: PharmacyLocationDistanceOutput) => <PreferenceLine key={pharmacy.npi}
                                         title={pharmacy.name || ''}
                                         subtitle={pharmacy.address || ''}
      />)}
      {!pharmacies?.length && <PreferenceLine title={'You haven\'t added a pharmacy'}
                                          subtitle={''}/>}
    </CollapseSection>
    <div className={'h-30'} />
  </div>
}

export default HealthcarePreferences;

const SeeMore = withStyles({
  icon: {
    marginLeft: 10,
    transform: 'rotate(0)',
    transition: 'all 0.3s'
  },
  iconOpen: {
    transform: 'rotate(-180deg) !important',
  },
})(({classes, expanded, onToggle}: {classes: any, expanded: boolean, onToggle: (expanded: boolean) => void}) =>
  <Box className={'flex flex-align-center pointer'} justifyContent={'center'} mt={'6px'} onClick={() => onToggle(expanded)}>
    <Typography variant={'h5'} color={'textSecondary'}>See {!expanded ? 'more' : 'less'}</Typography>
    <img src={'/img/arrow_green.svg'} className={clsx(classes.icon, expanded && classes.iconOpen)} />
  </Box>)

export const PreferenceLine = (props: {title: string, subtitle: string}) => <div className={'mt-10'}>
  <Typography variant={'body2'} color={'textPrimary'}>{props.title}</Typography>
  <Typography variant={'h5'} color={'textSecondary'}>{props.subtitle}</Typography>
</div>

export const FilterButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:active': {
      backgroundColor: 'white',
    },
  },
  contained: {
    borderRadius: 4,
    background: 'rgba(2, 144, 148, 0.05)',
    width: '100%',
    padding: '5px 0 4px',
    border: '1px solid #029094',
  },
  disabled: {},
  label: {
    color: 'rgba(2, 144, 148, 1)',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'none',
  },
})(AscendButton);
