import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import {FilterItem} from "../../types";
import {usePlanFilterData} from "./hooks/usePlanFilterData";
import {PlanFilterContext} from "./PlanFilterContext";
import AscendDivider from "../../shared/components/AscendDivider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 10,
      padding: '16px 26px',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    medium: {
      fontWeight: 500
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: 12,
    },
  }),
);

type ListItem = {
  value: any,
  label: any,
  disabled?: boolean
}

export default function PlanTopFilter() {
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [maxOutOfPocketRanges, setMaxOutOfPocketRanges] = React.useState<ListItem[]>([]);
  const [maxCostRanges, setMaxCostRanges] = React.useState<ListItem[]>([]);

  const [getPlanFilterData, {data: planFilterData}] = usePlanFilterData();

  React.useEffect(() => {
    if (filter.values) {
      getPlanFilterData({
        variables: {
          filter: filter.values
        }
      });
    }
  }, [filter.values]);

  React.useEffect(() => {

    if (planFilterData) {
      setMaxCostRanges(transformListItem(planFilterData.maxCostRanges));
      setMaxOutOfPocketRanges(transformListItem(planFilterData.maxOutOfPocketRanges));
    }
  }, [planFilterData]);

  if (!filter.values) {
    return null;
  }

  return <div className={`${classes.card} plan-top-filters`}>
    <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>Select your maximum out-of-pocket and estimated average total cost per month preferences to find the right matching plans for your needs:</Typography>
    <div className={'h-16'}/>
    {!!maxOutOfPocketRanges &&
    <FilterItemWithCheckbox items={maxOutOfPocketRanges} selectedKeys={filter.values.maxOutOfPocketRangeNames || []} onChange={filter.switchMaxOutOfPocketRanges}
                            title={'Max Out-of-Pocket'}/>
    }
    <AscendDivider/>
    {!!maxCostRanges &&
    <FilterItemWithCheckbox items={maxCostRanges} selectedKeys={filter.values.maxCostRangeNames || []} onChange={filter.switchMaxCostRanges}
                            title={'Estimated average total cost per month'}/>
    }
  </div>
}

function FilterItemWithCheckbox({items, selectedKeys, onChange, title}: { items?: ListItem[], selectedKeys: string[], onChange: (key: any) => void, title: string }) {
  const classes = useStyles();

  return <Box display={'flex'} alignItems={'center'}>
    <Box width={'180px'}><Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>{title}</Typography></Box>
    {!!items && items.map(item => <AscendCheckbox variant={'standart'}
                                                  key={item.value}
                                                  name={item.value}
                                                  labelClassName={classes.checkboxLabel}
                                                  controlClassName={classes.checkbox}
                                                  label={item.label}
                                                  checked={!!selectedKeys && selectedKeys.includes(item.value)}
                                                  disabled={item.disabled}
                                                  onChange={() => {
                                                            onChange(item.value)
                                                          }}

    />)
    }
  </Box>
}

function transformListItem(collection: FilterItem[]): ListItem[] {
  return collection.map((item: any) => ({
      value: item.key,
      label: (item.count > 0) ? `${item.name} (${item.count})`: item.name,
      disabled: item.count === 0
    })
  )
}
