import React, {useState} from "react";
import {Badge, Box, Button, createStyles, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {colors} from "../../shared/AppTheme";
import {Field, Plan, Sort} from "../../types";
import useSaveRequest from "../../Questionnaire/hooks/useSaveRequest";
import {getQuoteId, setQuoteId} from "../../shared/QuoteId";
import Footer from "../shared/Footer";
import AscendPreloader from "../../shared/components/AscendPreloader";
import withErrorBoundary from "../../shared/withErrorBoundary";
import usePlans from "../PlanList/hooks/usePlans";
import {QuoteContext} from "../PlanList/QuoteContext";
import {RouteComponentProps, useHistory} from "react-router";
import PlanCard from "../PlanList/PlanCard";
import Layout from "../../shared/Layout";
import ScrollableLayout from "../../shared/ScrollableLayout";
import LoginWidget from "../PlanList/LoginWidget";
import BackButton from "../shared/BackButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
    seeMoreButton: {
      '& .MuiButton-label': {
        textTransform: 'none',
        color: colors.custom.green.variant2
      }
    },
  }),
);

function Favorites(props: RouteComponentProps<{ zip: string, countyName: string}>) {
  let {zip, countyName} = props.match.params;
  const history = useHistory();
  const pageSize = 20;
  const classes = useStyles();
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const {id} = React.useContext(QuoteContext);

  const [total, setTotal] = React.useState<number>();
  const [request, results] = usePlans((response) => {
    setPlans([...plans, ...response.plans.data])
  });

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const loadMore = React.useCallback(() => {
    setPage({
      ...page,
      page: page.page + 1
    })
  },[page]);

  const quoteId = React.useMemo(() => {
    return id || getQuoteId();
  }, [id])

  React.useEffect(() => {
    if (hasMorePlans) {
      request({
        variables: {
          filter: {onlyFavorite: true, zip, countyName},
          page,
          quoteId,
          sort: [
            { "field": Field.Premium,"direction":Sort.Asc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ]
        }
      })
    }
  }, [page]);

  const hasMorePlans = React.useMemo(() => {
    return !total || plans.length < total;
  }, [total, plans.length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data?.plans.totalElements)
    }

  }, [results.data]);

  return  <Layout sideHeaderContent={<LoginWidget/>} SideComponent={() => (
    <Box width={252}>
      <BackButton
        startIcon={<img src={"/img/arrow-left.svg"}/>}
        onClick={() => history.push(`/plans/${zip}/${countyName}`)}>
        Back to Plans
      </BackButton>
    </Box>
  )}>
    <Box alignItems={"center"} display={'flex'} flex={1} flexDirection={'column'}>
      <Box flexDirection={'column'} my={'15px'} maxWidth={'800px'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h1'} color={'textPrimary'} className={classes.title}>You favorite plans are listed below</Typography>
        </Box>
        <Box mt={'16px'}>
          {plans.map(plan => <PlanCard plan={plan}
                                       zip={zip}
                                       countyName={countyName}
                                       key={plan.bidId} />)}

          {results.loading &&
            <Box textAlign={"center"} my={2}>
              <AscendPreloader />
            </Box>
          }
          {(!!plans.length && hasMorePlans && !results.loading) && <Box textAlign={"center"} mt={'32px'}>
            <Button disabled={results.loading} className={classes.seeMoreButton} onClick={loadMore}
                    endIcon={<ExpandMoreIcon />}><div style={{textDecoration: 'underline'}}>See more plans</div></Button>
          </Box>}
        </Box>
        <Footer />
      </Box>
    </Box>
  </Layout>
}

export default withErrorBoundary(Favorites);
