import React, {PropsWithChildren} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import withErrorBoundary from "../shared/withErrorBoundary";
import Terms from "../shared/SidePanel/Terms";
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      position: 'absolute',
      left: 40,
      top: 32,
    },
    terms: {
      position: 'fixed',
      left: 40,
      bottom: 32,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }),
);

function QuoteBlankLeftSide() {
  const classes = useStyles();

  return (
    <>
      <img src={'/img/Ascend.svg'} width={140} height={31} className={classes.image} />
      <div className={classes.terms}><Terms /></div>
    </>
  );
}

export default withErrorBoundary(QuoteBlankLeftSide, true);
