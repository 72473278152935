import React from 'react';
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";
import {ProviderOutput} from "../../../types";

export default function useDoctorsByRequestId(requestId?: string) {
  const [doctors, setDoctors] = React.useState<ProviderOutput[]>([]);

  const [getRequest, {loading, refetch}] = useQuestionnaireRequest(async data => {
    if (data.preferredDoctors?.preferredDoctors?.length) {
      setDoctors(data.preferredDoctors?.preferredDoctors)
    }
  })

  React.useEffect(() => {
    if (requestId) {
      getRequest({variables: {id: requestId}});
    }
  }, [requestId])

  return {doctors, setDoctors, loading, refetch};
}
