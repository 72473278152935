import React from "react";
import {Box} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import {IQuestionnaireState, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {withTitle} from "../../shared/components/withTitle";
import paths from "../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import CreateAccountForm from "../../shared/components/CreateAccountForm";
import useTemporalData from "../../shared/hooks/useTemporalData";
import {keycloak} from "../../shared/keycloak";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";
import {GraphQLErrorType} from "../../shared/ApolloClient";
import useSaveUserProfile from "../../Private/hooks/useSaveUserProfile";


const formName = 'BASIC_INFO';

function Step13b1(props: RouteComponentProps<any>) {
  const [saveError, setSaveError] = React.useState();
  const [state] = React.useContext(QuestionnaireContext);
  const [create, data] = useSaveUserProfile({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = <>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
            keycloak.login({redirectUri: document.location.href})
          }} style={{color: "red"}}>sign in</a></>
          break;
        case GraphQLErrorType.NO_CONNECTION: text = 'Service is not available';
        default: text = 'Unknown error'
      }
      setSaveError(text)
    },
  })
  const {put} = useTemporalData()

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = (values: any) => {
    if (values?.email) {
      create({variables: {data: {
            email: values.email,
            firstName: values.firstName,
            phoneNumber: values.phoneNumber,
            birthDate: getStoredUser()?.birthDate,
            zip: getStoredUser()?.zip,
            quoteId: state.id,
            cId: (window as any).gaGlobal?.vid,
          }}}).then((res) => {
            if (res?.data?.saveUserProfile) {
              setStoredUser({
                ...getStoredUser(),
                userId: res?.data?.saveUserProfile.userId,
                enrollmentId: res?.data?.saveUserProfile.enrollmentId,
                email: values.email,
                firstName: values.firstName,
                phoneNumber: values.phoneNumber,
              })
              return next();
            }
          })
    }
  };

  const next = () => {
    const storedFilters = localStorage.getItem('filters');
    let filters: any = {};
    if (storedFilters) {
      filters = JSON.parse(storedFilters);
    }
    return put({variables: {data: JSON.stringify({
          user: getStoredUser(),
          filters: JSON.stringify({...filters, zip: state.personalDetails?.zip})
        })}}).then(data => {
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step14.replace('/:tempId', '') + '/' + data.data?.putTemporalData)
    })
  }

  return <>
    <Box display={'flex'}  justifyContent={'center'} flex={1}>
      <Box >
        <div className={'h-32'} />
        <CreateAccountForm showSkip
                           onSkipClick={next}
                           onSubmit={onSubmit}
                           emailError={saveError}
                           submitButtonLabel={'Continue'}
                           backButtonLabel={'Back'}
                           onCancelClick={() => props.history.goBack()} />
      </Box>
    </Box>
  </>
}

export default withTitle(
  Step13b1,
  (state: IQuestionnaireState) => `Create an account to save your progress and\nsee your Medicare Advantage options.`,
  'Add your details below to create an Ascend profile. You can log in again later to see your saved plans and change your healthcare preferences.'
)
