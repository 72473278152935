import React, {FunctionComponent} from "react";
import {Box, createStyles, Theme, Typography, withStyles} from "@material-ui/core";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import AscendButton from "./AscendButton";
import HelpModal from "./HelpModal";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerHelpText: {
      fontSize: 11,
      color: colors.text.primary,
      '& span': {
        fontWeight: 600,
        marginRight: 3
      },
      marginTop: 2
    },
    phone: {
      color: colors.custom.green.variant1,
      display: 'flex',
      borderRadius: 4,
      fontSize: 20,
      lineHeight: '25px',
      fontWeight: 700,
      margin: '0 0 0 8px',
      textDecoration: 'none',
      '& img': {
        marginRight: 8
      }
    },
    phoneButton: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
    alignRight: {
      textAlign: 'right'
    },
    pin: {
      letterSpacing: 2
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    }
  }),
);

export default function ContactsSection({pin, helpModalComponent}: {pin?: string, helpModalComponent?: FunctionComponent}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const Modal = helpModalComponent ? helpModalComponent : HelpModal;

  return <Box className={classes.wrapper}>
    <Box mb={'4px'}>
      <div className={clsx(classes.headerHelpText, classes.alignRight)}><span>Need help?</span> Speak with one of our licensed sales agents</div>
    </Box>
    <Box mb={'4px'}  display={'flex'} alignItems={'center'}>
      <a href="tel:8889697667" className={classes.phone}>
        <img src={'/img/phone.svg'} width={18} />
        +1 (888) 969-7667 (TTY: 711)
      </a>
      <BookButton className={'gtm-button-event'} data-gtm-button-name={'BOOK_A_CALL_BUTTON'} onClick={() => setOpen(true)} variant={'contained'}>Book a call</BookButton>
    </Box>
    {pin && <Typography color={'textPrimary'} variant={'body1'} className={'bold'}>(Session ID: <span className={`data-hj-allow ${classes.pin}`}>{pin}</span>)</Typography>}
    <div className={classes.headerHelpText}>8AM-10PM ET Mon-Fri, 9AM-4PM Sat-Sun</div>
    <Modal open={open} onClose={() => setOpen(false)} />
  </Box>
}

const BookButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 21px',
    marginLeft: 16,
  },
  label: {
    color: 'white',
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
