import {ProviderAddressOutput, ProviderOutput} from "../../types";
import React, {Dispatch, SetStateAction} from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {useDebouncedEffect} from "../useDebouncedEffect";
import {Box, CircularProgress, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import * as _ from "lodash";
import AscendAutocomplete, {ScrollbarList} from "./AscendAutocomplete";
import FormTextInput from "./FormTextInput";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import AddressSelectionModal from "../../Questionnaire/components/modals/AddressSelectionModal";

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 90
  },
  title: {
    fontWeight: 500,
  },
  loadingIcon: {
    width: '20px!important',
    height: '20px!important',
  },
  lineWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px 8px 20px',
    borderBottom: '1px solid rgba(28, 67, 79, 0.12)',
    marginBottom: 8,
    background: 'white',
    border: '1px solid #CCD8DC',
    borderRadius: 4,
  },
  addDoctorButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: `1px dashed ${colors.custom.green.variant2}`,
    background: 'rgba(2, 144, 148, 0.05)',
    borderRadius: 8,
    width: '100%',
    paddingTop: 17,
    paddingBottom: 17,
    color: colors.custom.green.variant2,
    fontWeight: 700,
    fontSize: 16,
  }
});

type DoctorSelectionProps = {
  zip: string,
  selectedDoctors: ProviderOutput[],
  setSelectedDoctors: Dispatch<SetStateAction<ProviderOutput[]>>,
  showAutocomplete: boolean,
  setShowAutocomplete: Dispatch<SetStateAction<boolean>>,
}

export const DoctorSelection = ({
                                  zip,
                                  selectedDoctors,
                                  setSelectedDoctors,
                                  showAutocomplete,
                                  setShowAutocomplete
                                }: DoctorSelectionProps) => {
  const classes = useStyles();
  const [autocompleteValue, setAutocompleteValue] = React.useState<string>('');
  const [getDoctors, data] = useLazyQuery<{findProvidersByFilter: ProviderOutput[]}, {zip: string, searchTerm: string}>(gql(doctorsRequest));
  const [open, setOpen] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [doctor, setDoctor] = React.useState<ProviderOutput>();
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (doctor) {
      if (doctor.addressesNumber > 1) {
        setModalOpen(true)
      } else {

        setSelectedDoctors(doctors => ([...doctors, doctor]));
        setDoctor(undefined);
      }
    }
  }, [doctor])

  React.useEffect(() => {
    if (inputRef.current && showAutocomplete) {
      (inputRef.current as any).focus();
    }
  }, [showAutocomplete, inputRef.current])

  useDebouncedEffect(() => {
    if (!!autocompleteValue) {
      getDoctors({
        variables: {
          zip,
          searchTerm: autocompleteValue
        }
      })
    }
  }, 700, [autocompleteValue])

  const onModalClose = (address?: ProviderAddressOutput) => {
    if (address && doctor) {
      setSelectedDoctors(doctors => ([
        ..._.differenceWith(doctors, [doctor], (v1, v2) => v1.npi === v2.npi),
        {
          ...doctor,
          address,
        }]));
      setShowAutocomplete(false);
      setAutocompleteValue('');
    } else {
      setDoctor(undefined);
    }
    setModalOpen(false);
  }

  const autocompleteOpen = React.useMemo(() => {
    return !!autocompleteValue && !data.loading && data.data?.findProvidersByFilter?.length && open
  }, [autocompleteValue, data, open])

  return <>
    <AddressSelectionModal open={modalOpen} doctor={doctor} onClose={onModalClose} />
    {selectedDoctors.map(doctor => <Box key={doctor.npi} className={classes.lineWrapper}>
      <DoctorLine doctor={doctor} />
      <Box ml={3} mr={'-12px'} display={'flex'}>
        {doctor.addressesNumber > 1 && <Typography className={classes.link} variant={'body2'}
                    onClick={() => {
                      setDoctor(doctor);
                      setModalOpen(true);
                    }}
                    color={'textSecondary'}>Change location</Typography>}
        <IconButton onClick={() => setSelectedDoctors(doctors => {
          const result = _.without(doctors, doctor);
          if (!result.length) {
            setShowAutocomplete(true);
          }
          return result;
        })}>
          <img height={24} width={24} src={'/img/remove_round_green_2.svg'} />
        </IconButton>
      </Box>
    </Box>)}
    {!!selectedDoctors.length && <div className={'h-16'} />}
    {showAutocomplete && <AscendAutocomplete fullWidth
                                             open={!!autocompleteOpen}
                                             onClose={() => {
                                               setTimeout(() => setOpen(false), 300) // todo: hack - fix closing autocomplete by blur
                                             }}
                                             onFocus={() => setOpen(true)}
                                             options={(data.data?.findProvidersByFilter || []).filter((doctor: ProviderOutput) =>
                                               !selectedDoctors.some(d => d.npi === doctor.npi))
                                             }
                                             value={autocompleteValue}
                                             onInputChange={(event, newInputValue) => {
                                               setAutocompleteValue(newInputValue);
                                             }}
                                             filterOptions={(options) => options}
                                             popupIcon={data.loading ? <CircularProgress className={classes.loadingIcon}/> : <img src={'/img/popupIcon.svg'} />}
                                             onChange={(e: any, doctor: any | null) => {
                                               if (doctor) {
                                                 setDoctor({...doctor, address: {...doctor.address}});
                                               }
                                             }}
                                             ListboxComponent={ScrollbarList as any}
                                             renderOption={(doctor: any, state) => <DoctorLine doctor={doctor} hideAddress={true} />}
                                             renderInput={params => <FormTextInput inputRef={inputRef}
                                                                                   name={'search-doctor-input'}
                                                                                   label={'Search doctor name'}
                                                                                   {...params} />} />}
    {!showAutocomplete && <Box id={'add-doctors-button'} className={classes.addDoctorButton} onClick={() => setShowAutocomplete(true)}>
      <Box mr={'10px'} mt={'11px'}>
        <img src={'/img/plus_green_2.svg'} />
      </Box>
      Add more doctors
    </Box>}
  </>
}

const useLineStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    title: {
      fontWeight: 500,
    },
    addressLine: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
        alignItems: 'flex-start',
      },
    }
  })
);

type DoctorLineProps = {
  doctor: ProviderOutput,
  hideAddress?: boolean
}

const DoctorLine = (props: DoctorLineProps) => {
  const classes = useLineStyles();

  return <Box className={classes.root}>
    <Box>
      <Box mb={'10px'}>
        <Typography variant={'h4'} color={'textPrimary'} className={`${classes.title} doctor-name`}>{props.doctor.name}</Typography>
      </Box>
      <Typography variant={'body2'} color={'textSecondary'}>{props.doctor.address.specialty}</Typography>
    </Box>
    {!props.hideAddress && <Box className={classes.addressLine}>
      <Typography variant={'body2'} color={'textSecondary'}>{props.doctor.address.addressLine1}</Typography>
      <Typography variant={'body2'} color={'textSecondary'}>{props.doctor.address.addressLine2}</Typography>
    </Box>}
  </Box>
}

const doctorsRequest = `
query ($zip: String!, $searchTerm: String!) {
  findProvidersByFilter(filterInput: {zipCode: $zip, searchTerm: $searchTerm}) {
    address {
      addressLine1
      addressLine2
      id
      specialty
    }
    addressesNumber
    name
    npi
  }
}
`;
