import React from "react";
import {Box, Collapse, Divider, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import clsx from 'clsx';
import AscendTooltip from "../../shared/components/AscendTooltip";
import useCommonStyles from "../../shared/useCommonStyles";
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {AnnualQuestionnaireContext} from "./AnnualEnrollment/AnnualQuestionnaireContextProvider";
import {IQuestionnaireState, QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import {questionsRoutes} from "../hooks/useStepperState";
import {withTitle} from "../../shared/components/withTitle";
import * as _ from 'lodash';
import {storeFilters} from "../../shared/utils";

const useStyles = makeStyles({
  info: {
    backgroundColor: 'rgba(94, 203, 158, 0.1)',
    borderRadius: 10,
    padding: '30px',
    fontWeight: 500,
    color: colors.text.primary,
  },
  medium: {
    fontWeight: 500,
  },
  collapseButton: {
    minWidth: 30,
    width: 30,
    height: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 15,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
    '& img': {
      transition: 'all 0.3s',
      transform: 'rotate(0)',
    },
    '& img.open': {
      transform: 'rotate(-180deg) !important',
    }
  },
  divider: {
    marginTop: 26,
    marginBottom: 22,
    backgroundColor: '#C4C4C4',
  }
});

const formName = 'STEP_9a';

function Step9a(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const [originalOpen, setOriginalOpen] = React.useState(false);
  const [advantageOpen, setAdvantageOpen] = React.useState(false);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('xs'));

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  React.useEffect(() => {
    const selected = calculateSelected(state);
    setOriginalOpen(selected === PlanType.MG);
    setAdvantageOpen(selected !== PlanType.MG);
    if (selected === PlanType.PPO) {
      storeFilters({ planTypes: ["PPO"] }, props.match.params.zip)
    } else if (selected === PlanType.HMO) {
      storeFilters({ planTypes: ["HMO"] }, props.match.params.zip)
    }
  }, [state])

  const onSubmit = (plan: string) => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, medicarePlanType: plan, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + (plan === 'MA' ? questionsRoutes.step9a1 : questionsRoutes.step9b))
  };

  const selected = React.useMemo(() => {
    return calculateSelected(state);
  }, [state])

  return <Box display={'flex'} flexDirection={'column'} mt={'47px'}>

    <Box className={classes.info}>
      <Box display={'flex'}>
        <Box className={classes.collapseButton} onClick={() => setOriginalOpen(prev => !prev)}>
          <img src={'/img/arrow.svg'} className={clsx({open: originalOpen})}/>
        </Box>
        <Box mt={'7px'} ml={2}>
          <Typography variant={'h3'} color={'textPrimary'} className={classes.medium}>Medicare Supplement ("Medigap") and Prescription Drug Plans (PDP)</Typography>
        </Box>
      </Box>
      <Collapse in={originalOpen}>
        <Box display={'flex'} mt={'13px'}>
          <Typography variant={'body1'} color={'textSecondary'} className={'medium'}>
            Medicare Supplement (or "Medigap") plans are private insurance plans that charge a monthly premium to help cover out-of-pocket costs (or “gaps”) you are responsible for paying under Original Medicare (such as deductibles, copays and coinsurance expenses). There are 8 different federally-standardized plan types (A, B, D, G, K, L, M, N) available to newly eligible Medicare beneficiaries. Most beneficiaries choose to purchase Medigap plans that provide the most comprehensive coverage - these plans typically charge monthly premiums of $90 - $300+.<br/><br/>

            Prescription Drug Plans (PDPs) are standalone prescription drug plans offered by private insurance companies. PDPs can be purchased if you have Original Medicare, or Original Medicare with a Medigap plan.
          </Typography>
        </Box>
      </Collapse>
      <Divider className={classes.divider} />
      <Box display={'flex'} mt={'20px'}>
        <Box className={classes.collapseButton} onClick={() => setAdvantageOpen(prev => !prev)}>
          <img src={'/img/arrow.svg'} className={clsx({open: advantageOpen})}/>
        </Box>
        <Box mt={'7px'} ml={2}>
          <Typography variant={'h3'} color={'textPrimary'} className={classes.medium}>Medicare Advantage</Typography>
        </Box>
      </Box>
      <Collapse in={advantageOpen}>
        <Box mt={'13px'}>
          <Typography variant={'body1'} color={'textSecondary'} className={'medium'}>
            Medicare Advantage (or “Part C”) plans, are an “all-in-one” bundled private alternative to Original Medicare. You will typically pay a low or $0 monthly premium, and pay for healthcare services as “as-you-go”. Medicare Advantage HMO plans require you to use doctors and hospitals within a network to receive care, whereas PPO plans will cover out-of-network providers (but at a higher cost).  All plans have a maximum annual cap on out-of-pocket costs.<br/><br/>

            Medicare Advantage plans provide the same coverage as Original Medicare, and often bundle prescription drug coverage as well as extra benefits such as vision, dental and hearing services.
          </Typography>
        </Box>
      </Collapse>
    </Box>

    <DesktopContent selected={selected} />

    <Box display={'flex'} padding={'17px 8px 17px 30px'} mt={'23px'} width={'100%'}>
      <Box display={'flex'} flex={3}>
        <Box ml={'-30px'}>
          <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
            props.history.goBack();
          }} color={'secondary'}>Back</AscendButton>
        </Box>
      </Box>
      <Box display={'flex'} flex={4} justifyContent={'flex-end'} alignItems={'center'}>
        <MedigapButton tabIndex={4} className={'gtm-step-event'} variant={'contained'} onClick={() => onSubmit('MG')}>See Medigap<br />Plans</MedigapButton>
      </Box>
      <Box display={'flex'} flex={2} mx={'5px'} justifyContent={'center'} alignItems={'center'}>
        <div className={'w-10'}/>
        <AdvantageButton tabIndex={4} className={'gtm-step-event'} variant={'contained'} onClick={() => onSubmit('MA')}>See Medicare Advantage Plans</AdvantageButton>
      </Box>
    </Box>
  </Box>
}

const MedigapButton = withStyles({
  contained: {
    borderRadius: 50,
    padding: '4px 30px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)

const AdvantageButton = withStyles({
  contained: {
    borderRadius: 50,
    padding: '4px 0px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)

const useDesktopStyles = makeStyles({
  title: {
    lineHeight: '20px'
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
  centered: {
    textAlign: 'center',
    paddingTop: 3
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
    marginTop: 30,
    fontSize: '16px',
    textDecoration: 'none',
  },
  divider: {
    color: '#EDEDED',
    width: '100%',
    marginBottom: 10
  },
  totalLine: {
    '& p': {
      fontWeight: 700,
      fontSize: 16
    },
    backgroundColor: '#C8E8EB',
    borderRadius: 10,
  },
  denseLine: {
    padding: '8px 8px 8px 30px!important'
  },
  whiteLine: {
    backgroundColor: 'white'
  },
  greenLine: {
    backgroundColor: 'rgba(2, 144, 148, 0.05)',
    borderRadius: 10,
  },
  plus: {
    position: 'absolute',
    right: -5,
    top: 21
  },
  selected: {
    position: 'absolute',
    top: -5,
    left: -5,
    right: -7,
    height: 1383,
    border: '1px solid #5ECB9E',
    background: 'rgba(94, 203, 158, 0.05)',
    borderRadius: 10
  },
  original: {
    position: 'absolute',
    top: 83,
    left: 3,
    right: 3,
    height: 1295,
    background: 'rgba(164, 180, 185, 0.15)',
  }
});

const DesktopContent = ({selected}: {selected: number}) => {
  const classes = useDesktopStyles();

  return <>
    <Box display={'flex'} flex={1} mt={'21px'} flexDirection={'column'} width={835}>
      <Box display={'flex'} padding={'17px 8px 17px 30px'}>
        <Box display={'flex'} flex={3}></Box>
        <Box display={'flex'} flex={2} position={'relative'} flexDirection={'column'} alignItems={'center'}>
          {<div className={classes.original} />}
          <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} position={'relative'}>
            <Typography variant={"h4"} color={'textSecondary'} className={clsx(classes.centered, classes.bold, classes.title)}>Original<br />Medicare</Typography>
            <AscendTooltip placement={'top'} arrow title={'Original Medicare'} className={'ml-8 mt-20'}><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box height={'6px'} bgcolor={'#778E95'} width={'90%'}/>
        </Box>
        <Box display={'flex'} flex={2} position={'relative'} flexDirection={'column'} alignItems={'center'}>
          {selected === PlanType.MG && <div className={classes.selected} />}
          <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} zIndex={5}>
            <Typography variant={"h4"} color={'textPrimary'} className={clsx(classes.bold, classes.title)}>
              Original Medicare <br />
              + Medigap <AscendTooltip placement={'top'} arrow title={'Medicare Supplement plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.  By law, these plans are standardized. 8 different plans (A, B, D, G, K, L, M, and N) are available to newly eligible for Medicare beneficiaries.  '} className={'ml-8'}><img src={'/img/info-green1.svg'} /></AscendTooltip><br />
              + Drug Plan <AscendTooltip placement={'top'} arrow title={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies. PDPs can be purchased if you have Original Medicare, or Original Medicare with a Medigap plan.'} className={'ml-8'}><img src={'/img/info-green1.svg'} /></AscendTooltip></Typography>
          </Box>
          <Box height={'6px'} bgcolor={'#029094'} width={'90%'}/>
        </Box>
        <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'} position={'relative'} flexDirection={'column'}>
          {selected !== PlanType.MG && <div className={classes.selected} />}
          <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} zIndex={5}>
            <Typography variant={"h4"} color={'textPrimary'} className={clsx(classes.bold, classes.title, classes.centered)}>Medicare<br />Advantage</Typography>
            <AscendTooltip placement={'top'} arrow title={'Medicare Advantage (Part C), is a “all-in-one” bundled alternative to Original Medicare. Medicare Advantage plans provide coverage for everything covered under Original Medicare, and often include prescription drug coverage (known as "MA-PD" plans). Medicare Advantage plans can also offer additional benefits, such as coverage for vision, dental and hearing services.'} className={'ml-8'}><img src={'/img/info-green1.svg'} /></AscendTooltip>
          </Box>
          <Box height={'6px'} bgcolor={'#029094'} width={'90%'}/>
        </Box>
      </Box>
      <TableLine title={<strong>Coverage</strong>}
                 className={clsx('fs-16', 'bold', classes.greenLine)}
                 value1={<></>}
                 value2={<></>}
                 value3={<></>}
      />
      <TableLine title={'Part A (Hospital insurance)'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Part A (Hospital insurance) coverage includes:</p>
                   <ul>
                     <li>Inpatient care in a hospital</li>
                     <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                     <li>Hospice care</li>
                     <li>Home health care</li>
                     <li>Inpatient care in a religious non-medical health care institution</li>
                   </ul>
                 </div>}
                 value1={true} value2={true} value3={true} />
      <TableLine title={'Part B (Medical insurance)'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Part B (Medical insurance) coverage includes:</p>
                   <ul>
                     <li>Medically necessary doctor’s services</li>
                     <li>Outpatient care</li>
                     <li>Home health services</li>
                     <li>Durable medical equipment</li>
                     <li>Mental health services</li>
                     <li>Other medical services</li>
                     <li>May also cover preventive services</li>
                   </ul>
                 </div>}
                 className={classes.whiteLine}
                 value1={true} value2={true} value3={true} />
      <TableLine title={'Part D (Prescription drug coverage)'}
                 tooltip={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often combined with Medicare Advantage plans.'}
                 className={classes.denseLine}
                 value1={false}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                            <Icon value={true} />
                            <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>With purchase of<br />Prescription Drug Plan</Typography>
                         </Box>}
                 value3={true} />
      <TableLine title={'Limit on out-of-pocket costs'}
                 tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                 value1={false}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Icon value={false} />
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Some Medigap plans may<br />cover almost all costs</Typography>
                 </Box>}
                 value3={true} className={classes.whiteLine} />
      <TableLine title={'Extra benefits like vision, hearing, dental, and more'}
                 tooltip={'Additional benefits offered at no extra cost by your Medicare plan, including hearing, vision and dental benefits.'}
                 value1={false} value2={false} value3={true} />
      <TableLine title={'Doctor coverage'}
                 tooltip={'Your ability to see any doctor may be dependent on the type of Medicare plan you choose.'}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                   Any doctor or<br />hospital that takes<br />Medicare
                 </Typography>}
                 value2={<Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                   Any doctor or<br />hospital that takes<br />Medicare
                 </Typography>}
                 value3={<Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                   <strong>HMO:</strong> Covers in<br />network doctors<br />
                   <strong>PPO:</strong> Covers<br />out-of-network
                 </Typography>} />
      <TableLine title={'Specialized plans for people on Medicaid'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Medicaid is a state-administered public health insurance program that provides health coverage to low-income families and individuals. Medicaid is different to Medicare (which is primarily for people over 65).  People who qualify for both Medicaid and Medicare are known as "dual-eligibles".  Dual-eligibles are eligible for certain Medicare Advantage plans which are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare.  If you are eligible for Medicaid, you may receive premium and cost-sharing assistance for your Medicare costs.</p>
                 </div>}
                 value1={false} value2={false} value3={true} />
    </Box>

    <Box display={'flex'} flex={1} flexDirection={'column'} width={'100%'} mt={2}>
      <TableLine title={<strong>Key out-of-pocket costs</strong>}
                 className={clsx('fs-16', 'bold', classes.greenLine)}
                 value1={<></>}
                 value2={<></>}
                 value3={<></>}
      />
      <TableLine title={'Medical costs'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Medical costs include those costs associated with Medicare Part B (Medical insurance) and include:</p>
                   <ul>
                     <li>Medically necessary doctor’s services</li>
                     <li>Outpatient care</li>
                     <li>Home health services</li>
                     <li>Durable medical equipment</li>
                     <li>Mental health services</li>
                     <li>Other medical services</li>
                     <li>May also cover preventive services</li>
                   </ul>
                 </div>}
                 className={classes.denseLine}
                 value1={<Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>20% coinsurance</Typography>}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
                 </Box>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0-$50 copay per<br />doctor visit</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Plans generally have copays<br />for other medical services</Typography>
                 </Box>}
      />
      <TableLine title={'Hospital costs'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Hospital costs include those costs assocated with Medicare Part A (Hospital insurance). This coverage is typically subject to a deductible, and includes:</p>
                   <ul>
                     <li>Inpatient care in a hospital</li>
                     <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                     <li>Hospice care</li>
                     <li>Home health care</li>
                     <li>Inpatient care in a religious non-medical health care institution</li>
                   </ul>
                 </div>}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$1,485 copay</Typography>
                   <Typography variant={'h5'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Covers first 60 days</Typography>
                 </Box>}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
                 </Box>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$200-$500<br />copay per day</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>First 3-7 days</Typography>
                 </Box>}
      />
      <TableLine title={'Extended Hospital Stay'}
                 tooltip={'Costs for a hospital stay beyond the first period covered by your plan.'}
                 className={classes.denseLine}
                 value1={<Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Days 61-90: $371/day<br />
                   Days 91+: Up to 100%<br />of cost</Typography>}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
                 </Box>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>
                     <strong>Days 7-90:</strong> $0/day<br />
                     <strong>Days 91+:</strong> Varies by plan
                   </Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Some plans have unlimited<br />coverage after 90 days</Typography>
                 </Box>}
      />
    </Box>

    <Box display={'flex'} flex={1} flexDirection={'column'} width={'100%'} mt={2}>
      <TableLine title={<strong>Monthly premiums</strong>}
                 className={clsx('fs-16', 'bold', classes.greenLine)}
                 value1={<></>}
                 value2={<></>}
                 value3={<></>}
      />
      <TableLine title={'Standard Part B'}
                 tooltip={'Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your last tax return), you may pay an extra charge on top of the regular Part B premium.'}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
                 value3={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
      />
      <TableLine title={'Prescription drug plan (PDP)'}
                 tooltip={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies.'}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Avg. $42</Typography>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Included in 89%<br />of plans</Typography>
                 </Box>}
      />
      <TableLine title={'Medicare Supplement (Medigap)'}
                 tooltip={'Medigap plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.'}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$90 - $300+</Typography>}
                 value3={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered)}>n.a.</Typography>}
      />
      <TableLine title={'Medicare Advantage'}
                 tooltip={'Medicare Advantage (Part C) is an “all-in-one” bundled alternative to Original Medicare, which often include prescription drug coverage and extra benefits (sucha as vision, dental and hearing).'}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>n.a.</Typography>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Typically $0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>54% of plans have<br/>$0 premium</Typography>
                 </Box>}
      />
      <TableLine title={'Total monthly premiums'}
                 className={clsx(classes.totalLine)}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$270.00+</Typography>}
                 value3={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50+</Typography>}
      />
    </Box>
  </>
}

const useMobileStyles = makeStyles({
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
  tableWrapper: {
    '&>div:nth-child(even)': {
      backgroundColor: '#F5F6F7'
    }
  },
  lastGreen: {
    '&>div:last-child': {
      backgroundColor: 'rgba(94, 203, 158, 0.1)'
    }
  },
  centered: {
    textAlign: 'center',
    paddingTop: 1
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
    display: 'block',
    fontSize: '16px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  divider: {
    backgroundColor: '#F5F7F6',
    width: 'calc(100% + 80px)',
    height: 7,
    margin: '20px -40px',
  },
  totalLine: {
    '& p': {
      fontWeight: 700,
      fontSize: 16
    }
  },
  denseLine: {
    padding: '7px 8px 7px 30px!important'
  },
  line: {
    padding: '16px 16px 16px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subTitle: {
    fontSize: 12,
    lineHeight: '15px',
  }
});

const MobileContent = () => {
  const classes = useMobileStyles();

  return <>
    <Box display={'flex'} flex={1} mt={'40px'} flexDirection={'column'} width={'100%'} className={classes.tableWrapper}>
      <Box display={'flex'} pb={'13px'} ml={'15px'}>
        <Typography variant={"h4"} color={'textPrimary'} className={classes.bold}>Original Medicare Coverage</Typography>
      </Box>
      <Box className={classes.line}>
        <Box display={'flex'}>
          <Box mr={1}>
            <AscendTooltip placement={'top'} arrow
                           title={'Medicare Part A (Hospital coverage) is the part of Original Medicare that covers inpatient hospital stay. If you have a red, white and blue Medicare card, you will know if you are enrolled in Part A if your card lists a \'coverage start date\' for Part A.  You will likely be enrolled in Part A only if you worked past 65 and received health insurance from your employer (or if you are over 65 and are a dependent on your spouse\'s employer health insurance plan).'}
            ><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box>
            <Typography variant={"body2"} color={'textPrimary'}>Part A (Hospital insurance)</Typography>
          </Box>
        </Box>
        <Icon value={true} />
      </Box>
      <Box className={classes.line}>
        <Box display={'flex'}>
          <Box mr={1}>
            <AscendTooltip placement={'top'} arrow
                           title={'Medicare Part B (Medical coverage) is the part of Original Medicare that covers outpatient medical services and supplies, and certain preventive services. If you have a red, white and blue Medicare card, you will know if you are enrolled in Part B if your card lists a \'coverage start date\' for Part B. There is a monthly premium for Medicare Part B.'}
            ><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box>
            <Typography variant={"body2"} color={'textPrimary'}>Part B (Medical insurance)</Typography>
          </Box>
        </Box>
        <Icon value={true} />
      </Box>
      <Box className={classes.line}>
        <Box display={'flex'}>
          <Box mr={1}>
            <AscendTooltip placement={'top'} arrow
                           title={'Medicare Part D refers to prescription drug coverage.  If you are on Original Medicare, you may have purchased a standalone Prescription Drug Plan (PDP) to help cover your prescription drug costs.'}
            ><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box>
            <Typography variant={"body2"} color={'textPrimary'}>Part D (Prescription drug coverage)</Typography>
          </Box>
        </Box>
        <Icon value={true} />
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Maximum out-of-pocket costs</Typography>
        </Box>
        <Icon value={false} />
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Vision, hearing, dental, and more</Typography>
        </Box>
        <Icon value={false} />
      </Box>
      <Box className={classes.line}>
        <Box flex={1}>
          <Typography variant={"body2"} color={'textPrimary'}>Doctor coverage</Typography>
          <Typography variant={'body2'} color={'textSecondary'}>
            Use of any doctor or hospital that takes Medicare across the US
          </Typography>
        </Box>
      </Box>
    </Box>

    <Box display={'flex'} flex={1} mt={'23px'} flexDirection={'column'} width={'100%'} className={clsx(classes.tableWrapper, classes.lastGreen)}>
      <Box display={'flex'} pb={'13px'} ml={'15px'}>
        <Typography variant={"h4"} color={'textPrimary'} className={classes.bold}>Original Medicare Cost</Typography>
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Medicare Part B premium</Typography>
          <Typography variant={'h4'} color={'textSecondary'} className={classes.subTitle}>This may be higher if your income is above a certain threshold</Typography>
        </Box>        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$145.50</Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant={"body2"} color={'textPrimary'}>Prescription drug plan</Typography>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$10 - $120</Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant={"body2"} color={'textPrimary'}>Medigap policy</Typography>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$20 - $270</Typography>
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Medicare Advantage</Typography>
          <Typography variant={'h4'} color={'textSecondary'} className={classes.subTitle}>54% of plans have $0 premium</Typography>
        </Box>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant={"body2"} color={'textPrimary'} className={classes.bold}>Total monthly<br />premiums</Typography>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$175.50 - $535.50</Typography>
      </Box>
    </Box>

    <Box mb={'20px'} mt={'30px'}>
      <a href={'https://ascendmedicare.com/blog/medicare-advantage/medicare-advantage-vs-medicare/'}
         className={classes.link}>I want to learn more about Original Medicare</a>
    </Box>

    <Divider className={classes.divider} />

    <Box display={'flex'} flex={1} mt={'20px'} flexDirection={'column'} width={'100%'} className={classes.tableWrapper}>
      <Box display={'flex'} pb={'13px'} ml={'15px'}>
        <Typography variant={"h4"} color={'textPrimary'} className={classes.bold}>Medicare Advantage Coverage</Typography>
      </Box>
      <Box className={classes.line}>
        <Box display={'flex'}>
          <Box mr={1}>
            <AscendTooltip placement={'top'} arrow
                           title={'Medicare Part A (Hospital coverage) is the part of Original Medicare that covers inpatient hospital stay. If you have a red, white and blue Medicare card, you will know if you are enrolled in Part A if your card lists a \'coverage start date\' for Part A.  You will likely be enrolled in Part A only if you worked past 65 and received health insurance from your employer (or if you are over 65 and are a dependent on your spouse\'s employer health insurance plan).'}
            ><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box>
            <Typography variant={"body2"} color={'textPrimary'}>Part A (Hospital insurance)</Typography>
          </Box>
        </Box>
        <Icon value={true} />
      </Box>
      <Box className={classes.line}>
        <Box display={'flex'}>
          <Box mr={1}>
            <AscendTooltip placement={'top'} arrow
                           title={'Medicare Part B (Medical coverage) is the part of Original Medicare that covers outpatient medical services and supplies, and certain preventive services. If you have a red, white and blue Medicare card, you will know if you are enrolled in Part B if your card lists a \'coverage start date\' for Part B. There is a monthly premium for Medicare Part B.'}
            ><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box>
            <Typography variant={"body2"} color={'textPrimary'}>Part B (Medical insurance)</Typography>
          </Box>
        </Box>
        <Icon value={true} />
      </Box>
      <Box className={classes.line}>
        <Box display={'flex'}>
          <Box mr={1}>
            <AscendTooltip placement={'top'} arrow
                           title={'Medicare Part D refers to prescription drug coverage.  If you are on Original Medicare, you may have purchased a standalone Prescription Drug Plan (PDP) to help cover your prescription drug costs.'}
            ><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box>
            <Typography variant={"body2"} color={'textPrimary'}>Part D (Prescription drug coverage)</Typography>
          </Box>
        </Box>
        <Icon value={true} />
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Maximum out-of-pocket costs</Typography>
        </Box>
        <Icon value={false} />
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Vision, hearing, dental, and more</Typography>
        </Box>
        <Icon value={false} />
      </Box>
      <Box className={classes.line}>
        <Box flex={1}>
          <Typography variant={"body2"} color={'textPrimary'}>Doctor coverage</Typography>
          <Typography variant={'body2'} color={'textSecondary'}>
            In many cases, you’ll need to use doctors who are in the plan’s network
          </Typography>
        </Box>
      </Box>
    </Box>

    <Box display={'flex'} flex={1} mt={'23px'} flexDirection={'column'} width={'100%'} mb={'16px'} className={clsx(classes.tableWrapper, classes.lastGreen)}>
      <Box display={'flex'} pb={'13px'} ml={'15px'}>
        <Typography variant={"h4"} color={'textPrimary'} className={classes.bold}>Medicare Advantage Cost</Typography>
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Standard Part B</Typography>
          <Typography variant={'h4'} color={'textSecondary'} className={classes.subTitle}>This may be higher if your income is above a certain threshold</Typography>
        </Box>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$145.50</Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant={"body2"} color={'textPrimary'}>Prescription drug plan</Typography>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant={"body2"} color={'textPrimary'}>Medigap policy</Typography>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>
      </Box>
      <Box className={classes.line}>
        <Box>
          <Typography variant={"body2"} color={'textPrimary'}>Medicare Advantage</Typography>
          <Typography variant={'h4'} color={'textSecondary'} className={classes.subTitle}>54% of plans have $0 premium</Typography>
        </Box>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>
      </Box>
      <Box className={classes.line}>
        <Typography variant={"body2"} color={'textPrimary'} className={classes.bold}>Total monthly<br />premiums</Typography>
        <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0 - $301</Typography>
      </Box>
    </Box>
  </>
}

type TableLineProps = {
  title: string | JSX.Element,
  subtitle?: string,
  tooltip?: string | JSX.Element,
  value1: boolean | JSX.Element,
  value2: boolean | JSX.Element,
  value3: boolean | JSX.Element,
  classes: any,
  className?: string
}

const TableLine = withStyles({
  wrapper: {
    display: 'flex',
    padding: '16px 8px 16px 30px'
  },
  tooltip: {
    marginRight: 8
  },
  subTitle: {
    fontSize: 12,
    lineHeight: '15px',
  },
  transparent: {
    opacity: 0.6,
  }
})((props: TableLineProps) => <Box className={clsx(props.classes.wrapper, props.className)}>
  <Box display={'flex'} flex={3} pt={'3px'} alignItems={'center'}>
    {props.tooltip && <AscendTooltip placement={'top'} arrow title={props.tooltip} className={props.classes.tooltip}><img src={'/img/info.svg'} /></AscendTooltip>}
    <Box>
      <Typography variant={"body2"} color={'textPrimary'}>{props.title}</Typography>
      {props.subtitle && <Typography className={props.classes.subTitle} color={'textSecondary'}>{props.subtitle}</Typography>}
    </Box>
  </Box>
  <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'} className={props.classes.transparent}>
    {typeof props.value1 === 'boolean' ? <DarkIcon value={props.value1} /> : <>{props.value1}</>}
  </Box>
  <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'}>
    {typeof props.value2 === 'boolean' ? <Icon value={props.value2} /> : <>{props.value2}</>}
  </Box>
  <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'}>
    {typeof props.value3 === 'boolean' ? <Icon value={props.value3} /> : <>{props.value3}</>}
  </Box>
</Box>)

const DarkIcon = ({value}: {value: boolean}) => value ? <img src={'/img/dark-check.svg'}/> : <img src={'/img/dark-close.svg'}/>
const Icon = ({value}: {value: boolean}) => value ? <img src={'/img/green-check.svg'}/> : <img src={'/img/red-close.svg'}/>

export default withTitle(
  Step9a,
  (state) => {
    switch (calculateSelected(state)) {
      case PlanType.SNP: return `It looks like a Medicare Advantage Dual-Special Needs Plan\n could be the best option for you.`;
      case PlanType.HMO: return `It looks like a Medicare Advantage HMO plan could be\nthe best option for you.`;
      case PlanType.PPO: return `It looks like a Medicare Advantage PPO plan could be\nthe best option for you.`;
      default: return 'It looks like a Medicare Supplement Medigap\nplan is the right option for you.';
    }
  },
  '',
  undefined,
  undefined,
  undefined,
  835
)

const calculateSelected = _.memoize((state: IQuestionnaireState): PlanType => {
  if (!state.STEP_8?.receiveMedicaid) {
    const mask = [state.STEP_8?.prepay, state.STEP_8?.monthlyBudget, state.STEP_8?.certainDoctors, state.STEP_8?.extraBenefits].map(v => v ? 1 : 0).join('');
    const values = [
      ['1111', '1110', '1100'],
      ['1101', '1011', '1010', '0010', '0011', '0111', '0110'],
      ['1000', '1001', '0000', '0001', '0100', '0101']
    ];
    for (const index in values) {
      if (values[index].some(v => v === mask)) {
        return parseFloat(index) as PlanType;
      }
    }
  }
  return PlanType.SNP;
})

enum PlanType {
  MG,
  PPO,
  HMO,
  SNP
}
