import React from "react";
import {withStyles} from "@material-ui/core";
import AscendButton from "./AscendButton";

const LoginButton = () => {

  return <StyledLoginButton variant={'contained'}
                            onClick={() => {document.location.href = process.env.REACT_APP_PRIVATE_URL as string}}>
    Sign in
  </StyledLoginButton>
}

export default LoginButton;

const StyledLoginButton = withStyles({
  contained: {
    borderRadius: 4,
    padding: '8px 16px 6px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
