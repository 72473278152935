import {withStyles} from "@material-ui/core";
import {colors} from "../../shared/AppTheme";
import AscendButton from "../../shared/components/AscendButton";

const BackButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      '& .MuiButton-label': {
        color: colors.custom.green.variant1,
      }
    },
  },
  startIcon: {
    marginTop: -1
  },
  label: {
    fontWeight: 700,
    color: colors.text.primary,
    marginTop: 3
  }
})(AscendButton);

export default BackButton;
