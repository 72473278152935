import React from 'react';
import {Box, Divider, Grid, Typography} from "@material-ui/core";
import TooltipedTitle from "../../PlanList/components/TooltipedTitle";

type ListPlanDetail = {
  title: string,
  titleClassName?: string,
  tooltip?: string,
  content: (string | JSX.Element)[],
  thin?: boolean
}

export function ListPlanDetail({title, content, thin, tooltip, titleClassName}: ListPlanDetail) {
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"start"} my={"10px"}>
        <Grid item xs={5}>
          {!tooltip && <Typography variant={"h5"} color={'textPrimary'}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <TooltipedTitle title={title} className={!thin ? `bold ${titleClassName ? titleClassName : ''}` : 'thin'} tooltip={tooltip} />}
        </Grid>
        <Grid item xs={7}>
          <ListPlanDetailText content={content} />
        </Grid>
      </Box>
      <Divider/>
    </>
  )
}

export const ListPlanDetailText = ({content}: { content: (string | JSX.Element)[] }) => <Box display={"flex"} flexGrow={2} flexDirection={"column"}>
  {( !content || content.length == 0 ) &&
  <Typography variant={"h5"}>Not covered</Typography>
  }
  {(!!content && content.length > 0 ) && content.map((line, i) =>
    <Box key={i}>
      <Typography className={'fs-12'} color={'textPrimary'}>
        {typeof line === 'string' && <span dangerouslySetInnerHTML={{__html: convertBold(line)}}></span>}
        {typeof line !== 'string' && <>{line}</>}
      </Typography>
    </Box>
  )}
</Box>

function convertBold(text: string) {
  return text
    .replace('[b]', '<strong>')
    .replace('[/b]', '</strong>')
}
