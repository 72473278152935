import React from "react";
import {IQuestion, IStep} from "../../shared/SidePanel/AscendStepper";
import * as _ from "lodash";
import Step1 from "../questions/Step1";
import Quiz1 from "../questions/Quiz1";
import Quiz2 from "../questions/Quiz2";
import Quiz3 from "../questions/Quiz3";
import Step2 from "../questions/Step2";
import Step3 from "../questions/Step3";
import Step4 from "../questions/Step4";
import Step5 from "../questions/Step5";
import Step6 from "../questions/Step6";
import Step7 from "../questions/Step7";
import Step8 from "../questions/Step8";
import Step9 from "../questions/Step9";
import Step10 from "../questions/Step10";

export const questionsRoutes = {
  quiz1: "quiz-1",
  quiz2: "quiz-2",
  quiz3: "quiz-3",
  step1: "",
  step2: "step2",
  step3: "step3",
  step4: "step4",
  step5: "step5",
  step6: "step6",
  step7: "step7",
  step8: "step8",
  step9: "step9",
  step10: "step10",
}

let steps: IStep[] = [
  {
    label: "Basic details",
    firstStep: questionsRoutes.step1,
    questions: [{
      link: questionsRoutes.quiz1,
      component: Quiz1
    }, {
      link: questionsRoutes.quiz2,
      component: Quiz2
    },  {
      link: questionsRoutes.quiz3,
      component: Quiz3
    }, {
      link: questionsRoutes.step1,
      component: Step1
    }, {
      link: questionsRoutes.step2,
      component: Step2
    }, {
      link: questionsRoutes.step3,
      component: Step3
    }, {
      link: questionsRoutes.step4,
      component: Step4
    }, {
      link: questionsRoutes.step5,
      component: Step5
    }],
  },
  {
    label: "Your preferences",
    firstStep: questionsRoutes.step6,
    questions: [{
      link: questionsRoutes.step6,
      component: Step6
    }],
  },
  {
    label: "Get your quotes",
    firstStep: questionsRoutes.step7,
    questions: [{
      link: questionsRoutes.step7,
      component: Step7
    }, {
      link: questionsRoutes.step8,
      component: Step8
    }, {
      link: questionsRoutes.step9,
      component: Step9
    }, {
      link: questionsRoutes.step10,
      component: Step10
    }],
  }
];


const getQuestions = (steps: IStep[]) => {
  return steps.reduce((previousValue, currentValue) => {
    const result = [...previousValue];
    if (currentValue.questions) {
      result.push(...currentValue.questions);
    }
    if (currentValue.children?.length) {
      result.push(..._.flatten(currentValue.children.map((ch: any) => ch.questions)));
    }
    return result;
  }, [] as any[]);
}

let listeners: any[] = [];

export function setCompletedByLabel(label: string, completed: boolean) {
  let st = [...state.steps.map(step => ({
    ...step,
    completed: step.label === label ? completed : step.completed,
    children: step.children ? step.children.map(s => ({
      ...s,
      completed: s.label === label ? completed : s.completed
    })) : step.children,
  }))]
  setState({...state, steps: st, questions: getQuestions(st)})
}


let state: IStepperState = {
  steps,
  questions: getQuestions(steps)
};

const setState = (newState: IStepperState | Function) => {
  if (typeof newState === "function") {
    state = newState(state);
  } else {
    state = {...state, ...newState};
  }
  listeners.forEach((listener) => {
    listener(state);
  });
};

export interface IStepperState {
  steps: IStep[],
  questions: IQuestion[],
}

const useStepperState = () => {
  const newListener = React.useState()[1];
  React.useEffect(() => {
    listeners.push(newListener);
    return () => {
      listeners = listeners.filter(listener => listener !== newListener);
    };
  }, []);
  return {state, setState};
};

export default useStepperState;

export {
  steps,
}


