import React from "react";
import {Plan} from "../../../types";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Fade, IconButton, Slide, Typography} from "@material-ui/core";
import {ComparisonContext} from "../ComparisonContext";
import {getCompanyLogo} from "../../../shared/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '2px dashed #029094',
      marginRight: 30,
      height: 122,
      width: 242,
      borderRadius: 10
    },
    card: {
      background: 'white',
      height: '100%',
      width: '100%',
      padding: '12px 20px',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      position: 'relative',
      overflow: 'hidden'
    },
    medium: {
      fontWeight: 500,
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 8,
    }
  }),
);

type ComparisonCardProps = {
  plan?: Plan
}

export default function ComparisonCard(props: ComparisonCardProps) {
  const classes = useStyles();

  return <div className={classes.container}>
    {props.plan && <Card plan={props.plan} /> }
  </div>
}

function Card(props: {plan: Plan}) {
  const classes = useStyles();
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    setShow(true);
  }, [props.plan])

  return <Slide direction={'up'} in={show}><div className={classes.card}>
    <IconButton onClick={() => {
      setShow(false);
      removeFromCompare(props.plan.bidId)
    }} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
    <img src={getCompanyLogo(props.plan.parentOrgName)} width={99} height={35} />
    <Typography variant={'body2'} color={'textPrimary'}>{props.plan.planName}</Typography>
    <Typography variant={'body2'} color={'textPrimary'} className={classes.medium}>{props.plan.monthlyPremium} Monthly premium</Typography>
  </div></Slide>
}
