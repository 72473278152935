import React from 'react';
import useQuestionnaireRequest from "../../../shared/hooks/useQuestionnaireRequest";
import usePharmacyByNpi from "../../../Questionnaire/hooks/usePharmacyByNpi";
import {PharmacyLocationDistanceOutput} from "../../../types";
import * as _ from "lodash";

export default function usePharmaciesByRequestId(requestId?: string) {
  const [pharmacies, setPharmacies] = React.useState<PharmacyLocationDistanceOutput[]>();
  const [filled, setFilled] = React.useState<boolean>();

  const [getRequest, {loading, refetch, called}] = useQuestionnaireRequest(async data => {
    setFilled(data.preferredPharmacies?.preferredPharmaciesFilled);
    if (data.preferredPharmacies?.preferredPharmacies?.length) {
      setPharmacies(data.preferredPharmacies?.preferredPharmacies);
    }
  })

  React.useEffect(() => {
    if (requestId) {
      getRequest({variables: {id: requestId}});
    }
  }, [requestId])

  return {pharmacies, setPharmacies, loading, refetch, filled, setFilled, called};
}

export const formatPharmacyName = (name: string) => {
  return _.startCase(name.toLowerCase())
    .replace('Cvs', 'CVS')
    .replace('Ahf', 'AHF')
    .replace('Svrf', 'S.V.R.H.');
}
