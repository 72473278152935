import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from "apollo-link-error";
import { from, split } from 'apollo-link';
import {setContext} from "apollo-link-context";
import {config} from "../Questionnaire/config/config";
import {getToken} from "./ApolloAuthClient";
const httpLink = createHttpLink({
  uri: config.graphQL,
  headers: {
    "content-type": "application/json",
  }
});

let errorHandler: ({ graphQLErrors, networkError }: any) => void;

export const setErrorHandler = (callback: ({ graphQLErrors, networkError }: any) => void) => errorHandler = callback;

const authLink = setContext(async (_, { headers }: any) => {
  const token = await getToken().catch(() => {});
  if (token) {
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
        ...headers,
      }
    };
  } else {
    return {
      headers
    };
  }
});

const elink = onError((errors: any) => {
  if (errorHandler) {
    errorHandler(errors);
  }
});

const link =
  from([
    authLink,
    elink,
    httpLink,
  ])
;

export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  })
});

export enum GraphQLErrorType {
  NO_CONNECTION = 'NO_CONNECTION',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ERROR_STATUS = 'ERROR_STATUS'
}
