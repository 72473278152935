import React from "react";
import {getQuoteId} from "../../shared/QuoteId";
import {
  DrugCostOutput
} from "../../types";
import * as _ from "lodash";
import {usePlanLazy} from "../../App/DetailedPlan/hooks/usePlan";
import {Bar} from "react-chartjs-2";
import AscendPreloader from "./AscendPreloader";

const options = {
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
    chartArea: {
      backgroundColor: 'rgba(251, 85, 85, 0.4)'
    }
  },
}

const chartColors = ['#1E95A0', '#1DB399', '#C8E8EB', '#7DA8AC', '#84C7BB', '#C3D4D6'];

type PlanSummaryChartProps = {
  zip: string,
  countyName: string,
  planId: string
}

export default function PlanSummaryChart(props: PlanSummaryChartProps) {
  let {zip, planId, countyName} = props;

  const [getPlan, {data}] = usePlanLazy();

  React.useEffect(() => {
    if (zip && planId && countyName) {
      getPlan({variables: {bidId: planId, zip, countyName, quoteId: getQuoteId()}})
    }
  }, [zip, planId, countyName])

  const plan = React.useMemo(() => {
    if (data) {
      return data.plan;
    }
    return null;
  }, [data])

  const chartdata = React.useMemo(() => {
    /*
    [
        {
          label: '# of Red Votes',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: 'rgb(255, 99, 132)',
        },
        {
          label: '# of Blue Votes',
          data: [2, 3, 20, 5, 1, 4],
          backgroundColor: 'rgb(54, 162, 235)',
        },
        {
          label: '# of Green Votes',
          data: [3, 10, 13, 15, 22, 30],
          backgroundColor: 'rgb(75, 192, 192)',
        },
      ]
     */
    function getRandomInt(min: number, max: number) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const costs = Object.values(_.groupBy(plan?.drugCostMonthlyBreakdown?.costs, 'name'));

    if (costs.length) {
      return {
        labels: costs[0].map(c => c.month).map(intToMonth),
        datasets: costs.map((costArr: DrugCostOutput[], i: number) => ({
          label: costArr[0].name,
          data: costArr.map(c => c.cost.replace('$', '').replace(',', '')),
          backgroundColor: i < chartColors.length ? chartColors[i] : `rgb(${getRandomInt(0, 255)}, ${getRandomInt(0, 255)}, ${getRandomInt(0, 255)})`,
        })),
      }
    } else {
      return [];
    }
  }, [plan])

  const plugin = React.useMemo(() => {
    const monthCount = plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length;
    if (monthCount) {
      return {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart: any) => {
          const ctx = chart.ctx;
          const canvas = chart.canvas;
          const chartArea = chart.chartArea;

          // Chart background
          var gradientBack = canvas.getContext("2d").createLinearGradient(chartArea.left, 0, chartArea.right, 0);
          gradientBack.addColorStop(0, "#FEF7EB");
          gradientBack.addColorStop(3 / monthCount, "#FEF7EB");
          gradientBack.addColorStop(3 / monthCount, "#F2FBFF");
          gradientBack.addColorStop(1, "#F2FBFF");

          ctx.fillStyle = gradientBack;
          ctx.fillRect(chartArea.left, chartArea.bottom,
            chartArea.right - chartArea.left, chartArea.top - chartArea.bottom);
        }
      };
    }
    return {};
  }, [plan?.drugCostMonthlyBreakdown?.monthlyCosts?.length])

  if (!plan) {
    return <AscendPreloader />;
  }

  return <Bar data={chartdata} options={options} />;
}

function intToMonth(month: number): string {
  switch (month) {
    case 1: return 'January';
    case 2: return 'February';
    case 3: return 'March';
    case 4: return 'April';
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '';
  }
}
