export const storeFilters = (data: any, zip: string) => {
  const storedFilters = localStorage.getItem('filters');
  let result: any = {zip, companies: [], planTypes: [], rating: [], SNPTypes: [], extraBenefits: []}
  if (storedFilters) {
    result = JSON.parse(storedFilters);
  }
  Object.keys(data).forEach(key => {
    result[key] = data[key];
  })
  localStorage.setItem('filters', JSON.stringify(result));
}

export const getCompanyLogo = (name?: string | null): string => {
  switch (name) {
    case 'Aetna Better Health of Ohio, MyCare Ohio': return '/img/logos/aetna-better-health-of-ohio-mycare-ohio.png';
    case 'Aetna Medicare': return '/img/logos/aetna-medicare.png';
    // case 'AgeWell New York': return '/img/logos/agewell-new-york.png';
    case 'Allwell': return '/img/logos/allwell.png';
    // case 'Anthem Blue Cross and Blue Shield': return '/img/logos/anthem-blue-cross-and-blue-shield.png';
    // case 'BlueCross BlueShield of WNY and BlueShield of NENY': return '/img/logos/bluecross-blueshield-of-wny-and-blueshield-of-neny.png';
    case 'Bright Health': return '/img/logos/bright-health.png';
    // case 'Buckeye Health Plan - MyCare Ohio': return '/img/logos/buckeye-health-plan-mycare-ohio.png';
    // case 'Capital Advantage Insurance Company': return '/img/logos/capital-advantage-insurance-company.png';
    case 'CareSource': return '/img/logos/caresource.png';
    case 'CareSource MyCare Ohio': return '/img/logos/caresource-mycare-ohio.png';
    // case 'CDPHP Medicare Advantage': return '/img/logos/cdphp-medicare-advantage.png';
    // case 'Centers Plan for Healthy Living': return '/img/logos/centers-plan-for-healthy-living.png';
    case 'Cigna': return '/img/logos/cigna.png';
    case 'Clover Health': return '/img/logos/clover-health.png';
    // case 'Devoted Health': return '/img/logos/devoted-health.png';
    // case 'Elderplan': return '/img/logos/elderplan.png';
    case 'EmblemHealth Medicare HMO': return '/img/logos/emblemhealth-medicare-hmo.png';
    case 'Empire BlueCross BlueShield': return '/img/logos/empire-bluecross-blueshield.png';
    // case 'Excellus Health Plan, Inc': return '/img/logos/excellus-health-plan-inc.png';
    case 'Fidelis Care': return '/img/logos/fidelis-care.png';
    // case 'Geisinger Gold': return '/img/logos/geisinger-gold.png';
    // case 'Health Alliance Medicare': return '/img/logos/health-alliance-medicare.png';
    // case 'Health Partners Medicare': return '/img/logos/health-partners-medicare.png';
    case 'Healthfirst Medicare Plan': return '/img/logos/healthfirst-medicare-plan.png';
    // case 'Highmark Inc.': return '/img/logos/highmark-inc-.png';
    case 'Humana': return '/img/logos/humana.png';
    // case 'Independence Blue Cross': return '/img/logos/independence-blue-cross.png';
    // case 'Independent Health': return '/img/logos/independent-health.png';
    // case 'Keystone Health Plan Central, Inc.': return '/img/logos/keystone-health-plan-central-inc-.png';
    // case 'Lasso Healthcare': return '/img/logos/lasso-healthcare.png';
    // case 'Medical Mutual of Ohio': return '/img/logos/medical-mutual-of-ohio.png';
    // case 'MediGold': return '/img/logos/medigold.png';
    // case 'MetroPlus Health Plan': return '/img/logos/metroplus-health-plan.png';
    case 'Molina Healthcare of Ohio': return '/img/logos/molina-healthcare-of-ohio.png';
    // case 'MVP HEALTH CARE': return '/img/logos/mvp-health-care.png';
    // case 'Oscar': return '/img/logos/oscar.png';
    // case 'Paramount Elite/Medicare Plans': return '/img/logos/paramount-elite-medicare-plans.png';
    // case 'Partners Health Plan': return '/img/logos/partners-health-plan.png';
    // case 'PrimeTime Health Plan': return '/img/logos/primetime-health-plan.png';
    // case 'SummaCare Medicare Advantage Plans': return '/img/logos/summacare-medicare-advantage-plans.png';
    // case 'The Health Plan': return '/img/logos/the-health-plan.png';
    case 'UnitedHealthcare': return '/img/logos/unitedhealthcare.png';
    case 'UnitedHealthcare Community Plan': return '/img/logos/unitedhealthcare-community-plan.png';
    // case 'UPMC for Life': return '/img/logos/upmc-for-life.png';
    // case 'Vibra Health Plan': return '/img/logos/vibra-health-plan.png';
    case 'WellCare': return '/img/logos/wellcare.png';

    default: return '/img/Ascend.svg';
  }
}
