const paths = {
  home: '/',
  plans: '/plans/',
  planDetails: '/plan/',
  planSummary: '/summary/',
  favorites: '/favorites/',
  comparison: '/compare/',
  editPreferences: '/preferences/',
};

export default paths;
