import React from "react";
import {Box, createStyles, IconButton, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendModal from "../../shared/components/AscendModal";
import {ScrollbarList} from "../../shared/components/AscendAutocomplete";
import PlanSummary from "../../shared/components/PlanSummary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      paddingRight: '30px',
      paddingLeft: '30px'
    },
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  })
);

type PlanSummaryModalProps = {
  onClose: () => void,
  open: boolean,
  zip: string,
  countyName: string,
  planId: string
}

export default function PlanSummaryModal(props: PlanSummaryModalProps) {
  const classes = useStyles();

  if (!props.open) {
    return null;
  }

  return <AscendModal
    width={740}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'30px 0px 40px'}
  >
    <div className={'plan-summary-modal'}>
      <IconButton onClick={props.onClose} className={classes.closeIcon}><img src={'/img/close.svg'} /></IconButton>
      <ScrollbarList height={'80vh'}>
        <Box className={classes.modalContent}>
          <PlanSummary zip={props.zip} countyName={props.countyName} planId={props.planId} />
        </Box>
      </ScrollbarList>
    </div>
  </AscendModal>
}
