import React from "react";
import {Box, createStyles, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps} from "react-router";
import useCommonStyles from "../shared/useCommonStyles";
import AscendButton from "../shared/components/AscendButton";
import {withTitle} from "../shared/components/withTitle";
import {useHistory} from "react-router-dom";
import AscendDivider from "../shared/components/AscendDivider";
import {usePlanLazy} from "../App/DetailedPlan/hooks/usePlan";
import paths from "./config/router-paths";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser} from "../shared/StoredUser";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import AscendCard from "../shared/components/AscendCard";
import AscendCheckbox from "../shared/components/AscendCheckbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    }
  })
);

function Enroll(props: RouteComponentProps<{planId: string, zip: string, countyName: string}>) {
  const {planId, zip, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const {authenticated} = React.useContext(KeycloakContext);

  const [getPlan, {data}] = usePlanLazy();

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data])

  const quoteId = React.useMemo(() => {
    const user = getStoredUser();
    if (user) {
      return user.quoteId;
    }
    return undefined
  }, [getStoredUser()])

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          countyName,
          zip,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, quoteId])

  const startApplication = () => {
    const url = '/' + planId + '/' + zip + '/' + countyName + (quoteId ? '/' + quoteId : '');
    history.push(paths.personalDetails + url);
  }

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'}>
      {plan && <>
        <Typography className={'fs-18 bold'} color={'textSecondary'}>Here’s the plan you selected:</Typography>
        <div className={'h-16'} />
        <ShortPlanCard plan={plan}/>
        <div className={'h-20'} />
      </>}

      <div className={'text-align-centered'}>
        <img className={'w-70 h-70'} src={'/img/support-green.svg'} />
        <Typography className={'fs-26 text-align-centered bold mv-20'} color={'textPrimary'}>A licensed sales agent will contact you to help complete your application.</Typography>
        <Typography className={'fs-16 text-align-centered'} color={'textPrimary'}>You’ll need to have your red, white and blue Medicare card handy.</Typography>
      </div>
    </Box>
    <Box display={'flex'} justifyContent={'space-between'} mt={'40px'} mb={'100px'}>
      <AscendButton variant={'contained'} onClick={() => {
        history.goBack()
      }} color={'secondary'}>Back to plans</AscendButton>
      <AscendButton className={'gtm-step-event'} variant={'contained'} onClick={startApplication}>Continue</AscendButton>
    </Box>
  </>
}

export default withTitle(
  Enroll,
  `Congratulations on selecting a new Medicare Advantage plan!`,
  ``,
  undefined,
  undefined,
  undefined,
  800
)
