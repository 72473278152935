import {PlanParam} from "./PlanParam";
import Benefit from "../../../shared/components/Benefit";
import React from "react";
import {PreferenceLine} from "../../PlanList/HealthcarePreferences";
import {DoctorCoverage} from "../../../types";
import {Typography} from "@material-ui/core";

function DoctorCoverageList({coverage}: { coverage: DoctorCoverage[] }) {

  if(coverage.length === 0) {
    return <Typography className={'fs-12'} color={'textPrimary'}>You haven’t added any doctors</Typography>;
  }

  return <>
    {coverage.map((doctor, index) => <PlanParam key={doctor.npi}
                                               value={<Benefit inactive={doctor.coverage === null}
                                                               value={doctor.coverage || false}
                                                               label={doctor.name} />} />)}
    </>
}

export {DoctorCoverageList}
