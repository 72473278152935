
import {config} from "../config/config";
import {PlanFilesOutput} from "../../types";

export default function planFiles(files?: PlanFilesOutput | null) {
  if (files) {
    const prefix = config.filesUrl as string + '/';
    return [{
      label: 'Summary of Benefits',
      url: prefix + files.summaryOfBenefitsUrl
    }, {
      label: 'Evidence of Coverage',
      url: prefix + files.evidenceOfCoverageDocUrl
    }, {
      label: 'Star Ratings information',
      url: prefix + files.starRatingDescDocUrl
    }, {
      label: 'Language Assistance / Non-discrimination Notice',
      url: prefix + files.nonDiscriminationNoticeDocUrl
    }]
  }
  return [];
}
