import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import withErrorBoundary from "../shared/withErrorBoundary";
import {Box, CircularProgress, MenuItem, Paper, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import Terms from "../shared/SidePanel/Terms";
import ContactsSection from "../shared/components/ContactsSection";
import {useHistory} from "react-router-dom";
import {config} from "./config/config";
import paths from "./config/router-paths";
import {Step2aAnswers} from "./questions/Step2a";
import {QuestionnaireActionTypes} from "./QuestionnaireContextProvider";
import {questionsRoutes} from "./hooks/useStepperState";
import clsx from 'clsx';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import useGetCounty from "./hooks/useGetCounty";
import FormTextInput from "../shared/components/FormTextInput";
import FormSelectInput from "../shared/components/FormSelectInput";
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      backgroundColor: '#F5F7F6',
      flexDirection: 'column',
    },
    centered: {
      textAlign: 'center'
    },
    image: {
      position: 'absolute',
      left: 40,
      top: 32,
    },
    terms: {
      position: 'absolute',
      left: 40,
      bottom: 32,
    },
    contacts: {
      position: 'absolute',
      right: 20,
      top: 16,
    },
    zipContainer: {
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    content: {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 40,
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  }),
);

const Schema = yup.object().shape({
  zip: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid zip code').required("Please enter a valid zip code"),
});

interface FormState {
  zip: string;
}

function ZipCode() {
  const classes = useStyles();
  const history = useHistory();
  const {register, handleSubmit, errors, formState, watch, getValues} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()
  const [getCounty, data] = useGetCounty();
  const [county, setCounty] = React.useState<string | undefined>(localStorage.getItem('county') || undefined);

  const counties = React.useMemo(() => {
    return data.data?.plans_zip_county_fips || [];
  }, [data.data, data.called])


  React.useEffect(() => {
    const zip = getValues().zip;
    if (zip && zip.length === 5) {
      setCounty(undefined)
      getCounty({variables: {zip}})
    }
  }, [getValues().zip])

  const onSubmit = (values: FormState) => {
    localStorage.setItem('zip', values.zip);
    localStorage.setItem('county', county || '');
    const planType = localStorage.getItem('planType');
    if (planType === 'MA') {
      document.location.href = config.resultsUrl + 'plans/' + values.zip;
    } else {
      history.push(paths.questionnaire + '/' + questionsRoutes.step9b)
    }
  };

  return (
    <Box display={'flex'} flex={1} height={'100vh'}>
      <Box display={'flex'} flex={1} position={'relative'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <img src={'/img/Ascend.svg'} width={140} height={31} className={classes.image} />
        <div className={classes.terms}><Terms /></div>
        <div className={classes.contacts}><ContactsSection /></div>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.centered}><strong>Great! We just need to check your zip code to <br />find plans in your area.</strong></Typography>
        <div className={'h-20'} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} className={classes.form}>
          <Box className={classes.container}>
            <Box className={classes.zipContainer}>
              <Box display={'flex'}>
                <FormTextInput error={formState.isSubmitted && !!errors.zip}
                               fullWidth
                               helperText={formState.isSubmitted && errors?.zip?.message}
                               inputRef={register}
                               label={'Zip Code'}
                               defaultValue={localStorage.getItem('zip')}
                               name={'zip'}
                               inputProps={{
                                 maxLength: 5
                               }}
                               type={'tel'}
                               placeholder={'Enter 5 digit zip code'} />
              </Box>
              <Box display={'flex'}>
                {data.loading && <Box ml={'20px'} mt={'40px'}><CircularProgress size={30} /></Box>}
                {(!data.loading && data.called) && <>
                  {counties.length === 0 && <Box className={classes.content}><Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>We can't find your county</Typography></Box></Box>}
                  {counties.length === 1 && <Box className={classes.content}><Box mt={'8px'}><Typography variant={'h3'} color={'textPrimary'}>{counties[0].countyname + ' County, ' + counties[0].state}</Typography></Box></Box>}
                  {counties.length > 1 && <FormSelectInput
                    fullWidth
                    label={'Select county'}
                    value={county}
                    placeholder={'Select county'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setCounty(event.target.value as string);
                    }}
                  >
                    {
                      counties.map(c => <MenuItem key={c.stcountyfp} value={c.stcountyfp}>{c.countyname}</MenuItem>)
                    }
                  </FormSelectInput>}
                </>}
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} justifyContent={'space-between'} mt={'30px'} width={696}>
            <AscendButton variant={'contained'} onClick={() => {
              history.goBack();
            }} color={'secondary'}>Back</AscendButton>
            <AscendButton disabled={!formState.isValid || !counties.length || counties.length > 1 && !county} variant={'contained'} type="submit">Continue</AscendButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(ZipCode);
