import React from "react";
import {Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import withErrorBoundary from "../../shared/withErrorBoundary";
import {AdminContext} from "./AdminContext";
import AdminNotification, {AdminNotificationHeight} from "./AdminNotification";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationContainer: {
      height: AdminNotificationHeight
    },
    notification: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      zIndex: 10000
    }
  }),
);

interface LayoutWithNotificationProps {
  children: JSX.Element,
}

function LayoutWithAdminNotification({children}: LayoutWithNotificationProps) {
  const classes = useStyles();

  const adminContext = React.useContext(AdminContext);

  return <Box display={'flex'} flex={1} flexDirection={'column'}>
    {!!adminContext && <div className={classes.notificationContainer}>
      <div className={classes.notification}>
        <AdminNotification/>
      </div>
    </div>}
    <Box display={'flex'}>
      {children}
    </Box>
  </Box>;
}

export default withErrorBoundary(LayoutWithAdminNotification, true);
