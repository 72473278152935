import React from 'react';
import {Box, Grid, Divider, Typography, makeStyles, Theme, createStyles, withStyles} from "@material-ui/core";
import * as Scroll from 'react-scroll';
import {colors} from "../../shared/AppTheme";
import Terms from "../../shared/SidePanel/Terms";
import {PlanWithTiers} from "./hooks/usePlan";
import paths from "../config/router-paths";
import {FilterButton} from "../PlanList/HealthcarePreferences";
import {useHistory, useParams} from "react-router";
import BackButton from "../shared/BackButton";

const scrollSpy = Scroll.scrollSpy;
const Link = Scroll.Link;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentHeader: {
      fontSize: 16,
      fontWeight: 700,
      padding: '15px 20px',
      marginLeft: '-20px',
      marginRight: '-20px',
      width: '270px',
      color: colors.text.primary,
    },
    content: {
      marginLeft: '-20px',
      marginRight: '-20px',
      width: '270px',
    }
  }),
);

export default function PlanDetailsContent({plan}: {plan?: PlanWithTiers}) {
  const params = useParams<{zip: string, countyName: string}>();
  const history = useHistory();

  const classes = useStyles();
  React.useEffect(() => {
    scrollSpy.update();

  }, []);

  const titles = React.useMemo(() => {
      if (plan && (!plan.extraBenefits.drugCoverage || !plan.drugDetails)) {
        return [
          "Key Plan Benefits Overview",
          "Medical & Hospital Coverage",
          "Plan Brochures",
        ]
      }
      return [
        "Key Plan Benefits Overview",
        "Drug Coverage & Costs",
        "Medical & Hospital Coverage",
        "Plan Brochures",
      ]
  }, [plan]);

  return <>
    <div className={'h-16'} />
    <FilterButton variant={'contained'}
                  id={'edit-your-info-button'}
                  onClick={() => history.push(paths.editPreferences + params.zip + '/' + params.countyName)}
                  startIcon={<img src={'/img/edit-green2.svg'}/>}>
      <Box mt={'3px'}>Edit your info</Box>
    </FilterButton>
    <div className={'mt-5'}>
      <BackButton
        startIcon={<img src={"/img/arrow-left.svg"}/>}
        onClick={() => history.push(`/plans/${params.zip}/${params.countyName}`)}>
        Back to Plans
      </BackButton>
    </div>
    <Box className={classes.contentHeader}>
      Table Of Contents
    </Box>
    <Box className={classes.content}>
      {titles.map((title, idx) => <PlanDetailTitle key={idx} title={title} index={idx + 1} />)}
    </Box>
    <div className={'h-200'}/>
    <Terms />
  </>
}

const PlanDetailTitle = withStyles({
  title: {
    fontSize: '16px',
    margin: 0,
    padding: '10px 0 10px 25px',
    fontWeight: 700,
    cursor: 'pointer',
    color: colors.text.secondary,
  }
})(({title, index, classes}: { title: string, index: number, classes: any}) => {
  return (<>
    <Link activeClass="activeMenu" to={title} smooth={true} spy={true} duration={200}>

      <div className={classes.title}>
        {index}. {title}
      </div>
    </Link>
  </>)
})

function PlanDetailSubTitle({title}: { title: string}) {
  return (<>
    <Link activeClass="activeMenu" to={title} smooth={true} spy={true} duration={200}>

      <div style={{fontSize: '16px', margin: '15px 20px', fontWeight: 400, cursor: 'pointer'}} >
        {title}
      </div>
    </Link>
  </>)
}
