import React from "react";
import PhoneInput from "react-phone-input-2";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .special-label': {
        fontFamily: 'Sailec',
        fontSize: '16px',
        lineHeight: '20px',
        marginBottom: '10px',
        color: colors.text.primary,
        fontWeight: 500,
      }
    },
    input: {
      padding: '15px 20px 12px!important',
      fontSize: '15px',
      lineHeight: '21px',
      fontFamily: 'Sailec',
      fontWeight: 500,
      backgroundColor: 'white',
      border: '1px solid #CCD8DC!important',
      borderRadius: '5px',
      width: '100%',
      marginBottom: '30px',
      '&::placeholder': {
        color: '#9fafb4',
        opacity: 1,
      },
      '&:focus': {
        borderColor: `rgba(2, 144, 148, 1)!important`,
        borderWidth: '1px!important',
        backgroundColor: 'rgba(2, 144, 148, 0.05)',
        outline: 'none'
      }
    },
    tick: {
      position: 'absolute',
      bottom: 49,
      right: 12,
    }
  }),
);

interface PhoneNumberInputProps {
  name: string;
  onChange: (value: string) => void;
  label: React.ReactNode,
  placeholder?: string,
  defaultValue?: string | null,
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const classes = useStyles(props);
  const [value, setValue] = React.useState<string>(props.defaultValue || '');
  const [showTick, setShowTick] = React.useState(false);

  React.useEffect(() => {
    setShowTick(/\d{10}/.test(value.toString()))
    props.onChange(value);
  }, [value]);

  return (
    <Box position={'relative'} width={'100%'}>
      {showTick && <img className={classes.tick} src={'/img/tick.svg'} />}
      <PhoneInput
        specialLabel={props.label as string}
        country={'us'}
        defaultMask={'(...) ...-....'}
        alwaysDefaultMask
        disableCountryCode={true}
        placeholder={props.placeholder || 'Enter your phone number'}
        defaultErrorMessage={'Please enter a valid phone'}
        containerClass={classes.root}
        inputClass={classes.input}
        value={value}
        onChange={setValue}
        inputProps={{ name: props.name }}
      />
    </Box>
  );
}
