import React from "react";
import {Box, Divider, Grid, Typography} from "@material-ui/core";
import TooltipedTitle from "../../PlanList/components/TooltipedTitle";

type SimplePlanDetailProps = {
  title: string | JSX.Element,
  tooltip?: string,
  content?: string | null | JSX.Element,
  emptyString?: string,
  hideDidider?: boolean,
  thin?: boolean
}

export function SimplePlanDetail({title, content, tooltip, emptyString, thin, hideDidider}: SimplePlanDetailProps) {
  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} my={"10px"}>
        <Grid item xs={5}>
          {!tooltip && <Typography variant={"h5"} color={"textPrimary"}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <TooltipedTitle title={title} className={!thin ? 'bold' : 'thin'} tooltip={tooltip} />}
        </Grid>
        <Grid item xs={7}>
          <Box display={"flex"} flexGrow={2}>
            {typeof content !== "string" && content}
            {typeof content === "string" &&
            <Typography variant={"h5"} color={"textPrimary"}>{content || emptyString}</Typography>}
          </Box>
        </Grid>
      </Box>
      {!hideDidider && <Divider/>}
    </>
  )
}
