import React from "react";
import {addToCompare, getCompareList, removeFromCompare} from "./utils";

interface ComparisonContextState {
  isInCompare: (bidId: string) => boolean,
  removeFromCompare: (bidId: string) => void,
  addToCompare: (bidId: string) => void,
  list: string[]
}

const defaultState: ComparisonContextState = {
  isInCompare: () => false,
  removeFromCompare: () => {},
  addToCompare: () => {},
  list: [],
};

export const ComparisonContext = React.createContext<ComparisonContextState>(defaultState);

export function ComparisonProvider(props: React.PropsWithChildren<ComparisonProviderProps>) {

  const [list, setList] = React.useState<string[]>(getCompareList());

  const remove = (bidId: string) => {
    removeFromCompare(bidId);
    setList(getCompareList())
  }

  const add = (bidId: string) => {
    addToCompare(bidId);
    setList(getCompareList())
  }

  const isInCompare = (bidId: string) => list.includes(bidId);

  return <ComparisonContext.Provider value={{removeFromCompare: remove, addToCompare: add, isInCompare, list}}>
    {props.children}
  </ComparisonContext.Provider>

}

type ComparisonProviderProps = {}
