import React from 'react';
import {AdminContext} from "./AdminContext";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const AdminNotificationHeight = '50px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: AdminNotificationHeight,
      background: '#FB0A0A',
      padding: '0 18px',
      color: 'white',
    },
    title: {
      fontSize: '18px',
      fontWeight: 700
    }
  }),
);

const AdminNotification = () => {
  const classes = useStyles();

  const adminContext = React.useContext(AdminContext);

  if (adminContext == null) {
    return null
  }

  const fullName = `${adminContext.clientView.firstName} ${adminContext.clientView.lastName}`.trim();

  return <Box className={classes.root} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
    <Typography className={classes.title} variant={'h4'}>{`${fullName}'s quote`}</Typography>
    <IconButton onClick={adminContext.clear}><img src={'/img/white-close.svg'} /></IconButton>
  </Box>
};

export default AdminNotification;
