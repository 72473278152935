import React, {FunctionComponent} from "react";
import {
  Box,
  createStyles, Drawer, Hidden, IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from 'clsx';
import withErrorBoundary from "./withErrorBoundary";
import SidePanel from "./SidePanel/SidePanel";
import {colors} from "./AppTheme";
import {RouteComponentProps} from "react-router";
import ContactsSection from "./components/ContactsSection";
import {AdminContext} from "../App/PlanList/AdminContext";
import {AdminNotificationHeight} from "../App/PlanList/AdminNotification";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      [theme.breakpoints.up('lg')]: {
        marginLeft: (props: LayoutProps) => props.withoutDrawer ? 0 : 320,
      },
      backgroundColor: '#F5F7F6',
      flexDirection: 'column',
      padding: '16px 20px 200px',
      [theme.breakpoints.down('lg')]: {
        padding: '16px 20px',
      },
    },
    drawerPaper: {
      width: 320,
      backgroundColor: 'white',
    },
    drawerOffsetForNotification: {
      top: AdminNotificationHeight,
    },
    mobileTitle: {
      fontWeight: 600,
      marginTop: 5,
    },
    phoneButton: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 49,
      height: 49,
      borderRadius: '50%',
    },
    sideContainer: {
      height: '100%'
    }
  }),
);

interface LayoutProps extends RouteComponentProps<any> {
  SideComponent: FunctionComponent,
  children: JSX.Element,
  sideHeaderContent?: JSX.Element,
  pin?: string,
  withoutDrawer?: boolean,
  helpModalComponent?: FunctionComponent
}

function Layout({SideComponent, ...props}: LayoutProps) {
  const classes = useStyles(props);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const adminContext = React.useContext(AdminContext);

  return (
    <>
      <Hidden lgUp implementation="css">
        {!props.withoutDrawer && <Drawer
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          classes={{
            paper: clsx({[classes.drawerPaper]: true, [classes.drawerOffsetForNotification]: !!adminContext}),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidePanel sideHeaderContent={props.sideHeaderContent}><SideComponent {...props} /></SidePanel>
        </Drawer>}
      </Hidden>
      <Hidden mdDown implementation="css">
        {!props.withoutDrawer && <Drawer
          variant="permanent"
          classes={{
            paper: clsx({[classes.drawerPaper]: true, [classes.drawerOffsetForNotification]: !!adminContext}),
          }}
        >
          <SidePanel containerClassName={classes.sideContainer} sideHeaderContent={props.sideHeaderContent}><SideComponent {...props} /></SidePanel>
        </Drawer>}
        {!!props.withoutDrawer && <SideComponent {...props} />}
      </Hidden>
      <div className={classes.container}>
        <Hidden lgUp implementation="css">
          <Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'} px={'10px'}>
            <Box display={'flex'} alignItems={'center'}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setMobileOpen(mobileOpen => !mobileOpen)}
              >
                <img src={'/img/mobile-menu.png'} />
              </IconButton>
              <img src={'/img/Ascend.svg'} width={91} />
            </Box>
            <a href="tel:8889697667"
               className={classes.phoneButton}
            >
              <img src={'/img/phone.svg'} />
            </a>
          </Box>
        </Hidden>
        <Hidden mdDown implementation="css">
          <ContactsSection helpModalComponent={props.helpModalComponent} pin={props.pin} />
        </Hidden>
        {props.children}
      </div>
    </>
  );
}

export default withErrorBoundary(Layout, true);
