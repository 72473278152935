import React from 'react';
import './App.css';
import '../shared/helpers.scss';
import { ApolloProvider } from '@apollo/react-hooks';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import paths from "./config/router-paths";
import EnrollLayout from "./EnrollLayout";
import {client, setErrorHandler} from "../shared/ApolloClient";
import {KeycloakProvider} from "../shared/KeycloakContext";
import {keycloak} from "../shared/keycloak";
import Soa from "./Soa";
import {SnackbarProvider} from "notistack";
import {ComparisonProvider} from "../App/PlanComparison/ComparisonContext";

function App() {
  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }: any) => {
          console.error(
            `Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
          )
        }
      );
  });

  return (
    <ApolloProvider client={client}>
      <KeycloakProvider keycloak={keycloak}>
        <SnackbarProvider preventDuplicate classes={{
          variantSuccess: 'success-snack'
        }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}>
          <BrowserRouter>
            <Switch>
              <Route exact path={paths.soa + ':id'} component={Soa} />
              <Route exact path={paths.home + ':routeName/:planId/:zip/:countyName/:quoteId?'} component={EnrollLayout} />
              <Route exact path={paths.home + ':routeName'} component={EnrollLayout} />
              <Route exact path={paths.home} component={EnrollLayout} />
              <Route>
                <Redirect to={paths.home} />
              </Route>
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      </KeycloakProvider>
    </ApolloProvider>
  );
}

export default App;
