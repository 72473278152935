import {Box, IconButton, withStyles} from "@material-ui/core";
import React from "react";
import * as Scroll from "react-scroll";
import clsx from "clsx";
import {colors} from "../../../shared/AppTheme";

export const AscendSubtitle = withStyles({
  wrapper: {
    background: 'rgba(2, 144, 148, 0.05)',
    marginBottom: 6,
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: colors.custom.green.variant2,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    transform: 'rotate(-180deg)',
    transition: 'all 0.3s'
  },
  iconOpen: {
    transform: 'rotate(0) !important',
  },
  button: {
    background: 'rgba(2, 144, 148, 0.5)',
    width: 30,
    maxWidth: 30,
    height: 30,
    padding: 10,
    marginTop: 5,
    '&:hover': {
      background: 'rgba(2, 144, 148, 0.5)',
    }
  },
  img: {
    width: 40,
    height: 40,
  }
})(({title, icon, expanded, classes, onToggleClick, wrapperClassName}: IAscendSubtitleProps) => {
  return (

    <Box p={'5px 16px'}
         className={clsx(classes.wrapper, wrapperClassName)}
         display={"flex"}
         flexDirection={"row"}
         alignContent={"center"}
         justifyContent={"space-between"}
         width={1}>

      <Box display={'flex'}
           alignContent={"center"}
           justifyContent={"start"}>
        {icon &&
          <Box mr={'10px'}><img className={classes.img} src={icon}/></Box>
        }
        <Box className={classes.title}>{title}</Box>
      </Box>
      {typeof expanded !== 'undefined' && <IconButton onClick={() => onToggleClick && onToggleClick(!expanded)} className={classes.button}>
        <img className={clsx(classes.icon, expanded && classes.iconOpen)} src={'/img/arrow-white.svg'} />
      </IconButton>}
    </Box>

  )
})


interface IAscendSubtitleProps {
  classes: any;
  wrapperClassName?: string,
  title: string;
  icon?: string;
  expanded?: boolean;
  onToggleClick?: (value: boolean) => void;
}

