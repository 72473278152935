import React from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, Collapse, RadioGroup, Typography} from "@material-ui/core";
import AscendRadio from "../../shared/components/AscendRadio";
import AscendInfo from "../../shared/components/AscendInfo";
import AscendButton from "../../shared/components/AscendButton";
import {QuestionnaireActionTypes, QuestionnaireContext} from "../QuestionnaireContextProvider";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import {OrderType} from "../../shared/components/PharmaciesSelection";
import useCommonStyles from "../../shared/useCommonStyles";
import AscendTitle from "../../shared/components/AscendTitle";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import * as _ from "lodash";
import {colors} from "../../shared/AppTheme";
import {storeFilters} from "../../shared/utils";

const formName = 'STEP_13';

function Step13(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuestionnaireContext);
  const commonClasses = useCommonStyles();
  const [values, setValues] = React.useState<string[]>(state?.STEP_13?.value.value);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const toggleValue = (val: string) => {
    if (values?.includes(val)) {
      setValues(_.without(values, val))
    } else {
      setValues([...(values || []), val])
    }
  }

  const onSubmit = () => {
    dispatch({
      type: QuestionnaireActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName, value: {
          questionText: 'What extra benefits would you like?',
          answerText: values.map(_.lowerCase).map(_.startCase).join(', '),
          value: values
        },
      }
    })
    storeFilters({
      extraBenefits: values.map(_.upperCase),
    }, props.match.params.zip);
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step13b)
  };

  return <>
    <div className={'h-32'} />
    <AscendTitle title={'What extra benefits would you like?'} />
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" classes={{root: commonClasses.radioGroup}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Dental'}
                        tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                        checked={values && values.includes('dental') } onChange={() => toggleValue('dental')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/implant.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Vision'}
                        tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                        checked={values && values.includes('vision') } onChange={() => toggleValue('vision')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/glasses.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Hearing'}
                        tooltip={'Medicare Advantage plans often provide hearing benefits at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}
                        checked={values && values.includes('hearing') } onChange={() => toggleValue('hearing')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/hear.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Transportation'}
                        tooltip={'Medicare Advantage plans often provide transportation benefits at no extra cost, for example coverage for trips to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                        checked={values && values.includes('transportation') } onChange={() => toggleValue('transportation')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/ambulance.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Fitness'}
                        tooltip={'Medicare Advantage plans often provide fitness benefits at no extra cost, for example gym memberships or fitness programs.'}
                        checked={values && values.includes('fitness') } onChange={() => toggleValue('fitness')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/weightlifting.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Telehealth'}
                        tooltip={'Medicare Advantage plans often provide telehealth benefits at no extra cost. These benefits can provide beneficiaries with access to medical and health-related services via telecommunications and digital communications technologies, such as computers and mobile devices.'}
                        checked={values && values.includes('telehealth') } onChange={() => toggleValue('telehealth')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/phone-call.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Over-the-counter benefits'}
                        tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                        checked={values && values.includes('DRUG_COVERAGE') } onChange={() => toggleValue('DRUG_COVERAGE')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/drugs.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Worldwide Emergency'}
                        tooltip={'Worldwide Emergency'}
                        checked={values && values.includes('worldwideEmergency') } onChange={() => toggleValue('worldwideEmergency')}
                        endAdornment={<Box mr={'12px'}><img src={'/img/worldwide.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <Box display={'flex'} mt={'30px'} className={commonClasses.buttonWrapper} justifyContent={'space-between'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
      <AscendButton id={formName} className={'gtm-step-event'}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
    </Box>
    <Box display={'flex'} mt={'30px'} justifyContent={'center'}>
      <Typography variant={'h4'} style={{
        color: colors.custom.green.variant1,
        cursor: 'pointer',
        fontWeight: 500,
        textDecoration: 'underline',
      }} onClick={() => {
        props.history.push(paths.questionnaire  + '/' + questionsRoutes.step13b)
      }}>Click here to skip this step</Typography>
    </Box>
  </>;
}

export default withTitle(
  Step13,
  () => 'What additional benefits are you looking for?',
  'Extra benefits can vary across plans—we’ll prioritize plans that include the benefits that matter to you most.'
);

