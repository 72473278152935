import React from 'react';
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {planFields, PlanWithTiers} from "./usePlan";

export default function usePlansByBid(zip?: string | null, countyName?: string | null, ids?: string[], quoteId?: string | null) {
  const [plans, setPlans] = React.useState<PlanWithTiers[]>([]);
  const [getPlans] = useLazyQuery<{[name: string]: PlanWithTiers}>(gql(getPlansQuery(zip, ids, quoteId, countyName)), {
    onCompleted: data => {
      if (data) {
        setPlans(Object.values(data))
      }
    },
    errorPolicy: "ignore",
    fetchPolicy: "no-cache"
  });

  React.useEffect(() => {
    if (zip && ids && countyName && ids.length) {
      getPlans();
    }
  }, [zip, ids, quoteId, countyName])

  return plans;

}

const getPlansQuery = (zip?: string| null, plans?: string[], quoteId?: string| null, countyName?: string| null) => {
  let query;
  if (!!plans?.length) {
    query = Object.keys(plans).map(parseFloat).map((i: number) => `plan${i}: plan(bidId: "${plans[i]}", countyName: "${countyName}", zip: "${zip}"${quoteId ? ', quoteId: "'+quoteId+'"' : ''}) {
      ${planFields}
      
    }`)
  } else {
    query = `getMyQuoteId`
  }
  return `{${query}}`;
};

