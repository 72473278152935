import {Box, withStyles} from "@material-ui/core";
import AscendTooltip from "../../../shared/components/AscendTooltip";
import React from "react";
import {colors} from "../../../shared/AppTheme";

const TooltipedTitle = withStyles({
  icon: {
    marginTop: 3
  }
})((props: {
  title: string | JSX.Element,
  tooltip: string,
  className?: string,
  classes: any,
  imgSrc?: string,
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>}) => <Box display={'flex'}>
  <Box fontSize={12} marginTop={'3px'} mr={1} fontWeight={500} className={props.className} color={colors.text.primary}>{props.title}</Box>
  <AscendTooltip placement={'top'} arrow title={props.tooltip}>
    <img {...props.imgProps} className={`${props.classes.icon} ${props.imgProps?.className}`} src={props.imgSrc || '/img/info.svg'}></img>
  </AscendTooltip>
</Box>)

export default TooltipedTitle;
