import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, InputAdornment, Typography} from "@material-ui/core";
import clsx from 'clsx';
import FormTextInput from "../../shared/components/FormTextInput";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import CollapseSection from "../../shared/components/CollapseSection";
import TooltipedTitle from "./components/TooltipedTitle";
import StyledRating from "./components/StyledRating";
import {FilterItem, Rating} from "../../types";
import {usePlanFilterData} from "./hooks/usePlanFilterData";
import {PlanFilterContext} from "./PlanFilterContext";
import AscendDivider from "../../shared/components/AscendDivider";
import {colors} from "../../shared/AppTheme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      marginTop: 5
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      marginTop: 5,
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    inputLabel: {
      fontSize: 14
    },
    input: {
      '& input': {
        padding: '18px 0 14px!important'
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    filterTitle: {
      fontSize: 14,
    },
    checkboxLabel: {
      fontSize: 12,
    }
  }),
);
type ListItem = {
  value: any,
  label: any,
  disabled?: boolean
}

export default function PlanFilter() {
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [maxPremium, setMaxPremium] = React.useState('');
  const [maxOutOfPocket, setMaxOutOfPocket] = React.useState('');
  const [planTypes, setPlanTypes] = React.useState<ListItem[]>([]);
  const [snpTypes, setSnpTypes] = React.useState<ListItem[]>([]);
  const [companies, setCompanies] = React.useState<ListItem[]>([]);
  const [extraBenefits, setExtraBenefits] = React.useState<ListItem[]>([]);
  const [maxOutOfPocketRanges, setMaxOutOfPocketRanges] = React.useState<ListItem[]>([]);
  const [maxCostRanges, setMaxCostRanges] = React.useState<ListItem[]>([]);

  const [getPlanFilterData, {data: planFilterData}] = usePlanFilterData();

  React.useEffect(() => {

    if (filter.values) {
      getPlanFilterData({
        variables: {
          filter: filter.values
        }
      });
    }
    // getPlanFilterData()
  }, [filter.values]);

  React.useEffect(() => {

    if (planFilterData) {
      setCompanies(transformListItem(planFilterData.companies));
      setPlanTypes(transformListItem(planFilterData.planTypes));
      setSnpTypes(transformListItem(planFilterData.SNPTypes));
      setExtraBenefits(transformListItem(planFilterData.extraBenefits));
      setMaxCostRanges(transformListItem(planFilterData.maxCostRanges));
      setMaxOutOfPocketRanges(transformListItem(planFilterData.maxOutOfPocketRanges));
      window.scrollTo(0, 0)
    }
  }, [planFilterData]);

  if (!filter.values) {
    return null;
  }

  return <Box width={'100%'} style={{background: "#fff"}} className={'plan-filters'}>
    <Box marginTop={'32px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography className={clsx(classes.title, classes.bold)} color={'textPrimary'}>Filter your results</Typography>
      <Typography variant={'body2'} className={clsx(classes.link)} onClick={filter.reset}>Reset filters</Typography>
    </Box>
   {/* <Box marginTop={'29px'}>
      <CollapseSection title={<TooltipedTitle title={'Monthly Budget'} tooltip={''}/>}>
        <FormTextInput label={'Maximum cost per month'}
                       inputLabelClass={classes.inputLabel}
                       value={maxPremium}
                       onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                         setMaxPremium(event.target?.value as string)
                       }}
                       InputProps={{
                         startAdornment: <InputAdornment position="start">$</InputAdornment>,
                         endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                         classes: {root: classes.input}
                       }}
        />
        <Box mt={'32px'}>
          <Box mb={'16px'}>
            <TooltipedTitle title={'Max Out-of-Pocket'} tooltip={''}/>
          </Box>
          <FormTextInput label={'Total cap per year'}
                         inputLabelClass={classes.inputLabel}
                         value={maxOutOfPocket}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setMaxOutOfPocket(event.target?.value as string)
                         }}
                         InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                           classes: {root: classes.input}
                         }}
          />
        </Box>
      </CollapseSection>
    </Box>
    <Box marginTop={'24px'}>
      {!!maxCostRanges &&
      <FilterItemWithCheckbox items={maxCostRanges}
                              tooltip={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this form for a full description of the breakdown of these costs.'}
                              selectedKeys={filter.values.maxCostRangeNames || []} onChange={filter.switchMaxCostRanges}
                              title={'Estimated monthly cost'}/>
      }
    </Box>
    <Box marginTop={'24px'}>
      {!!maxOutOfPocketRanges &&
      <FilterItemWithCheckbox items={maxOutOfPocketRanges}
                              tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                              selectedKeys={filter.values.maxOutOfPocketRangeNames || []} onChange={filter.switchMaxOutOfPocketRanges}
                              title={'Max Out-of-Pocket'}/>
      }
    </Box>*/}
    <Box marginTop={'24px'}>
      <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={'Star rating'} tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}/>}>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R5}
                        label={<StyledRating readOnly defaultValue={5}/>}
                        checked={filter.values.rating.includes(Rating.R5)}
                        onChange={() => filter.switchRating(Rating.R5)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R4}
                        label={<StyledRating readOnly defaultValue={4}/>}
                        checked={filter.values.rating.includes(Rating.R4)}
                        onChange={() => filter.switchRating(Rating.R4)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R3}
                        label={<StyledRating readOnly defaultValue={3}/>}
                        checked={filter.values.rating.includes(Rating.R3)}
                        onChange={() => filter.switchRating(Rating.R3)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R2}
                        label={<StyledRating readOnly defaultValue={2}/>}
                        checked={filter.values.rating.includes(Rating.R2)}
                        onChange={() => filter.switchRating(Rating.R2)}/>
        <AscendCheckbox variant={'standart'}
                        controlClassName={classes.checkbox}
                        name={Rating.R1}
                        label={<StyledRating readOnly defaultValue={1}/>}
                        checked={filter.values.rating.includes(Rating.R1)}
                        onChange={() => filter.switchRating(Rating.R1)}/>
      </CollapseSection>
    </Box>
    {/*<AscendDivider mt={'32px'}/>
    <Box marginTop={'24px'}>
      <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={'Referral Needed?'} tooltip={'A referral is a written order from your doctor (usually your primary care physician) for you to see a specialist doctor. Some Medicare Advantage plans (in particular, Health Maintenance Organization (HMO) plans) require you to receive a referral from your primary care physician before seeing a specialist. If you don\'t get a referral, you can be charged out of pocket for the specialist services.'}/>}>
        <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox} label="Yes" checked={true}
                        labelClassName={classes.checkboxLabel}
                        onChange={() => {
                        }}/>
        <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox} label="No" checked={false}
                        labelClassName={classes.checkboxLabel}
                        onChange={() => {
                        }}/>
      </CollapseSection>
    </Box>*/}
    <AscendDivider mt={'32px'}/>
    <Box marginTop={'24px'}>
      {!!planTypes &&
      <FilterItemWithCheckbox items={planTypes} selectedKeys={filter.values.planTypes || []} onChange={filter.switchPlanType}
                              tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'}
                              title={'Plan type'}/>
      }
    </Box>

    <Box marginTop={'24px'}>
      {!!snpTypes &&
      <FilterItemWithCheckbox items={snpTypes} selectedKeys={filter.values.SNPTypes || []} onChange={filter.switchSNPType}
                              tooltip={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific special diseases or characteristics. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve. There are three different types of SNP plans. Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid. Chronic-SNPs (C-SNP) serve beneficiaries with certain chronic illnesses. Institutional-SNPs (I-SNP) serve beneficiaries who reside in an institution, like a nursing home.'}
                              title={'Special Needs Plans'}/>
      }
    </Box>

    <AscendDivider mt={'32px'}/>
    <Box marginTop={'24px'}>
      {!!companies &&
      <FilterItemWithCheckbox items={companies} selectedKeys={filter.values.companies || []} onChange={filter.switchCompany}
                              tooltip={'The name of the parent company issuing the plan.'}
                              title={'Company'}/>
      }
    </Box>

    <AscendDivider mt={'32px'}/>

    <Box marginTop={'24px'}>
      {!!extraBenefits &&
      <FilterItemWithCheckbox items={extraBenefits} selectedKeys={filter.values.extraBenefits || []} onChange={filter.switchExtraBenefits}
                              tooltip={'Many Medicare Advantage plans offer additional vision, hearing and dental beenfits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'}
                              title={'Extra benefits'}/>
      }
    </Box>
  </Box>
}

function FilterItemWithCheckbox({items, selectedKeys, onChange, title, tooltip}: { items?: ListItem[], selectedKeys: string[], onChange: (key: any) => void, title: string, tooltip?: string }) {
  const classes = useStyles();

  return <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={title} tooltip={tooltip || ''}/>}>

    {!!items && items.map(item => <AscendCheckbox variant={'standart'}
                                                  key={item.value}
                                                  name={item.value}
                                                  labelClassName={classes.checkboxLabel}
                                                  controlClassName={classes.checkbox}
                                                  label={item.label}
                                                  checked={!!selectedKeys && selectedKeys.includes(item.value)}
                                                  disabled={item.disabled}
                                                  onChange={() => {
                                                            onChange(item.value)
                                                          }}

    />)
    }
  </CollapseSection>
}

function transformListItem(collection: FilterItem[]): ListItem[] {
  return collection.map((item: any) => ({
      value: item.key,
      label: (item.count > 0) ? `${item.name} (${item.count})`: item.name,
      disabled: item.count === 0
    })
  )
}
