import React, {useCallback} from "react";
import {Box, Collapse, createStyles, Grid, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendCard from "../../shared/components/AscendCard";
import TooltipedTitle from "./components/TooltipedTitle";
import {colors} from "../../shared/AppTheme";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import AscendButton from "../../shared/components/AscendButton";
import {PlanTitle} from "./components/PlanTitle";
import {DrugDeliveryMethod, ExtraBenefit, Plan, QueryGenerateEhealthPlanLinkArgs} from "../../types";
import {Link, useHistory} from "react-router-dom";
import {PlanParam} from "../DetailedPlan/components/PlanParam";
import Benefit from "../../shared/components/Benefit";
import {DrugCoverageList} from "../DetailedPlan/components/DrugCoverageList";
import {ComparisonContext} from "../PlanComparison/ComparisonContext";
import {DoctorCoverageList} from "../DetailedPlan/components/DoctorCoverageList";
import PlanSummaryModal from "./PlanSummaryModal";
import paths from "../config/router-paths";
import AscendDivider from "../../shared/components/AscendDivider";
import clsx from "clsx";
import {usePlanLazy} from "../DetailedPlan/hooks/usePlan";
import {getQuoteId} from "../../shared/QuoteId";
import {AscendSubtitle} from "../DetailedPlan/components/AscendSubtitle";
import {SimplePlanDetail} from "../DetailedPlan/components/SimplePlanDetail";
import {ListPlanDetail} from "../DetailedPlan/components/ListPlanDetail";
import AscendTooltip from "../../shared/components/AscendTooltip";
import {PlanFilterContext} from "./PlanFilterContext";
import {FavoritesContext} from "../shared/FavoritesContext";
import {QuoteContext} from "./QuoteContext";
import AscendPreloader from "../../shared/components/AscendPreloader";
import {config} from "../config/config";
import {getStoredUser} from "../../shared/StoredUser";
import useTemporalData from "../../shared/hooks/useTemporalData";
import {getInitialTooltipByTier} from "../DetailedPlan/DetailedPlan";
import {AdminContext} from "./AdminContext";
import AdminNotification from "./AdminNotification";
import {useLazyQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import useGetEHealthLinkRequest from "../../shared/hooks/useGetEHealthLinkRequest";

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: 'white',
      fontWeight: 700,
    },
    logo: {
      height: 33,
      width: 119,
      marginTop: 9,
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '10px 0',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '0 14px 10px 16px',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    enrollSection: {
      maxWidth: 195,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '35px 19px 16px 16px',
      position: 'relative',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      marginRight: 20,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      padding: 4,
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
      }
    },
    enrollButton: {
      fontSize: 14,
      width: '100%',
      padding: 11,
    },
    detailsEnrollButton: {
      padding: '11px 44px',
      whiteSpace: 'nowrap'
    },
    excl: {
      color: colors.custom.green.variant2
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    pharmacyLabelClass: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.text.primary
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    }
  }),
);

type PlanCardProps = {
  plan: Plan,
  zip: string,
  countyName: string,
  hideEnroll?: boolean,
  onAddClick?: () => void,
}

const tooltipImgProps = {
  width: 12,
  height: 12,
}

function PlanCard({plan, zip, countyName, hideEnroll, onAddClick} : PlanCardProps) {
  const classes = planStyles();
  const history = useHistory();
  const filter = React.useContext(PlanFilterContext)
  const adminContext = React.useContext(AdminContext);
  const {preferredDrugs} = React.useContext(QuoteContext);
  const {addToCompare, isInCompare, removeFromCompare} = React.useContext(ComparisonContext);
  const [open, setOpen] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false);
  const [getDetails, {data, loading}] = usePlanLazy();
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const {put} = useTemporalData();
  const [generateEhealthPlanLink] = useGetEHealthLinkRequest({
    onCompleted: data => {
      if (data?.generateEhealthPlanLink) {
        navigator.clipboard.writeText(data?.generateEhealthPlanLink)
      }
    }
  })

  const planDetails = React.useMemo(() => {
    return data?.plan;
  }, [data])

  React.useEffect(() => {
    if (open) {
      getDetails({
        variables: {
          quoteId: getQuoteId(),
          zip,
          countyName,
          bidId: plan.bidId
        }
      })
    }
  }, [open])

  const copyEHealthLink = () => {
    generateEhealthPlanLink({variables: {bidId: plan.bidId, countyName, zip}})
  }

  const getInactive = useCallback((value: ExtraBenefit): boolean => {
    if (!filter.values?.extraBenefits.length) {
      return false;
    }
    return !filter.values?.extraBenefits.includes(value)
  }, [filter.values?.extraBenefits]);

  const onEnrollClick = () => {
    put({variables: {data: JSON.stringify(getStoredUser() || {})}})
      .then((data: any) => { document.location.href = config.enrollUrl + plan.bidId + '/' + zip + '/' + countyName})
  }

  return <div className={'mt-24'}>
    <PlanSummaryModal zip={zip} planId={plan.bidId} countyName={countyName} open={modalOpen} onClose={() => setModalOpen(false)} />
    {!!plan.SNPType && <div className={classes.snp}><TooltipedTitle title={'Special Needs Plan (SNP)'}
                                                                    className={classes.white}
                                                                    tooltip={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific special diseases or characteristics. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve. There are three different types of SNP plans. Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid. Chronic-SNPs (C-SNP) serve beneficiaries with certain chronic illnesses. Institutional-SNPs (I-SNP) serve beneficiaries who reside in an institution, like a nursing home.'}
                                                                    imgSrc={'/img/info-white.svg'}
                                                                    imgProps={tooltipImgProps}/></div>}
    <AscendCard className={classes.card}>
      <div className={'flex mh-10'}>
        <div className={classes.infoWrapper}>
          <PlanTitle plan={plan} inFavorites={favorites.indexOf(plan.bidId) >= 0} favoritesClick={() => toggleFavorite(plan.bidId, zip)} />
          <AscendDivider my={2} />
          <div className={'flex'}>
            <div className={'flex-column'}>
              <TooltipedTitle title={'Monthly Plan Premium'} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'} imgProps={tooltipImgProps}/>
              <PlanParam label={''} value={plan.monthlyPremium || '$0'} />
              <AscendDivider my={2} />
              <TooltipedTitle title={'Deductibles'} tooltip={'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'} imgProps={tooltipImgProps}/>
              <div className={'flex'}>
                <div className={'flex-column'}>
                  <PlanParam label={'Medical'} value={`${plan?.inNetworkDeductableAmount || '$0'}`} />
                </div>
                <div className={'flex-column'}>
                  <PlanParam label={'Drug'} value={`${plan?.drugDetails?.mrxAltDedAmount || 'N/A'}`} />
                </div>
              </div>
              <div className={'mb-4 mt-5'}>
                <TooltipedTitle title={'Max out-of-pocket'} tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'} imgProps={tooltipImgProps}/>
              </div>
              <div className={'flex'}>
                <div className={'flex-column'}>
                  <PlanParam label={'In network'} value={`${plan.outOfPocketAmount} p.a.`} />
                </div>
                <div className={'flex-column'}>
                  {!!plan.maxEnrollmentAmount &&
                  <PlanParam label={'Out-of-network'} value={`${plan.maxEnrollmentAmount} p.a.`} />
                  }
                </div>
              </div>
              <AscendDivider my={2} />
              <div className={'mb-4'}>
                <TooltipedTitle title={'Doctor & Hospital Costs'} tooltip={'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'} imgProps={tooltipImgProps}/>
              </div>
              <PlanParam label={'Primary doctor (In-network):'} value={plan?.primaryCarePhysicianShort || 'N/A' } />
              <PlanParam label={'Specialist (In-network):'} value={plan.physicianSpecialistShort.join(",")} />
              <PlanParam label={'Hospital (In-network):'} value={plan.firstWeekOfStay || 'N/A'} />
            </div>
            <div className={'flex-column ml-20'}>
              <div className={'mb-4 flex-column'}>
                <TooltipedTitle title={'Your Doctors'} tooltip={'Coverage of your doctors under this plan. In-network and out-of network (if applicable) doctor costs will be subject to the copays or coinsurance outlined as per this plan\'s policy.'} imgProps={tooltipImgProps}/>
                {!!plan.doctorsCoverage.length && <DoctorCoverageList coverage={plan.doctorsCoverage} />}
                {!plan.doctorsCoverage.length && <> <div className={'h-10'} /> <div>
                  <RoundedButton variant={'contained'} onClick={() => {
                    if (onAddClick) {
                      onAddClick()
                    } else {
                      history.push(paths.editPreferences + zip + '/' + countyName)
                    }
                  }}>Add doctors</RoundedButton>
                </div></>}
              </div>
              <AscendDivider my={2} />
              <div className={'mb-4 flex-column'}>
                <div onClick={() => window.open(document.location.origin + '/summary/' +  zip + '/' + plan.bidId)}>
                  <TooltipedTitle title={'Your Drugs'} tooltip={'Coverage of your prescription drugs under this plan. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug\'s tier according to plan policy details.'} imgProps={tooltipImgProps}/>
                </div>
                {!!plan.drugsCoverage.length && <DrugCoverageList coverage={plan.drugsCoverage} />}
                {!plan.drugsCoverage.length && <> <div className={'h-10'} /> <div>
                  <RoundedButton variant={'contained'} onClick={() => {
                    if (onAddClick) {
                      onAddClick()
                    } else {
                      history.push(paths.editPreferences + zip + '/' + countyName)
                    }
                  }}>Add drugs</RoundedButton>
                </div></>}
              </div>
            </div>
            <div className={'flex-column ml-20'}>
              <TooltipedTitle title={'Prescription coverage'} tooltip={'Indicates whether this plan also includes bundled prescription drug coverage.'} imgProps={tooltipImgProps}/>
              <div className={'mv-8'}><Benefit label={!!plan.extraBenefits.drugCoverage ? 'Yes' : 'No'} value={plan.extraBenefits.drugCoverage} /></div>
              <TooltipedTitle title={'Part B Giveback'}
                              tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                              imgProps={tooltipImgProps}/>
              <div className={'mt-8'}><Benefit label={!!plan.partBGiveBack ? `${plan.partBAmount}` : 'No'} value={!!plan.partBGiveBack} /></div>

              <AscendDivider my={2} />
              <TooltipedTitle title={'Extra benefits'} tooltip={'Many Medicare Advantage plans offer additional vision, hearing and dental beenfits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'} imgProps={tooltipImgProps}/>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.Dental)} label={'Dental'} value={plan.extraBenefits.dental} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.Vision)} label={'Vision'} value={plan.extraBenefits.vision} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.Hearing)} label={'Hearing'} value={plan.extraBenefits.hearing} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.Fitness)} label={'Fitness'} value={plan.extraBenefits.fitness} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.InsulinSavings)} label={'Insulin savings'} value={plan.extraBenefits.insulinSavings} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.OverTheCounter)} label={'OTC benefits'} value={plan.extraBenefits.overTheCounter} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.Telehealth)} label={'Telehealth'} value={plan.extraBenefits.telehealth} /></div>
              <div className={'mt-8'}><Benefit inactive={getInactive(ExtraBenefit.Transportation)} label={'Transportation'} value={plan.extraBenefits.transportation} /></div>
              {/*plan.otherDefinedSupplemental && (  WAITING FOR API
                <Typography variant={'h5'}
                            color={'textSecondary'}
                            className={'mt-8'}><i>Some extra benefits require an additional premium (see Plan details for more information)</i></Typography>
              )*/}
            </div>
          </div>
        </div>
        <div className={classes.enrollSection}>
          {!!adminContext && <Box position={'absolute'} right={5} top={5}
                                  onClick={copyEHealthLink}
                                  className={`${classes.excl} fs-12 pointer underline`}>E Health</Box>}
          <div className={'flex-column drug-calculation'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography className={classes.price}>{plan.monthlyCost || 'N/A'}</Typography>
              <AscendTooltip placement={'top'} arrow title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'}>
                <img className={'w-12 h-12'} src={'/img/info-green.svg'} />
              </AscendTooltip>
            </Box>
            <Typography variant={'body2'} color={'textPrimary'} className={classes.bold}>estimated average total cost per month</Typography>
            <Box className={'mt-15 flex-space-between'} alignItems={'flex-end'}>
              <Typography variant={'h5'} color={'textPrimary'}>Monthly Plan Premium:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan.monthlyPremium}</Typography>
            </Box>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Drug costs:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan.drugsCost || 'N/A'}</Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Doctor costs:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan.doctorsCost}</Typography>
            </div>
            <div className={'mt-8'}>
              <AscendTooltip placement={'top'} arrow title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You stil pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income.'}>
                <Typography variant={'h5'} className={classes.excl}>Excludes standard Part B premium</Typography>
              </AscendTooltip>
              {plan.optionalSupplementalBenefits && <Typography variant={'h5'} className={classes.excl}>Excludes optional benefits</Typography>}
            </div>

            {!!preferredDrugs?.preferredDrugs?.length && <div className={'mt-10'}>
              <AscendButton className={classes.summaryButton} onClick={() => setModalOpen(true)} variant={'outlined'}>Drug cost breakdown</AscendButton>
            </div>}
          </div>
          <div>
            <div className={'mb-8'}>
              <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox}
                              labelClassName={classes.checkboxLabel}
                              label={'Add to compare'} checked={isInCompare(plan.bidId)}
                              onChange={(e, checked) => {checked ? addToCompare(plan.bidId) : removeFromCompare(plan.bidId)}} />
            </div>
            <div className={'mb-8'}>
              <Link to={`/plan/${zip}/${countyName}/${plan.bidId}`} style={{textDecoration: 'none'}}>
                <AscendButton className={classes.enrollButton} variant={'outlined'}>Plan details</AscendButton>
              </Link>
            </div>
            {!hideEnroll && <AscendButton className={classes.enrollButton}
                          onClick={onEnrollClick}
                          variant={'contained'}>Select this plan</AscendButton>}
          </div>
        </div>
      </div>
      <div className={'mr-10 ml-25'}>
        <AscendDivider mt={'11px'} mb={1} />
      </div>
      <div className={'pointer flex-column flex-align-center'} onClick={() => setOpen(prev => !prev)}>
        <div className={'flex'}>
          <Typography variant={'h5'} color={'textPrimary'}>{open ? 'Hide' : 'Show'} additonal details</Typography>
          <img src={'/img/green-arrow-small.svg'} className={clsx({[classes.icon]: true, [classes.iconOpen]: open})} />
        </div>
      </div>
      <Collapse in={open} mountOnEnter>
        {loading && <AscendPreloader/>}
        {planDetails && <>
          <div className={'h-32'} />
          <AscendSubtitle title={'Addtional Details'} icon={'/img/medical-record-1.svg'}/>
          <Box p={'33px 43px 16px'}>
            <SimplePlanDetail title={"Plan Type"} tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'} content={planDetails?.planType}/>
            {!!plan.partBGiveBack && <SimplePlanDetail title={"Part B Giveback"}
                                                       tooltip={'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'}
                                                       content={<Benefit label={`${plan.partBAmount} per month`} value={!!plan.partBGiveBack} />}/>}
            <ListPlanDetail title={"Inpatient Hospital Care"} tooltip={'Typically includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'} content={planDetails?.hospitalBenefits || []}/>
            {(!!planDetails?.drugDetails && !!planDetails.extraBenefits.drugCoverage) && <>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} my={"10px"}>
                <Grid item xs={5}>
                  <TooltipedTitle title={'Prescription drug coverage'} className={'bold'} tooltip={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'} />
                  <Typography variant={"h5"} color={'textPrimary'}><i>(Initial coverage phase for 30 day supply)</i></Typography>
                </Grid>
                <Grid item xs={7}>
                  {planDetails?.quoteDrugsTiers?.pharmacyName && <Box display={"flex"} flexGrow={2}>
                      <Benefit label={planDetails?.quoteDrugsTiers?.pharmacyName} value={true} labelClass={classes.pharmacyLabelClass}/>
                      <div className={'w-20'} />
                      <Typography variant={"body2"} className={'fs-12'} color={'textPrimary'}>({mapDeliveryType(planDetails?.quoteDrugsTiers?.deliveryType)})</Typography>
                  </Box>}
                  <div className={'h-15'} />
                  <RoundedButton variant={'contained'} onClick={() => history.push(paths.editPreferences + zip)}>{!!planDetails?.quoteDrugsTiers?.pharmacyName ? 'Change' : 'Add'} Pharmacy</RoundedButton>
                </Grid>
              </Box>
              <ListPlanDetail thin
                              title={"Drug Deductible"}
                              tooltip={'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay.'}
                              content={[planDetails?.drugDetails?.mrxAltDedAmount || '', planDetails?.drugDetails?.mrxAltNoDedTier || '']}/>
              {planDetails?.quoteDrugsTiers?.initialCoverageDrugTiers.map(item => <SimplePlanDetail key={item.tierName} title={item.tierName}
                                                                                                    tooltip={getInitialTooltipByTier(item.tier)}
                                                                                                  content={item.coverage} thin/>)}
            </>}
            <Box my={2}>
              <Typography variant={"h5"} color={'textPrimary'}><strong>Dental, Vision and Hearing Additional Benefits details</strong></Typography>
            </Box>
            {planDetails!!.optionalSupplementalBenefits && <SimplePlanDetail title={"Optional supplemental benefits"}
                                                                      tooltip={'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'}
                                                                      content={''} thin/>}
            <ListPlanDetail title={"Dental Services"}
                            tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                            content={planDetails?.dental || []} thin/>

            <ListPlanDetail title={"Vision Services"}
                            tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                            content={planDetails?.vision || []} thin/>

            <ListPlanDetail title={"Hearing Services"}
                            tooltip={'Medicare Advantage plans often provide hearing benefits at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}
                            content={planDetails?.hearing || []} thin/>
            <Box display={"flex"} alignItems={"center"} mt={"44px"} justifyContent={'space-between'}>
              <AscendCheckbox variant={'standart'} controlClassName={classes.checkbox}
                              labelClassName={classes.checkboxLabel}
                              label={'Add to compare'} checked={isInCompare(plan.bidId)}
                              onChange={(e, checked) => {checked ? addToCompare(plan.bidId) : removeFromCompare(plan.bidId)}} />
              <Box display={"flex"} alignItems={"center"}>
                <Link to={`/plan/${zip}/${countyName}/${plan.bidId}`} style={{textDecoration: 'none'}}>
                  <AscendButton className={clsx(classes.enrollButton, classes.detailsEnrollButton)} variant={'outlined'}>Plan details</AscendButton>
                </Link>
                <div className={'w-32'} />
                <AscendButton className={clsx(classes.enrollButton, classes.detailsEnrollButton)} onClick={onEnrollClick} variant={'contained'}>Select this plan</AscendButton>
              </Box>
            </Box>
          </Box>
        </>}
      </Collapse>
    </AscendCard>
  </div>;
}

export default React.memo(PlanCard);

const mapDeliveryType = (type: DrugDeliveryMethod): string => ({
  [DrugDeliveryMethod.PreferredMail]: 'Preferred mail-order pharmacy',
  [DrugDeliveryMethod.StandardMail]: 'Standard mail-order pharmacy',
  [DrugDeliveryMethod.PreferredRetail]: 'Preferred retail pharmacy',
  [DrugDeliveryMethod.StandardRetail]: 'Standard retail pharmacy',
})[type];

export const RoundedButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  contained: {
    borderRadius: 4,
    background: 'rgba(2, 144, 148, 0.05)',
    border: '1px solid #029094',
    padding: '4px 21px',
  },
  disabled: {},
  label: {
    color: 'rgba(2, 144, 148, 1)',
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'none',
  },
})(AscendButton);
