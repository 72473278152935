import * as QueryString from "query-string"
import {getStoredUser, setStoredUser} from "./StoredUser";

export function getQuoteId(): string | undefined {
  const params = QueryString.parse(document.location.search);
  let user = getStoredUser();

  if (params.quote) {
    user = {...user, quoteId: params.quote as string};
    setStoredUser(user)
  }

  return user?.quoteId || undefined;
}

export function setQuoteId(quoteId: string) {
  setStoredUser({...getStoredUser(), quoteId})
}

export function resetQuoteId(): void {
  setStoredUser({...getStoredUser(), quoteId: undefined})
}


