import React from "react";
import {Box, Typography, withStyles} from "@material-ui/core";

const PlanParam = withStyles({
  value: {
    color: '#029094',
    fontWeight: 500,
  },
  label: {
    fontSize: 11,
  }
})(({classes, ...props}: PlanParamProps) =>
  <Box mt={'5px'}>
  {props.label && <Typography className={classes.label} color={'textSecondary'}>{props.label}:</Typography>}
  <Typography variant={'h5'} className={classes.value}>{props.value}</Typography>
</Box>);

interface PlanParamProps {
  value: string | JSX.Element
  classes: any,
  label?: string,
}
export {PlanParam};
