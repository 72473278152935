import React, {ReactNode} from "react";
import {withTitle} from "../../shared/components/withTitle";
import {Box, createStyles, RadioGroup, Theme, Typography} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import paths from "../config/router-paths";
import {RouteComponentProps} from "react-router";
import {questionsRoutes} from "../hooks/useStepperState";
import useCommonStyles from "../../shared/useCommonStyles";
import AscendTitle from "../../shared/components/AscendTitle";
import {useLazyQuery} from "@apollo/react-hooks";
import {ExtraBenefit, FilterItem, QueryPlanTypesArgs} from "../../types";
import {gql} from "apollo-boost";
import AscendCheckbox from "../../shared/components/AscendCheckbox";
import * as _ from 'lodash';
import {makeStyles} from "@material-ui/core/styles";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import {storeFilters} from "../../shared/utils";

const formName = 'STEP_6';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      background: 'white',
      borderRadius: 4,
      padding: '8px 20px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
      width: '100%',
      maxWidth: 220,
    },
    medium: {
      fontWeight: 500
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      fontSize: 12,
    },
  }),
);

export const maxCostRanges = [
  {key: '50-150', name: '$50 to $150'},
  {key: '150-250', name: '$150 to $250'},
  {key: '250-350', name: '$250 to $350'},
  {key: '350+', name: '$350+'},
];

function Budget(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const [monthlyCost, setMonthlyCost] = React.useState<string[]>(state?.STEP_6?.monthlyCost?.value || []);


  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName,
        monthlyCost: {
          questionText: 'Do you have a monthly budget in mind?',
          answerText: monthlyCost.map(_.lowerCase).map(_.startCase).join(', '),
          value: monthlyCost
        }}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step9)
  };

  return <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <div className={'h-32'} />
    <div className={classes.card}>
      {maxCostRanges.map(item => <AscendCheckbox variant={'standart'}
                                                                                       labelClassName={classes.checkboxLabel}
                                                                                       controlClassName={classes.checkbox}
                                                                                       key={item.key}
                                                                                       label={item.name}
                                                                                       checked={!!monthlyCost && monthlyCost.includes(item.key)}
                                                                                       onChange={() => {
                                                                                         if (monthlyCost.includes(item.key)) {
                                                                                           setMonthlyCost(_.without(monthlyCost, item.key))
                                                                                         } else {
                                                                                           setMonthlyCost([...monthlyCost, item.key])
                                                                                         }
                                                                                       }}

      />)}
    </div>

    <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
      <AscendButton className={'gtm-step-event'} id={formName}
                    disabled={typeof monthlyCost === 'undefined'}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <AscendButton variant={'contained'} onClick={() => {
          props.history.goBack();
        }} color={'secondary'}>Back</AscendButton>
      </Box>
    </Box>
  </Box>;
}

const Step6Budget = withTitle(
  Budget,
  'Do you have a budget in mind?',
  'Let us know what your budget is for monthly premiums.',
  undefined,
  undefined,
  isMobile => isMobile,
);

function Benefits(props: RouteComponentProps<any>) {
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [values, setValues] = React.useState<string[]>(state?.STEP_6?.benefits?.value || []);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const toggleValue = (val: string) => {
    if (values?.includes(val)) {
      setValues(_.without(values, val))
    } else {
      setValues([...(values || []), val])
    }
  }

  const disabled = React.useMemo(() => {
      return values?.length > 2;
  }, [values]);

  const onSubmit = () => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, form: formName, benefits: {
          questionText: 'What extra benefits would you like?',
          answerText: values.map(_.lowerCase).map(_.startCase).join(', '),
          value: values
        },
      }
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step9)
  };

  return <>
    <div className={'h-32'} />
    <AscendTitle title={'Select three features that matter to you most:'} />
    <div className={'h-8'} />
    <RadioGroup defaultValue="female" classes={{root: commonClasses.radioGroup}} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Dental'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Dental) < 0}
                        tooltip={'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '}
                        checked={values && values.includes(ExtraBenefit.Dental) } onChange={() => toggleValue(ExtraBenefit.Dental)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/implant.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Vision'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Vision) < 0}
                        tooltip={'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'}
                        checked={values && values.includes(ExtraBenefit.Vision) } onChange={() => toggleValue(ExtraBenefit.Vision)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/glasses.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Hearing'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Hearing) < 0}
                        tooltip={'Medicare Advantage plans often provide hearing benefits at no extra cost. For example: coverage for routine hearing exams and hearing aids.'}
                        checked={values && values.includes(ExtraBenefit.Hearing) } onChange={() => toggleValue(ExtraBenefit.Hearing)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/hear.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Fitness'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Fitness) < 0}
                        tooltip={'Medicare Advantage plans often provide fitness benefits at no extra cost, for example gym memberships or fitness programs.'}
                        checked={values && values.includes(ExtraBenefit.Fitness) } onChange={() => toggleValue(ExtraBenefit.Fitness)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/weightlifting.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Insulin savings'}
                        disabled={disabled && values.indexOf(ExtraBenefit.InsulinSavings) < 0}
                        tooltip={'Plans that offer Insulin Savings are part of Medicare\'s Senior Savings Model. These plans cover several types of insulin at a maximum $35 copay for a 30-day supply in the deductible, initial coverage, and coverage gap phases of the Part D benefit.'}
                        checked={values && values.includes(ExtraBenefit.InsulinSavings) } onChange={() => toggleValue(ExtraBenefit.InsulinSavings)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/insulin.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Over-the-counter benefits'}
                        disabled={disabled && values.indexOf(ExtraBenefit.OverTheCounter) < 0}
                        tooltip={'A periodical (such as monthly or quarterly) allowance for over-the-counter pharmacy products such as cold and allergy medications, eye drops, vitamins, supplements, and compression stockings.'}
                        checked={values && values.includes(ExtraBenefit.OverTheCounter) } onChange={() => toggleValue(ExtraBenefit.OverTheCounter)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/drugs.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <div className={'h-16'} />
    <RadioGroup defaultValue="female" className={commonClasses.radioGroup} row={true}>
      <Box display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Telehealth'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Telehealth) < 0}
                        tooltip={'Medicare Advantage plans often provide telehealth benefits at no extra cost. These benefits can provide beneficiaries with access to medical and health-related services via telecommunications and digital communications technologies, such as computers and mobile devices.'}
                        checked={values && values.includes(ExtraBenefit.Telehealth) } onChange={() => toggleValue(ExtraBenefit.Telehealth)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/phone-call.svg'} /></Box>}
        />
      </Box>
      <Box className={commonClasses.radio} display={'flex'} flex={1}>
        <AscendCheckbox variant={'outlined'} label={'Transportation'}
                        disabled={disabled && values.indexOf(ExtraBenefit.Transportation) < 0}
                        tooltip={'Medicare Advantage plans often provide transportation benefits at no extra cost, for example coverage for trips to or from doctor\'s appointments, pharmacies, or fitness centers.'}
                        checked={values && values.includes(ExtraBenefit.Transportation) } onChange={() => toggleValue(ExtraBenefit.Transportation)}
                        endAdornment={<Box mr={'12px'}><img src={'/img/ambulance.svg'} /></Box>}
        />
      </Box>
    </RadioGroup>
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
        <AscendButton className={'gtm-step-event'} id={formName}
                      variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
        <Box display={'flex'} justifyContent={'center'} mt={2}>
          <AscendButton variant={'contained'} onClick={() => {
            props.history.goBack();
          }} color={'secondary'}>Back</AscendButton>
        </Box>
      </Box>
    </Box>
  </>;
}

const Step6Benefits = withTitle(
  Benefits,
  () => 'Are there any extra benefits that are important to you?',
  'We’ll prioritize plans that meet your criteria.',
  undefined,
  undefined,
  isMobile => isMobile,
);


const Step6 = (props: any) => {
  const [state, dispatch] = React.useContext(QuoteContext);
  return state?.QUIZ_3?.medicarePlanType?.value === 'MG' ? <Step6Budget {...props} /> : <Step6Benefits {...props} />;
};

export default Step6
