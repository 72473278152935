import React from "react";
import {Box, createStyles, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import {Plan} from "../../types";
import AscendTooltip from "./AscendTooltip";
import AscendCard from "./AscendCard";
import AscendDivider from "./AscendDivider";
import {getCompanyLogo} from "../utils";
import moment from "moment";
import {EnrollmentOutput, StatusOutput} from "../../enrollment-types";

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      fontWeight: 500,
      color: colors.text.primary,
      marginTop: 3
    },
    white: {
      color: 'white',
      fontWeight: 700,
    },
    logo: {
      height: 33,
      width: 119,
      marginTop: 9,
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '15px 0 0',
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '0 14px 10px 16px',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    enrollSection: {
      maxWidth: 195,
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      padding: '16px 17px 16px 16px',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      marginRight: 20,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      padding: 4,
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
      }
    },
    enrollButton: {
      fontSize: 14,
      width: '100%',
      padding: 11,
    },
    detailsEnrollButton: {
      padding: '11px 44px',
    },
    excl: {
      color: colors.custom.green.variant2
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(0)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    pharmacyLabelClass: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.text.primary
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    enrollmentStatusSection: {
      height: 55,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 19,
      paddingRight: 11
    },
    pending: {
      background: '#778E95',
      color: 'white',
      padding: '7px 30px',
      borderRadius: 10,
      fontSize: 16,
      fontWeight: 700,
    },
    benefits: {
      padding: '16px 15px 16px 27px',
      background: '#E9F1F0',

    }
  }),
);

type PlanCardProps = {
  plan: Plan,
  enrollment?: EnrollmentOutput,
}

const tooltipImgProps = {
  width: 12,
  height: 12,
}

function ShortPlanCard({plan, enrollment} : PlanCardProps) {
  const classes = planStyles();

  const getStatusContent = (status: StatusOutput): JSX.Element => {
    switch (status) {
      case StatusOutput.Draft: return <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16 mr-25'} color={'textPrimary'}>Application date: {moment(enrollment?.createdAt).format('MM/DD/YY')}</Typography>
        <div className={classes.pending}>Pending</div>
      </Box>;

      case StatusOutput.Enrolled: return <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16 mr-8 bold'} color={'textPrimary'}>Enrolled ({moment(enrollment?.approvedAt).format('MM/DD/YY')})</Typography>
        <img src={'/img/green2-check.svg'} width={40} height={40} />
      </Box>;

      default: return <></>;
    }
  }

  return <div>
    <AscendCard className={classes.card}>
      <div className={'flex mh-15 mb-15'}>
        <div className={classes.infoWrapper}>
          <Box minHeight={80} className={'flex-space-between flex-align-center'}>
            <div className={'mt-8'}>
              <Typography variant={'h2'} color={'textPrimary'} className={classes.bold}>{plan?.planName}</Typography>
            </div>
            <div>
              <img className={classes.logo} width={119} height={33} src={getCompanyLogo(plan.parentOrgName)} />
            </div>
          </Box>
          <AscendDivider my={2} />
          <div className={'flex'}>
            <div className={'flex-column'}>
              <TooltipedTitle title={'Monthly Premium'} tooltip={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'} imgProps={tooltipImgProps}/>
              <PlanParam label={''} value={plan.monthlyPremium || '$0'} />
            </div>
            <div className={'flex-column ml-20'}>
              <TooltipedTitle title={'Deductibles'} tooltip={'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'} imgProps={tooltipImgProps}/>
              <div className={'flex'}>
                <div className={'flex-column'}>
                  <PlanParam label={'Medical'} value={`${plan?.inNetworkDeductableAmount || '$0'}`} />
                </div>
                <div className={'flex-column'}>
                  <PlanParam label={'Drug'} value={`${plan?.drugDetails?.mrxAltDedAmount || 'N/A'}`} />
                </div>
              </div>
            </div>
            <div className={'flex-column ml-20'}>
              <div className={'mb-4'}>
                <TooltipedTitle title={'Doctor Costs'} tooltip={'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'} imgProps={tooltipImgProps}/>
              </div>
              <PlanParam label={'Primary doctor (In-network)'} value={plan?.primaryCarePhysicianShort || 'N/A' } />
            </div>
          </div>
        </div>
        <div className={classes.enrollSection}>
          <div className={'flex-column'}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography className={classes.price}>{plan.monthlyCost || 'N/A'}</Typography>
              <AscendTooltip placement={'top'} arrow title={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this form for a full description of the breakdown of these costs.'}>
                <img width={21} height={21} src={'/img/info-green.svg'} />
              </AscendTooltip>
            </Box>
            <Typography variant={'body2'} color={'textPrimary'} className={classes.bold}>average total cost per month</Typography>
            <div className={'mt-15 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Monthly Premium:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.monthlyPremium}</Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Drug costs:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.drugsCost || 'N/A'}</Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>Doctor costs:</Typography>
              <Typography variant={'h5'} color={'textPrimary'} className={classes.bold}>{plan?.doctorsCost}</Typography>
            </div>
            <div className={'mt-8'}>
              <AscendTooltip placement={'top'} arrow title={'The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You stil pay the Part B premium, even if you\'re enrolled in a Medicare Advantage plan'}>
                <Typography variant={'h6'} className={classes.excl}>Excludes standard Part B premium</Typography>
              </AscendTooltip>
              <div className={'mt-8'}>
                <Typography variant={'h6'} className={classes.excl}>Excludes optional benefits</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!enrollment && <div className={classes.enrollmentStatusSection} style={{backgroundColor: getStatusBackground(enrollment?.status) }}>
        <Typography className={'bold fs-16'} color={'textPrimary'}>Enrollment status</Typography>
        {getStatusContent(enrollment?.status)}
      </div>}
    </AscendCard>
    {/*<div className={classes.benefits}>

    </div>*/}
  </div>;
}

export default React.memo(ShortPlanCard);

const getStatusBackground = (status: StatusOutput): string => {
  switch (status) {
    case StatusOutput.Draft: return `#E8ECED`;
    default: return `rgba(2, 144, 148, 0.1)`;
  }
}

const TooltipedTitle = withStyles({
  icon: {
    marginTop: 3
  }
})((props: {
  title: string | JSX.Element,
  tooltip: string,
  className?: string,
  classes: any,
  imgSrc?: string,
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>}) => <Box display={'flex'}>
  <Box fontSize={14} marginTop={'3px'} mr={1} fontWeight={500} className={props.className} color={colors.text.primary}>{props.title}</Box>
  <AscendTooltip placement={'top'} arrow title={props.tooltip}>
    <img {...props.imgProps} className={props.classes.icon} src={props.imgSrc || '/img/info.svg'}></img>
  </AscendTooltip>
</Box>)

const PlanParam = withStyles({
  value: {
    color: '#029094',
    fontWeight: 500,
  },
  label: {
    fontSize: 12,
  }
})(({classes, ...props}: PlanParamProps) =>
  <Box mt={'5px'}>
    {props.label && <Typography className={classes.label} color={'textSecondary'}>{props.label}:</Typography>}
    <Typography variant={'body2'} className={classes.value}>{props.value}</Typography>
  </Box>);

interface PlanParamProps {
  value: string | JSX.Element
  classes: any,
  label?: string,
}
export {PlanParam};
