import React from "react";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import useSendMedicareTypeCompareEmail from "../hooks/useSendMedicareTypeCompareEmail";
import {MedicareTypeCompareInput} from "../../types";
import AscendModal from "../../shared/components/AscendModal";
import FormTextInput, {TickAdornment} from "../../shared/components/FormTextInput";
import AscendButton from "../../shared/components/AscendButton";
import {useSnackbar} from "notistack";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    pointer: {
      cursor: 'pointer'
    }
  })
);
const Schema = yup.object().shape({
  email: yup.string().email('Enter a valid email address').required("Enter a valid email address"),
});

interface FormState {
  email: string;
}

type MAMOEmailModalProps = {
  onClose: () => void,
  open: boolean,
  input: MedicareTypeCompareInput,
}
export default function MAMOEmailModal(props: MAMOEmailModalProps) {
  const classes = useStyles();
  const {register, handleSubmit, errors, getValues, watch, formState} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()
  const [sendEmail] = useSendMedicareTypeCompareEmail();
  const { enqueueSnackbar } = useSnackbar();

  const send = (email: string) => sendEmail({
    variables: {
      email,
      input: props.input
    }
  })

  const onSubmit = (values: FormState) => {
    send(values.email).then((res) => {
      enqueueSnackbar('Email sent', {variant: 'success'});
    }).catch(() => {
      enqueueSnackbar('Error', {variant: 'error'});
    }).finally(props.onClose);
  };

  return <AscendModal
    width={700}
    open={props.open}
    onClose={props.onClose}
    paperPadding={'45px 55px 40px'}
  >
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
      <IconButton onClick={props.onClose} className={classes.closeIcon}><img src={'/img/close.svg'} /></IconButton>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography className={'fs-26 bold text-align-centered'} color={'textPrimary'}>Enter your email below to send your<br />selection to yourself. </Typography>
        <div className={'h-16'} />
        <img src={'/img/mail.svg'} />
      </Box>
      <Box mt={'16px'}>
        <FormTextInput fullWidth
                       name={'email'}
                       autoFocus={true}
                       inputProps={{
                         autoCapitalize: 'none'
                       }}
                       InputProps={{
                         endAdornment: (!errors.email && getValues().email?.length > 0 && formState.touched.email) ?
                           <TickAdornment/> : null
                       }}
                       placeholder={'Enter your email address'}
                       error={!!errors.email && formState.isSubmitted}
                       helperText={formState.isSubmitted && errors?.email?.message}
                       inputRef={register} />
        <Box display={'flex'}>
          <img src={'/img/padlock.svg'} className={'mr-5'} />
          <Typography className={'fs-14 bold'} color={'textPrimary'}>100% secure. We’ll never sell your details.</Typography>
        </Box>
      </Box>

      <Box display={'flex'} mt={'20px'} justifyContent={'center'}>
        <AscendButton variant={'contained'} disabled={!formState.isValid} type={'submit'}>Send now</AscendButton>
      </Box>
      <div className={'h-30'} />
      <Typography className={'fs-13'} color={'textSecondary'}>By submitting my email and clicking ’Send now’ above, I confirm that the email provided is my own, and I provide my express written consent to be contacted by Ascend including its subsidiaries and/or affiliates at the email I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement Plans, Prescription Drug Plans, and other marketing and informational content, through the use of email.  I further understand and agree that my communications with Ascend are subject to Ascend's Privacy Policy and Terms of Use.</Typography>
    </form>
  </AscendModal>
}
