import React from "react";
import {Box, Collapse, createStyles, Grid, MenuItem, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../App/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendDivider from "../shared/components/AscendDivider";
import AscendCheckbox from "../shared/components/AscendCheckbox";
import AscendButton from "../shared/components/AscendButton";
import PersonalDetailsConfirmModal from "./component/PersonalDetailsConfirmModal";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import {EnrollmentInput, PreferredLanguageDto} from "../enrollment-types";
import moment from "moment";
import * as _ from 'lodash';
import {client} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import useGetCounty from "../Questionnaire/hooks/useGetCounty";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import useUserProfile, {useUserProfileLazy} from "../Private/hooks/useUserProfile";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import MedicareNumberInput from "../shared/components/MedicareNumberInput";
import PhoneNumberInput from "../shared/components/PhoneNumberInput";
import {ScrollbarList} from "../shared/components/AscendAutocomplete";
import AscendRadio from "../shared/components/AscendRadio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: '#E8ECED',
    },
    hardDivider: {
      backgroundColor: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    row: {
      padding: '16px 0',
    },
    info: {
      background: 'rgba(94, 203, 158, 0.1)',
      border: '1px solid #5ECB9E',
      borderRadius: 4,
      padding: '16px 19px',
      fontSize: 16,
    }
  })
);
const formName = 'EnrollmentForm';


const Schema = yup.object().shape({
  firstName: yup.string().required("Enter a valid first name"),
  applicantFirstName: yup.string(),
  lastName: yup.string().required("Enter a valid last name"),
  applicantLastName: yup.string(),
  city: yup.string(),
  aptNumber: yup.string(),
  street: yup.string(),
  phoneNumber: yup.string(),
  zip: yup.string(),
  relationship: yup.string(),
});

interface PersonalDetailsFormState {
  firstName: string;
  applicantFirstName: string;
  lastName: string;
  applicantLastName: string;
  zip: string;
  email: string;
  aptNumber: string;
  city: string;
  street: string;
  phoneNumber: string;
  relationship: string;
}

function EnrollmentForm5(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [effectiveDate, setEffectiveDate] = React.useState<Date | null>(null);
  const [birthDate, setBirthDate] = React.useState<Date | null>(null);
  const [state, setState] = React.useState<string>('');
  const [anotherBeneficiary, setAnotherBeneficiary] = React.useState();
  const [acknowledgement, setAcknowledgement] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [county, setCounty] = React.useState<string | undefined>('');
  const history = useHistory();
  const [save] = useSaveEnrollment();
  const [getCounty, countyData] = useGetCounty();
  const [getUserInfo, userInfoData] = useUserProfileLazy();
  const {authenticated} = React.useContext(KeycloakContext);

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  React.useEffect(() => {
    if (zip) {
      getCounty({variables: {zip}})
    }
  }, [zip])

  const permanentStates = React.useMemo(() => {
    return _.uniq(countyData.data?.plans_zip_county_fips?.map(c => c.state))
  }, [countyData.data?.plans_zip_county_fips])


  const [getPlan, {data}] = usePlanLazy({ client });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const onSubmit = (values: EnrollmentInput) => {
    if (formState.isValid  && moment(birthDate).isValid()) {
      clearErrors()
      setOpen(true);
    }
  };

  const values: EnrollmentInput = React.useMemo(() => {
    return {
      quoteId: authenticated ? userInfoData?.data?.userProfile.quoteId : getStoredUser()?.quoteId,
      id: authenticated ? undefined : getStoredUser()?.enrollmentId,
      bidId: planId,
      birthDate: birthDate ? moment(birthDate).format('MM/DD/YYYY') : '',
      email: getValues().email,
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      permanentAddress: {
        aptNumber: getValues().aptNumber,
        city: getValues().city,
        state: state || '',
        streetName: getValues().street,
        zip: getValues().zip,
      },
      phoneNumber: getValues().phoneNumber,
    }
  }, [getValues(), state, birthDate])

  React.useEffect(() => {
    if (authenticated) {
      getUserInfo();
    }
  }, [authenticated])

  React.useEffect(() => {
    if (userInfoData.data?.userProfile) {
      setState(userInfoData.data.userProfile.state || '');
      setBirthDate(userInfoData.data.userProfile.birthDate ? new Date(userInfoData.data.userProfile.birthDate) : null);
    }
  }, [userInfoData])

  const userInfo = React.useMemo(() => {
    if (authenticated) {
      return userInfoData.data?.userProfile;
    } else if (getStoredUser()) {
      return getStoredUser()
    }
    return undefined
  }, [authenticated, userInfoData])

  const counties = React.useMemo(() => {
    return countyData.data?.plans_zip_county_fips || [];
  }, [countyData.data, countyData.called])

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800}>
    <PersonalDetailsConfirmModal data={values} onClose={() => setOpen(false)} onConfirm={() => {
      setOpen(false);
      save({variables: {data: values}}).then(data => {
        setStoredUser({
          ...getStoredUser(),
          enrollmentId: data?.data?.saveEnrollment,
        })
      });
      props.history.push(paths.receive + "/" + planId + "/" + zip + "/" + countyName)}
    } open={open} />
    {plan && <div className={'mb-40'}><ShortPlanCard plan={plan}/></div>}
    <Typography className={'bold fs-26'}>Review and submit</Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Review your application</Typography>
            <Typography className={'fs-18'} color={'textPrimary'}>Review the information provided below.</Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>Applicant name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>{userInfo?.firstName}</Typography>
          </Grid>
          <AscendDivider className={classes.divider} />
          <Grid item xs={12} sm={6}className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>Your enrollment information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}className={classes.row}>
            <Typography className={'fs-14 underline green pointer mb-16'}>Review enrollment form</Typography>
            <Typography className={'fs-14'} color={'textPrimary'}>Click the Back button below to make changes to any of your answers.</Typography>
          </Grid>
          <AscendDivider className={classes.divider} />
          <Grid item xs={12} sm={6} className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>Enrollment plan</Typography>
          </Grid>
          <Grid item xs={12} sm={6}className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>{plan?.planName}</Typography>
          </Grid>
          <AscendDivider className={classes.divider} />
          <Grid item xs={12} sm={6} className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>Monthly plan premium</Typography>
          </Grid>
          <Grid item xs={12} sm={6}className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>{plan?.monthlyPremium}</Typography>
          </Grid>
          <AscendDivider className={classes.divider} />
          <Grid item xs={12} sm={6} className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>Premium payment method</Typography>
          </Grid>
          <Grid item xs={12} sm={6}className={classes.row}>
            <Typography className={'fs-14'} color={'textPrimary'}>Coupon book</Typography>
          </Grid>
          <AscendDivider className={classes.divider} />
          <AscendDivider my={'20px'} className={classes.hardDivider} />

          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Agreements</Typography>
            <Typography className={'fs-16'} color={'textPrimary'}>Please review the disclosure below before clicking to confirm you have read the disclosures. You must scroll all the way to the bottom to enable the confirmation button.</Typography>
            <div className={'h-10'} />

            <div className={classes.info}>
              <ScrollbarList height={'211px'}>
                <Typography className={'fs-16'} color={'textPrimary'}><b>PLEASE READ THIS IMPORTANT INFORMATION</b><br /><br />If you currently have health coverage from an employer or union, joining Humana could affect your employer or union healthcare benefits. You could lose your employer or union health coverage if you join [Humana]. Read the communications your employer or union sends you. If you have questions, visit their website, or contact the office listed in their communications. If there isn’t information on whom to contact, your benefits administrator or the office that answers questions about your coverage can help.
                </Typography>
            </ScrollbarList>
      </div>
            <div className={'h-20'} />

            <AscendCheckbox variant={'standart'}
                            controlClassName={'flex-align-start'}
                            label={'I acknowledge that I have read the above information and fully understand the contents of the application. I have read and understand the important information on the preceding pages. I have reviewed and received a copy of the Summary of Benefits.'}
                            checked={acknowledgement}
                            onChange={() => setAcknowledgement(!acknowledgement)}

            />
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  disableFuture
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="dense"
                  label={'Proposed effective date for this coverage'}
                  InputAdornmentProps={{
                    classes: {
                      root: commonClasses.hidden
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.input
                    }
                  }}
                  error={formState.isSubmitted && !moment(birthDate).isValid()}
                  helperText={(formState.isSubmitted && !moment(birthDate).isValid()) && 'Please enter a valid date'}
                  placeholder={'MM / DD / YYYY'}
                  value={effectiveDate}
                  invalidDateMessage={'Please enter a valid date'}
                  onChange={date => {
                    setEffectiveDate(date)
                  }}
                  TextFieldComponent={FormTextInput as any}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
          <AscendDivider mb={'20px'} className={classes.hardDivider} />
          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Applicant</Typography>
            <RadioGroup>
                <AscendRadio variant={'standart'}
                             label={'I am completing this form for myself'}
                             checked={anotherBeneficiary === true}
                             onChange={() => setAnotherBeneficiary(true)}/>
                <AscendRadio variant={'standart'}
                             label={'I am completing this form as the Power of Attorney for another beneficiary'}
                             checked={anotherBeneficiary === false}
                             onChange={() => setAnotherBeneficiary(false)}/>
                <Box ml={'51px'}>
                  <Typography className={'fs-16'} color={'textPrimary'}>
                    If you are the authorized legal representative, you MUST sign and provide the following information:*
                  </Typography>
                </Box>
            </RadioGroup>
            <Collapse in={anotherBeneficiary === false} className={`${anotherBeneficiary === false && 'mt-16'}`}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'applicantFirstName'}
                                 error={formState.isSubmitted && !!errors.applicantFirstName}
                                 helperText={formState.isSubmitted && errors?.applicantFirstName?.message}
                                 inputRef={register}
                                 placeholder={'Enter first name'}
                                 label={'First name'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'applicantLastName'}
                                 error={formState.isSubmitted && !!errors.applicantLastName}
                                 helperText={formState.isSubmitted && errors?.applicantLastName?.message}
                                 inputRef={register}
                                 placeholder={'Enter last name'}
                                 label={'Last name'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'street'}
                                 error={formState.isSubmitted && !!errors.street}
                                 helperText={formState.isSubmitted && errors?.street?.message}
                                 inputRef={register}
                                 placeholder={'Enter street address'}
                                 label={'Street Address (P.O. box not allowed)*'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'aptNumber'}
                                 error={formState.isSubmitted && !!errors.aptNumber}
                                 helperText={formState.isSubmitted && errors?.aptNumber?.message}
                                 inputRef={register}
                                 placeholder={'Enter apt. / unit'}
                                 label={'Apt. / Unit*'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput fullWidth
                                 name={'city'}
                                 error={formState.isSubmitted && !!errors.city}
                                 helperText={formState.isSubmitted && errors?.city?.message}
                                 inputRef={register}
                                 placeholder={'Enter city'}
                                 label={'City*'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelectInput
                    fullWidth
                    label={'State'}
                    value={state}
                    placeholder={'Select state'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setState(event.target.value as string);
                    }}
                  >
                    {permanentStates.map(state => <MenuItem key={state} value={state}>{state}</MenuItem>)}
                  </FormSelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormSelectInput
                    fullWidth
                    label={'County'}
                    value={county}
                    disabled={!counties.length}
                    placeholder={'Select county'}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setCounty(event.target.value as string);
                    }}
                  >
                    {
                      counties.map(c => <MenuItem key={c.stcountyfp} value={c.countyname}>{c.countyname}</MenuItem>)
                    }
                  </FormSelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput error={formState.isSubmitted && !!errors.zip}
                                 helperText={formState.isSubmitted && errors?.zip?.message}
                                 fullWidth
                                 inputRef={register}
                                 label={'Zip'}
                                 name={'zip'}
                                 inputProps={{
                                   maxLength: 5
                                 }}
                                 type={'tel'}
                                 placeholder={'Enter 5 digit zip code'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name={'phoneNumber'}
                    render={({ onChange }) => <PhoneNumberInput label={'Phone number'}
                                                                placeholder={'+1 (123) 456-7890'}
                                                                name={'phoneNumber'}
                                                                onChange={onChange} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextInput error={formState.isSubmitted && !!errors.relationship}
                                 helperText={formState.isSubmitted && errors?.relationship?.message}
                                 fullWidth
                                 inputRef={register}
                                 label={'Relationship to applicant'}
                                 name={'relationship'}
                                 placeholder={'Enter relationship'} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <AscendDivider mb={'20px'} className={classes.hardDivider} />
          <Grid item xs={12}>
            <Typography className={'bold fs-24'}>Signature</Typography>
            <Typography className={'fs-16'} color={'textPrimary'}>I understand that my signature (or the signature of the person authorized to act on behalf of the individual under the laws of the state where the individual resides) on this enrollment form means that I have read and understand the contents of this enrollment form. If signed by an authorized individual (as described above), the signature certifies that: <br /> 1) this person is authorized under state law to complete this enrollment and 2) documentation of this authority is available upon request from Medicare.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           name={'firstName'}
                           defaultValue={userInfo?.firstName}
                           error={formState.isSubmitted && !!errors.firstName}
                           helperText={formState.isSubmitted && errors?.firstName?.message}
                           inputRef={register}
                           placeholder={'Enter first name'}
                           label={'First name'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextInput fullWidth
                           defaultValue={userInfoData.data?.userProfile?.lastName}
                           name={'lastName'}
                           error={formState.isSubmitted && !!errors.lastName}
                           helperText={formState.isSubmitted && errors?.lastName?.message}
                           inputRef={register}
                           placeholder={'Enter last name'}
                           label={'Last name'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="MM/dd/yyyy"
                margin="dense"
                label={'Date of birth'}
                InputAdornmentProps={{
                  classes: {
                    root: commonClasses.hidden
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.input
                  }
                }}
                error={formState.isSubmitted && !moment(birthDate).isValid()}
                helperText={(formState.isSubmitted && !moment(birthDate).isValid()) && 'Please enter a valid date'}
                placeholder={'MM / DD / YYYY'}
                value={birthDate}
                invalidDateMessage={'Please enter a valid date'}
                onChange={date => {
                  setBirthDate(date)
                }}
                TextFieldComponent={FormTextInput as any}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'100px'}>
        <AscendButton variant={'contained'} onClick={() => {
          history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Review and submit</AscendButton>
      </Box>
    </form>
  </Box>
}

export default EnrollmentForm5;
