import React from "react";
import {client} from "../shared/ApolloClient";
import {gql} from "apollo-boost";
import {getQuoteId, setQuoteId} from "../shared/QuoteId";
import * as _ from 'lodash';

export const QuoteContext = React.createContext<[IQuoteState, (action: IQuoteAction) => void]>([] as any);

export const QuoteContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<(state: IQuoteState, action: IQuoteAction) => IQuoteState>
  (reducer, {} as any);

  return (
    <QuoteContext.Provider value={[state, dispatch]}>
      {props.children}
    </QuoteContext.Provider>
  );
};

export const withQuoteContextProvider = (WrappedComponent: any) => (props: any) => {
  return (
    <QuoteContextProvider>
      <WrappedComponent {...{...props}} />
    </QuoteContextProvider>
  )
}

const reducer = (state: IQuoteState, action: IQuoteAction) => {
  let newState: IQuoteState = {...state};
  let filters: any = undefined;
  switch (action.type) {
    case QuoteActionTypes.INIT_STATE:
      if (!action.payload) {
        const stored = localStorage.getItem('quote')
        if (stored) {
          action.payload = JSON.parse(stored);
        }
      }
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case QuoteActionTypes.SAVE_STEP_VALUE:
      const {stateKey, ...payload} = action.payload;
      if (payload?.form === 'STEP_6' && action.payload.benefits?.value) {
        filters = {
          extraBenefits: action.payload.benefits?.value,
        }
      }
      newState = {
        ...state,
        [stateKey]: payload,
      };
      break;
    default:
      throw new Error();
  }
  if (action.type !== QuoteActionTypes.INIT_STATE && newState.STEP_2?.zip) {
    client.mutate({
      mutation: gql(saveRequestMutation),
      variables: {
        data: {
          ...prepareToSave(newState),
          zip: newState.STEP_2?.zip,
          county: newState.STEP_2?.countyName,
          form: 'BASIC_INFO',
          status: 'IN_PROGRESS',
          cId: (window as any).gaGlobal?.vid,
          id: getQuoteId(),
          filters,
        }
      }
    }).then((data: any) => {
      setQuoteId(data.data.saveMedicareQuote);
      /*(window as any).fcWidget.user.setProperties({
        quoteId: data.data.saveMedicareQuote
      })*/
    })
    // localStorage.removeItem('questionnaire')
  } else {
    localStorage.setItem('quote', JSON.stringify(newState))
  }

  return newState;
};

function prepareToSave(state: IQuoteState) {
  let result: any = {customAnswers: {}};
  for (const key in state) {
    if (key.indexOf('STEP_') === 0 || key.indexOf('QUIZ_') === 0 ) {
      result.customAnswers = {...result.customAnswers, [key]: state[key as keyof IQuoteState]};
    } else {
      if (_.isObject(state[key as keyof IQuoteState])) {
        result = {...result, ...(state[key as keyof IQuoteState] as any)};
      } else  {
        result = {...result, [key]: state[key as keyof IQuoteState]};
      }
    }
  }
  delete result.pin;
  result.customAnswers = JSON.stringify(result.customAnswers);
  return result;
}

const saveRequestMutation = `
mutation ($data: MedicareQuoteInput!) {
  saveMedicareQuote(data: $data)
}
`;

export enum QuoteActionTypes {
  INIT_STATE,
  SAVE_STEP_VALUE,
}

interface IQuoteAction {
  payload?: any,
  type: QuoteActionTypes
}

export interface IQuoteState {
  id?: string,
  county?: string,
  pin?: string;
  STEP_1?: {
    value?: any
  },
  STEP_2?: {
    zip?: any,
    countyName?: any,
    state?: any,
  },
  QUIZ_3?: {
    medicarePlanType: {
      value?: string
    },
  },
  QUIZ_2?: {
    prepay?: any,
    certainDoctors?: any
    drugsCoverage?: any
    receiveMedicaid?: any
    extraBenefits?: any
    monthlyBudget?: any
  },
  STEP_4?: {
    value?: any,
  },
  STEP_6?: {
    monthlyCost?: any,
    benefits?: any,
  },
  STEP_8?: {
    value?: any,
  },
}
