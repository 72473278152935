import React from "react";
import {Box, Collapse, createStyles, IconButton, Theme, Typography, withStyles} from "@material-ui/core";
import AscendButton from "../components/AscendButton";
import AscendModal from "../components/AscendModal";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import FormTextInput, {TickAdornment} from "./FormTextInput";
import AscendCheckbox from "./AscendCheckbox";
import {CollapseTrigger} from "./CollapsableCard";
import {useSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import PhoneNumberInput from "./PhoneNumberInput";
import ReceivedRequestModal from "./ReceivedRequestModal";
import useSaveUserProfile from "../../Private/hooks/useSaveUserProfile";
import {ProfileOption} from "../../enrollment-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      textAlign: "center",
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: "center",
      cursor: "pointer"
    },
    phone: {
      color: colors.custom.green.variant1,
      display: "flex",
      borderRadius: 4,
      fontSize: 36,
      fontWeight: 700,
      margin: "0 0 0 8px",
      textDecoration: "none",
      "& img": {
        marginRight: 10
      }
    },
    checkboxWrapper: {
      alignItems: "flex-start",
    },
    wrapper: {
      minWidth: 500,
      [theme.breakpoints.down("xs")]: {
        minWidth: "auto",
      },
    },
    nameWrapper: {
      display: "flex",
      alignItems: "flex-end",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    icon: {
      position: "absolute",
      top: 8,
      right: 8,
    },
    textLink: {
      color: colors.text.primary,
    }
  })
);

type EmployedModalProps = {
  onClose: () => void,
  open: boolean
}

export default function HelpModal(props: EmployedModalProps) {
  enum ModalMode {
    Entry,
    Calendly,
    CallRequest,
    ReceivedRequest
  }

  const classes = useStyles();
  const [modalMode, setModalMode] = React.useState<ModalMode | undefined>()
  const [calendly, setCalendly] = React.useState(false);

  React.useEffect(() => {
    if (props.open) {
      setModalMode(ModalMode.Entry)
      setCalendly(false)
    }
  }, [props.open])

  const showEntry = modalMode == ModalMode.Entry
  const showCalendly = modalMode == ModalMode.Calendly
  const showCallRequest = modalMode == ModalMode.CallRequest
  const showReceivedRequest = modalMode == ModalMode.ReceivedRequest

  return <>
    <AscendModal
      width={650}
      disableSidePadding
      open={props.open && (showEntry || showCalendly)}
      onClose={props.onClose}
      modalScroll
    >
      <>
        <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        {showEntry && <Entry onCallClick={() => setModalMode(ModalMode.CallRequest)}
                             onCalendlyClick={() => {
                               setCalendly(true)
                               setModalMode(ModalMode.CallRequest)
                             }} />}
        {showCalendly && <Calendly />}
      </>
    </AscendModal>
    <CallRequest onSubmit={() => {
      if (calendly) {
        setModalMode(ModalMode.Calendly)
      } else {
        setModalMode(ModalMode.ReceivedRequest)
      }
    }} buttonTitle={calendly ? 'Schedule a call' : 'Call me now'} onClose={props.onClose} open={props.open && showCallRequest}/>
    <ReceivedRequestModal onClose={props.onClose} open={props.open && showReceivedRequest}/>
  </>
}

interface FormState {
  phoneNumber: string;
}

const Schema = yup.object().shape({
  phoneNumber: yup.string().required("Please enter a valid phone").min(10, "Please enter a valid phone"),
});

const CallRequest = (props: { open: boolean, buttonTitle: string, onClose: () => void, onSubmit: () => void }) => {
  const classes = useStyles();
  const [value, setCheckboxValue] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [requestACall] = useSaveUserProfile();

  const {register, handleSubmit, errors, watch, formState, getValues, control} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onChange",
  });
  watch()

  const onSubmit = (values: any) => {
    if (formState.isValid) {
      requestACall({
        variables: {
          data: {
            phoneNumber: values.phoneNumber,
            options: [ProfileOption.RequestCall],
            cId: (window as any).gaGlobal?.vid,
          }
        }
      }).then((res) => {
        // enqueueSnackbar("Request submitted", {variant: "success"});
        props.onSubmit();
      }).catch(() => {
        enqueueSnackbar("Error", {variant: "error"}); // todo add text
        props.onClose();
      });
    }
  }

  return <AscendModal
    width={650}
    disableSidePadding
    open={props.open}
    onClose={props.onClose}
    modalScroll
  >
    <>
      <Box display={"flex"} flexDirection={"column"} flex={1}>
        <IconButton onClick={props.onClose} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography variant={"h1"} color={"textPrimary"} className={classes.centered}>Need some help?</Typography>
        </Box>
        <Box display={"flex"} mt={"24px"} justifyContent={"center"}>
          <Typography variant={"h3"} color={"textSecondary"} className={classes.centered}>You can request a call from a licensed Ascend sales<br />agent by entering your details below.</Typography>
        </Box>
        <Box display={"flex"} marginY={"16px"} justifyContent={"center"}>
          <img src={"/img/support.svg"}/>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
          <Box flex={1} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box className={`${classes.wrapper} ${classes.centered} w-300`}>
              <Controller
                control={control}
                name={"phoneNumber"}
                render={({onChange}) => <PhoneNumberInput label={''} placeholder={"Enter your phone number"} name={"phoneNumber"}
                                                          onChange={onChange}/>}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <CallButton variant={"contained"} className={'gtm-button-event'} data-gtm-button-name={'CREATE_PROFILE_BUTTON'} disabled={!formState.isValid} type={"submit"}>
              {props.buttonTitle}
            </CallButton>
          </Box>
        </form>
        <div className={"ph-35"}>
          <Typography variant={"body1"} color={"textPrimary"} className={"mt-16"}>
            By submitting my phone number and clicking ‘{props.buttonTitle}’ above, I confirm that the phone number provided is my own, and I provide my express written consent to be contacted by Ascend including its subsidiaries and/or affiliates at the phone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement Plans, Prescription Drug Plans, hospital indemnity, dental, vision and hearing plans, and other marketing and informational content, through the use of text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with Ascend are subject to Ascend's Privacy
            Policy and Terms of Use.<br /><br />
            I understand my consents to receive text messages and telephone calls are not required to receive services from Ascend; I may instead reach Ascend directly at +1 (888) 969-7667 and I can revoke my consents at any time by emailing hello@ascendmedicare.com.</Typography>
        </div>
        <Box mt={"62px"}>
          <Typography variant={"h3"} color={"textSecondary"} className={classes.centered}>Or you can reach a licensed agent
            directly by calling the number below.</Typography>
        </Box>
        <Box display={"flex"} mt={"16px"} justifyContent={"center"}>
          <a href="tel:8889697667" className={classes.phone}>
            <img src={"/img/phone.svg"} width={36} height={36}/>
            +1 (888) 969-7667 | (TTY: 711)
          </a>
        </Box>
      </Box>
    </>
  </AscendModal>
}

const Calendly = () => {
  const classes = useStyles();

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }, [])

  return <Box display={"flex"} flexDirection={"column"} flex={1}>
    <Box display={"flex"} justifyContent={"center"}>
      <Typography variant={"h1"} color={"textPrimary"} className={classes.centered}>Book a time with a licensed sales
        agent</Typography>
    </Box>
    <Box display={"flex"} mt={"24px"} justifyContent={"center"}>
      <Typography variant={"h3"} color={"textSecondary"} className={classes.centered}>You’ll be emailed a confirmation
        with call details once you submit.</Typography>
    </Box>
    <Box display={"flex"} mt={"31px"} justifyContent={"center"}>
      <div className="calendly-inline-widget" data-url="https://calendly.com/ascendsupport"
           style={{width: "100%", minWidth: "320px", height: "750px"}}
      ></div>
    </Box>

  </Box>
}

const Entry = ({onCalendlyClick, onCallClick}: { onCalendlyClick: () => void, onCallClick: () => void }) => {
  const classes = useStyles();
  return <Box display={"flex"} flexDirection={"column"} flex={1}>
    <Box display={"flex"} justifyContent={"center"}>
      <Typography variant={"h1"} color={"textPrimary"} className={classes.centered}>Need some help?</Typography>
    </Box>
    <Box display={"flex"} mt={"24px"} justifyContent={"center"}>
      <Typography variant={"h3"} color={"textSecondary"} className={classes.centered}>Feel free to organize a call with one of our licensed Ascend sales agents through one of the links below.</Typography>
    </Box>
    <Box display={"flex"} mt={"31px"} justifyContent={"center"}>
      <img src={"/img/support.svg"}/>
    </Box>
    <Box display={"flex"} mt={"31px"} justifyContent={"center"}>
      <RoundedButton variant={"contained"} onClick={onCallClick}>Request a call now</RoundedButton>
    </Box>
    <Box display={"flex"} mt={"16px"} justifyContent={"center"}>
      <RoundedButton variant={"contained"} onClick={onCalendlyClick}>Schedule a call for later</RoundedButton>
    </Box>
    <Box mt={"62px"}>
      <Typography variant={"h3"} color={"textSecondary"} className={classes.centered}>Or you can reach a licensed agent
        directly by calling the number below.</Typography>
    </Box>
    <Box display={"flex"} mt={"16px"} justifyContent={"center"}>
      <a href="tel:8889697667" className={classes.phone}>
        <img src={"/img/phone.svg"} width={36} height={36}/>
        +1 (888) 969-7667 | (TTY: 711)
      </a>
    </Box>
  </Box>
}

export const RoundedButton = withStyles({
  contained: {
    borderRadius: 4,
    width: 310,
    padding: "15px 0 11px",
  },
  label: {
    fontSize: 24,
  },
})(AscendButton);

export const CallButton = withStyles({
  contained: {
    borderRadius: 4,
    width: 310,
    padding: "14px 0 11px",
  },
  label: {
    fontSize: 16,
  },
})(AscendButton);
