import React from 'react';
import {useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";

export default function useSaveRequest(onCompleted?: (data: any) => void) {
  return useMutation<any, {data: any}>(
    gql(saveRequestMutation),
    {
      onCompleted
    }
  );
}

const saveRequestMutation = `
mutation ($data: MedicareQuoteInput!) {
  saveMedicareQuote(data: $data)
}
`;
