import React from "react";
import {Box, Button, createStyles, InputAdornment, Theme, Typography, withStyles} from "@material-ui/core";
import clsx from "clsx";
import FormTextInput from "../../shared/components/FormTextInput";
import CollapseSection from "../../shared/components/CollapseSection";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import AscendButton from "../../shared/components/AscendButton";
import {QuoteContext} from "./QuoteContext";
import useSaveRequest from "../../Questionnaire/hooks/useSaveRequest";
import {getQuoteId} from "../../shared/QuoteId";
import {PlanFilterContext} from "./PlanFilterContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      marginTop: 5,
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    input: {
      width: 145,
      '& input': {
        padding: '12px 0 12px 14px!important',
        fontSize: 12,
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    label: {
      fontSize: 11,
    },
    placeholder: {
      '& p': {
        fontSize: 12,
      }
    },
    inputLabel: {
      fontSize: 14,
    }
  }),
);

const MonthlyCostCalculator = () => {
  const classes = useStyles();
  const {doctorVisitsNumber, specialistVisitsNumber} = React.useContext(QuoteContext);
  const {refresh} = React.useContext(PlanFilterContext)
  const [save] = useSaveRequest();
  const [doctorVisits, setDoctorVisits] = React.useState<string>('');
  const [specialistVisits, setSpecialistVisits] = React.useState<string>('');

  React.useEffect(() => {
    if (typeof doctorVisitsNumber !== 'undefined') {
      setDoctorVisits(doctorVisitsNumber.toString())
    }
  }, [doctorVisitsNumber])

  React.useEffect(() => {
    if (typeof specialistVisitsNumber !== 'undefined') {
      setSpecialistVisits(specialistVisitsNumber.toString())
    }
  }, [specialistVisitsNumber])

  const onClick = () => {
    save({
      variables: {
        data: {
          doctorVisitsNumber: doctorVisits,
          specialistVisitsNumber: specialistVisits,
          status: 'IN_PROGRESS',
          form: 'SEARCH_RESULTS',
          id: getQuoteId()
        }
      },
    }).then(refresh);
  }

  return <div className={'monthly-cost-calculator'}>
    <CollapseSection
      title={<Typography className={clsx(classes.title, classes.bold)} color={'textPrimary'}>Monthly Cost Calculator</Typography>}>
      <Typography className={classes.label} color={'textSecondary'}>Your estimated monthly cost will change based on the
        amounts you enter below. We have included
        prescription information that you’ve already
        entered.</Typography>
      <Box mt={2}>
        <div className={'flex flex-align-center relative'}>
          <FormTextInput label={'Primary care visits'}
                         inputLabelClass={classes.inputLabel}
                         value={doctorVisits}
                         InputProps={{
                           className: 'w-55'
                         }}
                         onChange={event => setDoctorVisits(event.target.value)}
                         type={'number'}
                         name={'primary-care-visits'}
          />
          <Box position={'absolute'} left={'70px'}>
            <Typography color={'textPrimary'} className={'fs-12'}>per year</Typography>
          </Box>
        </div>
        <div className={'flex flex-align-center relative'}>
          <FormTextInput label={'Specialist visits'}
                         inputLabelClass={classes.inputLabel}
                         InputProps={{
                           className: 'w-55'
                         }}
                         value={specialistVisits}
                         onChange={event => setSpecialistVisits(event.target.value)}
                         name={'specialist-visits'}
          />
          <Box position={'absolute'} left={'70px'}>
            <Typography color={'textPrimary'} className={'fs-12'}>per year</Typography>
          </Box>
        </div>
        <Box mt={'4px'}>
          <RoundedButton onClick={onClick} variant={'contained'}>Update doctor costs</RoundedButton>
        </Box>
      </Box>
    </CollapseSection>
  </div>
}

export default MonthlyCostCalculator;


const RoundedButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'white',
    },
    '&:active': {
      backgroundColor: 'white',
    },
  },
  contained: {
    borderRadius: 4,
    background: 'rgba(2, 144, 148, 0.05)',
    width: '100%',
    padding: '6px 0 5px',
    border: '1px solid #029094',
  },
  disabled: {},
  label: {
    color: 'rgba(2, 144, 148, 1)',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'none',
  },
})(AscendButton);
