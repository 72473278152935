import React from "react";
import {
  Box,
  CircularProgress,
  createStyles,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import FormTextInput, {TickAdornment} from "../../shared/components/FormTextInput";
import AscendButton from "../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps} from "react-router";
import {withTitle} from "../../shared/components/withTitle";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import paths from "../../Questionnaire/config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import AscendTitle from "../../shared/components/AscendTitle";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useCommonStyles from "../../shared/useCommonStyles";
import moment from "moment";
import AscendInfo from "../../shared/components/AscendInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 40,
      maxWidth: 320,
    },
    input: {
      paddingRight: 0
    }
  }),
);

const formName = 'STEP_3';

function Step3(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const commonClasses = useCommonStyles();
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    getStoredUser()?.birthDate ? moment(getStoredUser()?.birthDate).toDate() : null
  );


  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = () => {
    if (moment(selectedDate).isValid() && moment(selectedDate).year() > 1900) {
      dispatch({
        type: QuoteActionTypes.SAVE_STEP_VALUE,
        payload: {stateKey: 'STEP_3', form: formName}
      })
      setStoredUser({...getStoredUser(), birthDate: moment(selectedDate).format('MM/DD/YYYY')})
      props.history.push(paths.questionnaire + '/' + questionsRoutes.step6);
    }
  };

  return <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <Box className={classes.container}>
      <AscendTitle title={'Date of birth'} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          disableFuture
          autoFocus={true}
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          onKeyPress={event => {
            if (event.key === 'Enter') {
              onSubmit();
            }
          }}
          InputAdornmentProps={{
            classes: {
              root: moment(selectedDate).isValid() && moment(selectedDate).year() >= 1900 ? undefined : commonClasses.hidden
            }
          }}
          InputProps={{
            classes: {
              root: classes.input
            }
          }}
          keyboardIcon={<TickAdornment className={'mr-15'}/>}
          placeholder={'MM / DD / YYYY'}
          value={selectedDate}
          invalidDateMessage={'Please enter a valid date of birth'}
          maxDateMessage={'Please enter a valid date of birth'}
          minDateMessage={'Please enter a valid date of birth'}
          onChange={setSelectedDate}
          TextFieldComponent={FormTextInput as any}
        />
      </MuiPickersUtilsProvider>
      <AscendInfo open={state?.QUIZ_3?.medicarePlanType?.value === 'MG'}
                  description={`Medicare Supplement prices can vary based on your age.`}/>
    </Box>

    <Box display={'flex'} flexDirection={'column'} width={'100%'} mt={'30px'} maxWidth={320}>
      <AscendButton className={'gtm-step-event'} id={formName}
                    disabled={!moment(selectedDate).isValid() || moment(selectedDate).year() <= 1900}
                    variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <AscendButton variant={'contained'} onClick={() => {
          props.history.goBack();
        }} color={'secondary'}>Back</AscendButton>
      </Box>
    </Box>
  </Box>
}

export default withTitle(
  Step3,
  () => 'What is your date of birth?',
  '',
  undefined,
  undefined,
  isMobile => isMobile,
)
