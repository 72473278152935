import React from "react";
import {Box, Collapse, createStyles, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ScrollbarList} from "../../shared/components/AscendAutocomplete";
import {useHistory} from "react-router";
import {getQuoteId} from "../../shared/QuoteId";
import {
  CoverageEntryOutput,
  DrugCalculationStage,
  DrugCostOutput,
  Plan
} from "../../types";
import moment from "moment";
import clsx from "clsx";
import * as _ from "lodash";
import {colors} from "../AppTheme";
import {usePlanLazy} from "../../App/DetailedPlan/hooks/usePlan";
import {AscendSubtitle} from "../../App/DetailedPlan/components/AscendSubtitle";
import {Bar} from "react-chartjs-2";
import StyledRating from "../../App/PlanList/components/StyledRating";
import {getCompanyLogo} from "../utils";
import AscendPreloader from "./AscendPreloader";
import PlanSummaryChart from "./PlanSummaryChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    icon: {
      transform: 'rotate(0)',
      transition: 'all 0.3s',
      marginRight: 15,
      marginTop: 1
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    titleWrapper: {
      width: '100%',
      background: 'rgba(2, 144, 148, 0.05)',
      borderRadius: 4,
      marginTop: '15px',
      marginBottom: '15px',
    },
    medium: {
      fontWeight: 500,
    },
    bold: {
      fontWeight: 700,
    },
    green: {
      color: colors.custom.green.variant2,
    },
    table: {
      tableLayout: 'fixed',
      width: '100%',
      '& td': {
        paddingTop: 16,
        paddingBottom: 16,
        fontSize: 12,
        color: colors.text.primary,
        paddingLeft: 15,
      },
      '& tr td:last-child': {
        paddingRight: 15,
      },
      '& td:not(:first-child)': {
        textAlign: 'right'
      }
    },
    borderRow: {
      '& td': {
        borderBottom: '1px solid #E8ECED',
      }
    },
    subtitleRow: {
      background: 'rgba(164, 180, 185, 0.1)',
      '& td': {
        verticalAlign: 'top',
        paddingTop: 9,
        paddingBottom: 9,
      }
    },
    titleRow: {
      background: 'rgba(2, 144, 148, 0.05)',
      '& td': {
        paddingTop: 13,
        paddingBottom: 13,
        fontWeight: 700,
      }
    },
    monthDetailsRow: {
      '& td': {
        fontStyle: 'italic',
      },
      '& td:first-child': {
        paddingLeft: 50,
      }
    },
    pointer: {
      cursor: 'pointer'
    },
    planTitle: {
      fontSize: '20px',
      fontWeight: 700
    },
    planLogo: {
      maxWidth: '183px',
      maxHeight: '50px',
      width: 'auto',
      height: 'auto',
    },
    roundedCorners: {
      borderRadius: '4px'
    },
    summaryByMonthTable: {
      '& td': {
        paddingLeft: 9,
      },
    },
    summaryByMonthTitleRow: {
      '& td': {
        paddingTop: 14,
        paddingBottom: 14,
      }
    },
  })
);

type PlanSummaryProps = {
  zip: string,
  countyName: string,
  planId: string
}

export default function PlanSummary(props: PlanSummaryProps) {
  const classes = useStyles();
  let {zip, planId, countyName} = props;
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(true);
  const [expandedState, setExpandedState] = React.useState<{[key: string]: boolean}>({});

  const [getPlan, {data}] = usePlanLazy();

  React.useEffect(() => {
    if (zip && planId) {
      getPlan({variables: {bidId: planId, zip, countyName, quoteId: getQuoteId()}})
    }
  }, [zip, planId])

  const plan = React.useMemo(() => {
    if (data) {
      return data.plan;
    }
    return null;
  }, [data])

  const getExpandedValue = (key: string): boolean => typeof expandedState[key] === 'undefined' ? false : expandedState[key];

  if (!plan) {
    return <AscendPreloader />;
  }

  const toTable = (costs: DrugCostOutput[]) => {
    const month = costs[0].month;
    const monthName = moment(month.toString(), 'M').format('MMMM')
    const monthlyCost = plan?.drugCostMonthlyBreakdown?.monthlyCosts?.find(mc => mc.month == month)
    return <>
      <table className={classes.table} cellSpacing={0}>
        <tbody>
        <tr className={clsx(classes.borderRow, classes.pointer)}
            onClick={
              () => setExpandedState(prevState => ({...prevState, [monthName] : typeof prevState[monthName] === 'undefined' ? true : !prevState[monthName]}))
            }
        >
          <td colSpan={4} className={classes.medium}>
            <img src={'/img/arrow.svg'} className={clsx(classes.icon, getExpandedValue(monthName) && classes.iconOpen)} />
            {monthName}
          </td>
          <td className={classes.medium}>
            {monthlyCost?.amountString}
          </td>
          <td className={classes.medium}>
            {_.upperFirst(_.lowerCase(monthlyCost?.stage)) || ''}
          </td>
        </tr>
        </tbody>
      </table>
      <Collapse in={getExpandedValue(monthName)}>
        <table className={classes.table} cellSpacing={0}>
          <tbody>
          {costs.map(cost => <tr key={`${cost.month}-${cost.name}`} className={clsx(classes.borderRow, classes.monthDetailsRow)}>
            <td colSpan={4}>{cost.name}</td>
            <td>{cost.cost}</td>
            <td></td>
          </tr>)}

          </tbody>
        </table>
      </Collapse>
    </>;
  }

  const hitIC = plan.drugCostMonthlyBreakdown?.coverageEntry.find(entry => entry.stage === DrugCalculationStage.InitialCoverage);
  const hitCG= plan.drugCostMonthlyBreakdown?.coverageEntry.find(entry => entry.stage === DrugCalculationStage.CoverageGap);
  const hitCC= plan.drugCostMonthlyBreakdown?.coverageEntry.find(entry => entry.stage === DrugCalculationStage.Catastrophic);

  return <Box>
      <div onClick={() => history.push(`/plan/${zip}/${planId}`)}>
        <PlanRating plan={plan}/>
        <div className={'flex-space-between flex-align-center'}>
          <div>
            <Typography variant={'h3'} color={'textPrimary'} className={classes.planTitle}>{plan.planName}</Typography>
          </div>
          <div>
            <img className={classes.planLogo} src={getCompanyLogo(plan.parentOrgName)} />
          </div>
        </div>
      </div>
      <Box className={classes.titleWrapper} display={'flex'} justifyContent={'center'} paddingY={'11px'}>
        <Typography variant={'h3'} className={clsx(classes.bold, classes.green)}>Estimated Drug Cost Summary</Typography>
      </Box>
      <Box>
        <div className={'mb-15'}>
          <PlanSummaryChart {...props} />
        </div>
        <InitialCoverageSection plan={plan} entry={hitIC}/>
        <CoverageGapSection plan={plan} entry={hitCG}/>
        <CatastrophicSection plan={plan} entry={hitCC}/>

        <Box mt={'7px'} mb={'12px'}>
          <AscendSubtitle wrapperClassName={classes.roundedCorners}
                          title={'See detailed cost calculations'}
                          expanded={expanded}
                          onToggleClick={() => setExpanded(!expanded)}/>
        </Box>

        <Collapse in={expanded}>
          <table className={classes.table} cellSpacing={0}>
            <thead>
            <tr className={classes.titleRow}>
              <td>Your drugs</td>
              <td>Qty</td>
              <td>Package</td>
              <td>Covered under this plan?</td>
              <td>Quantity limits under this plan?</td>
              <td>Branded or generic?</td>
            </tr>
            </thead>
            <tbody>
            {plan.drugPrices.map((item, i, arr) => <tr key={item.rxcui} className={i != (arr.length - 1) ? classes.borderRow : undefined}>
              <td>{item.name}</td>
              <td><i>{item.quoteQuantity}</i></td>
              <td><i>{item.packageDescription}</i></td>
              <td><i>{item.isCoveredByPlan ? 'Yes' : 'No'}</i></td>
              <td><i>{item.quantityLimit || '-'}</i></td>
              <td>{item.isGeneric ? 'Generic' : 'Branded'}</td>
            </tr>)}
            </tbody>
            <thead>
            <tr className={clsx(classes.titleRow, classes.borderRow)}>
              <td>By coverage phase</td>
              <td>Tier</td>
              <td>Deductible</td>
              <td>Initial coverage</td>
              <td>Coverage gap ("Donut hole")</td>
              <td>Catastrophic coverage</td>
            </tr>
            <tr className={classes.subtitleRow}>
              <td>What you pay</td>
              <td></td>
              <td>Full retail price</td>
              <td>Plan determined copay/
                coinsurance (based on tier)</td>
              <td>25% of retail (both branded and generic)</td>
              <td>Greater of 5% or $3.70 for generics Greater of 5% or $9.20 for branded</td>
            </tr>
            </thead>
            <tbody>
            {plan.drugPrices.map((item, i, arr) => <tr key={item.rxcui} className={i != (arr.length - 1) ? classes.borderRow : undefined}>
              <td>{item.name}{item.isAggregatedPrice ? <sup>*</sup>: ""}</td>
              <td>{item.tier}</td>
              <td>{item.price} / {item.unitPrice} {item.isAggregatedPrice ? <sup>*</sup>: ""}</td>
              <td>{item.initialCoveragePrice}</td>
              <td>{item.coverageGapPrice}</td>
              <td>{item.catastrophicPrice}</td>
            </tr>)}
            </tbody>
          </table>
          <table className={clsx(classes.table, classes.summaryByMonthTable)} cellSpacing={0}>
            <tbody>
            <tr className={clsx(classes.titleRow, classes.summaryByMonthTitleRow)}>
              <td colSpan={4} className={classes.medium}>Your summary by month</td>
              <td className={classes.medium}>Monthly cost</td>
              <td className={classes.medium}>Coverage level</td>
            </tr>
            </tbody>
          </table>
          {!!plan && Object.values(_.groupBy(plan.drugCostMonthlyBreakdown?.costs, 'month')).map((monthlyCosts, i) =>
            <div key={i}>
              {toTable(monthlyCosts)}
            </div>)}
          <table className={clsx(classes.table, classes.summaryByMonthTable)} cellSpacing={0}>
            <tbody>
            <tr className={clsx(classes.titleRow, classes.summaryByMonthTitleRow)}>
              <td colSpan={4} className={classes.medium}>Total Annual Cost</td>
              <td className={classes.medium}>{plan?.drugCostMonthlyBreakdown?.totalClientCost}</td>
              <td className={classes.medium}></td>
            </tr>
            </tbody>
          </table>
        </Collapse>
      </Box>
    </Box>
}

function InitialCoverageSection(props: {plan: Plan, entry?: CoverageEntryOutput}) {
  const classes = useStyles();

  return <>
    <Typography variant={'body1'} className={classes.bold} color={'textPrimary'}>
      {!!props.entry && <>You will enter <span className={'underline'}>Initial Coverage Phase in {intToMonth(props.entry.month)}</span> this year under this plan</>}
      {!props.entry && <>You won't enter <span className={'underline'}>Initial Coverage Phase</span> this year under this plan</>}
    </Typography>
    <Typography variant={'body1'} color={'textPrimary'}>
      This plan has a drug deductible of {props.plan?.drugDetails?.mrxAltDedAmount}. That means you’ll pay the retail drug price for your covered drugs up until that point, before your insurance starts paying.
    </Typography>

  </>
}

function CoverageGapSection(props: {plan: Plan, entry?: CoverageEntryOutput}) {
  const classes = useStyles();

  return <>
    <Typography variant={'body1'} className={clsx(classes.bold, 'mt-15')} color={'textPrimary'}>
      {!!props.entry && <>You will enter <span className={'underline'}>Coverage Gap Phase in {intToMonth(props.entry.month)}</span> this year under this plan</>}
      {!props.entry && <>You won't enter <span className={'underline'}>Coverage Gap Phase</span> this year under this plan</>}
    </Typography>
    <Typography variant={'body1'} color={'textPrimary'}>
      You’ll pay a copay or co-insurance on your drugs up until the sum of your portion of costs and what the plan has paid is $4,130 (in 2021) per year, and then you’ll enter the Coverage Gap (or “donut hole”) phase, where you will typically pay 25% of retail cost for your drugs.
    </Typography>
  </>
}

function CatastrophicSection(props: {plan: Plan, entry?: CoverageEntryOutput}) {
  const classes = useStyles();

  return <>
    <Typography variant={'body1'} className={clsx(classes.bold, 'mt-15')} color={'textPrimary'}>
      {!!props.entry && <>You will enter <span className={'underline'}>Catastrophic Coverage Phase in {intToMonth(props.entry.month)}</span> this year under this plan</>}
      {!props.entry && <>You won't enter <span className={'underline'}>Catastrophic Coverage Phase</span> this year under this plan</>}
    </Typography>
    <Typography variant={'body1'} color={'textPrimary'}>
      You will enter the Catastrophic Coverage phase once your total out-of-pocket costs reaches $6,550 (in 2021). Under this phase, you’ll pay the maximum of $3.70 or 5% of retail price for generic drugs, and $9.20 or 5% of retail price for branded drugs.
    </Typography>
  </>
}

function intToMonth(month: number): string {
  switch (month) {
    case 1: return 'January';
    case 2: return 'February';
    case 3: return 'March';
    case 4: return 'April';
    case 5: return 'May';
    case 6: return 'June';
    case 7: return 'July';
    case 8: return 'August';
    case 9: return 'September';
    case 10: return 'October';
    case 11: return 'November';
    case 12: return 'December';
    default: return '';
  }
}

function PlanRating({plan}: {plan: Plan}) {
  return !plan.rating ? <Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography>
    : <StyledRating className={'mt-0'} precision={0.5} readOnly defaultValue={plan.rating || 0}/>;
}
