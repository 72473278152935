import React from "react";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import {Box, Grid, Typography} from "@material-ui/core";
import clsx from 'clsx';
import DashboardCard, {DashboardCardProps} from "./components/DashboardCard";
import AscendButton from "../../shared/components/AscendButton";
import usePlansByBid from "../../App/DetailedPlan/hooks/usePlansByBid";
import FavoritePlanCard from "./components/FavoritePlanCard";
import {FavoritesContext} from "../../App/shared/FavoritesContext";
import useMyEnrollment from "../hooks/useMyEnrollment";
import useUserProfile from "../hooks/useUserProfile";
import ShortPlanCard from "../../shared/components/ShortPlanCard";
import {usePlanLazy} from "../../App/DetailedPlan/hooks/usePlan";
import { useHistory } from "react-router-dom";
import {UserProfileInput} from "../../enrollment-types";
import {config} from "../config/config";
import paths from "../config/router-paths";
import {QuoteContext} from "../../App/PlanList/QuoteContext";
import PlanSummaryChart from "../../shared/components/PlanSummaryChart";
import AscendCard from "../../shared/components/AscendCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    mainTitle: {
      fontSize: 36,
    },
    imgTitle: {
      position: 'absolute',
      left: 34,
      top: 39,
      '& p': {
        letterSpacing: '-1px'
      }
    },
    offersCard: {
      cursor: 'pointer',
      width: 256,
      background: 'white',
      borderRadius: 10,
      marginTop: 24,
    }
  }),
);

function UserDashboard() {
  const classes = useStyles();
  const history = useHistory();
  const {userProfile, request: {refetch}} = useUserProfile();
  const enrollment = useMyEnrollment();
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const favoritePlans = usePlansByBid(userProfile?.zip, userProfile?.countyName, favorites)
  const quote = React.useContext(QuoteContext);
  const [getPlan, {data}] = usePlanLazy();

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data])

  React.useEffect(() => {
    if (userProfile?.zip && userProfile?.countyName && enrollment?.bidId) {
      getPlan({
        variables: {
          bidId: enrollment?.bidId,
          zip: userProfile.zip,
          countyName: userProfile.countyName,
          quoteId: userProfile?.quoteId
        }
      })
    }
  }, [userProfile, enrollment])


  const toggle = React.useCallback((key: keyof UserProfileInput) => {
    if (userProfile) {
     //  saveUserProfile({variables: {data: {[key]: !(userProfile as any)[key]}}}).then(() => refetch())
    }
  }, [userProfile])

  const cardsData: DashboardCardProps[] = React.useMemo(() => {
    return [
      {
        icon: '/img/User.svg',
        title: 'Personal details',
        isDone: !!userProfile?.zip,
        description: 'Enter your personal information so we can find plans in your area and check if you are eligible.',
        onClick: () => {history.push(paths.personalDetails)},
      },
      {
        icon: '/img/Mydoctor.svg',
        title: 'My Doctors',
        isDone: !!quote?.preferredDoctors?.preferredDoctors?.length ? true : !!userProfile?.hasNoPreferredDoctors,
        description: 'Not all doctors are covered by all plans. We can highlight plans that cover your doctors.',
        checkboxLabel: 'I don’t have any preferred doctors',
        checkboxValue: userProfile?.hasNoPreferredDoctors || false,
        onClick: () => {history.push(paths.myHealthcare)},
        onCheckboxClick: () => toggle('hasNoPreferredDoctors'),
      },
      {
        icon: '/img/Pill.svg',
        title: 'My Drugs',
        isDone: !!quote?.preferredDrugs?.preferredDrugs?.length ? true : !!userProfile?.hasNoPrescriptionsDrugs,
        description: 'Enter your prescriptions drugs and we can look  for plans that minimize cost for you.',
        checkboxLabel: 'I don’t have any prescription drugs',
        checkboxValue: userProfile?.hasNoPrescriptionsDrugs || false,
        onClick: () => {history.push(paths.myHealthcare)},
        onCheckboxClick: () => toggle('hasNoPrescriptionsDrugs'),
      },
      {
        icon: '/img/Pharmacy.svg',
        title: 'My Pharmacies',
        isDone: !!quote?.preferredPharmacies?.preferredPharmacies?.length ? true : !!userProfile?.hasNoPreferredPharmacies,
        description: 'Pharmacies lets us show you your estimated drug costs and helps us fine the lowest cost plan for you.',
        checkboxLabel: 'I don’t  have a preferred pharmacy',
        checkboxValue: userProfile?.hasNoPreferredPharmacies || false,
        onClick: () => {history.push(paths.myHealthcare)},
        onCheckboxClick: () => toggle('hasNoPreferredPharmacies'),
      }
    ]
  }, [userProfile, toggle, quote])

  const completedPercent = React.useMemo(() => {
    let result = 0;
    if (!!userProfile?.zip) {
      result += 20;
    }
    if (!!quote?.preferredDoctors?.preferredDoctors?.length ? true : !!userProfile?.hasNoPreferredDoctors) {
      result += 20;
    }
    if (!!quote?.preferredDrugs?.preferredDrugs?.length ? true : !!userProfile?.hasNoPrescriptionsDrugs) {
      result += 20;
    }
    if (!!quote?.preferredPharmacies?.preferredPharmacies?.length ? true : !!userProfile?.hasNoPreferredPharmacies) {
      result += 20;
    }
    return result;
  }, [userProfile, quote])

  return <Box display={'flex'} justifyContent={'center'}>
    <Box maxWidth={800}>
      <Typography color={'textPrimary'} className={clsx(classes.mainTitle, classes.bold)}>Hi {userProfile?.firstName}. Welcome to your Ascend portal.</Typography>
      <div className={'h-10'} />
      <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>Create your optional Ascend healthcare profile, review your saved plans and access benefits from our trusted partners.</Typography>
      <div className={'h-10'} />
      <Box display={'flex'}>
        <img src={'/img/padlock.svg'} className={'mr-5'} />
        <Typography className={'fs-14 bold mt-5'} color={'textPrimary'}>Your information is always stored securely and governed by our Privacy Policy.</Typography>
      </Box>
      {(!!enrollment && !!plan) && <>
        <Typography color={'textPrimary'} variant={'h1'} className={'bold mt-20 mb-24'}>Your plan</Typography>
        <ShortPlanCard plan={plan} enrollment={enrollment} />
      </>}
      {!enrollment && <Box position={'relative'} mt={'32px'}>
        <div className={classes.imgTitle}>
          <Typography color={'textPrimary'} className={clsx(classes.mainTitle, classes.bold)}>There are 20 Medicare<br />Advantage plans<br />available in your area!</Typography>
          <div className={'h-24'}/>
          <AscendButton variant={'contained'} onClick={() => document.location.href = config.resultsUrl as string}>Review quotes now</AscendButton>
        </div>
        <img width={800} height={487} src={'/img/comparing-options-senior-tablet.png'} />
      </Box>}
      {(!!favorites.length && !enrollment) && <>
        <div className={'h-40'}/>
        <Typography color={'textPrimary'} variant={'h1'} className={clsx(classes.bold)}>Your saved plans</Typography>
        {favoritePlans && favoritePlans.map(plan => <FavoritePlanCard key={plan.bidId}
                                                                      plan={plan}
                                                                      onEnrollClick={() => {
                                                                        if (userProfile?.zip) {
                                                                          document.location.href = config.enrollUrl + plan.bidId + '/' + userProfile.zip
                                                                        }
                                                                      }}
                                                                      onRemoveClick={() => {
                                                                        if (userProfile?.zip) {
                                                                          toggleFavorite(plan.bidId, userProfile.zip)
                                                                        }
                                                                      }} />
                                                                      )}
        <div className={'h-32'}/>
        <Box display={'flex'} justifyContent={'center'}>
          <AscendButton variant={'contained'} onClick={() => history.push(paths.myBenefits)}>See all saved plans</AscendButton>
        </Box>
      </>}

      <div className={'h-30'}/>
      <Typography color={'textPrimary'} variant={'h1'} className={clsx(classes.bold)}>
        Your profile is <span className={clsx(classes.mainTitle, classes.bold)}>{completedPercent}%</span> complete
      </Typography>
      <div className={'h-5'}/>
      <ProgressBar value={completedPercent} />

      <div className={'h-30'}/>
      <Grid container spacing={2}>
        {cardsData.map(item => <Grid item key={item.title} xs={12} sm={6}>
          <DashboardCard {...item} />
        </Grid>)}
      </Grid>

      <div className={'h-40'}/>
      <Typography color={'textPrimary'} variant={'h1'} className={clsx(classes.bold)}>Your benefits and offers</Typography>
      <div className={classes.offersCard} onClick={() => history.push(paths.myBenefits)}>
        <img width={256} height={171} src={'/img/doctor-writing.png'} />
        <div className={'pv-32 mh-15'}>
          <Typography color={'textPrimary'} variant={'h2'} className={clsx(classes.bold)}>We’ve found you drug savings through one of our partners!</Typography>
          <div className={'h-10'}/>
          <Typography color={'textSecondary'} variant={'body1'}>Check out <span style={{color: colors.text.primary}} className={'medium'}>My Benefits</span> to see your latest discount offers.</Typography>
        </div>
      </div>
      {(!!plan && userProfile?.countyName && userProfile?.zip) && <>
        <div className={'h-32'}/>
        <Typography color={'textPrimary'} variant={'h1'} className={clsx(classes.bold)}>Your estimated drug costs</Typography>
        <div className={'h-24'}/>
        <AscendCard padding={'0px'}>
          <PlanSummaryChart planId={plan.bidId} countyName={userProfile.countyName} zip={userProfile.zip} />
        </AscendCard>
        <div className={'h-32'}/>
        <AscendButton variant={'contained'} onClick={() => history.push(paths.drugCostBreakdown + '/' + plan.bidId)}>Go to estimated drug cost summary</AscendButton>
      </>}
      <div className={'h-100'}/>

    </Box>
  </Box>
}

export default UserDashboard;

const ProgressBar = withStyles({
  container: {
    width: 320,
    height: 25,
    borderRadius: 10,
    position: 'relative',
    background: 'white'
  },
  progress: {
    background: colors.custom.green.variant1,
    borderRadius: 10,
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%'
  }
})(({value, classes}: {value: number, classes: any}) => <div className={classes.container}>
  <div style={{width: value + '%'}} className={classes.progress}></div>
</div>)
