import React from "react";
import {Plan} from "../../../types";
import {Box, Typography} from "@material-ui/core";
import StyledRating from "./StyledRating";
import {planStyles} from "../PlanCard";
import {getCompanyLogo} from "../../../shared/utils";
import clsx from 'clsx';
import TooltipedTitle from "./TooltipedTitle";

interface PlanTitleProps {
  plan: Plan,
  showBid?: boolean,
  className?: string,
  inFavorites?: boolean,
  hideFavorites?: boolean,
  favoritesClick?: () => void,
}

export function PlanTitle({plan, className, showBid, inFavorites, favoritesClick, hideFavorites}: PlanTitleProps) {
  const classes = planStyles();

  const rating = !plan.rating ? <Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography>
    : <StyledRating precision={0.5} readOnly defaultValue={plan.rating || 0}/>;

  return <div className={clsx('flex', className)}>
    <div className={'flex-column'}>
      <div className={'flex-space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <TooltipedTitle title={rating}
            tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}
            imgProps={{
              className: `w-12 h-12 ${!plan.rating ? 'mt-5' : 'mt-16'}`,
            }}/>
          {!!plan.rating && <Typography className={'medium fs-12 mt-5 ml-8'} color={'textPrimary'}>(2021)</Typography>}
        </Box>
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium'} color={'textPrimary'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium'} color={'textPrimary'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/img/heart-fill.svg'}/>
          </>}
        </div>}
      </div>
      <div className={'flex-space-between'}>
        <div className={'mt-8'}>
          <Typography variant={'h2'} color={'textPrimary'} className={classes.bold}>{plan.planName}</Typography>
          {showBid && <>
            <div className={'h-20'} />
            <Typography variant={'h4'} color={'textSecondary'}>Plan ID: {plan.bidId}</Typography>
          </>}
        </div>
        <div>
          <img className={classes.logo} width={119} height={33} src={getCompanyLogo(plan.parentOrgName)} />
        </div>
      </div>
    </div>
  </div>
}
