import React from "react";
import {ExtraBenefit, FormName, PlansFilterInput, Rating, SnpType} from "../../types";
import {handleCollectionChange, handleRatingChange} from "./hooks/filterUtils";
import {QuoteContext} from "./QuoteContext";
import {getQuoteId, setQuoteId} from "../../shared/QuoteId";
import useSaveRequest from "../../Questionnaire/hooks/useSaveRequest";
import * as QueryString from "query-string";

export interface IPlansFilter extends PlansFilterInput {
  companies: string[];
  isMA?: boolean;
  isMD?: boolean;
  isMS?: boolean;
  planTypes: string[];
  SNPTypes: SnpType[];
  extraBenefits: ExtraBenefit[];
  rating: Rating[];
  zip: string;
  countyName: string;
}

interface PlanFilterContextState {
  values?: IPlansFilter,
  switchZip: (zip: string, countyName: string) => void,
  toggleFavorites: () => void,
  switchCompany: (name: string) => void,
  switchPlanType: (name: string) => void,
  switchSNPType: (type: SnpType) => void,
  switchRating: (rating: Rating) => void,
  switchExtraBenefits: (type: ExtraBenefit) => void,
  switchMaxOutOfPocketRanges: (name: string) => void,
  switchMaxCostRanges: (name: string) => void,
  refresh: () => void,
  reset: () => void,
}

const defaultState: PlanFilterContextState = {
  values: {
    zip: "NOT_DEFINED",
    countyName: "NOT_DEFINED",
    isMA: true,
    onlyFavorite: false,
    companies: [],
    planTypes: [],
    rating: [],
    SNPTypes: [],
    extraBenefits: [],
    maxCostRangeNames: [],
    maxOutOfPocketRangeNames: []
  },
  switchZip: (zip: string) => {},
  toggleFavorites: () => {},
  switchCompany: (name: string) => {},
  switchPlanType: (name: string) => {},
  switchSNPType: (type: SnpType) => {},
  switchRating: (rating: Rating) => {},
  switchExtraBenefits: (type: ExtraBenefit) => {},
  switchMaxOutOfPocketRanges: (name: string) => {},
  switchMaxCostRanges: (name: string) => {},
  refresh: () => {},
  reset: () => {},
};

export const PlanFilterContext = React.createContext<PlanFilterContextState>(defaultState);

export function PlanFilterProvider(props: React.PropsWithChildren<PlanFilterProviderProps>) {

  const quoteId = getQuoteId();
  const quote = React.useContext(QuoteContext);
  const [save] = useSaveRequest();

  const [filters, setFilters] = React.useState<IPlansFilter>();

  React.useEffect(() => {
    if (filters) {
      localStorage.setItem('filters', JSON.stringify(filters))
    }
  }, [filters])

  React.useEffect(() => {
    if (!quoteId) {
      setFilters(() => {
        const storedFilters = localStorage.getItem('filters');
        const params = QueryString.parse(document.location.search);
        if (!params.resetFilters && storedFilters) {
          const parsed = JSON.parse(storedFilters);
          if (parsed.zip !== props.initFilter.zip) {
            parsed.zip = props.initFilter.zip
          }
          if (parsed.countyName !== props.initFilter.countyName) {
            parsed.countyName = props.initFilter.countyName
          }
          return parsed;
        } else {
          return props.initFilter
        }
      })
    }
  }, [quoteId])

  React.useEffect(() => {
    if (quote) {
      setFilters(() => {
        let result: IPlansFilter;
        const storedFilters = localStorage.getItem('filters');
        const params = QueryString.parse(document.location.search);
        if (!params.resetFilters && storedFilters) {
          const parsed = JSON.parse(storedFilters);
          if (parsed.zip !== props.initFilter.zip) {
            parsed.zip = props.initFilter.zip
          }
          if (parsed.countyName !== props.initFilter.countyName) {
            parsed.countyName = props.initFilter.countyName
          }
          result = parsed;
        } else {
          result = props.initFilter
        }
        if (quote.filters?.extraBenefits) {
          result.extraBenefits = quote.filters?.extraBenefits;
        }
        return result;
      })
    }
  }, [quote])

  function switchFilter<T>(key: T, filterKey: keyof IPlansFilter) {
    setFilters(prev => {
      if (prev && filters) {
        const newInstance = {...prev}
        newInstance[filterKey] = handleCollectionChange<T>(key, filters[filterKey])
        if (filterKey === 'extraBenefits' && quote) {
          save({variables: {
              data: {
                form: FormName.Drugs,
                id: quoteId,
                county: filters.countyName,
                zip: filters.zip,
                filters: {
                  extraBenefits: newInstance[filterKey],
                }
              }
            }}).then((data: any) => setQuoteId(data.data.saveMedicareQuote))
        }
        return newInstance
      }
      return prev;
    })
  }
  function switchRating(rating: Rating) {
    setFilters(prev => {
      if (prev && filters) {
        return {...prev, rating: handleRatingChange(rating, filters.rating)}
      }
      return prev;
    })
  }

  function refresh() {
    setFilters(prev => {
      return prev
    })
  }

  const contextValue = React.useMemo<PlanFilterContextState>(() => (
      {
        values: filters,
        reset: () => {
          setFilters(props.initFilter)
        },
        switchZip: (zip: string, countyName: string) => {
          setFilters({...props.initFilter, zip, countyName})
        },
        toggleFavorites: () => {
          setFilters(prev => {
            if (prev) {
              return {...prev, onlyFavorite: !prev.onlyFavorite}
            }
            return prev;
          })
        },
        switchCompany: (key: string) => {
          switchFilter<string>(key,'companies')
        },
        switchPlanType: (key: string) => {
          switchFilter<string>(key,'planTypes')
        },
        switchRating,
        switchSNPType: (key: SnpType) => {
          switchFilter<SnpType>(key,'SNPTypes')
        },
        switchExtraBenefits: (key: ExtraBenefit) => {
          switchFilter<ExtraBenefit>(key,'extraBenefits')
        },
        switchMaxOutOfPocketRanges: (key: string) => {
          switchFilter<string>(key,'maxOutOfPocketRangeNames')
        },
        switchMaxCostRanges: (key: string) => {
          switchFilter<string>(key,'maxCostRangeNames')
        },
        refresh
      }
    )
  , [filters]);

  return <PlanFilterContext.Provider value={contextValue}>
    {props.children}
  </PlanFilterContext.Provider>

}

interface PlanFilterProviderProps {
  initFilter: IPlansFilter
}
/***
 switchMaxOutOfPocketRanges: (name: string) => {},
 switchMaxCostRanges: (name: string) => {},


 maxCostRangeNames: [],
 maxOutOfPocketRangeNames: []
 */
