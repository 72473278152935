import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import withErrorBoundary from "../shared/withErrorBoundary";
import {Box, Paper, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import Terms from "../shared/SidePanel/Terms";
import ContactsSection from "../shared/components/ContactsSection";
import {useHistory} from "react-router-dom";
import paths from "./config/router-paths";
import clsx from 'clsx';
import {config} from "./config/config";
import {questionsRoutes} from "./hooks/useStepperState";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
      backgroundColor: '#F5F7F6',
      flexDirection: 'column',
    },
    centered: {
      textAlign: 'center'
    },
    image: {
      position: 'absolute',
      left: 40,
      top: 32,
    },
    terms: {
      position: 'absolute',
      left: 40,
      bottom: 32,
    },
    selected: {
      backgroundColor: 'rgba(94, 203, 158, 0.1)!important',
      border: '1px solid #5ECB9E!important',
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      backgroundColor: 'white',
      border: '1px solid #CCD8DC',
      borderRadius: 4,
      width: 275,
      height: 230,
      cursor: 'pointer',
      paddingTop: 25,
      paddingBottom: 38
    },
    label: {
      fontSize: 20,
      textAlign: 'center'
    }
  }),
);

function ResultsTypeSelection() {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState<string | undefined>(localStorage.getItem('planType') || undefined);

  const onSubmit = () => {
    localStorage.setItem('planType', value || '');
    if (value === 'MA') {
      document.location.href = config.resultsUrl + 'plans';
    } else {
      history.push(paths.questionnaire + '/' + questionsRoutes.step9b)
    }
  };

  return (
    <Box display={'flex'} flex={1} height={'100vh'}>
      <Box display={'flex'} flex={1} position={'relative'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        <img src={'/img/Ascend.svg'} width={91} height={30} className={classes.image} />
        <div className={classes.terms}><Terms /></div>
        <Typography variant={'h1'} color={'textPrimary'} className={classes.centered}><strong>What type of Medicare plans would you like<br />to compare today</strong></Typography>
        <div className={'h-32'} />
        <Box display={'flex'}>
          <Box className={clsx({[classes.paper]: true, [classes.selected]: value === 'MO'})} onClick={() => setValue('MO')}>
            <Typography className={classes.label} color={'textPrimary'}><strong>Medicare Supplement</strong></Typography>
            <Typography className={'fs-14'} color={'textPrimary'}>and Prescription Drug Plans</Typography>
            <div className={'h-20'} />
            <img src={'/img/HandAndPills.svg'} />
          </Box>
          <div className={'w-20'} />
          <Box className={clsx({[classes.paper]: true, [classes.selected]: value === 'MA'})} onClick={() => setValue('MA')}>
            <Typography className={classes.label} color={'textPrimary'}><strong>Medicare Advantage</strong></Typography>
            <div className={'h-20'} />
            <img src={'/img/building2.svg'} />
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'} mt={'40px'} width={696}>
          <AscendButton variant={'contained'} onClick={() => {
            history.goBack();
          }} color={'secondary'}>Back</AscendButton>
          <AscendButton disabled={typeof value === 'undefined'} variant={'contained'} onClick={onSubmit}>Continue</AscendButton>
        </Box>
      </Box>
    </Box>
  );
}

export default withErrorBoundary(ResultsTypeSelection);
