import React from "react";
import {Box, Collapse, createStyles, Theme, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import {withTitle} from "../../shared/components/withTitle";
import paths from "../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {keycloak} from "../../shared/keycloak";
import {getStoredUser, setStoredUser} from "../../shared/StoredUser";
import {GraphQLErrorType} from "../../shared/ApolloClient";
import useSaveUserProfile from "../../Private/hooks/useSaveUserProfile";
import {QuoteContext} from "../QuoteContextProvider";
import {makeStyles} from "@material-ui/core/styles";
import CreateProfileForm from "../components/CreateProfileForm";
import AscendButton from "../../shared/components/AscendButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& > *': {
        maxWidth: 320,
        width: '100%'
      }
    },
  })
);

const formName = 'STEP_9';

function Step9(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [saveError, setSaveError] = React.useState();
  const [state] = React.useContext(QuoteContext);

  const [create, data] = useSaveUserProfile({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = <>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
            keycloak.login({redirectUri: document.location.href})
          }} style={{color: "red"}}>sign in</a></>
          break;
        case GraphQLErrorType.NO_CONNECTION: text = 'Service is not available';
        default: text = 'Unknown error'
      }
      setSaveError(text)
    },
  })

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const onSubmit = (values: any) => {
    if (values?.email) {
      create({variables: {data: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            birthDate: getStoredUser()?.birthDate,
            zip: getStoredUser()?.zip,
            quoteId: state.id,
            cId: (window as any).gaGlobal?.vid,
          }}}).then((res) => {
            setStoredUser({...getStoredUser(), firstName: values.firstName})
            props.history.push(paths.questionnaire + '/' + questionsRoutes.step10)
          })
    }
  };

  return <>
    <Box display={'flex'} justifyContent={'center'} flex={1}>
      <Box >
        <div className={'h-32'} />
        <CreateProfileForm onSubmit={onSubmit}
                           emailError={saveError}
                           buttonWrapperClassName={classes.buttonWrapper}
                           submitButtonLabel={'Request quotes now'}
                           backButtonLabel={'Back'}
                           onCancelClick={() => props.history.goBack()} />
      </Box>
    </Box>
  </>
}

export default withTitle(
  Step9,
  `One last step before we can provide your quotes.`,
  'Provide your contact details so we can reach you.',
  undefined,
  undefined,
  isMobile => isMobile,
)
