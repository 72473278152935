export const config = {
  graphQL: process.env.REACT_APP_GRAPHQL,
  keycloak: {
    url: process.env.REACT_APP_KEYCLOAK_SERVER,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    protocol: 'openid-connect',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
  },
  filesUrl: process.env.REACT_APP_FILES_URL,
  enrollUrl: process.env.REACT_APP_ENROLL_URL,
  privateUrl: process.env.REACT_APP_PRIVATE_URL,
}
