import React from "react";
import {Box, Collapse, createStyles, Grid, MenuItem, RadioGroup, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {RouteComponentProps, useHistory} from "react-router";
import {usePlanLazy} from "../App/DetailedPlan/hooks/usePlan";
import FormTextInput from "../shared/components/FormTextInput";
import FormSelectInput from "../shared/components/FormSelectInput";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useCommonStyles from "../shared/useCommonStyles";
import AscendButton from "../shared/components/AscendButton";
import useSaveEnrollment from "./hooks/useSaveEnrollment";
import {client} from "../shared/ApolloClient";
import paths from "./config/router-paths";
import ShortPlanCard from "../shared/components/ShortPlanCard";
import AscendRadio from "../shared/components/AscendRadio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      color: '#778E95',
    },
    card: {
      background: 'white',
      padding: '24px 36px 27px 32px',
      marginTop: 24,
    },
    input: {
      paddingRight: 0
    },
    datepicker: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
      }
    },
    note: {
      background: 'rgba(94, 203, 158, 0.1)',
      border: '1px solid #5ECB9E',
      borderRadius: 4,
      padding: '16px 19px',
      fontSize: 16,
    },
    group: {
      background: '#F5F6F7',
      padding: 16,
      marginTop: 25
    }
  })
);
const formName = 'EnrollmentForm';

const Schema = yup.object().shape({
  creditCardName: yup.string(),
  creditCardNumber: yup.string(),
  expirationMonth: yup.string(),
  expirationYear: yup.string(),
  routingNumber: yup.string(),
  accountNumber: yup.string(),
  bankName: yup.string(),
});

interface PersonalDetailsFormState {
  creditCardName: string;
  creditCardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
}

function EnrollmentForm4(props: RouteComponentProps<{planId: string, zip: string, quoteId: string, countyName: string}>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [creditCardType, setCreditCardType] = React.useState<string>('');
  const [accountType, setAccountType] = React.useState();
  const [method, setMethod] = React.useState();
  const history = useHistory();
  const [save] = useSaveEnrollment();

  const {register, handleSubmit, errors, formState, watch, getValues, setError, clearErrors, control} = useForm<PersonalDetailsFormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
    defaultValues: {
    }
  });
  watch();

  const [getPlan, {data}] = usePlanLazy({ client });

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (planId && zip && countyName) {
      getPlan({
        variables: {
          bidId: planId,
          zip,
          countyName,
          quoteId
        }
      })
    }
  }, [planId, zip, getPlan, countyName])

  const onSubmit = (values: PersonalDetailsFormState) => {
    if (formState.isValid) {
      clearErrors()
      props.history.push(paths.personalDetails5 + "/" + planId + "/" + zip + "/" + countyName)
    }
  };

  return <Box display={'flex'} flexDirection={'column'} mt={'32px'} maxWidth={800}>
    {plan && <div className={'mb-40'}><ShortPlanCard plan={plan}/></div>}
    <Typography className={'bold fs-26'} color={'textPrimary'}>Payment information</Typography>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={'bold fs-24'} color={'textPrimary'}>Confirm payment method</Typography>
            <Typography className={'fs-16'} color={'textPrimary'}>Select your premium payment option below.</Typography>
            <Typography className={'fs-16'} color={'textPrimary'}>You have several ways to pay your monthly plan premium. You can change your method of payment after you have enrolled in the plan.
              <br/><br/><b>Paying your plan premiums</b>
              <br/><br/>If you have to pay a Part D-Income Related Monthly Adjustment Amount (Part D-IRMAA), you must pay this extra amount in addition to your plan premium. The amount is usually taken out of your Social Security benefit, or you may get a bill from Medicare (or the RRB). DON’T pay the plan, the Part D-IRMAA.
              <br/><br/>People with limited incomes may qualify for Extra Help to pay for their prescription drug costs. If you qualify, Medicare could pay for 75% or more of your drug costs including monthly prescription drug premiums, annual deductibles, and co-insurance. Additionally, those who qualify won’t have a coverage gap or a late enrollment penalty. Many people qualify for these savings and don’t even know it. For more information about this Extra Help, contact your local Social Security office or call Social Security at 1-800-772-1213. TTY users should call 1-800-325-0778. You can also apply for Extra Help online at www.socialsecurity.gov/prescriptionhelp.
              <br/><br/>If you qualify for Extra Help with your Medicare prescription drug coverage costs, Medicare will pay all or part of your plan premium. If Medicare pays only a portion of this premium, we will bill you for the amount that Medicare doesn’t cover. If you don’t select a payment option, you will receive a bill in the mail each month.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup>
              <AscendRadio variant={'standart'}
                           label={'Automatic withholding from my Social Security benefit check.'}
                           checked={method === 'social'}
                           onChange={() => setMethod('social')}/>
              <Collapse in={method === 'social'} className={`${method === 'social' && 'mt-16 mb-32'}`}>
                <Typography className={classes.note} color={'textPrimary'}>
                  <b>NOTE:</b> Due to processing timelines mandated by CMS (Medicare), your SSA or RRB deduction may be denied for your first premium payment. Humana will issue you an invoice for the initial payment and resubmit your request to CMS (Medicare) for SSA or RRB deduction to begin with your second month's premium. The deduction may take two or more benefit checks to begin. In most cases, if SSA or RRB accepts your request for automatic deduction, the first deduction from your benefit check will start with the month that SSA accepts the withholding. If SSA or RRB does not approve your request for automatic deduction, we will send you a Coupon book for your monthly plan premiums.
                </Typography>
              </Collapse>
              <AscendRadio variant={'standart'}
                           label={'Automatic withholding from my Railroad Retirement Board benefit check.'}
                           checked={method === 'railroad'}
                           onChange={() => setMethod('railroad')}/>

              <Collapse in={method === 'railroad'} className={`${method === 'railroad' && 'mt-16 mb-20'}`}>
                <Typography className={'fs-16'} color={'textPrimary'}>You must currently be receiving a Railroad Retirement Board benefit check in order to qualify for this payment option.</Typography>
                <Typography className={classes.note} color={'textPrimary'}>
                  <b>NOTE:</b> Due to processing timelines mandated by CMS (Medicare), your SSA or RRB deduction may be denied for your first premium payment. Humana will issue you an invoice for the initial payment and resubmit your request to CMS (Medicare) for SSA or RRB deduction to begin with your second month's premium. The deduction may take two or more benefit checks to begin. In most cases, if SSA or RRB accepts your request for automatic deduction, the first deduction from your benefit check will start with the month that SSA accepts the withholding. If SSA or RRB does not approve your request for automatic deduction, we will send you a Coupon book for your monthly plan premiums.
                </Typography>
              </Collapse>
              <AscendRadio variant={'standart'}
                           label={'Credit or debit card'}
                           checked={method === 'card'}
                           onChange={() => setMethod('card')}/>
              <Collapse in={method === 'card'} className={`${method === 'card' && 'mt-16 mb-20'}`}>
                <Grid container spacing={2} className={classes.group}>
                  <Grid item xs={12} sm={6}>
                    <FormSelectInput
                      fullWidth
                      value={creditCardType}
                      label={'Type of credit card'}
                      placeholder={'Select Credit Card type'}
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        setCreditCardType(event.target.value as string);
                      }}
                    >
                      <MenuItem value={'VISA'}>VISA</MenuItem>
                      <MenuItem value={'Mastercard'}>Mastercard</MenuItem>
                    </FormSelectInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextInput fullWidth
                                   name={'creditCardName'}
                                   error={formState.isSubmitted && !!errors.creditCardName}
                                   helperText={formState.isSubmitted && errors?.creditCardName?.message}
                                   inputRef={register}
                                   placeholder={'Enter name on credit card'}
                                   label={'Name on credit card'} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextInput fullWidth
                                   name={'creditCardNumber'}
                                   error={formState.isSubmitted && !!errors.creditCardNumber}
                                   helperText={formState.isSubmitted && errors?.creditCardNumber?.message}
                                   inputRef={register}
                                   placeholder={'Enter 16 digit credit card number'}
                                   label={'Credit card number'} />
                  </Grid>
                  <Grid item xs={12} sm={6} className={'flex flex-align-end'}>
                    <FormTextInput name={'expirationMonth'}
                                   error={formState.isSubmitted && !!errors.expirationMonth}
                                   helperText={formState.isSubmitted && errors?.expirationMonth?.message}
                                   inputRef={register}
                                   placeholder={'MM'}
                                   fullWidth={false}
                                   className={'w-150'}
                                   label={'Expiration date'} />
                    <FormTextInput name={'expirationYear'}
                                   error={formState.isSubmitted && !!errors.expirationYear}
                                   helperText={formState.isSubmitted && errors?.expirationYear?.message}
                                   inputRef={register}
                                   fullWidth={false}
                                   className={'w-150 ml-16'}
                                   placeholder={'YYYY'} />
                  </Grid>
                </Grid>
              </Collapse>
              <AscendRadio variant={'standart'}
                           label={'Automatic account deduction'}
                           checked={method === 'automatic'}
                           onChange={() => setMethod('automatic')}/>
              <Collapse in={method === 'automatic'} className={`${method === 'automatic' && 'mt-16 mb-32'}`}>
                <Grid container spacing={2} className={classes.group}>
                  <Grid item xs={12} sm={6}>
                    <FormTextInput fullWidth
                                   name={'bankName'}
                                   error={formState.isSubmitted && !!errors.bankName}
                                   helperText={formState.isSubmitted && errors?.bankName?.message}
                                   inputRef={register}
                                   placeholder={'Enter bank name'}
                                   label={'Bank name'} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={'fs-16'} color={'textPrimary'}>Account type</Typography>
                    <div className={'h-8'} />
                    <RadioGroup defaultValue="female" className={commonClasses.radioGroup}>
                      <Box display={'flex'} flex={1}>
                        <AscendRadio checked={accountType} variant={'standart'} onChange={(e:any, checked: boolean) => {
                          setAccountType(checked)
                        }} value="checking" label="Checking" />
                      </Box>
                      <Box className={commonClasses.radio} display={'flex'} flex={1}>
                        <AscendRadio checked={accountType === false} variant={'standart'} onChange={(e:any, checked: boolean) => {
                          setAccountType(!checked)
                        }} value="savings" label="Savings" />
                      </Box>
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextInput fullWidth
                                   name={'routingNumber'}
                                   error={formState.isSubmitted && !!errors.routingNumber}
                                   helperText={formState.isSubmitted && errors?.routingNumber?.message}
                                   inputRef={register}
                                   placeholder={'Enter routing number'}
                                   label={'Routing number'} />
                  </Grid>
                  <Grid item xs={12} sm={6} direction={'row'}>
                    <FormTextInput name={'accountNumber'}
                                   error={formState.isSubmitted && !!errors.accountNumber}
                                   helperText={formState.isSubmitted && errors?.accountNumber?.message}
                                   inputRef={register}
                                   placeholder={'Enter account number'}
                                   label={'Account number'} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <img src={'/img/account-helper.png'} className={'mt-25'}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.note} color={'textPrimary'}>
                    <b>By clicking Save and Continue</b> below, I authorize my bank or financial organization to pay my premium through electronic bank withdrawal payable to [Carrier]. I authorize the deduction of up to $[x] per month to settle my current balance due. The bank or other financial organization will be fully protected in honoring these payments until written notice from me cancelling this request is received. This recurring payment option will continue as long as I am enrolled in a [Carrier] Medicare Advantage plan unless I cancel or elect a new payment option.
                  </Typography>
                </Grid>
              </Collapse>

              <Grid item xs={12}>
                <AscendRadio variant={'standart'}
                             label={'Coupon book'}
                             checked={method === 'coupon'}
                             onChange={() => setMethod('coupon')}/>
              </Grid>

              <Grid item xs={12}>
                <AscendRadio variant={'standart'}
                             label={'I want to pay directly'}
                             checked={method === 'directly'}
                             onChange={() => setMethod('directly')}/>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </div>

      <Box className={commonClasses.buttonWrapper} mt={'40px'} mb={'100px'}>
        <AscendButton variant={'contained'} onClick={() => {
          history.goBack()
        }} color={'secondary'}>Back</AscendButton>
        <AscendButton className={'gtm-step-event'} variant={'contained'} type={'submit'}>Review and submit</AscendButton>
      </Box>
    </form>
  </Box>
}

export default EnrollmentForm4;

const TickTitle = ({title}: {title: string}) => <Box display={'flex'} alignItems={'center'}>
  <img src={'/img/green2-check.svg'} className={'mr-15'} />
  <Typography color={'textPrimary'} className={'fs-16'}>{title}</Typography>
</Box>
