import React from "react";
import {Box, createStyles, Grid, RadioGroup, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import AscendRadio from "../../shared/components/AscendRadio";
import {makeStyles} from "@material-ui/core/styles";
import useCommonStyles from "../../shared/useCommonStyles";
import clsx from 'clsx';
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {withTitle} from "../../shared/components/withTitle";
import {questionsRoutes} from "../hooks/useStepperState";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    radio: {
      alignItems: 'flex-start',
      height: 218,
      display: 'flex',
      flex: 1,
      width: '100%',
      maxWidth: 320,
    },
    img: {
      paddingBottom: '15px'
    },
    centered: {
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    textCentered: {
      textAlign: 'center'
    }
  })
);
const formName = 'STEP_4';

function Step4(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const [enrolled, setEnrolled] = React.useState<boolean>();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  React.useEffect(() => {
    if (typeof state.STEP_4?.value?.value !== 'undefined') {
      setEnrolled(!!state.STEP_4?.value.value)
    }
  }, [state.STEP_4?.value])

  const notEnrolled = <Box p={'27px 0 12px 24px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <img src={'/img/no-enroll.svg'} className={classes.img} />
    <div>No, not yet</div>
    <Box mt={1} textAlign={'center'}>
      <Typography variant={'body2'} color={'textSecondary'}>Pick this if you are not sure</Typography>
    </Box>
  </Box>

  const isEnrolled = <Box p={'27px 0 0'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
    <img src={'/img/enroll.png'} className={classes.img} />
    <div>Yes</div>
    <Box mt={1} textAlign={'center'}>
      <Typography variant={'body2'} color={'textSecondary'} className={classes.textCentered}>Select yes, if have a red, white and blue Medicare card</Typography>
    </Box>
  </Box>

  const submit = (value: boolean) => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, value: {
          questionText: 'Do you already have your Medicare card?',
          answerText: enrolled ? 'Yes' : 'No',
          value
        }, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + (value ? questionsRoutes.step6 : questionsRoutes.step5))
  }

  return <>
    <Box display={'flex'} flexDirection={'column'} mt={'32px'} mb={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} alignItems={'center'} justify={'center'} className={'flex'}>
          <AscendRadio checked={enrolled === false} controlClassName={classes.radio}
                       onClick={() => submit(false)} value="female" label={notEnrolled} />
        </Grid>
        <Grid item xs={12} sm={6} alignItems={'center'} justify={'center'} className={'flex'}>
          <AscendRadio checked={enrolled} controlClassName={classes.radio}
                       onClick={() => submit(true)} value="male" label={isEnrolled} />
        </Grid>
      </Grid>
    </Box>
    <Box mt={'30px'} display={'flex'} justifyContent={'center'}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack()
      }} color={'secondary'}>Back</AscendButton>
    </Box>
  </>
}

export default withTitle(
  Step4,
  'Do you already have your Medicare card?',
  ``,
  undefined,
  undefined,
  isMobile => isMobile,
)
