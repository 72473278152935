import React from "react";
import {Box, Typography, withStyles} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import Terms from "../shared/SidePanel/Terms";
import clsx from 'clsx';
import {colors} from "../shared/AppTheme";
import paths from "./config/router-paths";
import AscendButton from "../shared/components/AscendButton";
import {config} from "./config/config";

export default function NavigationPanel() {
  let location = useLocation();

  return <Box pt={'15px'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} flex={1}>
    <div>
      {menu.map(item => <Item key={item.label} item={item} selected={location.pathname === item.link} />)}
      <div className={'h-24'} />
      <RoundedButton variant={'outlined'} color={'primary'} onClick={() => document.location.href = config.resultsUrl as string}>
        Back to Ascend marketplace
      </RoundedButton>
    </div>
    <div>
      <Terms />
    </div>
  </Box>
}

export const RoundedButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${colors.custom.green.variant1}`,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  outlined: {
    borderRadius: 4,
    padding: '4px 21px',
  },
  disabled: {},
  label: {
    fontWeight: 700,
    fontSize: 12,
    textTransform: 'none',
  },
})(AscendButton);

const Item = withStyles({
  link: {
    textDecoration: 'none',
  },
  label: {
    fontWeight: 500,
  },
  green: {
    color: colors.custom.green.variant1,
  }
})(({item, selected, classes}: {item: MenuItem, selected: boolean, classes: any}) => <Link className={classes.link} to={item.link}>
  <Box display={'flex'} alignItems={'center'} py={'5px'} mt={'15px'}>
    <Box width={'24px'} height={'24px'} display={'flex'} justifyContent={'center'} mr={'16px'}>
      <img height={24} width={24} src={`/img/${item.icon}${selected ? '-fill' : ''}.svg`} />
    </Box>
    <Typography variant={'h4'} color={'textPrimary'} className={clsx({[classes.label]: true})}>{item.label}</Typography>
  </Box>
</Link>)

interface MenuItem {
  link: string,
  icon: string,
  label: string,
}
/*
const menu: MenuItem[] = [
  {
    link: paths.dashboard,
    icon: 'Home',
    label: 'Home'
  },
  {
    link: paths.personalDetails,
    icon: 'User',
    label: 'Personal Information'
  },
  {
    link: paths.editPreferences,
    icon: 'Note',
    label: 'Edit your information'
  },
  {
    link: paths.accountSettings,
    icon: 'Settings',
    label: 'Account settings'
  },
]

*/

const menu: MenuItem[] = [
  {
    link: paths.dashboard,
    icon: 'Home',
    label: 'Home'
  },
  {
    link: paths.personalDetails,
    icon: 'User',
    label: 'Personal details'
  },
  {
    link: paths.myCoverage,
    icon: 'Note',
    label: 'My Coverage'
  },
  {
    link: paths.myHealthcare,
    icon: 'Mydoctor',
    label: 'My Healthcare'
  },
  {
    link: paths.myBenefits,
    icon: 'Pill',
    label: 'My Benefits'
  },
  {
    link: paths.accountSettings,
    icon: 'Settings',
    label: 'Account settings'
  },
]
