import React from "react";
import {Typography, withStyles} from "@material-ui/core";
import clsx from 'clsx';
import {colors} from "../AppTheme";

const Benefit = withStyles({
  label: {
    fontWeight: 500,
    marginLeft: 8,
    fontSize: 12
  },
  green: {
    color: '#029094',
  },
  red: {
    color: '#DD3D3D',
  },
  img: {
    width: 14,
    height: 14
  },
  transparent: {
    opacity: 0.5,
  }
})(({classes, ...props}: BenefitProps) => <div className={'flex flex-align-center'}>
  {props.inactive && <>
    <img className={clsx(classes.img, classes.transparent, props.imageClass)} src={props.value ? '/img/dark-check.svg' : '/img/dark-close.svg'} />
    <Typography variant={'body1'} color={'textPrimary'} className={clsx(classes.label, classes.transparent, props.labelClass)}>{props.label}</Typography>
  </>}
  {!props.inactive && <>
    <img className={clsx(classes.img, props.imageClass)} src={props.value ? '/img/green-check.svg' : '/img/red-close.svg'} />
    <Typography variant={'body1'} className={clsx(classes.label, props.value ? classes.green : classes.red, props.labelClass)}>{props.label}</Typography>
  </>}
</div>);

export interface BenefitProps {
  value: boolean,
  inactive?: boolean,
  classes: any,
  labelClass?: string,
  imageClass?: string,
  label: string,
}

export default Benefit;
