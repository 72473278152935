import React from "react";
import {
  Box,
  createStyles,
  IconButton,
  Theme,
  Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../shared/AppTheme";
import AscendModal from "../../../shared/components/AscendModal";
import CreateAccountForm from "../../../shared/components/CreateAccountForm";
import AscendButton from "../../../shared/components/AscendButton";
import {ScrollbarList} from "../../../shared/components/AscendAutocomplete";
import useSaveUserProfile from "../../../Private/hooks/useSaveUserProfile";
import {GraphQLErrorType} from "../../../shared/ApolloClient";
import {keycloak} from "../../../shared/keycloak";
import {ProfileOption} from "../../../enrollment-types";
import {getStoredUser, setStoredUser} from "../../../shared/StoredUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '32px',
      fontWeight: 700,
    },
    subtitle: {
      textAlign: 'center',
      lineHeight: '23px',
      fontWeight: 700,
    },
    link: {
      color: colors.custom.green.variant1,
      textAlign: 'center',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    icon: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    form: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      marginTop: 8,
    },
    lastName: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        marginLeft: 16,
      },
    },
    email: {
      marginTop: 17,
      [theme.breakpoints.down('md')]: {
        marginTop: 0
      },
    },
    zipContainer: {
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        marginTop: 10,
      },
    },
    content: {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 40,
    },
    buttonWrapper: {
      flexDirection: 'column-reverse'
    }
  })
);

type CreateAccountModalProps = {
  onClose: () => void,
  open: boolean,
}

export default function CreateAccountModal(props: CreateAccountModalProps) {
  const classes = useStyles();
  const [submited, setSubmited] = React.useState(false);
  const [saveError, setSaveError] = React.useState();
  const [create, data] = useSaveUserProfile({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = <>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
            keycloak.login({redirectUri: document.location.href})
          }} style={{color: "red"}}>sign in</a></>
          break;
        case GraphQLErrorType.NO_CONNECTION: text = 'Service is not available';
        default: text = 'Unknown error'
      }
      setSaveError(text)
    },
  })

  const onSubmit = (values: any) => {
    if (values?.email) {
      create({variables: {data: {
            email: values.email,
            firstName: values.firstName,
            phoneNumber: values.phoneNumber,
            birthDate: getStoredUser()?.birthDate,
            zip: getStoredUser()?.zip,
            quoteId: getStoredUser()?.quoteId,
            cId: (window as any).gaGlobal?.vid,
            options: [ProfileOption.RegisterUser]
          }}}).then((res) => {
        if (res?.data?.saveUserProfile) {
          setSubmited(true);
          setStoredUser({
            ...getStoredUser(),
            userId: res?.data?.saveUserProfile.userId,
            enrollmentId: res?.data?.saveUserProfile.enrollmentId,
            email: values.email,
            firstName: values.firstName,
            phoneNumber: values.phoneNumber,
          })
        }
      })
    }
  };

  return <AscendModal
    width={590}
    open={props.open}
    onClose={() => props.onClose()}
    paperPadding={'5px 5px'}
  >
    <ScrollbarList height={'80vh'}>
      <Box p={'27px 45px'}>
        <IconButton onClick={() => props.onClose()} className={classes.icon}><img src={'/img/close.svg'} /></IconButton>
        {!submited && <>
          <Typography color={'textPrimary'} className={'text-align-centered fs-26 medium'}>Create an account to save your<br />results.</Typography>
          <div className={'text-align-centered mt-24 mb-20'}>
            <img src={'/img/prescription.svg'} />
          </div>
          <Typography color={'textSecondary'} className={'text-align-centered fs-18'}>Add your details below to create an Ascend profile. You can log in again later to see your saved plans and change your healthcare preferences.</Typography>
          <CreateAccountForm onSubmit={onSubmit}
                             emailError={saveError}
                             submitButtonLabel={'Create account'}
                             backButtonLabel={'I don’t want to easily access plans later'}
                             buttonWrapperClassName={classes.buttonWrapper}
                             onCancelClick={() => props.onClose()} />
        </>}
        {submited && <>
          <Typography color={'textPrimary'} className={'text-align-centered fs-26 medium'}>Success!<br /> Your account has been created.</Typography>
          <div className={'text-align-centered mt-16 mb-20'}>
            <img src={'/img/congratulations.svg'} />
          </div>
          <Typography color={'textSecondary'} className={'text-align-centered fs-18'}>We’ve sent you an email with instructions to complete your account creation. You can continue to browse your plan options now.</Typography>
          <Box display={'flex'} flex={1} justifyContent={'center'} mt={'20px'}>
            <AscendButton variant={'contained'} onClick={() => props.onClose()}>Back to plans</AscendButton>
          </Box>
        </>}
      </Box>
    </ScrollbarList>
  </AscendModal>
}
