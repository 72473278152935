import React from "react";
import {Plan} from "../../../types";
import AscendButton from "../../../shared/components/AscendButton";
import {planStyles} from "../../PlanList/PlanCard";
import {Box, createStyles, IconButton, Theme, Typography} from "@material-ui/core";
import {ComparisonContext} from "../ComparisonContext";
import {makeStyles} from "@material-ui/core/styles";
import {getCompanyLogo} from "../../../shared/utils";
import {useScrollPosition} from "../../../shared/useScrollPosition";
import {colors} from "../../../shared/AppTheme";
import StyledRating from "../../PlanList/components/StyledRating";
import {config} from "../../config/config";
import {getQuoteId} from "../../../shared/QuoteId";


const styles = makeStyles((theme: Theme) =>
  createStyles({
    removeButton: {
      background: 'rgba(164, 180, 185, 0.1)',
      width: 30,
      maxWidth: 30,
      height: 30,
      padding: 7,
    },
    planName: {
      fontWeight: 500,
      color: colors.custom.green.variant2
    },
    premium: {
      fontWeight: 500,
    },
    ratingIcon: {
      width: 20,
      height: 20,
      marginRight: 4
    }
  }),
);

type CompareHeaderCardProps = {
  plan: Plan
}

export default function CompareHeaderCard({plan}: CompareHeaderCardProps) {
  const classes = styles();
  const planClasses = planStyles();
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const scrollPosition = useScrollPosition();

  return <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} minHeight={scrollPosition < 150 ? '257px' : '175px'}>
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box width={'99px'} height={'35px'}>
          <img width={99} height={35} src={getCompanyLogo(plan.parentOrgName)} />
        </Box>
        <IconButton onClick={() => removeFromCompare(plan.bidId)} className={classes.removeButton}><img src={'/img/icon-close.svg'} /></IconButton>
      </Box>
      <Box display={'flex'} flex={1} flexDirection={'column'} justifyContent={'space-between'} mt={'10px'} mb={'15px'}>
        <div>
          <Typography variant={'h3'} className={'medium'} color={'textPrimary'}>{plan.planName}</Typography>
          {scrollPosition < 150 && <>
            {!plan.rating && <><div className={'h-10'} /><Typography variant={'h5'} color={'textPrimary'}>{plan.ratingNote}</Typography></>}
            {!!plan.rating && <StyledRating precision={0.5} classes={{icon: classes.ratingIcon}} readOnly defaultValue={plan.rating || 0}/>}
          </>}
        </div>
        <div>
          <Typography variant={'body2'} color={'textPrimary'} className={classes.premium}>{plan.monthlyCost}</Typography>
          <Typography variant={'body2'} color={'textPrimary'} className={classes.premium}>Estimated monthly cost</Typography>
        </div>
      </Box>
    </Box>
    <div>
      <AscendButton className={planClasses.enrollButton}
                    onClick={() => document.location.href = config.enrollUrl + plan.bidId + '/' + plan.zip + (getQuoteId() ? `/${getQuoteId()}` : '')}
                    variant={'contained'}>Select this plan</AscendButton>
    </div>
  </Box>
}
