import React from "react";
import {client} from "../shared/ApolloClient";
import {gql} from "apollo-boost";
import {getQuoteId, setQuoteId} from "../shared/QuoteId";
import * as _ from 'lodash';
import {convertPreferredDrugInput} from "../App/PlanList/EditPreferences";
import {DrugDeliveryTypeDto} from "../types";

export const QuestionnaireContext = React.createContext<[IQuestionnaireState, (action: IQuestionnaireAction) => void]>([] as any);

export const QuestionnaireContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<(state: IQuestionnaireState, action: IQuestionnaireAction) => IQuestionnaireState>
  (reducer, {} as any);

  return (
    <QuestionnaireContext.Provider value={[state, dispatch]}>
      {props.children}
    </QuestionnaireContext.Provider>
  );
};

export const withQuestionnaireContextProvider = (WrappedComponent: any) => (props: any) => {
  return (
    <QuestionnaireContextProvider>
      <WrappedComponent {...{...props}} />
    </QuestionnaireContextProvider>
  )
}

const navHistoryKey = 'NAV_HISTORY';

export const navHistory = {
  save: (route: string) => {
    const stored = sessionStorage.getItem(navHistoryKey);
    let history: string[];
    if (!stored) {
      history = [];
    } else {
      history = JSON.parse(stored);
    }
    if (!history.length || history[history.length - 1] !== route) {
      sessionStorage.setItem(navHistoryKey, JSON.stringify([...history, route]));
    }
  },
  findFirstIndexInHistory: (route: string): number => {
    const stored = sessionStorage.getItem(navHistoryKey);
    let history: string[];
    if (!stored) {
      history = [];
    } else {
      history = JSON.parse(stored);
    }
    return history.reverse().indexOf(route);
  },

}

const reducer = (state: IQuestionnaireState, action: IQuestionnaireAction) => {
  let newState: IQuestionnaireState = {...state};
  switch (action.type) {
    case QuestionnaireActionTypes.INIT_STATE:
      if (!action.payload) {
        const stored = localStorage.getItem('questionnaire')
        if (stored) {
          action.payload = JSON.parse(stored);
        }
      }
      newState = {
        ...state,
        ...action.payload
      };
      break;
    case QuestionnaireActionTypes.SAVE_STEP_VALUE:
      const {stateKey, ...payload} = action.payload;
      newState = {
        ...state,
        [stateKey]: payload,
      };
      break;
    default:
      throw new Error();
  }
  if (action.type !== QuestionnaireActionTypes.INIT_STATE && newState.personalDetails?.zip) {
    client.mutate({
      mutation: gql(saveRequestMutation),
      variables: {
        data: {
          ...prepareToSave(newState),
          form: 'BASIC_INFO',
          status: 'IN_PROGRESS',
          cId: (window as any).gaGlobal?.vid,
          id: getQuoteId()
        }
      }
    }).then((data: any) => {
      setQuoteId(data.data.saveMedicareQuote);
      /*(window as any).fcWidget.user.setProperties({
        quoteId: data.data.saveMedicareQuote
      })*/
    })
    // localStorage.removeItem('questionnaire')
  } else {
    localStorage.setItem('questionnaire', JSON.stringify(newState))
  }

  return newState;
};

function prepareToSave(state: IQuestionnaireState) {
  let result: any = {customAnswers: {}};
  for (const key in state) {
    if (key.indexOf('STEP_') === 0) {
      result.customAnswers = {...result.customAnswers, [key]: state[key as keyof IQuestionnaireState]};
    } else if (key.indexOf('preferredDoctors') === 0) {
      result.preferredDoctors = state?.preferredDoctors?.preferredDoctors?.map((d: any) => ({
          npi: d.npi,
          addressId: d.address.id
        }));
    } else if (key.indexOf('preferredDrugs') === 0) {
      result.preferredDrugs = state?.preferredDrugs?.preferredDrugs?.map(convertPreferredDrugInput);
    } else if (key.indexOf('preferredPharmacies') === 0) {
      result.preferredPharmacies = state?.preferredPharmacies?.preferredPharmacies?.map((p: any) => p.npi ? p.npi : p);
      result.drugDeliveryType = result.preferredPharmacies?.length ? DrugDeliveryTypeDto.Pharmacy : DrugDeliveryTypeDto.Mail;
    } else {
      if (_.isObject(state[key as keyof IQuestionnaireState])) {
        result = {...result, ...(state[key as keyof IQuestionnaireState] as any)};
      } else  {
        result = {...result, [key]: state[key as keyof IQuestionnaireState]};
      }
    }
  }
  delete result.pin;
  result.customAnswers = JSON.stringify(result.customAnswers);
  return result;
}

const saveRequestMutation = `
mutation ($data: MedicareQuoteInput!) {
  saveMedicareQuote(data: $data)
}
`;

export enum QuestionnaireActionTypes {
  INIT_STATE,
  SAVE_STEP_VALUE,
}

interface IQuestionnaireAction {
  payload?: any,
  type: QuestionnaireActionTypes
}

export interface IQuestionnaireState {
  id?: string,
  county?: string,
  pin?: string;
  STEP_1?: {
    value?: any
  },
  STEP_2a?: {
    value?: any
  },
  STEP_2b?: {
    value?: any
  },
  STEP_2c?: {
    value?: any
  },
  STEP_5a1?: {
    value?: any
  },
  STEP_5a4?: {
    value?: any
  },
  STEP_6b?: {
    value?: any
  },
  STEP_7?: {
    compare?: any,
    value?: any
  },
  STEP_13?: {
    value?: any
  },
  STEP_13a?: {
    outOfPocketCost?: any,
    monthlyCost?: any,
  },
  STEP_6a?: {
    employed?: any
    intendToKeep?: any
    hasMore20?: any
  },
  STEP_8?: {
    prepay?: any,
    certainDoctors?: any
    drugsCoverage?: any
    receiveMedicaid?: any
    extraBenefits?: any
    monthlyBudget?: any
  },
  basicInfo?: {
    gender?: string,
    email?: string,
    lastName?: string,
    firstName?: string,
    phoneNumber?: any,
  },
  favorites?: string[],
  homeAddress?: {
    streetName?: string,
    aptNumber?: string,
  },
  personalDetails?: {
    birthDate?: string,
    firstName?: string,
    zip?: string,
    countyName?: string,
  },
  workInfo?: {
    employed?: any
    spouseEmployed?: any
    hasMore20?: any
    spouseHasMore20?: any
    intendToKeep?: any
  },
  enrolled?: {
    enrolledFilled?: any
    enrolled?: any
    coverageFilled?: any
    coverage?: any
  },
  receivedBenefits?: {
    receivedBenefits?: any,
    travelMoreThan30DaysAYear?: any,
    chronicIllness?: any,
    prepayCoverage?: any,
    doctorsWithinNetwork?: any,
  },
  governmentAssistant?: {
    governmentAssistant?: any
  },
  preferredDoctors?: {
    preferredDoctors?: any
    preferredDoctorsFilled?: any
  },
  preferredPharmacies?: {
    preferredPharmacies?: any
    preferredPharmaciesFilled?: any
  },
  preferredDrugs?: {
    preferredDrugs?: any
    preferredDrugsFilled?: any
  },
  medicareOptions?: {
    medicarePlanType?: any
  },
  medicaid?: {
    receivesMedicalSupport?: any,
    receivesLIS?: any,
  },
  phoneNumber?: {
    phoneNumber?: any,
  },
  filters?: any,
  doctorVisitsNumber?: number,
  specialistVisitsNumber?: number,
}
