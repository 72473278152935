import React from "react";
import {Box, createStyles, Paper, Theme, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {QuoteContext} from "../../Quote/QuoteContextProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: (props: any) => props.maxWidth ? props.maxWidth : 656,
      paddingBottom: 96
    },
    titleText: {
      marginTop: 16,
      textAlign: 'center',
      whiteSpace: 'pre-line',
      fontWeight: 700,
    },
    secondaryText: {
      marginTop: 7,
      textAlign: 'center',
      fontWeight: 700,
    },
    footerText: {
      marginTop: 40,
      fontSize: 14,
    },
    amy: {
      minHeight: 84,
      minWidth: 80,
      maxHeight: 84,
      maxWidth: 80,
    }
  }),
);

export const withTitle = (WrappedComponent: any,
                         title: ((props: any, isMobile: boolean) => string | JSX.Element) | string | JSX.Element,
                         subtitle: ((props: any, isMobile: boolean) => string | JSX.Element | JSX.Element) | string | JSX.Element,
                         hideFooter?: boolean,
                         hideHeader?: boolean,
                         hideAmy?: boolean | ((isMobile: boolean) => boolean),
                         maxWidth?: string | number,
) => (props: any) => {
  const classes = useStyles({maxWidth});
  const [state] = React.useContext(QuoteContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const getTitle = () => typeof title === 'function' ? title(state, phone) : title;
  const getSubtitle = () => typeof subtitle === 'function' ? subtitle(state, phone) : subtitle;
  const getHideAmy = () => typeof hideAmy === 'function' ? hideAmy(phone) : hideAmy;


  return <Box width={'100%'}>
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <div className={classes.root}>
        {!hideHeader && <>
          {!getHideAmy() && <Box display={'flex'} justifyContent={'center'}>
            <img src={'/img/Amy.png'} width={80} height={84} className={classes.amy} />
          </Box>}
          <Box display={'flex'} justifyContent={'center'}>
            <Typography variant={'h1'} color={'textPrimary'} className={classes.titleText}>{getTitle()}</Typography>
          </Box>
          <Box display={'flex'} justifyContent={'center'} maxWidth={'800px'}>
            <Typography variant={'h3'} color={'textSecondary'} className={classes.secondaryText}>{getSubtitle()}</Typography>
          </Box>
        </>}

        <WrappedComponent {...props} />
      </div>
    </Box>
  </Box>
}
