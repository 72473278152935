import React from "react";
import {KeycloakContext} from "../../shared/KeycloakContext";
import {KeycloakRoles} from "../../shared/keycloak";
import {ClientViewOutput} from "../../enrollment-types";
import useClientView from "./hooks/useClientView";
import {resetStoredUser} from "../../shared/StoredUser";
import {removeClientId} from "../../shared/ClienId";

interface AdminContextState {
  clientId: string;
  clientView: ClientViewOutput;
  clear: () => void;
}

export const AdminContext = React.createContext<AdminContextState | null>(null);

interface AdminContextProviderProps {
  clientId?: string;
}

export function AdminContextProvider(props: React.PropsWithChildren<AdminContextProviderProps>) {
  const [isCleared, setIsCleared] = React.useState(false);

  const {authenticated, keycloak} = React.useContext(KeycloakContext);

  const isAdmin = keycloak?.realmAccess?.roles?.includes(KeycloakRoles.ROLE_ADMIN);

  const clear = React.useCallback(() => {
    setIsCleared(true)
    removeClientId();
  }, [setIsCleared]);

  if (authenticated && isAdmin && props.clientId && !isCleared) {
    return <AdminContextProviderWithSubscription clientId={props.clientId} onCleared={clear}>
      {props.children}
    </AdminContextProviderWithSubscription>
  } else {
    return <AdminContext.Provider value={null}>
      {props.children}
    </AdminContext.Provider>
  }
}

function AdminContextProviderWithSubscription(props: React.PropsWithChildren<{clientId: string, onCleared: () => void}>) {
  const clientView = useClientView(props.clientId);

  const [adminState, setAdminState] = React.useState<AdminContextState | null>(null);

  const clear = React.useCallback(() => {
    resetStoredUser();
    document.location.href = document.location.href.split("?")[0];
    props.onCleared();
  }, []);

  React.useEffect(() => {
    if (clientView != null) {
      setAdminState({
        clientId: props.clientId,
        clientView: clientView,
        clear: clear
      })
    }
  }, [clientView]);

  return !!adminState ? <AdminContext.Provider value={adminState}>
    {props.children}
  </AdminContext.Provider> : null
}
