import React from "react";
import {Box, Typography} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import {DoctorSelection} from "../shared/components/DoctorSelection";
import useDoctorsByRequestId from "../App/PlanList/hooks/useDoctorsByRequestId";
import {setQuoteId} from "../shared/QuoteId";
import useDrugsByRequestId from "../App/PlanList/hooks/useDrugsByRequestId";
import {DrugSelection} from "../shared/components/DrugSelection";
import {OrderType, PharmaciesSelection} from "../shared/components/PharmaciesSelection";
import usePharmaciesByRequestId from "../App/PlanList/hooks/usePharmaciesByRequestId";
import {convertPreferredDrugInput} from "../App/PlanList/EditPreferences";
import useSaveRequest from "../Questionnaire/hooks/useSaveRequest";
import useUserProfile from "./hooks/useUserProfile";
import AscendPreloader from "../shared/components/AscendPreloader";
import AscendTooltip from "../shared/components/AscendTooltip";
import {DrugDeliveryTypeDto} from "../types";
import {useSnackbar} from "notistack";


export default function EditPreferencesForm() {
  const {userProfile} = useUserProfile();
  const {doctors, setDoctors} = useDoctorsByRequestId(userProfile?.quoteId);
  const [showDoctorsAutocomplete, setShowDoctorsAutocomplete] = React.useState(false);
  const {drugs, setDrugs} = useDrugsByRequestId(userProfile?.quoteId);
  const [showDrugsAutocomplete, setShowDrugsAutocomplete] = React.useState(false);
  const {pharmacies, setPharmacies, filled, setFilled} = usePharmaciesByRequestId(userProfile?.quoteId);
  const [preferredPharmaciesFilled, setPreferredPharmaciesFilled] = React.useState(false);
  const [orderType, setOrderType] = React.useState<OrderType>();
  const [savePreferences, {loading}] = useSaveRequest();
  const { enqueueSnackbar } = useSnackbar();

  const save = React.useCallback(() => {
    if (userProfile?.zip) {
      setFilled(preferredPharmaciesFilled);
      savePreferences({
        variables: {
          data: {
            preferredDoctors: doctors.map(d => ({
              npi: d.npi,
              addressId: d.address.id
            })),
            preferredDrugs: drugs.map(convertPreferredDrugInput),
            preferredPharmacies: orderType === OrderType.RetailPharmacy ? pharmacies?.map(p => p.npi) : [],
            drugDeliveryType: orderType === OrderType.RetailPharmacy ? DrugDeliveryTypeDto.Pharmacy : DrugDeliveryTypeDto.Mail,
            preferredPharmaciesFilled: preferredPharmaciesFilled,
            form: 'SEARCH_RESULTS',
            id: userProfile.quoteId,
            zip: userProfile.zip
          }
        },
      }).then((data: any) => {
        setQuoteId(data.data.saveMedicareQuote)
        enqueueSnackbar('Changes saved!', {variant: 'success'});
      })
    } else {

    }
  }, [userProfile, drugs, doctors, pharmacies, preferredPharmaciesFilled])

  if (loading) {
    return <AscendPreloader/>
  }

  return <Box display={'flex'} justifyContent={'center'}>
    <Box maxWidth={656}>
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Your healthcare preferences</Typography>
      <div className={'h-10'} />
      <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>Add any doctors you like too see, prescription drugs and your pharmacy preferences below—we’ll show you plans that include them.</Typography>
      <div className={'h-25'} />
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Your doctors</Typography>
      <div className={'h-25'} />
      <DoctorSelection selectedDoctors={doctors}
                       zip={'10001'}
                       setSelectedDoctors={setDoctors}
                       showAutocomplete={showDoctorsAutocomplete}
                       setShowAutocomplete={setShowDoctorsAutocomplete}/>
      <div className={'h-32'} />
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Your prescription drugs</Typography>
      <div className={'h-25'} />
      <DrugSelection selectedPrescriptions={drugs}
                     setSelectedPrescriptions={setDrugs}
                     showAutocomplete={showDrugsAutocomplete}
                     setShowAutocomplete={setShowDrugsAutocomplete}
      />
      <div className={'h-32'} />
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>Your pharmacies</Typography>
      <div className={'h-25'} />
      <Typography color={'textPrimary'} variant={'h4'} className={'medium'}>Where do you go to fill your prescriptions?</Typography>
      <div className={'h-8'} />
      <PharmaciesSelection zip={`${userProfile?.zip}`}
                           selectedPharmacies={pharmacies}
                           filled={filled}
                           onOrderTypeChange={value => {
                             setPreferredPharmaciesFilled(true)
                             setOrderType(value)
                           }}
                           setSelectedPharmacies={setPharmacies} />
      {!pharmacies?.length && <div className={'h-20'} />}
      <div className={'h-20'} />
      <AscendTooltip placement={'right'} arrow
                     title={'Fill zip code first'}
                     open={userProfile?.zip ? false : undefined}
      >
        <div className={'w-210'}>
          <AscendButton variant={'contained'} disabled={!userProfile?.zip} onClick={save}>Save changes</AscendButton>
        </div>
      </AscendTooltip>

      <div className={'h-100'} />
    </Box>
  </Box>
}
