import React from "react";
import {Box, createStyles, Theme, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router";
import paths from "./config/router-paths";
import CreateAccountForm from "../shared/components/CreateAccountForm";
import {withTitle} from "../shared/components/withTitle";
import useCreateUser from "../shared/hooks/useCreateUser";
import {KeycloakContext} from "../shared/KeycloakContext";
import {getStoredUser, setStoredUser} from "../shared/StoredUser";
import {GraphQLErrorType} from "../shared/ApolloClient";


function CreateAccount(props: RouteComponentProps<{ planId: string, zip: string, quoteId: string, countyName: string }>) {
  const {planId, zip, quoteId, countyName} = props.match.params;
  const [saveError, setSaveError] = React.useState();
  const {authenticated, keycloak} = React.useContext(KeycloakContext);

  const [create] = useCreateUser({
    onError: (error) => {
      let text;
      switch (error.graphQLErrors[0]?.extensions?.type) {
        case GraphQLErrorType.ALREADY_EXISTS:
          text = <>Looks like that email is already in use. Please <a href={"#"} onClick={() => {
            keycloak.login({redirectUri: document.location.href})
          }} style={{color: "red"}}>sign in</a></>
          break;
        case GraphQLErrorType.NO_CONNECTION: text = 'Service is not available';
        default: text = 'Unknown error'
      }
      setSaveError(text)
    },
  })

  React.useEffect(() => {
    if (authenticated) {
      props.history.push(paths.personalDetails + "/" + planId + "/" + zip + (quoteId ? "/" + quoteId : ""));
    }
  }, [authenticated])

  const onSubmit = (values: any) => {
    if (values?.email) {
      create({
        variables: {
          data: {
            email: values.email,
            firstName: values.firstName,
            phoneNumber: values.phoneNumber,
            quoteId: getStoredUser()?.quoteId,
          }
        }
      }).then((response) => {
        if (response?.data?.createUser) {
          setStoredUser({
            ...getStoredUser(),
            userId: response?.data?.createUser.userId,
            enrollmentId: response?.data?.createUser.enrollmentId,
            email: values.email,
            firstName: values.firstName,
            phoneNumber: values.phoneNumber,
          })
          sessionStorage.setItem('ACCOUNT_TYPE', AccountType.CREATED);
          props.history.push(paths.personalDetails + "/" + planId + "/" + zip + "/" + countyName)
        }
      })
    }
  };

  return <>
    <Box display={"flex"} justifyContent={"center"} flex={1}>
      <Box>
        <div className={"h-32"}/>
        <CreateAccountForm onSubmit={onSubmit}
                           showSkip
                           showSignIn
                           onSkipClick={() => {
                             sessionStorage.setItem('ACCOUNT_TYPE', AccountType.SKIPPED);
                             props.history.push(paths.personalDetails + "/" + planId + "/" + zip + "/" + countyName)
                           }}
                           onSignInClick={() => {
                             sessionStorage.setItem('ACCOUNT_TYPE', AccountType.SIGN_IN);
                             document.location.href = process.env.REACT_APP_PRIVATE_URL as string
                           }}
                           submitButtonLabel={"Save and continue"}
                           emailError={saveError}
                           backButtonLabel={"Back"}
                           onCancelClick={() => props.history.goBack()}/>
      </Box>
    </Box>
  </>
}

export enum AccountType {
  SIGN_IN = 'SIGN_IN',
  SKIPPED = 'SKIPPED',
  CREATED = 'CREATED'
}

export default withTitle(
  CreateAccount,
  `Create an optional account to save your application progress.`,
  "Your details will always be stored securely.",
  undefined,
  undefined,
  undefined,
  600
)
