import React from "react";
import {Box, createStyles, Paper, Theme, Typography, withStyles} from "@material-ui/core";
import AscendButton from "../shared/components/AscendButton";
import {FavoritesContext} from "../App/shared/FavoritesContext";
import usePlansByBid from "../App/DetailedPlan/hooks/usePlansByBid";
import useUserProfile from "./hooks/useUserProfile";
import {colors} from "../shared/AppTheme";
import FavoritePlanCard from "./Dashboard/components/FavoritePlanCard";
import {config} from "./config/config";
import useMyEnrollment from "./hooks/useMyEnrollment";
import {usePlanLazy} from "../App/DetailedPlan/hooks/usePlan";
import PlanCard from "../App/PlanList/PlanCard";
import paths from "./config/router-paths";
import { useHistory } from "react-router-dom";
import planFiles from "../App/shared/planFiles";
import {EnrollmentOutput, StatusOutput} from "../enrollment-types";
import moment from "moment";
import {planStyles} from "../shared/components/ShortPlanCard";


export default function Coverage() {
  const {userProfile} = useUserProfile();
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);
  const favoritePlans = usePlansByBid(userProfile?.zip, userProfile?.countyName, favorites);
  const enrollment = useMyEnrollment();
  const [getPlan, {data}] = usePlanLazy();
  const history = useHistory();

  const plan = React.useMemo(() => {
    return data?.plan;
  }, [data])

  const files = React.useMemo(() => {
    return planFiles(plan?.planFiles);
  }, [plan])

  React.useEffect(() => {
    if (userProfile?.zip && userProfile?.countyName && enrollment?.bidId) {
      getPlan({
        variables: {
          bidId: enrollment?.bidId,
          zip: userProfile.zip,
          countyName: userProfile.countyName,
          quoteId: userProfile?.quoteId
        }
      })
    }
  }, [userProfile, enrollment])



  return <Box display={'flex'} justifyContent={'center'}>
    <Box maxWidth={800}>
      <Typography color={'textPrimary'} variant={'h1'} className={'bold'}>My Medicare coverage</Typography>
      <div className={'h-10'} />
      <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>{plan ? 'See details on your saved plans below.' : 'See details on your current plan below, including your key plan documents.'}</Typography>
      <div className={'h-35'} />
      {(!!plan && userProfile?.countyName && userProfile?.zip) && <>
        <EnrollmentView enrollment={enrollment} planName={plan.planName} />
        <Typography color={'textPrimary'} variant={'h2'} className={'bold'}>Your Medicare Advantage plan</Typography>
        <PlanCard onAddClick={() => history.push(paths.myHealthcare)} hideEnroll countyName={userProfile.countyName} zip={userProfile.zip} plan={plan} />
        <div className={'h-35'} />
        <Typography color={'textPrimary'} variant={'h2'} className={'bold mb-24'}>Key plan documents</Typography>
        {files.filter(i => !!i.url).map(item => <a className={`color-primary medium fs-18 mb-16 flex flex-align-center`} target={'_blank'} href={item.url}>
          <img src={'/img/download.svg'} className={'mr-8 w-16 h-16'} />
          {item.label}
        </a>)}
        {plan?.planFiles?.preEnrollmentChecklistDocUrl ?
          <a className={`color-primary medium fs-18 flex flex-align-center`}
             target={'_blank'}
             href={config.filesUrl + '/' + plan?.planFiles?.preEnrollmentChecklistDocUrl}>
            <img src={'/img/download.svg'} className={'mr-8 w-16 h-16'} />
            Medicare & You Handbook (2021)
          </a> : ''}
      </>}
      {!plan && <>
        <Typography color={'textPrimary'} variant={'h2'} className={'bold'}>Saved Medicare Advantage plans</Typography>
        <div className={'h-24'} />
        {favoritePlans && favoritePlans.map(plan => <FavoritePlanCard key={plan.bidId}
                                                                      plan={plan}
                                                                      onTitleClick={() => { document.location.href = config.resultsUrl + `plan/${userProfile?.zip}/${userProfile?.countyName}/${plan.bidId}` }}
                                                                      onEnrollClick={() => { document.location.href = config.enrollUrl + plan.bidId + '/' + userProfile?.zip + '/' + userProfile?.countyName}}
                                                                      onRemoveClick={() => {
                                                                        if (userProfile?.zip) {
                                                                          toggleFavorite(plan.bidId, userProfile.zip)
                                                                        }
                                                                      }} />
        )}
        <div className={'h-8'} />
        <div className={'text-align-centered'}>
          <AscendButton variant={'contained'}
                        onClick={() => { document.location.href = config.resultsUrl + `plans/${userProfile?.zip}/${userProfile?.countyName}` }}>
            See all Medicare Advantage plans
          </AscendButton>
        </div>
      </>}
      <div className={'h-100'} />
    </Box>
  </Box>
}


const EnrollmentView = ({enrollment, planName}: {enrollment?: EnrollmentOutput, planName?: string | null}) => {
  if (!enrollment) {
    return null;
  }
  return <div className={'mb-50'}>
    <Box display={'flex'} justifyContent={'space-between'} mb={'36px'}>
      <div>
        <Typography color={'textPrimary'} variant={'h2'} className={'bold'}>Medicare Advantage</Typography>
        <div className={'h-10'} />
        <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>
          {enrollment.status === StatusOutput.Draft && <>Your enrollment is pending.</>}
          {enrollment.status === StatusOutput.Enrolled && <>You’re currently enrolled in {planName}.</>}
        </Typography>
      </div>
      <div>
        {getStatusContent(enrollment.status)}
      </div>
    </Box>
    <Box display={'flex'} justifyContent={'space-between'}>
      <div>
        <Typography color={'textPrimary'} variant={'h3'} className={'medium'}>Medicare Part C (Medicare Advantage)</Typography>
        <Typography color={'textPrimary'} className={'medium fs-14'}>+ MyOption Platinum Dental</Typography>
      </div>
      <div>
        <Typography className={'fs-18 medium'} color={'textPrimary'}>Application date: {moment(enrollment?.createdAt).format('MM/DD/YY')}</Typography>
      </div>
    </Box>
  </div>
}

const getStatusContent = (status: StatusOutput): JSX.Element => {
  const classes = planStyles();
  switch (status) {
    case StatusOutput.Draft: return <div className={classes.pending}>Pending</div>
    case StatusOutput.Enrolled: return <img src={'/img/green2-check.svg'} width={40} height={40} />
    default: return <></>;
  }
}

export const RoundedButton = withStyles({
  root: {
    '&:hover': {
    },
    '&:active': {
    },
  },
  contained: {
    borderRadius: 4,
    background: colors.custom.green.variant2,
    padding: '11px 37px',
  },
  '&:hover': {
    backgroundColor: colors.custom.green.variant3,
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: colors.custom.green.variant3,
    boxShadow: 'none',
  },
  disabled: {},
  label: {
    color: 'white',
    fontWeight: 700,
    fontSize: 16,
    textTransform: 'none',
    whiteSpace: 'nowrap'
  },
})(AscendButton);
