export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** A type representing a formatted an instantaneous point on the timeline */
  Instant: any;
  /** Long type */
  Long: any;
  /** A type representing a string identifier */
  UUID: any;
};



export type AddressDto = {
  __typename?: 'AddressDto';
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type AgentViewOutput = {
  __typename?: 'AgentViewOutput';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
};

export type ClientViewOutput = {
  __typename?: 'ClientViewOutput';
  agent?: Maybe<AgentViewOutput>;
  birthDate?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customAnswers?: Maybe<Scalars['String']>;
  doctors: Array<DoctorViewOutput>;
  drugs: Array<DrugViewOutput>;
  email?: Maybe<Scalars['String']>;
  filters?: Maybe<FiltersOutput>;
  firstName?: Maybe<Scalars['String']>;
  followUpDate?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lastName?: Maybe<Scalars['String']>;
  leadSources: Array<LeadSourceOutput>;
  note?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['UUID']>;
  quoteId?: Maybe<Scalars['UUID']>;
  scopeOfAppointmentStatus?: Maybe<ScopeOfAppointmentStatusDto>;
  status: StatusDto;
  zip?: Maybe<Scalars['String']>;
};

export type DoctorAddressViewOutput = {
  __typename?: 'DoctorAddressViewOutput';
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  id: Scalars['String'];
  isPcp: Scalars['Boolean'];
  specialty: Scalars['String'];
};

export type DoctorViewOutput = {
  __typename?: 'DoctorViewOutput';
  address: DoctorAddressViewOutput;
  addressesNumber: Scalars['Int'];
  name: Scalars['String'];
  npi: Scalars['String'];
  pcpDetails: Array<PcpInfoViewOutput>;
};

export type DrugViewOutput = {
  __typename?: 'DrugViewOutput';
  frequency?: Maybe<Scalars['String']>;
  genericName?: Maybe<Scalars['String']>;
  genericRxcui?: Maybe<Scalars['String']>;
  isGeneric?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  packName?: Maybe<Scalars['String']>;
  packRxcui?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['BigDecimal']>;
  rxcui?: Maybe<Scalars['String']>;
};

export type EnrollmentOutput = {
  __typename?: 'EnrollmentOutput';
  approvedAt?: Maybe<Scalars['String']>;
  bidId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  communicationPreference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isElectronicallyCommunications?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<AddressDto>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  permanentAddress?: Maybe<AddressDto>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<PreferredLanguageDto>;
  quoteId?: Maybe<Scalars['UUID']>;
  status: StatusOutput;
  userId?: Maybe<Scalars['UUID']>;
};

export type FiltersOutput = {
  __typename?: 'FiltersOutput';
  extraBenefits: Array<ExtraBenefit>;
};

export type KeycloakUserOutput = {
  __typename?: 'KeycloakUserOutput';
  enrollmentId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

export type LeadSourceOutput = {
  __typename?: 'LeadSourceOutput';
  campaign: Scalars['String'];
  firstTime: Scalars['String'];
  lastTime: Scalars['String'];
  medium: Scalars['String'];
  source: Scalars['String'];
  term?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  assignClientView: Scalars['Boolean'];
  assignClientViewToMe: Scalars['Boolean'];
  changeEnrollmentStatus?: Maybe<Scalars['UUID']>;
  confirmScopeOfAppointment: Scalars['UUID'];
  createScopeOfAppointment: Scalars['UUID'];
  /** @deprecated Need to use saveUserProfile API */
  createUser: KeycloakUserOutput;
  deleteUser: Scalars['Boolean'];
  openedScopeOfAppointment: Scalars['Boolean'];
  putTemporalData: Scalars['UUID'];
  resetPassword: Scalars['Boolean'];
  saveEnrollment: Scalars['UUID'];
  saveFollowUpDate: Scalars['Boolean'];
  saveNote: Scalars['Boolean'];
  saveStatus: Scalars['Boolean'];
  saveUserProfile: UserProfileSaveOutput;
  updateSources: Array<LeadSourceOutput>;
};


export type MutationAssignClientViewArgs = {
  agentId: Scalars['UUID'];
  clientViewId: Scalars['UUID'];
};


export type MutationAssignClientViewToMeArgs = {
  clientViewId: Scalars['UUID'];
};


export type MutationChangeEnrollmentStatusArgs = {
  enrollmentId: Scalars['UUID'];
  status: EnrollmentStatusInput;
};


export type MutationConfirmScopeOfAppointmentArgs = {
  userInput: ScopeOfAppointmentUserInput;
};


export type MutationCreateScopeOfAppointmentArgs = {
  adminInput: ScopeOfAppointmentAdminInput;
};


export type MutationCreateUserArgs = {
  input: KeycloakUserInput;
};


export type MutationOpenedScopeOfAppointmentArgs = {
  id: Scalars['UUID'];
};


export type MutationPutTemporalDataArgs = {
  data: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationSaveEnrollmentArgs = {
  input: EnrollmentInput;
};


export type MutationSaveFollowUpDateArgs = {
  followUpDate?: Maybe<Scalars['Instant']>;
  id: Scalars['UUID'];
};


export type MutationSaveNoteArgs = {
  id: Scalars['UUID'];
  note: Scalars['String'];
};


export type MutationSaveStatusArgs = {
  id: Scalars['UUID'];
  status: StatusDto;
};


export type MutationSaveUserProfileArgs = {
  input: UserProfileInput;
};


export type MutationUpdateSourcesArgs = {
  clientId: Scalars['UUID'];
};

export type NetworkInfoViewOutput = {
  __typename?: 'NetworkInfoViewOutput';
  carrierName: Scalars['String'];
  name: Scalars['String'];
};

export type PageableClientViewOutput = {
  __typename?: 'PageableClientViewOutput';
  data: Array<ClientViewOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableEnrollmentOutput = {
  __typename?: 'PageableEnrollmentOutput';
  data: Array<EnrollmentOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PageableUserProfileOutput = {
  __typename?: 'PageableUserProfileOutput';
  data: Array<UserProfileOutput>;
  hasNext: Scalars['Boolean'];
  hasPrevious: Scalars['Boolean'];
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  number: Scalars['Int'];
  size: Scalars['Int'];
  totalElements: Scalars['Long'];
  totalPages: Scalars['Int'];
};

export type PcpInfoViewOutput = {
  __typename?: 'PcpInfoViewOutput';
  id: Scalars['UUID'];
  networkDetails: Array<NetworkInfoViewOutput>;
  pcpId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  agents: Array<AgentViewOutput>;
  checkUserExistenceByEmail: Scalars['Boolean'];
  clientLeadSources: Array<LeadSourceOutput>;
  clientViewByPin?: Maybe<ClientViewOutput>;
  clientViews: PageableClientViewOutput;
  enrollments: PageableEnrollmentOutput;
  getMyEnrollment?: Maybe<EnrollmentOutput>;
  pushTemporalData?: Maybe<Scalars['String']>;
  scopeOfAppointment?: Maybe<ScopeOfAppointmentOutput>;
  userProfile?: Maybe<UserProfileOutput>;
  userProfiles: PageableUserProfileOutput;
};


export type QueryCheckUserExistenceByEmailArgs = {
  email: Scalars['String'];
};


export type QueryClientLeadSourcesArgs = {
  cid: Scalars['String'];
};


export type QueryClientViewByPinArgs = {
  pin: Scalars['String'];
};


export type QueryClientViewsArgs = {
  filterInput?: Maybe<ClientViewFilterInput>;
  pageInput: PageInput;
  sort?: Maybe<ClientViewSortInput>;
};


export type QueryEnrollmentsArgs = {
  input?: Maybe<EnrollmentFilterInput>;
  page: PageInput;
};


export type QueryPushTemporalDataArgs = {
  id: Scalars['UUID'];
};


export type QueryScopeOfAppointmentArgs = {
  soaId: Scalars['UUID'];
};


export type QueryUserProfilesArgs = {
  filterInput?: Maybe<UserProfileFilterInput>;
  page: PageInput;
};

export type ScopeOfAppointmentOutput = {
  __typename?: 'ScopeOfAppointmentOutput';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  isConfirmed: Scalars['Boolean'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  clientCard: ClientViewOutput;
};


export type SubscriptionClientCardArgs = {
  data: ClientDetailsSubscriptionInput;
};

export type UserProfileOutput = {
  __typename?: 'UserProfileOutput';
  aptNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hasNoPreferredDoctors?: Maybe<Scalars['Boolean']>;
  hasNoPreferredPharmacies?: Maybe<Scalars['Boolean']>;
  hasNoPrescriptionsDrugs?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileId: Scalars['UUID'];
  quoteId?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  streetName?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserProfileSaveOutput = {
  __typename?: 'UserProfileSaveOutput';
  enrollmentId?: Maybe<Scalars['UUID']>;
  profileId: Scalars['UUID'];
  userId?: Maybe<Scalars['UUID']>;
};

export enum EnrollmentStatusInput {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum ExtraBenefit {
  Dental = 'DENTAL',
  DrugCoverage = 'DRUG_COVERAGE',
  Fitness = 'FITNESS',
  Hearing = 'HEARING',
  InsulinSavings = 'INSULIN_SAVINGS',
  OverTheCounter = 'OVER_THE_COUNTER',
  Telehealth = 'TELEHEALTH',
  Transportation = 'TRANSPORTATION',
  Vision = 'VISION',
  WorldwideEmergency = 'WORLDWIDE_EMERGENCY'
}

export enum Field {
  BirthDate = 'birthDate',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  FollowUpDate = 'followUpDate',
  Gender = 'gender',
  LastName = 'lastName',
  Note = 'note',
  PhoneNumber = 'phoneNumber',
  ScopeOfAppointmentStatus = 'scopeOfAppointmentStatus',
  Status = 'status',
  Zip = 'zip'
}

export enum FormName {
  BasicInfo = 'BASIC_INFO',
  Calendar = 'CALENDAR',
  ChronicIllness = 'CHRONIC_ILLNESS',
  Doctors = 'DOCTORS',
  Drugs = 'DRUGS',
  Enrolled = 'ENROLLED',
  GovernmentAssistant = 'GOVERNMENT_ASSISTANT',
  HomeAddress = 'HOME_ADDRESS',
  Medicaid = 'MEDICAID',
  MedicareOverview = 'MEDICARE_OVERVIEW',
  PersonalDetails = 'PERSONAL_DETAILS',
  Pharmacies = 'PHARMACIES',
  PhoneNumber = 'PHONE_NUMBER',
  ReceivingBenefits = 'RECEIVING_BENEFITS',
  SearchResults = 'SEARCH_RESULTS',
  WorkInfo = 'WORK_INFO'
}

export enum PreferredLanguageDto {
  Chinese = 'CHINESE',
  English = 'ENGLISH',
  French = 'FRENCH',
  FrenchCreole = 'FRENCH_CREOLE',
  Korean = 'KOREAN',
  Polish = 'POLISH',
  Spanish = 'SPANISH',
  Vietnamese = 'VIETNAMESE'
}

export enum ProfileOption {
  CreateEnrollment = 'CREATE_ENROLLMENT',
  RegisterUser = 'REGISTER_USER',
  RequestCall = 'REQUEST_CALL',
  UnsupportedZip = 'UNSUPPORTED_ZIP'
}

export enum RequestStatusDto {
  Complete = 'COMPLETE',
  Deleted = 'DELETED',
  InProgress = 'IN_PROGRESS'
}

export enum ScopeOfAppointmentStatusDto {
  Opened = 'OPENED',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum StatusDto {
  ApplicationSubmitted = 'APPLICATION_SUBMITTED',
  Attempted = 'ATTEMPTED',
  Contacted = 'CONTACTED',
  Disqualified = 'DISQUALIFIED',
  Draft = 'DRAFT',
  Duplicated = 'DUPLICATED',
  Enrolled = 'ENROLLED',
  FollowUp = 'FOLLOW_UP',
  NotYetEngaged = 'NOT_YET_ENGAGED',
  OutOfService = 'OUT_OF_SERVICE'
}

export enum StatusOutput {
  Draft = 'DRAFT',
  Enrolled = 'ENROLLED',
  New = 'NEW',
  Rejected = 'REJECTED',
  Sent = 'SENT'
}

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}





export type AddressDtoInput = {
  aptNumber: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  streetName: Scalars['String'];
  zip: Scalars['String'];
};

export type ClientDetailsSubscriptionInput = {
  clientId: Scalars['UUID'];
};

export type ClientViewFilterInput = {
  agentId?: Maybe<Scalars['UUID']>;
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<StatusDto>;
};

export type ClientViewSortInput = {
  direction: SortDirection;
  field: Field;
};

export type EnrollmentFilterInput = {
  lastName?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<EnrollmentStatusInput>>;
};

export type EnrollmentInput = {
  bidId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  communicationPreference?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  isElectronicallyCommunications?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  mailingAddress?: Maybe<AddressDtoInput>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  permanentAddress?: Maybe<AddressDtoInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredLanguage?: Maybe<PreferredLanguageDto>;
  quoteId?: Maybe<Scalars['UUID']>;
};

export type KeycloakUserInput = {
  email: Scalars['String'];
  enrollmentId?: Maybe<Scalars['UUID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['UUID']>;
  quoteId?: Maybe<Scalars['UUID']>;
};

export type PageInput = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type ScopeOfAppointmentAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['UUID']>;
};

export type ScopeOfAppointmentUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type UserProfileFilterInput = {
  endDate?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
};

export type UserProfileInput = {
  aptNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
  cId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  form?: Maybe<FormName>;
  gender?: Maybe<Scalars['String']>;
  hasNoPreferredDoctors?: Maybe<Scalars['Boolean']>;
  hasNoPreferredPharmacies?: Maybe<Scalars['Boolean']>;
  hasNoPrescriptionsDrugs?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  medicareNumber?: Maybe<Scalars['String']>;
  medicarePartAStartedAt?: Maybe<Scalars['String']>;
  medicarePartBStartedAt?: Maybe<Scalars['String']>;
  options?: Maybe<Array<ProfileOption>>;
  phoneNumber?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['UUID']>;
  quoteId?: Maybe<Scalars['UUID']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<RequestStatusDto>;
  streetName?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  zip?: Maybe<Scalars['String']>;
};
