import React from "react";
import {Box, Collapse, Divider, Typography, useMediaQuery, useTheme, withStyles} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import clsx from 'clsx';
import AscendTooltip from "../../shared/components/AscendTooltip";
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {questionsRoutes} from "../hooks/useStepperState";
import {withTitle} from "../../shared/components/withTitle";
import * as _ from 'lodash';
import {IQuoteState, QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import {createPayload} from "./Quiz2";
import {RoundedButton} from "../../App/PlanList/PlanCard";
import MAMOEmailModal from "../components/MAMOEmailModal";

const useStyles = makeStyles({
  info: {
    backgroundColor: 'rgba(94, 203, 158, 0.1)',
    borderRadius: 10,
    padding: '30px',
    fontWeight: 500,
    color: colors.text.primary,
    marginTop: 32,
  },
  medium: {
    fontWeight: 500,
  },
  collapseButton: {
    minWidth: 30,
    width: 30,
    height: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 15,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
    '&.grey': {
      backgroundColor: 'rgba(164, 180, 185, 0.1)',
    },
    '& img': {
      transition: 'all 0.3s',
      transform: 'rotate(0)',
    },
    '& img.open': {
      transform: 'rotate(-180deg) !important',
    }
  },
  divider: {
    marginTop: 26,
    marginBottom: 22,
    backgroundColor: '#C4C4C4',
  },
  messageSubtitle: {
    maxWidth: 'calc(100% - 45px)',
  }
});

const formName = 'QUIZ_3';

function Quiz3(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);
  const [originalOpen, setOriginalOpen] = React.useState(false);
  const [advantageOpen, setAdvantageOpen] = React.useState(false);
  const [messagesCollapse, setMessagesCollapse] = React.useState<any>({});
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
    const urlData = getDataFromUrl();
    if (urlData) {
      dispatch({
        type: QuoteActionTypes.SAVE_STEP_VALUE,
        payload: createPayload(
          urlData.prepay,
          urlData.certainDoctors,
          urlData.drugsCoverage,
          urlData.extraBenefits,
          urlData.monthlyBudget,
          urlData.receiveMedicaid,
        )
      })
    }
  }, [])

  React.useEffect(() => {
    const selected = calculateSelected(state);
    setOriginalOpen(selected === PlanType.MG);
    setAdvantageOpen(selected !== PlanType.MG);
  }, [state])

  const onSubmit = (plan: string) => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {
        stateKey: formName,
        medicarePlanType: {
          questionText: 'Medicare plan type',
          answerText: plan,
          value: plan
        },
        form: formName
      }
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step2)
  };

  const selected = React.useMemo(() => {
    return calculateSelected(state);
  }, [state])

  const isMessageOpen = React.useCallback((index: number) => {
      if (!phone) {
        return true;
      } else {
        if (typeof messagesCollapse[index] === 'undefined') {
          return false;
        } else {
          return messagesCollapse[index];
        }
      }
  }, [messagesCollapse, phone]);

  const toggleMessage = (index: number) => {
    if (typeof messagesCollapse[index] === 'undefined') {
      setMessagesCollapse((prev: any) => ({...prev, [index]: true}))
    } else {
      setMessagesCollapse((prev: any) => ({...prev, [index]: !prev[index]}))
    }
  }

  return <Box display={'flex'} flexDirection={'column'} mt={'47px'}>
    <Typography className={'bold fs-20'} color={'textSecondary'}>Here’s why:</Typography>
    {_.orderBy(getHeresWhy(state), q => q.value ? -1 : 1).map((message, index) => <div key={index}>
      <Box display={'flex'} alignItems={'flex-start'} mt={2}>
        <HeresWhyIcon value={message.value} />
        <div className={'w-24'} />
        <div className={'fill-width'}>
          <Box display={'flex'} justifyContent={'space-between'} onClick={() => toggleMessage(index)}>
            <Typography className={'fs-14 bold'} color={'textPrimary'}>{message.title}</Typography>
            {phone && <Box className={`${classes.collapseButton} grey ml-15`}>
              <img src={'/img/arrow.svg'} className={clsx({open: isMessageOpen(index)})}/>
            </Box>}
          </Box>
          <Collapse in={isMessageOpen(index)}>
            <Typography className={`fs-14 ${classes.messageSubtitle}`} color={'textPrimary'}>{message.subtitle}</Typography>
          </Collapse>
        </div>
      </Box>
    </div>)}
    <Box className={classes.info}>
      <Box display={'flex'} className={'pointer'} onClick={() => setOriginalOpen(prev => !prev)}>
        <Box className={classes.collapseButton}>
          <img src={'/img/arrow.svg'} className={clsx({open: originalOpen})}/>
        </Box>
        <Box mt={'7px'} ml={2}>
          <Typography variant={'h3'} color={'textPrimary'} className={classes.medium}>Medicare Supplement ("Medigap") and Prescription Drug Plans (PDP)</Typography>
        </Box>
      </Box>
      <Collapse in={originalOpen}>
        <Box display={'flex'} mt={'13px'}>
          <Typography variant={'body1'} color={'textSecondary'} className={'medium'}>
            Medicare Supplement (or "Medigap") plans are private insurance plans that charge a monthly premium to help cover out-of-pocket costs (or “gaps”) you are responsible for paying under Original Medicare (such as deductibles, copays and coinsurance expenses). There are 8 different federally-standardized plan types (A, B, D, G, K, L, M, N) available to newly eligible Medicare beneficiaries. Most beneficiaries choose to purchase Medigap plans that provide the most comprehensive coverage - these plans typically charge monthly premiums of $90 - $300+.<br/><br/>

            Prescription Drug Plans (PDPs) are standalone prescription drug plans offered by private insurance companies. PDPs can be purchased if you have Original Medicare, or Original Medicare with a Medigap plan.
          </Typography>
        </Box>
      </Collapse>
      <Divider className={classes.divider} />
      <Box display={'flex'} mt={'20px'} className={'pointer'} onClick={() => setAdvantageOpen(prev => !prev)}>
        <Box className={classes.collapseButton}>
          <img src={'/img/arrow.svg'} className={clsx({open: advantageOpen})}/>
        </Box>
        <Box mt={'7px'} ml={2}>
          <Typography variant={'h3'} color={'textPrimary'} className={classes.medium}>Medicare Advantage</Typography>
        </Box>
      </Box>
      <Collapse in={advantageOpen}>
        <Box mt={'13px'}>
          <Typography variant={'body1'} color={'textSecondary'} className={'medium'}>
            Medicare Advantage (or “Part C”) plans, are an “all-in-one” bundled private alternative to Original Medicare. You will typically pay a low or $0 monthly premium, and pay for healthcare services as “as-you-go”. Medicare Advantage HMO plans require you to use doctors and hospitals within a network to receive care, whereas PPO plans will cover out-of-network providers (but at a higher cost).  All plans have a maximum annual cap on out-of-pocket costs.<br/><br/>

            Medicare Advantage plans provide the same coverage as Original Medicare, and often bundle prescription drug coverage as well as extra benefits such as vision, dental and hearing services.
          </Typography>
        </Box>
      </Collapse>
    </Box>

    {!phone && <DesktopContent selected={selected}
                               onBackClick={() => {
                                 props.history.goBack();
                               }}
                               onSubmit={onSubmit}
    />}
    {phone && <MobileContent selected={selected}
                             onBackClick={() => {
                               props.history.goBack();
                             }}
                             onSubmit={onSubmit}
    />}

  </Box>
}

const MedigapButton = withStyles({
  contained: {
    borderRadius: 50,
    padding: '4px 30px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)

const AdvantageButton = withStyles({
  contained: {
    borderRadius: 50,
    padding: '4px 10px',
  },
  label: {
    color: 'white',
    fontSize: 16,
    lineHeight: '22px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)

const useDesktopStyles = makeStyles({
  title: {
    lineHeight: '20px'
  },
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
  centered: {
    textAlign: 'center',
    paddingTop: 3
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
    marginTop: 30,
    fontSize: '16px',
    textDecoration: 'none',
  },
  divider: {
    color: '#EDEDED',
    width: '100%',
    marginBottom: 10
  },
  totalLine: {
    '& p': {
      fontWeight: 700,
      fontSize: 16
    },
    backgroundColor: '#C8E8EB',
    borderRadius: 10,
  },
  denseLine: {
    padding: '8px 8px 8px 30px!important'
  },
  whiteLine: {
    backgroundColor: 'white'
  },
  greenLine: {
    backgroundColor: 'rgba(2, 144, 148, 0.05)',
    borderRadius: 10,
  },
  plus: {
    position: 'absolute',
    right: -5,
    top: 21
  },
  selected: {
    position: 'absolute',
    top: -5,
    left: -5,
    right: -7,
    height: 1383,
    border: '1px solid #5ECB9E',
    background: 'rgba(94, 203, 158, 0.05)',
    borderRadius: 10
  },
  original: {
    position: 'absolute',
    top: 83,
    left: 3,
    right: 3,
    height: 1238,
    background: 'rgba(164, 180, 185, 0.15)',
  }
});

interface ContentProps {
  selected: number,
  onSubmit: (value: 'MA' | 'MG') => void,
  onBackClick: () => void
}

const DesktopContent = ({selected, onBackClick, onSubmit}: ContentProps) => {
  const classes = useDesktopStyles();
  const [open, setOpen] = React.useState(false);
  const [state] = React.useContext(QuoteContext);

  return <>
    <MAMOEmailModal open={open} onClose={() => setOpen(false)} input={{
      certainDoctors: state?.QUIZ_2?.certainDoctors.value,
      drugsCoverage: state?.QUIZ_2?.drugsCoverage.value,
      extraBenefits: state?.QUIZ_2?.extraBenefits.value,
      monthlyBudget: state?.QUIZ_2?.monthlyBudget.value,
      prepay: state?.QUIZ_2?.prepay.value,
      receiveMedicaid: state?.QUIZ_2?.receiveMedicaid.value
    }} />
    <Box display={'flex'} flex={1} mt={'21px'} flexDirection={'column'} width={835}>
      <Box display={'flex'} padding={'17px 8px 17px 30px'}>
        <Box display={'flex'} flex={3} alignItems={'flex-end'} justifyContent={'flex-start'}>
          {/*<Box ml={'-30px'}>
            <RoundedButton variant={"contained"}
                           classes={{
                             contained: 'ph-16 pv-5'
                           }}
                           onClick={() => setOpen(true)}>
              <img src={'/img/envelope.svg'} className={'mr-10'} />
              Email me this page</RoundedButton>
          </Box>*/}
        </Box>
        <Box display={'flex'} flex={2} position={'relative'} flexDirection={'column'} alignItems={'center'}>
          {<div className={classes.original} />}
          <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} position={'relative'}>
            <Typography variant={"h4"} color={'textSecondary'} className={clsx(classes.centered, classes.bold, classes.title)}>Original<br />Medicare</Typography>
            <AscendTooltip placement={'top'} arrow title={<div style={{textAlign: 'left'}}>
              <p style={{marginTop: 0, color: colors.text.primary}}>Traditionally, Medicare coverage consists of two parts. These are known as ‘Original Medicare’:</p>
              <ul>
                <li>Medicare Part A (Hospital Insurance): which covers inpatient hospital care, surgery, lab tests, short-term skilled nursing facilities, home health care as well as hospice care</li>
                <li>Medicare Part B (Medical Insurance): which covers medically necessary doctor and outpatient medical services and supplies.  Part B also covers ambulance services, durable medical equipment, hospice care, home health care and certain preventive services and screenings</li>
              </ul>
            </div>} className={'ml-8 mt-20'}><img src={'/img/info.svg'} /></AscendTooltip>
          </Box>
          <Box height={'6px'} bgcolor={'#778E95'} width={'90%'}/>
        </Box>
        <Box display={'flex'} flex={2} position={'relative'} flexDirection={'column'} alignItems={'center'}>
          {selected === PlanType.MG && <div className={classes.selected} />}
          <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} zIndex={5}>
            <Typography variant={"h4"} color={'textPrimary'} className={clsx(classes.bold, classes.title)}>
              Original Medicare <br />
              + Medigap <AscendTooltip placement={'top'} arrow title={'Medicare Supplement plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.  By law, these plans are standardized. 8 different plans (A, B, D, G, K, L, M, and N) are available to newly eligible for Medicare beneficiaries.  '} className={'ml-8'}><img src={'/img/info-green1.svg'} /></AscendTooltip><br />
              + Drug Plan <AscendTooltip placement={'top'} arrow title={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies. PDPs can be purchased if you have Original Medicare, or Original Medicare with a Medigap plan.'} className={'ml-8'}><img src={'/img/info-green1.svg'} /></AscendTooltip></Typography>
          </Box>
          <Box height={'6px'} bgcolor={'#029094'} width={'90%'}/>
        </Box>
        <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'} position={'relative'} flexDirection={'column'}>
          {selected !== PlanType.MG && <div className={classes.selected} />}
          <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} zIndex={5}>
            <Typography variant={"h4"} color={'textPrimary'} className={clsx(classes.bold, classes.title, classes.centered)}>Medicare<br />Advantage</Typography>
            <AscendTooltip placement={'top'} arrow title={'Medicare Advantage (Part C), is a “all-in-one” bundled alternative to Original Medicare. Medicare Advantage plans provide coverage for everything covered under Original Medicare, and often include prescription drug coverage (known as "MA-PD" plans). Medicare Advantage plans can also offer additional benefits, such as coverage for vision, dental and hearing services.'} className={'ml-8'}><img src={'/img/info-green1.svg'} /></AscendTooltip>
          </Box>
          <Box height={'6px'} bgcolor={'#029094'} width={'90%'}/>
        </Box>
      </Box>
      <TableLine title={<strong>Coverage</strong>}
                 className={clsx('fs-16', 'bold', classes.greenLine)}
                 value1={<></>}
                 value2={<></>}
                 value3={<></>}
      />
      <TableLine title={'Part A (Hospital insurance)'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Part A (Hospital insurance) coverage includes:</p>
                   <ul>
                     <li>Inpatient care in a hospital</li>
                     <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                     <li>Hospice care</li>
                     <li>Home health care</li>
                     <li>Inpatient care in a religious non-medical health care institution</li>
                   </ul>
                 </div>}
                 value1={true} value2={true} value3={true} />
      <TableLine title={'Part B (Medical insurance)'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Part B (Medical insurance) coverage includes:</p>
                   <ul>
                     <li>Medically necessary doctor’s services</li>
                     <li>Outpatient care</li>
                     <li>Home health services</li>
                     <li>Durable medical equipment</li>
                     <li>Mental health services</li>
                     <li>Other medical services</li>
                     <li>May also cover preventive services</li>
                   </ul>
                 </div>}
                 className={classes.whiteLine}
                 value1={true} value2={true} value3={true} />
      <TableLine title={'Part D (Prescription drug coverage)'}
                 tooltip={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often combined with Medicare Advantage plans.'}
                 className={classes.denseLine}
                 value1={false}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                            <Icon value={true} />
                            <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>With purchase of<br />Prescription Drug Plan</Typography>
                         </Box>}
                 value3={true} />
      <TableLine title={'Limit on out-of-pocket costs'}
                 tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                 value1={false}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Icon value={false} />
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Some Medigap plans may<br />cover almost all costs</Typography>
                 </Box>}
                 value3={true} className={classes.whiteLine} />
      <TableLine title={<>
                 <TooltipedText text={'Extra benefits'}
                                tooltipText={'Many Medicare Advantage plans offer additional vision, hearing and dental beenfits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'}
                                tooltipTitle={'Extra benefits — what is covered?'} /> like vision, hearing, dental, and more</>}
                 tooltip={'Additional benefits offered at no extra cost by your Medicare plan, including hearing, vision and dental benefits.'}
                 value1={false} value2={false} value3={true} />
      <TableLine title={<TooltipedText text={'Doctor coverage'}
                                       tooltipText={'Networks include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'}
                                       tooltipTitle={'Doctor coverage and networks'} />}
                 tooltip={'Your ability to see any doctor may be dependent on the type of Medicare plan you choose.'}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                   Any doctor or<br />hospital that takes<br />Medicare
                 </Typography>}
                 value2={<Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                   Any doctor or<br />hospital that takes<br />Medicare
                 </Typography>}
                 value3={<Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                   <strong>HMO:</strong> Covers in<br />network doctors<br />
                   <strong>PPO:</strong> Covers<br />out-of-network
                 </Typography>} />
      <TableLine title={'Specialized plans for people on Medicaid'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Medicaid is a state-administered public health insurance program that provides health coverage to low-income families and individuals. Medicaid is different to Medicare (which is primarily for people over 65).  People who qualify for both Medicaid and Medicare are known as "dual-eligibles".  Dual-eligibles are eligible for certain Medicare Advantage plans which are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare.  If you are eligible for Medicaid, you may receive premium and cost-sharing assistance for your Medicare costs.</p>
                 </div>}
                 value1={false} value2={false} value3={true} />
    </Box>

    <Box display={'flex'} flex={1} flexDirection={'column'} width={'100%'} mt={2}>
      <TableLine title={<strong>Key out-of-pocket costs</strong>}
                 className={clsx('fs-16', 'bold', classes.greenLine)}
                 value1={<></>}
                 value2={<></>}
                 value3={<></>}
      />
      <TableLine title={<TooltipedText text={'Medical costs'}
                                       tooltipText={<div>
                                         <Typography className={'fs-12 mb-15'}><strong>Medicare Advantage</strong> plans provide you the option to ‘pay as you go’ for your healthcare. Many plans have a $0 monthly premium and include plan-subsidized copays/coinsurances when you use services (such as doctor and hospital visits). If you don’t use healthcare services often, your costs will often be lower.</Typography>
                                         <Typography className={'fs-12'}>With <strong>Medigap</strong> plans, you ‘prepay’ for healthcare costs via monthly premiums. Medigap plans allow for more consistent monthly costs and you typically don’t have to worry about paying additional out-of-pocket costs, as these are covered by your plan premiums.</Typography>
                                       </div>}
                                       tooltipTitle={'Paying for healthcare services upfront vs monthly premiums'} />}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Medical costs include the copay for doctor costs in an outpatient setting (including visits to primary care physicians and specialists), and is typically subject to a deductible.</p>
                 </div>}
                 className={classes.denseLine}
                 value1={<Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>20% coinsurance</Typography>}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
                 </Box>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0-$50 copay per<br />doctor visit</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Plans generally have copays<br />for other medical services</Typography>
                 </Box>}
      />
      <TableLine title={'Hospital costs'}
                 tooltip={<div style={{textAlign: 'left'}}>
                   <p style={{marginTop: 0, color: colors.text.primary}}>Hospital costs include the daily copay for inpatient care in a hospital setting, and is typically subject to a deductible.</p>
                 </div>}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$1,485 copay</Typography>
                   <Typography variant={'h5'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Covers first 60 days</Typography>
                 </Box>}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
                 </Box>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$200-$500<br />copay per day</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>First 3-7 days</Typography>
                 </Box>}
      />
      <TableLine title={'Extended Hospital Stay'}
                 tooltip={'Costs for a hospital stay beyond the first period covered by your plan.'}
                 className={classes.denseLine}
                 value1={<Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Days 61-90: $371/day<br />
                   Days 91+: Up to 100%<br />of cost</Typography>}
                 value2={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
                 </Box>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>
                     <strong>Days 7-90:</strong> $0/day<br />
                     <strong>Days 91+:</strong> Varies by plan
                   </Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Some plans have unlimited<br />coverage after 90 days</Typography>
                 </Box>}
      />
    </Box>

    <Box display={'flex'} flex={1} flexDirection={'column'} width={'100%'} mt={2}>
      <TableLine title={<strong>Monthly premiums</strong>}
                 className={clsx('fs-16', 'bold', classes.greenLine)}
                 value1={<></>}
                 value2={<></>}
                 value3={<></>}
      />
      <TableLine title={'Medicare Part B premium'}
                 tooltip={'Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your tax return from two years ago), you may pay an extra charge on top of the regular Part B premium.'}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
                 value3={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>}
      />
      <TableLine title={'Prescription drug plan (PDP)'}
                 tooltip={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies.'}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Avg. $42</Typography>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Included in 89%<br />of plans</Typography>
                 </Box>}
      />
      <TableLine title={'Medicare Supplement (Medigap)'}
                 tooltip={'Medigap plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.'}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$90 - $300+</Typography>}
                 value3={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered)}>n.a.</Typography>}
      />
      <TableLine title={'Medicare Advantage'}
                 tooltip={'Medicare Advantage (Part C) is an “all-in-one” bundled alternative to Original Medicare, which often include prescription drug coverage and extra benefits (such as vision, dental and hearing).'}
                 className={clsx(classes.whiteLine, classes.denseLine)}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>-</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>n.a.</Typography>}
                 value3={<Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                   <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Typically $0</Typography>
                   <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>54% of plans have<br/>$0 premium</Typography>
                 </Box>}
      />
      <TableLine title={'Total monthly premiums'}
                 opaque
                 className={clsx(classes.totalLine)}
                 value1={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.bold)}>$148.50</Typography>}
                 value2={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.bold)}>$270.00+</Typography>}
                 value3={<Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.bold)}>$148.50+</Typography>}
      />
    </Box>
    <Box display={'flex'} padding={'17px 8px 17px 30px'} mt={'23px'} width={'100%'}>
      <Box display={'flex'} flex={3}>
        <Box ml={'-30px'}>
          <AscendButton tabIndex={5} variant={'contained'} onClick={onBackClick} color={'secondary'}>Back</AscendButton>
        </Box>
      </Box>
      <Box display={'flex'} flex={4} justifyContent={'flex-end'} alignItems={'center'}>
        <MedigapButton tabIndex={4} className={'gtm-step-event'} variant={'contained'} onClick={() => onSubmit('MG')}>Get Medigap<br />quotes</MedigapButton>
      </Box>
      <Box display={'flex'} flex={2} mx={'5px'} justifyContent={'center'} alignItems={'center'}>
        <div className={'w-10'}/>
        <AdvantageButton tabIndex={4} className={'gtm-step-event'} variant={'contained'} onClick={() => onSubmit('MA')}>Get Medicare<br />Advantage quotes</AdvantageButton>
      </Box>
    </Box>
  </>
};

const TooltipedText = withStyles({
  text: {
    color: colors.custom.green.variant2,
    borderBottom: '1px dashed ' + colors.custom.green.variant2,
    cursor: 'default'
  },
  lightIcon: {
    height: 17,
    width: 16,
    marginRight: 7,
    maxWidth: 'inherit',
    marginTop: 2,
  }
})((props: {text: string, tooltipText: string | JSX.Element, tooltipTitle: string, classes: any}) => {
  return <AscendTooltip placement={'top'} variant={'white'} arrow
                        title={<div>
                          <Box display={'flex'} mb={'12px'}>
                            <img className={props.classes.lightIcon} src={'/img/light.png'} />
                            <Typography color={'textPrimary'} className={'fs-14 medium underline'}>{props.tooltipTitle}</Typography>
                          </Box>
                          {typeof props.tooltipText === 'string' && <Typography color={'textPrimary'} className={'fs-12'}>{props.tooltipText}</Typography>}
                          {typeof props.tooltipText !== 'string' && <>{props.tooltipText}</>}
                        </div>} className={'w-180'}><span className={props.classes.text}>{props.text}</span></AscendTooltip>
})

const useMobileStyles = makeStyles({
  medium: {
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
  tableWrapper: {
  },
  lastGreen: {
    '&>div:last-child': {
      backgroundColor: 'rgba(94, 203, 158, 0.1)'
    }
  },
  centered: {
    textAlign: 'center',
    paddingTop: 1
  },
  link: {
    color: colors.custom.green.variant1,
    cursor: 'pointer',
    fontWeight: 500,
    display: 'block',
    fontSize: '16px',
    textDecoration: 'none',
    textAlign: 'center'
  },
  totalLine: {
    backgroundColor: '#C8E8EB',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  greenLine: {
    backgroundColor: 'rgba(2, 144, 148, 0.05)',
    padding: '16px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  denseLine: {
    padding: '7px 8px 7px 30px!important'
  },
  whiteLine: {
    backgroundColor: 'white'
  },
  line: {
    minHeight: 54,
    display: 'flex',
    justifyContent: 'space-between',
    '& .lineValueOriginal': {
      background: 'rgba(233, 236, 238, 1)'
    },
    '& .lineValueOriginalWhite': {
      background: 'rgba(242, 244, 245, 1)'
    },
    '& > div:first-child': {
      paddingLeft: 16,
      paddingTop: 9,
      paddingBottom: 9,
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      '& img': {
        minWidth: 16,
        minHeight: 16,
      }
    },
    '& > div:last-child': {
      width: '30%',
      minWidth: 132,
      paddingTop: 9,
      paddingBottom: 9,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 10,
    }
  },
  subTitle: {
    fontSize: 12,
    lineHeight: '15px',
  },
  collapseButton: {
    minWidth: 26,
    width: 26,
    height: 26,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(164, 180, 185, 0.1)',
    borderRadius: 15,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
    '& img': {
      transition: 'all 0.3s',
      transform: 'rotate(0)',
    },
    '& img.open': {
      transform: 'rotate(-180deg) !important',
    },
  },
  wrapper: {
    position: 'relative',
  },
  selected: {
    position: 'absolute',
    width: '30%',
    minWidth: 132,
    right: 0,
    top: 0,
    bottom: 0,
    border: '1px solid #5ECB9E',
    background: 'rgba(94, 203, 158, 0.05)',
    borderRadius: 5
  }
});

const MobileContent = ({selected, onBackClick, onSubmit}: ContentProps) => {
  const classes = useMobileStyles();
  const [isOriginalOpen, setIsOriginalOpen] = React.useState(false);
  const [isMedigapOpen, setIsMedigapOpen] = React.useState(selected === PlanType.MG);
  const [isMedicareOpen, setIsMedicareOpen] = React.useState(selected !== PlanType.MG);

  return <>
    {/*Start Original medicare*/}
    <Box display={'flex'} flex={1} mt={'40px'} flexDirection={'column'} width={'100%'} className={classes.tableWrapper}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={'6px'} pb={'6px'} borderBottom={'6px solid #778E95'}>
        <Box display={'flex'}>
          <Typography className={'bold fs-16'} color={'textSecondary'}>Original Medicare</Typography>
          <AscendTooltip placement={'top'} className={'ml-8'} arrow
                         title={<div style={{textAlign: 'left'}}>
                           <p style={{marginTop: 0, color: colors.text.primary}}>Traditionally, Medicare coverage consists of two parts. These are known as ‘Original Medicare’:</p>
                           <ul>
                             <li>Medicare Part A (Hospital Insurance): which covers inpatient hospital care, surgery, lab tests, short-term skilled nursing facilities, home health care as well as hospice care</li>
                             <li>Medicare Part B (Medical Insurance): which covers medically necessary doctor and outpatient medical services and supplies.  Part B also covers ambulance services, durable medical equipment, hospice care, home health care and certain preventive services and screenings</li>
                           </ul>
                         </div>}><img src={'/img/info.svg'} /></AscendTooltip>
        </Box>
        <Box className={`${classes.collapseButton} grey ml-15`} onClick={() => setIsOriginalOpen(!isOriginalOpen)}>
          <img src={'/img/arrow.svg'} className={clsx({open: isOriginalOpen})}/>
        </Box>
      </Box>
      <Collapse in={isOriginalOpen}>
        <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Coverage</Typography>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={<div style={{textAlign: 'left'}}>
                               <p style={{marginTop: 0, color: colors.text.primary}}>Part A (Hospital insurance) coverage includes:</p>
                               <ul>
                                 <li>Inpatient care in a hospital</li>
                                 <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                                 <li>Hospice care</li>
                                 <li>Home health care</li>
                                 <li>Inpatient care in a religious non-medical health care institution</li>
                               </ul>
                             </div>}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Part A (Hospital insurance)</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <GreyIcon value={true} />
          </Box>
        </Box>

        <Box className={clsx(classes.line, classes.whiteLine)}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={<div style={{textAlign: 'left'}}>
                               <p style={{marginTop: 0, color: colors.text.primary}}>Part B (Medical insurance) coverage includes:</p>
                               <ul>
                                 <li>Medically necessary doctor’s services</li>
                                 <li>Outpatient care</li>
                                 <li>Home health services</li>
                                 <li>Durable medical equipment</li>
                                 <li>Mental health services</li>
                                 <li>Other medical services</li>
                                 <li>May also cover preventive services</li>
                               </ul>
                             </div>}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Part B (Medical insurance)</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginalWhite'}>
            <GreyIcon value={true} />
          </Box>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often combined with Medicare Advantage plans.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Part D (Prescription drug coverage)</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <GreyIcon value={false} />
          </Box>
        </Box>
        <Box className={clsx(classes.line, classes.whiteLine)}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Limit on out-of-pocket costs</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginalWhite'}>
            <GreyIcon value={false} />
          </Box>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Additional benefits offered at no extra cost by your Medicare plan, including hearing, vision and dental benefits.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Extra benefits like vision, hearing, dental, and more</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <GreyIcon value={false} />
          </Box>
        </Box>
        <Box className={clsx(classes.line, classes.whiteLine)}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Your ability to see any doctor may be dependent on the type of Medicare plan you choose.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Doctor coverage</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginalWhite'}>
            <Typography variant={'body2'} color={'textSecondary'} className={classes.centered}>
              Any doctor or<br />hospital that takes<br />Medicare
            </Typography>
          </Box>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={<div style={{textAlign: 'left'}}>
                               <p style={{marginTop: 0, color: colors.text.primary}}>Medicaid is a state-administered public health insurance program that provides health coverage to low-income families and individuals. Medicaid is different to Medicare (which is primarily for people over 65).  People who qualify for both Medicaid and Medicare are known as "dual-eligibles".  Dual-eligibles are eligible for certain Medicare Advantage plans which are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare.  If you are eligible for Medicaid, you may receive premium and cost-sharing assistance for your Medicare costs.</p>
                             </div>}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Specialized plans for people on Medicaid</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <GreyIcon value={false} />
          </Box>
        </Box>

        <div className={'h-10'} />
        <Box className={classes.greenLine}>
          <Typography className={'bold fs-16'} color={'textPrimary'}>Key out-of-pocket costs</Typography>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={<div style={{textAlign: 'left'}}>
                               <p style={{marginTop: 0, color: colors.text.primary}}>Medical costs include those costs associated with Medicare Part B (Medical insurance) and include:</p>
                               <ul>
                                 <li>Medically necessary doctor’s services</li>
                                 <li>Outpatient care</li>
                                 <li>Home health services</li>
                                 <li>Durable medical equipment</li>
                                 <li>Mental health services</li>
                                 <li>Other medical services</li>
                                 <li>May also cover preventive services</li>
                               </ul>
                             </div>}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Medical costs</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <Typography variant={'body2'} color={'textSecondary'} className={'medium text-align-centered'}>20% <br /> coinsurance</Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.line, classes.whiteLine)}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={<div style={{textAlign: 'left'}}>
                               <p style={{marginTop: 0, color: colors.text.primary}}>Hospital costs include those costs assocated with Medicare Part A (Hospital insurance). This coverage is typically subject to a deductible, and includes:</p>
                               <ul>
                                 <li>Inpatient care in a hospital</li>
                                 <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                                 <li>Hospice care</li>
                                 <li>Home health care</li>
                                 <li>Inpatient care in a religious non-medical health care institution</li>
                               </ul>
                             </div>}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Hospital costs</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginalWhite'}>
            <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
              <Typography variant={'h5'} color={'textSecondary'} className={'medium text-align-centered'}>$1,485 copay</Typography>
              <Typography variant={'h5'} color={'textSecondary'} className={' text-align-centered'}>Covers first 60 days</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Costs for a hospital stay beyond the first period covered by your plan.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Extended Hospital Stay</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <Typography variant={'body2'} color={'textSecondary'} className={'medium text-align-centered'}><strong>Days 61-90:</strong><br />$371/day<br />
              <strong>Days 91+:</strong> Up to <br />100% of cost</Typography>
          </Box>
        </Box>


        <div className={'h-10'} />
        <Box className={classes.greenLine}>
          <Typography className={'bold fs-16'} color={'textPrimary'}>Monthly premiums</Typography>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your last tax return), you may pay an extra charge on top of the regular Part B premium.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Medicare Part B premium</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <Typography variant={'body2'} color={'textSecondary'} className={'medium text-align-centered'}>$148.50</Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.line, classes.whiteLine)}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Prescription drug plan (PDP)</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginalWhite'}>
            <Typography variant={'body2'} color={'textSecondary'} className={'medium text-align-centered'}>-</Typography>
          </Box>
        </Box>
        <Box className={classes.line}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Medigap plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Medicare Supplement (Medigap)</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginal'}>
            <Typography variant={'body2'} color={'textSecondary'} className={'medium text-align-centered'}>-</Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.line, classes.whiteLine)}>
          <Box display={'flex'}>
            <Box mr={1}>
              <AscendTooltip placement={'top'} arrow
                             title={'Medicare Advantage (Part C) is an “all-in-one” bundled alternative to Original Medicare, which often include prescription drug coverage and extra benefits (such as vision, dental and hearing).'}
              ><img src={'/img/info.svg'} /></AscendTooltip>
            </Box>
            <Box>
              <Typography variant={"body2"} color={'textPrimary'}>Medicare Advantage</Typography>
            </Box>
          </Box>
          <Box className={'lineValueOriginalWhite'}>
            <Typography variant={'body2'} color={'textSecondary'} className={'medium text-align-centered'}>-</Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.line, classes.totalLine)}>
          <div>
            <Typography className={'fs-16 bold'} color={'textPrimary'}>Total monthly premium</Typography>
          </div>
          <div>
            <Typography variant={'body2'} color={'textPrimary'} className={'medium text-align-centered'}>$148.50</Typography>
          </div>
        </Box>
      </Collapse>
    </Box>
    {/*End Original medicare*/}

    {/*Start Original medicare + Medigap*/}
    <Box display={'flex'} flex={1} mt={3} flexDirection={'column'} width={'100%'} className={classes.tableWrapper}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'} mb={'6px'} pb={'6px'}
           borderBottom={selected === PlanType.MG ? '6px solid #029094' : '6px solid #778E95'}>
        <Box display={'flex'} alignItems={'flex-end'}>
          <Typography className={'bold fs-16'} color={selected === PlanType.MG ? 'textPrimary' : 'textSecondary'}>Original Medicare<br />
            + Medigap<br />
            + Prescription Drug Plan</Typography>
          <AscendTooltip placement={'top'} className={'ml-8'} arrow title={'Medicare Supplement plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.  By law, these plans are standardized. 8 different plans (A, B, D, G, K, L, M, and N) are available to newly eligible for Medicare beneficiaries.  '}><img className={'mb-5'} src={'/img/info.svg'} /></AscendTooltip>
        </Box>
        <Box className={`${classes.collapseButton} grey ml-15`} onClick={() => setIsMedigapOpen(!isMedigapOpen)}>
          <img src={'/img/arrow.svg'} className={clsx({open: isMedigapOpen})}/>
        </Box>
      </Box>
      <Collapse in={isMedigapOpen}>
        <div className={classes.wrapper}>
          {selected === PlanType.MG && <div className={classes.selected} />}
          <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Coverage</Typography>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Part A (Hospital insurance) coverage includes:</p>
                                 <ul>
                                   <li>Inpatient care in a hospital</li>
                                   <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                                   <li>Hospice care</li>
                                   <li>Home health care</li>
                                   <li>Inpatient care in a religious non-medical health care institution</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Part A (Hospital insurance)</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={true} />
            </Box>
          </Box>

          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Part B (Medical insurance) coverage includes:</p>
                                 <ul>
                                   <li>Medically necessary doctor’s services</li>
                                   <li>Outpatient care</li>
                                   <li>Home health services</li>
                                   <li>Durable medical equipment</li>
                                   <li>Mental health services</li>
                                   <li>Other medical services</li>
                                   <li>May also cover preventive services</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Part B (Medical insurance)</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Icon value={true} />
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often combined with Medicare Advantage plans.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Part D (Prescription drug coverage)</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Icon value={true} />
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>With purchase of<br />Prescription<br />Drug Plan</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Limit on out-of-pocket costs</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Icon value={false} />
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Some Medigap plans<br />may cover almost<br />all costs</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Additional benefits offered at no extra cost by your Medicare plan, including hearing, vision and dental benefits.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Extra benefits like vision, hearing, dental, and more</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={false} />
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Your ability to see any doctor may be dependent on the type of Medicare plan you choose.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Doctor coverage</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Typography variant={'body2'} color={'textSecondary'} className={classes.centered}>
                Any doctor or<br />hospital that takes<br />Medicare
              </Typography>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Medicaid is a state-administered public health insurance program that provides health coverage to low-income families and individuals. Medicaid is different to Medicare (which is primarily for people over 65).  People who qualify for both Medicaid and Medicare are known as "dual-eligibles".  Dual-eligibles are eligible for certain Medicare Advantage plans which are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare.  If you are eligible for Medicaid, you may receive premium and cost-sharing assistance for your Medicare costs.</p>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Specialized plans for people on Medicaid</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={false} />
            </Box>
          </Box>

          <div className={'h-10'} />
          <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Key out-of-pocket costs</Typography>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Medical costs include those costs associated with Medicare Part B (Medical insurance) and include:</p>
                                 <ul>
                                   <li>Medically necessary doctor’s services</li>
                                   <li>Outpatient care</li>
                                   <li>Home health services</li>
                                   <li>Durable medical equipment</li>
                                   <li>Mental health services</li>
                                   <li>Other medical services</li>
                                   <li>May also cover preventive services</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medical costs</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by<br />Medigap plan</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Hospital costs include those costs assocated with Medicare Part A (Hospital insurance). This coverage is typically subject to a deductible, and includes:</p>
                                 <ul>
                                   <li>Inpatient care in a hospital</li>
                                   <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                                   <li>Hospice care</li>
                                   <li>Home health care</li>
                                   <li>Inpatient care in a religious non-medical health care institution</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Hospital costs</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Costs for a hospital stay beyond the first period covered by your plan.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Extended Hospital Stay</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Covered by Medigap plan</Typography>
              </Box>
            </Box>
          </Box>


          <div className={'h-10'} />
          <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Monthly premiums</Typography>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your last tax return), you may pay an extra charge on top of the regular Part B premium.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medicare Part B premium</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Prescription drug plan (PDP)</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Avg. $42</Typography>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Medigap plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medicare Supplement (Medigap)</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$90 - $300+</Typography>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Medicare Advantage (Part C) is an “all-in-one” bundled alternative to Original Medicare, which often include prescription drug coverage and extra benefits (such as vision, dental and hearing).'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medicare Advantage</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>n.a.</Typography>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.totalLine)}>
            <div>
              <Typography className={'fs-16 bold'} color={'textPrimary'}>Total monthly premium</Typography>
            </div>
            <div>
              <Typography variant={'body2'} color={'textPrimary'} className={'medium text-align-centered'}>$270.00+</Typography>
            </div>
          </Box>
        </div>
        <Box mt={'20px'}>
          <AscendButton tabIndex={4} className={'gtm-step-event fill-width'}  variant={'contained'} onClick={() => onSubmit('MG')}>Get Medigap quotes</AscendButton>
        </Box>
      </Collapse>
    </Box>
    {/*End Original medicare + Medigap*/}

    {/*Start Medicare Advantage*/}
    <Box display={'flex'} flex={1} mt={3} flexDirection={'column'} width={'100%'} className={classes.tableWrapper}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'} mb={'6px'} pb={'6px'}
           borderBottom={selected !== PlanType.MG ? '6px solid #029094' : '6px solid #778E95'}>
        <Box display={'flex'} alignItems={'flex-end'}>
          <Typography className={'bold fs-16'} color={selected !== PlanType.MG ? 'textPrimary' : 'textSecondary'}>Medicare Advantage</Typography>
          <AscendTooltip placement={'top'} className={'ml-8'} arrow title={'Medicare Advantage (Part C), is a “all-in-one” bundled alternative to Original Medicare. Medicare Advantage plans provide coverage for everything covered under Original Medicare, and often include prescription drug coverage (known as "MA-PD" plans). Medicare Advantage plans can also offer additional benefits, such as coverage for vision, dental and hearing services.'}><img className={'mb-5'} src={'/img/info.svg'} /></AscendTooltip>
        </Box>
        <Box className={`${classes.collapseButton} grey ml-15`} onClick={() => setIsMedicareOpen(!isMedicareOpen)}>
          <img src={'/img/arrow.svg'} className={clsx({open: isMedicareOpen})}/>
        </Box>
      </Box>
      <Collapse in={isMedicareOpen}>
        <div className={classes.wrapper}>
          {selected !== PlanType.MG && <div className={classes.selected} />}
          <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Coverage</Typography>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Part A (Hospital insurance) coverage includes:</p>
                                 <ul>
                                   <li>Inpatient care in a hospital</li>
                                   <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                                   <li>Hospice care</li>
                                   <li>Home health care</li>
                                   <li>Inpatient care in a religious non-medical health care institution</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Part A (Hospital insurance)</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={true} />
            </Box>
          </Box>

          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Part B (Medical insurance) coverage includes:</p>
                                 <ul>
                                   <li>Medically necessary doctor’s services</li>
                                   <li>Outpatient care</li>
                                   <li>Home health services</li>
                                   <li>Durable medical equipment</li>
                                   <li>Mental health services</li>
                                   <li>Other medical services</li>
                                   <li>May also cover preventive services</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Part B (Medical insurance)</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Icon value={true} />
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Part D (Prescrtiption drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often combined with Medicare Advantage plans.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Part D (Prescription drug coverage)</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={true} />
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Limit on out-of-pocket costs</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Icon value={true} />
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Additional benefits offered at no extra cost by your Medicare plan, including hearing, vision and dental benefits.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Extra benefits like vision, hearing, dental, and more</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={true} />
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Your ability to see any doctor may be dependent on the type of Medicare plan you choose.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Doctor coverage</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Typography variant={'body2'} color={'textPrimary'} className={classes.centered}>
                <strong>HMO:</strong> Covers in<br />network doctors<br />
                <strong>PPO:</strong> Covers<br />out-of-network
              </Typography>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Medicaid is a state-administered public health insurance program that provides health coverage to low-income families and individuals. Medicaid is different to Medicare (which is primarily for people over 65).  People who qualify for both Medicaid and Medicare are known as "dual-eligibles".  Dual-eligibles are eligible for certain Medicare Advantage plans which are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare.  If you are eligible for Medicaid, you may receive premium and cost-sharing assistance for your Medicare costs.</p>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Specialized plans for people on Medicaid</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Icon value={true} />
            </Box>
          </Box>

          <div className={'h-10'} />
          <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Key out-of-pocket costs</Typography>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Medical costs include those costs associated with Medicare Part B (Medical insurance) and include:</p>
                                 <ul>
                                   <li>Medically necessary doctor’s services</li>
                                   <li>Outpatient care</li>
                                   <li>Home health services</li>
                                   <li>Durable medical equipment</li>
                                   <li>Mental health services</li>
                                   <li>Other medical services</li>
                                   <li>May also cover preventive services</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medical costs</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0-$50 copay per<br />doctor visit</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Plans generally have<br />copays for other<br />medical services</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={<div style={{textAlign: 'left'}}>
                                 <p style={{marginTop: 0, color: colors.text.primary}}>Hospital costs include those costs assocated with Medicare Part A (Hospital insurance). This coverage is typically subject to a deductible, and includes:</p>
                                 <ul>
                                   <li>Inpatient care in a hospital</li>
                                   <li>Inpatient care in a skilled nursing facility (not custodial or long-term care)</li>
                                   <li>Hospice care</li>
                                   <li>Home health care</li>
                                   <li>Inpatient care in a religious non-medical health care institution</li>
                                 </ul>
                               </div>}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Hospital costs</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$200-$500<br />copay per day</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>First 3-7 days</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Costs for a hospital stay beyond the first period covered by your plan.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Extended Hospital Stay</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'body2'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>
                  <strong>Days 7-90:</strong> $0/day<br />
                  <strong>Days 91+:</strong> Varies by plan
                </Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Some plans have <br />unlimited coverage<br />after 90 days</Typography>
              </Box>
            </Box>
          </Box>


          <div className={'h-10'} />
          <Box className={classes.greenLine}>
            <Typography className={'bold fs-16'} color={'textPrimary'}>Monthly premiums</Typography>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Most Medicare beneficiaries pay the standard Part B premium, regardless of the type of Medicare coverage they have. If your income is above a certain amount (based on your last tax return), you may pay an extra charge on top of the regular Part B premium.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medicare Part B premium</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$148.50</Typography>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Prescription Drug Plans (PDPs) are standalone prescription drug coverage plans offered by private insurance companies.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Prescription drug plan (PDP)</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>$0</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>Included in 89%<br />of plans</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.line}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Medigap plans are sold by private insurance companies and designed to help pay for the out-of-pocket health care costs (or ‘gaps’) that the Original Medicare does not cover.'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medicare Supplement (Medigap)</Typography>
              </Box>
            </Box>
            <Box className={'lineValue'}>
              <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered)}>n.a.</Typography>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.whiteLine)}>
            <Box display={'flex'}>
              <Box mr={1}>
                <AscendTooltip placement={'top'} arrow
                               title={'Medicare Advantage (Part C) is an “all-in-one” bundled alternative to Original Medicare, which often include prescription drug coverage and extra benefits (such as vision, dental and hearing).'}
                ><img src={'/img/info.svg'} /></AscendTooltip>
              </Box>
              <Box>
                <Typography variant={"body2"} color={'textPrimary'}>Medicare Advantage</Typography>
              </Box>
            </Box>
            <Box className={'lineValueWhite'}>
              <Box display={'flex'} flexDirection={'column'} flex={2} justifyContent={'center'} alignItems={'center'}>
                <Typography variant={'h4'} color={'textPrimary'} className={clsx(classes.centered, classes.medium)}>Typically $0</Typography>
                <Typography variant={'h5'} color={'textSecondary'} className={clsx(classes.centered)}>54% of plans have<br/>$0 premium</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.line, classes.totalLine)}>
            <div>
              <Typography className={'fs-16 bold'} color={'textPrimary'}>Total monthly premium</Typography>
            </div>
            <div>
              <Typography variant={'body2'} color={'textPrimary'} className={'medium text-align-centered'}>$148.50+</Typography>
            </div>
          </Box>
        </div>
        <Box mt={'20px'}>
          <AscendButton tabIndex={4} className={'gtm-step-event fill-width'}  variant={'contained'} onClick={() => onSubmit('MA')}>Get Medicare Advantage quotes</AscendButton>
        </Box>
      </Collapse>
    </Box>
    {/*End Medicare Advantage*/}

    <Box mt={'11px'}>
      <AscendButton tabIndex={5} variant={'contained'} className={'fill-width'} onClick={onBackClick} color={'secondary'}>Back</AscendButton>
    </Box>
  </>
}



type TableLineProps = {
  title: string | JSX.Element,
  subtitle?: string,
  tooltip?: string | JSX.Element,
  value1: boolean | JSX.Element,
  value2: boolean | JSX.Element,
  value3: boolean | JSX.Element,
  classes: any,
  className?: string,
  opaque?: boolean
}

const TableLine = withStyles({
  wrapper: {
    display: 'flex',
    padding: '16px 8px 16px 30px'
  },
  tooltip: {
    marginRight: 8
  },
  subTitle: {
    fontSize: 12,
    lineHeight: '15px',
  },
  transparent: {
    opacity: 0.6,
  }
})((props: TableLineProps) => <Box className={clsx(props.classes.wrapper, props.className)}>
  <Box display={'flex'} flex={3} pt={'3px'} alignItems={'center'}>
    {props.tooltip && <AscendTooltip placement={'top'} arrow title={props.tooltip} className={props.classes.tooltip}><img src={'/img/info.svg'} /></AscendTooltip>}
    <Box>
      <Typography variant={"body2"} color={'textPrimary'}>{props.title}</Typography>
      {props.subtitle && <Typography className={props.classes.subTitle} color={'textSecondary'}>{props.subtitle}</Typography>}
    </Box>
  </Box>
  <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'} className={clsx({[props.classes.transparent]: !props.opaque})}>
    {typeof props.value1 === 'boolean' ? <DarkIcon value={props.value1} /> : <>{props.value1}</>}
  </Box>
  <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'}>
    {typeof props.value2 === 'boolean' ? <Icon value={props.value2} /> : <>{props.value2}</>}
  </Box>
  <Box display={'flex'} flex={2} justifyContent={'center'} alignItems={'center'}>
    {typeof props.value3 === 'boolean' ? <Icon value={props.value3} /> : <>{props.value3}</>}
  </Box>
</Box>)

const DarkIcon = ({value}: {value: boolean}) => value ? <img src={'/img/dark-check.svg'}/> : <img src={'/img/dark-close.svg'}/>
const Icon = ({value}: {value: boolean}) => value ? <img src={'/img/green-check.svg'}/> : <img src={'/img/red-close.svg'}/>
const GreyIcon = ({value}: {value: boolean}) => value ? <img src={'/img/grey-check.svg'}/> : <img src={'/img/grey-close.svg'}/>
const HeresWhyIcon = ({value}: {value: boolean}) => value ? <img src={'/img/green-big-check.svg'}/> : <img src={'/img/gray-big-check.svg'}/>

export default withTitle(
  Quiz3,
  (state) => {
    switch (calculateSelected(state)) {
      case PlanType.SNP: return `It looks like a Medicare Advantage Dual-Special Needs Plan \n could be the best option for you.`;
      case PlanType.HMO:
      case PlanType.PPO: return `It looks like a Medicare Advantage plan could be\nthe best option for you.`;
      default: return 'It looks like a Medicare Supplement (“Medigap”)\n plan is the right option for you.';
    }
  },
  '',
  undefined,
  undefined,
  undefined,
  835
)

const getDataFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('prepay') &&
    urlParams.has('monthlyBudget') &&
    urlParams.has('certainDoctors') &&
    urlParams.has('drugsCoverage') &&
    urlParams.has('extraBenefits') &&
    urlParams.has('receiveMedicaid')
  ) {
    return {
      prepay: urlParams.get('prepay') === 'true',
      monthlyBudget: urlParams.get('monthlyBudget') === 'true',
      certainDoctors: urlParams.get('certainDoctors') === 'true',
      drugsCoverage: urlParams.get('drugsCoverage') === 'true',
      extraBenefits: urlParams.get('extraBenefits') === 'true',
      receiveMedicaid: urlParams.get('receiveMedicaid') === 'true',
    }
  }
  return undefined;
}

const getMask = (state: IQuoteState) => [state?.QUIZ_2?.prepay.value, state?.QUIZ_2?.monthlyBudget.value, state?.QUIZ_2?.certainDoctors.value, state?.QUIZ_2?.extraBenefits.value].map(v => v ? 1 : 0).join('');

const values = [
  ['1111', '1110', '1100'],
  ['1101', '1011', '1010', '0010', '0011', '0111', '0110'],
  ['1000', '1001', '0000', '0001', '0100', '0101']
];

const calculateSelected = (state: IQuoteState): PlanType => {
  if (!state?.QUIZ_2?.receiveMedicaid.value) {
    const mask = getMask(state);
    for (const index in values) {
      if (values[index].some(v => v === mask)) {
        return parseFloat(index) as PlanType;
      }
    }
  }
  return PlanType.SNP;
}

const getMonthlyPremiumMessage = (type: number, plan: string, value: boolean) => {
  switch (type) {
    case 0: return {
      value,
      title: "You prefer to pay a monthly premium for lower out-of-pocket costs",
      subtitle: "With a Medigap plan, you will pay a fixed monthly premium and typically have minimal to no out-of-pocket costs when you use healthcare services."
    };
    case 1: return {
      value,
      title: "You prefer to pay a monthly premium for lower out-of-pocket costs",
      subtitle: `With a Medicare Advantage plan, you can pay a higher premium to lower your maximum annual out-of-pocket limit.`
    };
    default: return {
      value,
      title: "You prefer to pay out-of-pocket costs as you go, with low or no monthly premiums ",
      subtitle: `Medicare Advantage plans are typically pay-as-you-go, with fixed copays or coinsurances each time you use healthcare services and low or no monthly premiums.`
    }
  }
}

const getMonthlyBudgetMessage = (type: number, plan: string, value: boolean) => {
  switch (type) {
    case 0: return {
      value,
      title: "Your monthly premium budget is more than $100",
      subtitle: "Some comprehensive Medigap plans have premiums of over $100, depending on where you live, your age, gender, and whether you use tobacco products."
    };
    case 1: return {
      value,
      title: "Your monthly premium budget is less than $100.",
      subtitle: `You’ll typically pay little to no monthly premiums under a Medicare Advantage plan.`
    };
    case 2: return {
      value,
      title: "Your monthly premium budget is more than $100.",
      subtitle: `You’ll typically pay little to no additional monthly premiums under a Medicare Advantage plan`
    };
    default: return {
      value,
      title: "Your monthly premium budget is more than $100.",
      subtitle: `You’ll typically pay low to no additional monthly premiums under a Medicare Advantage plan.`
    }
  }
}

const getDoctorsMessage = (type: number, plan: string, value: boolean) => {
  switch (type) {
    case 0: return {
      value,
      title: "You want the flexibility to see doctors outside of a network",
      subtitle: "Under Original Medicare and a Medigap plan, you have the flexibility to see any doctor or visit any hospital in the U.S. that accepts Medicare."
    };
    case 1: return {
      value,
      title: "You want the flexibility to see doctors outside of a network",
      subtitle: `Medicare Advantage PPO plans allows you to see doctors out of the plan's standard network with no requirement for referrals or a dedicated primary care physician (PCP).`
    };
    case 2: return {
      value,
      title: "Under a Medigap plan, you will have the flexibility to see any doctor or visit any hospital that accepts Medicare",
      subtitle: `If seeing doctors or visiting hospitals within a network is more important to you, you could consider a Medicare Advantage plan.`
    };
    case 3: return {
      value,
      title: 'You’re okay with using a network of healthcare providers if it lowers costs',
      subtitle: `Medicare Advantage HMO plans use a network of doctors and providers to help beneficiaries manage their healthcare and keep costs down.`
    };
    default: return {
      value,
      title: 'You want the flexibility to see any doctor and visit any hospital',
      subtitle: `While Medicare Advantage ${plan} plans typically have a network of doctors and hospitals, you can see providers out of the plan’s network, usually for a higher cost.`
    }
  }
}

const getBenefitsMessage = (type: number, plan: string, value: boolean) => {
  switch (type) {
    case 0: return {
      value,
      title: 'You\'re looking for a plan with extra benefits (such as dental, vision or hearing)',
      subtitle: "A Medigap plan typically won't cover extra benefits. If these features are important to you, you can purchase standalone coverage or consider a Medicare Advantage plan instead."
    };
    case 1: return {
      value,
      title: 'You\'re indifferent as to whether your plan includes extra benefits (such as dental, vision or hearing)',
      subtitle: `Medigap plans don’t typically include any additional benefits. You can purchase this coverage separately if required.`
    };
    case 2: return {
      value,
      title: 'You\'re looking for a plan with extra benefits (such as dental, vision or hearing)',
      subtitle: `Many Medicare Advantage ${plan} plans offer additional benefits for no extra premium, like dental, vision and hearing services.`
    };
    default: return {
      value,
      title: 'You\'re indifferent as to whether your plan includes extra benefits (such as dental, vision or hearing)',
      subtitle: `Most Medicare Advantage plans offer extra benefits such as dental, vision and hearing coverage for no additional premium.`
    }
  }
}

const getDrugsMessage = (type: number, plan: string, value: boolean) => {
  switch (type) {
    case 0: return {
      value,
      title: 'You want prescription drug coverage',
      subtitle: "You can purchase a separate Prescription Drug Plan (PDP) alongside your Medigap plan."
    };
    case 1: return {
      value,
      title: 'You don’t need prescription drug coverage.',
      subtitle: `Medigap plans won't cover prescriptions drugs. You can buy a separate Prescription Drug Plan if you ever need drug coverage.`
    };
    case 2: return {
      value,
      title: 'You want prescription drug coverage',
      subtitle: `Medicare Advantage plans often include prescription drug coverage for no extra premium.`
    };
    default: return {
      value,
      title: 'You don’t need prescription drug coverage.',
      subtitle: `Great news!  Many Medicare Advantage plans will include prescription drug coverage at no extra premium so you have if you ever need it.`
    }
  }
}

const getHeresWhy = _.memoize((state: IQuoteState): Message[] => {
  if (!state?.QUIZ_2?.receiveMedicaid.value) {
    const mask = getMask(state);
    const plans = ['Medigap', 'PPO', 'HMO', 'SNP'];

    switch (mask) {
      case '1111':
        return [
          getMonthlyPremiumMessage(0, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(0, plans[calculateSelected(state)], true),
          getDoctorsMessage(0, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(0, plans[calculateSelected(state)], true) :
            getDrugsMessage(1, plans[calculateSelected(state)], true),
          getBenefitsMessage(0, plans[calculateSelected(state)], false),
        ];
      case '1110':
        return [
          getMonthlyPremiumMessage(0, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(0, plans[calculateSelected(state)], true),
          getDoctorsMessage(0, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(0, plans[calculateSelected(state)], true) :
            getDrugsMessage(1, plans[calculateSelected(state)], true),
          getBenefitsMessage(1, plans[calculateSelected(state)], true),
        ];
      case '1101':
        return [
          getMonthlyPremiumMessage(1, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(2, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '1100':
        return [
          getMonthlyPremiumMessage(0, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(0, plans[calculateSelected(state)], true),
          getDoctorsMessage(2, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(0, plans[calculateSelected(state)], true) :
            getDrugsMessage(1, plans[calculateSelected(state)], true),
          getBenefitsMessage(1, plans[calculateSelected(state)], true),
        ];
      case '1000':
        return [
          getMonthlyPremiumMessage(1, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(3, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(3, plans[calculateSelected(state)], true),
        ];
      case '1011':
        return [
          getMonthlyPremiumMessage(1, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '1010':
        return [
          getMonthlyPremiumMessage(1, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(3, plans[calculateSelected(state)], true),
        ];
      case '1001':
        return [
          getMonthlyPremiumMessage(1, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(3, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '0000':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(3, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(3, plans[calculateSelected(state)], true),
        ];
      case '0001':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(3, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '0010':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(3, plans[calculateSelected(state)], true),
        ];
      case '0011':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(1, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '0111':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(3, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '0100':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(3, plans[calculateSelected(state)], true),
          getDoctorsMessage(3, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(3, plans[calculateSelected(state)], true),
        ];
      case '0101':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(3, plans[calculateSelected(state)], true),
          getDoctorsMessage(3, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(2, plans[calculateSelected(state)], true),
        ];
      case '0110':
        return [
          getMonthlyPremiumMessage(2, plans[calculateSelected(state)], true),
          getMonthlyBudgetMessage(3, plans[calculateSelected(state)], true),
          getDoctorsMessage(1, plans[calculateSelected(state)], true),
          state?.QUIZ_2?.drugsCoverage.value ?
            getDrugsMessage(2, plans[calculateSelected(state)], true) :
            getDrugsMessage(3, plans[calculateSelected(state)], true),
          getBenefitsMessage(3, plans[calculateSelected(state)], true),
        ];
      default:
        return [];
    }
  } else {
    return [{
      value: true,
      title: 'You\'re receiving Medicaid, which makes you eligible for a Dual-Special Needs Plan (D-SNP)',
      subtitle: `Since you're receiving Medicaid, you're eligible to enroll in a special type of Medicare Advantage plan called a Dual-Special Needs Plan (D-SNP). These plans are specifically designed to deliver integrated care for people who are on both Medicaid and Medicare. While you are receiving Medicaid, most of the costs of joining a Medicare SNP will be covered for you.`
    }]
  }
});

interface Message {
  value: boolean;
  title?: string;
  subtitle?: string;
}

enum PlanType {
  MG,
  PPO,
  HMO,
  SNP
}
