import React from "react";
import {createStyles, IconButton, Slide, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import AscendButton from "../../shared/components/AscendButton";
import {useHistory} from "react-router";
import {ComparisonContext} from "./ComparisonContext";
import ComparisonCard from "./components/ComparisonCard";
import usePlansByBid from "../DetailedPlan/hooks/usePlansByBid";
import {getQuoteId} from "../../shared/QuoteId";
import paths from "../config/router-paths";

type ComparisonPanelProps = {
  zip: string,
  countyName: string,
  forceHide?: boolean
}
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#F5F7F6',
      boxShadow: '0px -10px 30px rgba(0, 0, 0, 0.1)',
    }
  }),
);

export default function ComparisonPanel(props: ComparisonPanelProps) {
  const classes = styles();
  const history = useHistory();
  const {list} = React.useContext(ComparisonContext);
  const plans = usePlansByBid(props.zip, props.countyName, list, getQuoteId());

  const open = React.useMemo(() => {
    return !!list.length && !props.forceHide;
  }, [list])

  return <Slide in={open} direction={'up'} style={{position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1200}}>
    <div className={classes.container}>
      {[0,1,2].map(index => <ComparisonCard key={index} plan={plans[index]} />)}
      <AscendButton variant={'contained'} onClick={() => history.push(paths.comparison + props.zip + '/' + props.countyName)}>Compare</AscendButton>
    </div>
  </Slide>
}
