import React from 'react';
import {MutationHookOptions, useMutation} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {MutationSendMedicareTypeCompareEmailArgs} from "../../types";

export default function useSendMedicareTypeCompareEmail(options?: MutationHookOptions<string, MutationSendMedicareTypeCompareEmailArgs>) {
  return useMutation<string, MutationSendMedicareTypeCompareEmailArgs>(
    gql(request),
    {
      ...options
    }
  );
}


const request = `
mutation ($email: String!, $input: MedicareTypeCompareInput!) {
  sendMedicareTypeCompareEmail(email: $email, input: $input)
}
`;
