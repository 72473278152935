import React from "react";
import useDoctorsByRequestId from "./hooks/useDoctorsByRequestId";
import useDrugsByRequestId from "./hooks/useDrugsByRequestId";
import {Box, createStyles, Grid, RadioGroup, Theme, Typography, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../shared/AppTheme";
import AscendButton from "../../shared/components/AscendButton";
import {DoctorSelection} from "../../shared/components/DoctorSelection";
import {DrugSelection} from "../../shared/components/DrugSelection";
import {OrderType, PharmaciesSelection} from "../../shared/components/PharmaciesSelection";
import usePharmaciesByRequestId from "./hooks/usePharmaciesByRequestId";
import {useHistory, useParams} from "react-router-dom";
import useSaveRequest from "../../Questionnaire/hooks/useSaveRequest";
import {getQuoteId, setQuoteId} from "../../shared/QuoteId";
import PlanListSideContent from "./PlanListSideContent";
import Layout from "../../shared/Layout";
import paths from "../config/router-paths";
import useGetCounty from "../../Questionnaire/hooks/useGetCounty";
import SelectCountyModal from "./components/SelectCountyModal";
import {DrugDeliveryTypeDto, DrugInput, DrugOutput, FormName} from "../../types";
import {QuoteContext} from "./QuoteContext";
import LoginWidget from "./LoginWidget";
import {AdminContext} from "./AdminContext";
import AscendInfo from "../../shared/components/AscendInfo";
import {Step1Answers} from "../../Questionnaire/questions/Step1";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: 40,
      marginTop: 32,
      maxWidth: 1000,
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    printButton: {
      width: 'auto',
      padding: '14px 73px',
    },
    medium: {
      fontWeight: 500,
    },
  }),
);

const EditPreferences = () => {
  const classes = useStyles();
  const params = useParams<{zip: string, countyName: string}>();
  const history = useHistory();
  const [savePreferences] = useSaveRequest();
  const quote = React.useContext(QuoteContext);

  const adminContext = React.useContext(AdminContext);

  const {doctors, setDoctors} = useDoctorsByRequestId(quote.id);
  const [showDoctorsAutocomplete, setShowDoctorsAutocomplete] = React.useState(false); // todo replace this in component

  const {drugs, setDrugs} = useDrugsByRequestId(quote.id);
  const [showDrugsAutocomplete, setShowDrugsAutocomplete] = React.useState(false); // todo replace this in component

  const [preferredPharmaciesFilled, setPreferredPharmaciesFilled] = React.useState(false); // todo replace this in component

  const {pharmacies, setPharmacies, filled} = usePharmaciesByRequestId(quote.id);
  const [orderType, setOrderType] = React.useState<OrderType>();

  const save = () => {
    savePreferences({
      variables: {
        data: {
          preferredDoctors: doctors.map(d => ({
            npi: d.npi,
            addressId: d.address.id
          })),
          preferredDrugs: drugs.map(convertPreferredDrugInput),
          preferredPharmacies: orderType === OrderType.RetailPharmacy ? pharmacies?.map(p => p.npi) : [],
          drugDeliveryType: orderType === OrderType.RetailPharmacy ? DrugDeliveryTypeDto.Pharmacy : DrugDeliveryTypeDto.Mail,
          preferredPharmaciesFilled: preferredPharmaciesFilled,
          county: params.countyName,
          zip: params.zip,
          form: 'SEARCH_RESULTS',
          id: getQuoteId()
        }
      },
    }).then((data: any) => setQuoteId(data.data.saveMedicareQuote)).finally(goToPlans)
  }

  const goToPlans = () => {
    document.location.href = document.location.origin + paths.plans + params.zip + '/' + params.countyName;
  }

  return <Layout sideHeaderContent={<LoginWidget/>} SideComponent={PlanListSideContent}>
      <Grid container alignItems={"center"} justify={"center"}>
        <Grid md={10} xs={12} item>
          <Box display={'flex'} justifyContent={'center'}>
            <Box maxWidth={656}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={'36px'}>
                <Typography className={classes.medium} variant={'h1'} color={'textPrimary'}>You can edit and/or update your doctor, drug and pharmacy preferences below.</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} mt={'16px'}>
                <AscendButton variant={'contained'} onClick={() => {
                  history.goBack();
                }} color={'secondary'}>Back</AscendButton>
                <AscendButton variant={'contained'} onClick={save}>Update and return to plans</AscendButton>
              </Box>
              <Box mt={'24px'}>
                <Title title={'Your doctors'} />
                <AscendInfo open={true}
                            description={<><strong>Doctor networks change all the time.</strong> We update our information regularly, but changes sometimes happen faster than insurers can update us.  We will aim to make sure to double check your doctors are covered before we enroll you.</>}/>
                <div className={'h-16'} />
                <DoctorSelection selectedDoctors={doctors}
                                 zip={params.zip}
                                 setSelectedDoctors={setDoctors}
                                 showAutocomplete={showDoctorsAutocomplete}
                                 setShowAutocomplete={setShowDoctorsAutocomplete}/>
              </Box>
              <Box mt={'32px'}>
                <Title title={'Your prescription drugs'} />
                <AscendInfo open={true}
                            description={<><strong>We’ll help you save.</strong> If you add a branded drug, we’ll suggest a generic alternative and give you the option to choose it instead.</>}/>
                <div className={'h-16'} />
                <DrugSelection selectedPrescriptions={drugs}
                               setSelectedPrescriptions={setDrugs}
                               showAutocomplete={showDrugsAutocomplete}
                               setShowAutocomplete={setShowDrugsAutocomplete}
                />
              </Box>
              <Box mt={'32px'}>
                <Title title={'Your pharmacies'} />
                <AscendInfo open={true}
                            description={<><strong>Choosing a pharmacy helps us more accurately estimate drug costs.</strong> We’ll find your drug cost-shares depending on your pharmacy type selection.</>}/>
                <div className={'h-16'} />
                <PharmaciesSelection zip={params.zip}
                                     selectedPharmacies={pharmacies}
                                     filled={filled}
                                     onOrderTypeChange={value => {
                                       setPreferredPharmaciesFilled(true)
                                       setOrderType(value)
                                     }}
                                     setSelectedPharmacies={setPharmacies} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
}

export default EditPreferences;

const Title = withStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: colors.text.primary,
    fontSize: 24,
    fontWeight: 700,
  }
})(({title, classes}: any) => {
  return <div className={classes.container}>
    <Typography className={classes.title}>{title}</Typography>
  </div>
})

export function convertPreferredDrugInput(drugInfo: DrugOutput): DrugInput {

  return {
    dosageRxcui: drugInfo.packRxcui,
    productRxcui: drugInfo.rxcui,
    quantity: drugInfo.quantity,
    frequency: drugInfo.frequency,
  }
}
