const paths = {
  home: '/',
  enroll: '/enroll',
  personalDetails: '/personal-details',
  receive: '/receive',
  soa: '/soa/',
  createAccount: '/create-account',
};

export default paths;
