import {useLazyQuery} from "@apollo/react-hooks";
import {FilterItem, QueryPlanTypesArgs} from "../../../types";
import {gql} from "apollo-boost";

export function usePlanFilterData() {

  return useLazyQuery<IPlanFilterResponse, QueryPlanTypesArgs>(gql(planFilterQuery))
}

export interface IPlanFilterResponse {
  planTypes: FilterItem[],
  companies: FilterItem[],
  SNPTypes: FilterItem[],
  extraBenefits: FilterItem[],
  maxOutOfPocketRanges: FilterItem[],
  maxCostRanges: FilterItem[],
}

const planFilterQuery = `
query($filter: PlansFilterInput!) {
    planTypes(filter: $filter) {
      key
      name
      count
    }
    companies(filter: $filter) {
      key
      name
      count
    }
    extraBenefits(filter: $filter) {
      key
      name
      count
    }
    SNPTypes(filter: $filter) {
      key
      name
      count
    }
    maxOutOfPocketRanges(filter: $filter) {
      key
      name
      count
    }
    maxCostRanges(filter: $filter) {
      key
      name
      count
    }
}
`;
