import React from "react";
import {Box, createStyles, RadioGroup, Theme, Typography, withStyles} from "@material-ui/core";
import AscendButton from "../../shared/components/AscendButton";
import AscendRadio from "../../shared/components/AscendRadio";
import {makeStyles} from "@material-ui/core/styles";
import useCommonStyles from "../../shared/useCommonStyles";
import clsx from 'clsx';
import {RouteComponentProps} from "react-router";
import paths from "../config/router-paths";
import {withTitle} from "../../shared/components/withTitle";
import AscendInfo from "../../shared/components/AscendInfo";
import {questionsRoutes} from "../hooks/useStepperState";
import {QuoteActionTypes, QuoteContext} from "../QuoteContextProvider";
import {colors} from "../../shared/AppTheme";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: 40,
      maxWidth: 320,
    },
  })
);
const formName = 'STEP_5';

function Step5(props: RouteComponentProps<any>) {
  const classes = useStyles();
  const [state, dispatch] = React.useContext(QuoteContext);

  React.useEffect(() => {
    (window as any).gtm_step_name = formName;
  }, [])

  const submit = (value: boolean) => {
    dispatch({
      type: QuoteActionTypes.SAVE_STEP_VALUE,
      payload: {stateKey: formName, value: {
          questionText: 'Are you already retired or planning on retiring soon?',
          answerText: value ? 'Yes' : 'No',
          value
        }, form: formName}
    })
    props.history.push(paths.questionnaire + '/' + questionsRoutes.step6)
  }

  return <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <AscendInfo open={true}
                description={`This can helps us assess whether you have other coverage that works with Medicare, or if you need a new plan.`}/>
    <Box className={classes.container}>
      <GreenButton variant={'contained'} color={'primary'} onClick={() => submit(true)}>Yes</GreenButton>
      <div className={'h-20'} />
      <GreenButton variant={'contained'} color={'primary'} onClick={() => submit(false)}>No</GreenButton>
    </Box>
    <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={'40px'} maxWidth={320}>
      <AscendButton variant={'contained'} onClick={() => {
        props.history.goBack();
      }} color={'secondary'}>Back</AscendButton>
    </Box>
  </Box>
}

export default withTitle(
  Step5,
  'Are you already retired or planning on retiring soon?',
  ``,
  undefined,
  undefined,
  isMobile => isMobile,
)


const GreenButton = withStyles({
  contained: {
    borderRadius: 4,
    width: '100%',
    maxWidth: 320,
    height: 64,
    background: colors.custom.green.variant2,
    '&:hover': {
      backgroundColor: colors.custom.green.variant3,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: colors.custom.green.variant3,
      boxShadow: 'none',
    },
  },
  label: {
    whiteSpace: 'nowrap',
    color: 'white',
    fontSize: 24,
    lineHeight: '30px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(AscendButton)
