import React from 'react';
import FormTextInput from "./FormTextInput";
import {Box, Collapse, createStyles, Theme, Typography} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import AscendButton from "./AscendButton";
import clsx from 'clsx';
import useCommonStyles from "../useCommonStyles";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../AppTheme";
import PhoneNumberInput from "./PhoneNumberInput";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingRight: 0
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500,
    },
    wrapper: {
      minWidth: 500,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    }
  }),
);

interface FormState {
  email: string;
  firstName: string;
  phoneNumber: string;
}
const Schema = yup.object().shape({
  firstName: yup.string().required("Please enter a valid name").typeError('Please enter a valid name'),
  email: yup.string().email('Enter a valid email address').required("Enter a valid email address"),
  phoneNumber: yup.string().required("Please enter a valid phone").min(10, "Please enter a valid phone"),
});

type CreateAccountFormProps = {
  onSubmit:(values: FormState) => void,
  onCancelClick:() => void,
  submitButtonLabel: string,
  emailError?: string,
  backButtonLabel: string,
  showSkip?: boolean,
  showSignIn?: boolean,
  onSkipClick?:() => void,
  onSignInClick?:() => void,
  buttonWrapperClassName?: string,
  continuous?: boolean
}

export default function CreateAccountForm(props: CreateAccountFormProps) {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  const {register, handleSubmit, errors, watch, formState, getValues, control} = useForm<FormState>({
    resolver: yupResolver(Schema),
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: 'onChange',
  });
  watch()

  return <form onSubmit={handleSubmit(props.onSubmit)} autoComplete={'off'} className={'t-base-info-form'}>
    <Box flex={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Box className={classes.wrapper}>
        <FormTextInput fullWidth
                       label={'Name'}
                       name={'firstName'}
                       autoFocus={true}
                       error={formState.touched.firstName && !!errors.firstName}
                       helperText={formState.touched.firstName && errors?.firstName?.message}
                       inputRef={register}
                       placeholder={'Enter your first name'} />
        <Collapse in={!props.continuous || !!getValues().firstName}>
          <FormTextInput fullWidth
                         label={'Email address'}
                         name={'email'}
                         inputProps={{
                           autoCapitalize: 'none'
                         }}
                         placeholder={'Enter your email address'}
                         error={(!!errors.email && formState.touched.email) || !!props.emailError}
                         helperText={(formState.touched.email && errors?.email?.message) || props.emailError}
                         inputRef={register} />
        </Collapse>
        <Collapse in={!props.continuous || !!getValues().email}>
          <Controller
            control={control}
            name={'phoneNumber'}
            render={({ onChange }) => <PhoneNumberInput label={'Phone number'} name={'phoneNumber'} onChange={onChange} />}
          />
        </Collapse>
        <Box display={'flex'} alignItems={'center'} className={'mb-24'}>
          <img src={'/img/padlock.svg'} />
          <Typography className={'bold fs-14 ml-8'} color={'textPrimary'}>100% secure. We’ll never sell your details.</Typography>
        </Box>
        {props.showSignIn && <Box display={'flex'} alignItems={'center'} className={'mb-24'}>
          <Typography className={'medium fs-18'} color={'textPrimary'}>Already have an account? <span className={`fs-18 ${classes.link}`} onClick={props.onSignInClick}>Sign in</span></Typography>
        </Box>}
      </Box>
    </Box>
    <Box className={clsx(commonClasses.buttonWrapper, props.buttonWrapperClassName)} mt={'20px'}>
      <AscendButton tabIndex={5} variant={'contained'} onClick={() => {
        props.onCancelClick();
      }} color={'secondary'}>{props.backButtonLabel}</AscendButton>
      <div className={'h-16'} />
      <AscendButton tabIndex={4} disabled={!formState.isValid}
                    className={'gtm-step-event'} variant={'contained'} type={'submit'}>{props.submitButtonLabel}</AscendButton>
    </Box>
    {props.showSkip && <Box display={'flex'} mt={'16px'} justifyContent={'center'}>
      <Typography variant={'h4'} className={classes.link} onClick={() => {
        props.onSkipClick && props.onSkipClick();

      }}>Click here to skip this step</Typography>
    </Box>}
    <Box display={'flex'} mt={'32px'} justifyContent={'center'}>
      <Typography color={'textSecondary'} className={'fs-13'}>By submitting my email and phone number and clicking ’Continue’ above, I confirm that the details provided are my email and mobile number, and I provide my express written consent to be contacted by Ascend including its subsidiaries and/or affiliates at the email or telephone number I have provided above regarding various Medicare products including Medicare Advantage Plans, Medicare Supplement Plans, Prescription Drug Plans, and other marketing and informational content, through the use of email, text message and/or live, automated and pre-recorded telephone calls, even if my number is on a Do Not Call list.  I further understand and agree that my communications with Ascend are subject to Ascend's Privacy Policy and Terms of Use.<br /><br />

        I understand my consents to receive text messages and telephone calls are not required to receive services from Ascend; I may instead reach Ascend directly at 1-XXX-XXX-XXX and I can revoke my consents at any time by emailing privacy@ascendmedicare.com.
      </Typography>
    </Box>
  </form>
}
