import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import clsx from 'clsx';
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps
} from "@material-ui/core";
import Check from '@material-ui/icons/Check';
import AscendTooltip from "./AscendTooltip";
import {colors} from "../AppTheme";

const useStyles = makeStyles({
  root: {
    border: (props: AscendCheckboxProps) => props.variant === 'outlined' ? '1px solid #A4B4B9' : undefined,
    pointerEvents: (props: AscendCheckboxProps) => props.readOnly ? 'none' : undefined,
    backgroundColor: 'white',
    padding: '9px 18px 9px 6px',
    display: 'flex',
    flex: 1,
    borderRadius: 4,
    marginRight: 0,
    marginLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  label: {
    fontSize: 16,
    color: colors.text.primary,
    marginLeft: 3,
    width: '100%',
  },
  checkedRoot: {
    borderColor: (props: AscendCheckboxProps) => props.variant === 'outlined' ? `${colors.custom.green.variant1}!important` : undefined,
    backgroundColor: (props: AscendCheckboxProps) => props.variant === 'outlined' ? 'rgba(94, 203, 158, 0.1)!important' : undefined
  },
  icon: {
    borderRadius: 4,
    width: 22,
    height: 22,
    backgroundColor: 'white',
    border: '1px solid #A4B4B9',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      border: '1px solid #5ECB9E'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    width: 22,
    height: 22,
    backgroundColor: colors.custom.green.variant1,
    borderRadius: 4,
    color: 'white',
    padding: 3,
  },
  subLabel: {
    color: colors.text.secondary,
    fontSize: 14,
    lineHeight: '15px',
  }
});

type AscendCheckboxProps = CheckboxProps & {
  variant: 'outlined' | 'standart',
  label: React.ReactNode,
  subLabel?: React.ReactNode,
  endAdornment?: React.ReactNode,
  labelClassName?: string,
  controlClassName?: string,
  iconClassName?: string,
  tooltip?: string
}

export default function AscendCheckbox(props: AscendCheckboxProps) {
  const classes = useStyles(props);
  const {labelClassName, controlClassName, iconClassName, subLabel, tooltip, endAdornment, ...rest} = props;

  return (
    <FormControlLabel className={clsx({[classes.root]: true, [classes.checkedRoot]: props.checked, [controlClassName || ""]: !!controlClassName})}
                      control={<Checkbox
                          color="default"
                          checkedIcon={props.checked ? <Check className={classes.checkedIcon} /> : <span className={clsx(classes.icon, iconClassName)} />}
                          icon={<span className={clsx(classes.icon, iconClassName)} />}
                          {...rest}
                      />}
                      classes={{label: clsx(classes.label, labelClassName)}}
                      label={<Box display={'flex'} flex={1} justifyContent={'space-between'} alignItems={'center'}>
                        <Box mr={'12px'}>
                          {props.label}
                          {subLabel && <Box className={classes.subLabel}>{subLabel}</Box>}
                        </Box>
                        <Box display={'flex'} alignItems={'center'}>
                          {endAdornment && <>{endAdornment}</>}
                          {tooltip && <AscendTooltip placement={'top'} arrow title={tooltip}>
                            <img width={16} height={16} src={'/img/info.svg'}></img>
                          </AscendTooltip>}
                        </Box>
                      </Box>}
    />

  );
}
